import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Button } from "reactstrap";
import { baseURL } from "../Axios";
import icon from "../assets/img/placeholder.png";

const Usercard = ({
  id,
  profileId,
  image,
  name,
  age,
  gender,
  dob,
  state,
  district,
  status,
  email,
  countryCode,
  phone,
  deletedReason,
  onDelete,
  onActive,
  errorButtonText = "Inactive",
}) => {
  const calculateAge = (dob) => {
    const birthDate = moment(dob, ["DD-MM-YYYY", "YYYY-MM-DD"]);
    if (!birthDate.isValid()) {
      return "Invalid date";
    }
    const today = moment();
    const years = today.diff(birthDate, "years");
    birthDate.add(years, "years");
    const months = today.diff(birthDate, "months");
    return { years, months };
  };
  const { years, months } = calculateAge(dob);

  return (
    <Col xs={12} md={6} lg={4} sm={12}>
      <div
        className="card"
        style={{
          width: "100%",
          padding: "20px 20px",
        }}
      >
        {" "}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {moment(dob).format("DD-MM-YYYY")} : {years} years & {months} months
        </div>
        <Container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            {image ? (
              <img
                src={baseURL + image}
                style={{ width: "150px", height: "150px" }}
                alt="..."
              />
            ) : (
              <img
                src={icon}
                style={{ width: "150px", height: "150px" }}
                alt="..."
              />
            )}
          </div>
          <div className="card-body">
            {/* <h5 className="card-title" style={{ color: "#eb3143" }}>
              {name}
            </h5> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h5 className="card-title">
                {name}

                <span
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#8bba40",
                    padding: "10px",
                  }}
                >
                  {profileId}
                </span>
              </h5>
            </div>

            <ul className="list">
              <li style={{ textTransform: "capitalize" }}>{gender}</li>
              <li>{email}</li>
              <li>
                {countryCode}

                <span> {phone}</span>
              </li>
              {/* <li style={{ textTransform: "capitalize" }}>{district}</li> */}
              {status === "terminated" ? (
                <li>{deletedReason} </li>
              ) : (
                <li style={{ textTransform: "capitalize" }}>{state}</li>
              )}
            </ul>
          </div>
        </Container>
        <div
          className="btns"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link
            to={"/dashboard1/" + id}
            className="btn"
            style={{
              fontSize: "14px",
              fontWeight: "500",
         border:"1px solid #000",
         
              padding: "0px 8px",
            }}
          >
            <i className="bi bi-eye-fill"></i> View
          </Link>
          {status === "terminated" ? null : (
            <Button
              className="btn"
              onClick={onDelete}
              style={{
                fontSize: "14px",
                fontWeight: "500",
      
                padding: "0px 8px",
              }}
            >
              Delete
            </Button>
          )}
          {status ? (
            status === "terminated" ? (
              <a
                href="#"
                className="btn"
                disabled
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  background: "gray",
                  color: "white",
                  padding: "0px 8px",
                  cursor: "not-allowed",
                }}
              >
                {status}
              </a>
            ) : (
              <a
                href="#"
                className="btn"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  background: status === "active" ? "green" : "#00008B",
                  color: "white",
                  padding: "0px 8px",
                  cursor: "pointer",
                }}
                onClick={onActive}
              >
                {status}
              </a>
            )
          ) : (
            false
          )}
        </div>
      </div>
    </Col>
  );
};

export default Usercard;
