import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import api from "../../Axios";
import Swal from "sweetalert2";

import "react-phone-input-2/lib/style.css";

const AddImage = () => {
  const history = useHistory();

  const [render, setRender] = useState(false);

  const [data, setData] = useState({
    images: null,
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
    image6: null,
  });
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.files[0] });
  };

  let { id } = useParams();
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value); // Append the specific key
    });

    const url = `/admin/manageUserProfile/addImages?id=${id}`;

    api.post(url, formData).then((res) => {
      setRender(!render);
      setData({
        images: null,
        image1: null,
        image2: null,
        image3: null,
        image4: null,
        image5: null,
        image6: null,
      }); // Reset data to initial state
      Swal.fire(res.data.data);
      history.push(`/addfamily/${id}`);
    });
  };

  // console.log(data);
  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Add Image</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <div className="content-body">
                  <Form onSubmit={handleSubmit} className="form">
                    {/* <!-- Email input --> */}
                    {/* <h3 id="reply-title" className="comment-reply-title">
                      Lets Create Your Profile{" "}
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href="#"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h3> */}
                    <label className="form-label" for="form2Example1">
                      <h5 style={{ paddingBottom: "5px" }}>Profile Image</h5>
                    </label>
                    <div
                      className="form-outline mb-4"
                      style={{ border: "solid 1px" }}
                    >
                      <input
                        type="file"
                        name="images"
                        onChange={handleChange}
                        required
                        id="form2Example1"
                        className="form-control"
                        placeholder="Profile Image"
                        accept=".jpg, .jpeg, .png"
                        style={{ padding: "10px" }}
                      />
                    </div>
                    <label className="form-label" for="form2Example1">
                      <h5 style={{ paddingBottom: "5px" }}>Multiple Images</h5>
                    </label>
                    <div
                      className="form-outline mb-4"
                      style={{ border: "solid 1px" }}
                    >
                      <input
                        type="file"
                        name="image1"
                        onChange={handleChange}
                        id="form2Example1"
                        className="form-control"
                        placeholder="image1"
                        accept=".jpg, .jpeg, .png"
                        style={{ padding: "10px" }}
                      />
                    </div>{" "}
                    <div
                      className="form-outline mb-4"
                      style={{ border: "solid 1px" }}
                    >
                      <input
                        type="file"
                        name="image2"
                        onChange={handleChange}
                        id="form2Example1"
                        className="form-control"
                        placeholder="image2"
                        accept=".jpg, .jpeg, .png"
                        style={{ padding: "10px" }}
                      />
                    </div>{" "}
                    <div
                      className="form-outline mb-4"
                      style={{ border: "solid 1px" }}
                    >
                      <input
                        type="file"
                        name="image3"
                        onChange={handleChange}
                        id="form2Example1"
                        className="form-control"
                        placeholder="image3"
                        accept=".jpg, .jpeg, .png"
                        style={{ padding: "10px" }}
                      />
                    </div>{" "}
                    <div
                      className="form-outline mb-4"
                      style={{ border: "solid 1px" }}
                    >
                      <input
                        type="file"
                        name="image4"
                        onChange={handleChange}
                        id="form2Example1"
                        className="form-control"
                        placeholder="image4"
                        accept=".jpg, .jpeg, .png"
                        style={{ padding: "10px" }}
                      />
                    </div>{" "}
                    <div
                      className="form-outline mb-4"
                      style={{ border: "solid 1px" }}
                    >
                      <input
                        type="file"
                        name="image5"
                        onChange={handleChange}
                        id="form2Example1"
                        accept=".jpg, .jpeg, .png"
                        className="form-control"
                        placeholder="image5"
                        style={{ padding: "10px" }}
                      />
                    </div>{" "}
                    <div
                      className="form-outline mb-4"
                      style={{ border: "solid 1px" }}
                    >
                      <input
                        type="file"
                        name="image6"
                        onChange={handleChange}
                        id="form2Example1"
                        accept=".jpg, .jpeg, .png"
                        className="form-control"
                        placeholder="image6"
                        style={{ padding: "10px" }}
                      />
                    </div>{" "}
                    <Button style={{ background: "black" }}>Submit</Button>
                  </Form>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddImage;
