import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import api from "../../Axios";
import Swal from "sweetalert2";

import "react-phone-input-2/lib/style.css";

const UpdateFamily = () => {
  const history = useHistory();

  const financialStatus = [
    "Rich",
    "Very Rich",
    " Upper Middle class",
    "Middle class",
    "Lower Middle class",
    "Affluent",
  ];
  const OccupationList = [
    "Govt employed",
    "private employed",
    "business man",
    "self employed",
    "Retired",
    "Not employed",
    "Passed Away",
  ];
  const familyList = ["Join Family", "Nuclear Family"];
  const familyValue = ["Orthodox", "Traditional", "Moderate", "Liberal"];

  const numberOfList = [0, 1, 2, 3, 4, 5];

  const [data, setData] = useState({
    fianacialStatus: "",
    familyType: "",
    familyValues: "",
    fatherOccupation: "",
    motherOccupation: "",
    noOfBrothers: "",
    noOfSisters: "",
    brothersMarried: "",
    sistersMarried: "",
    aboutFamily: "",
  });
  const [render, setRender] = useState(false);
  let { id } = useParams();
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  function handleSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();
    const fullData = { ...data, id: Number(id) };

    api.post("/admin/manageUserProfile/addFamily", fullData).then((res) => {
      setRender(!render);
      setData("");
      Swal.fire("Added!", "Profile has been Added.", "success");
    });
    history.goBack();
  }
  useEffect(() => {
    api.post("/admin/manageUserProfile/userFamily", { id: id }).then((res) => {
      if (res.data.data !== "Data not found") {
        setData(res.data.data);
      }
    });
  }, []);
  // console.log(data);
  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title" style={{fontWeight:"600"}}>Update Family Details</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <div className="content-body">
                  <Form onSubmit={handleSubmit} className="form p-3">
                    <Box sx={{ minWidth: 120 }}>
                      <FormGroup>
                        <Label for="" style={{fontWeight:"600"}}>Father Occuption</Label>
                        <Input
                          name="fatherOccupation"
                          value={data.fatherOccupation}
                          type="select"
                          id="fatherOccupation"
                          onChange={handleChange}
                          style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          <option value="" style={{fontWeight:"600"}}>Select Father Occupation</option>
                          {OccupationList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="" style={{fontWeight:"600"}}>Mother Occuption</Label>
                        <Input
                          name="motherOccupation"
                          value={data.motherOccupation}
                          type="select"
                          id="motherOccupation"
                          onChange={handleChange}
                          style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          <option value="">Select Mother Occupation</option>
                          {OccupationList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="fianacialStatus" style={{fontWeight:"600"}}>
                          Select your Finacial Status
                        </Label>
                        <Input
                          name="fianacialStatus"
                          value={data.fianacialStatus}
                          type="select"
                          id="fianacialStatus"
                          onChange={handleChange}
                          style={{ textTransform: "capitalize" }}
                        >
                          <option value="">Select financial status</option>
                          {financialStatus.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="familyType" style={{fontWeight:"600"}}>Select Family Type</Label>
                        <Input
                          name="familyType"
                          value={data.familyType}
                          type="select"
                          id="familyType"
                          onChange={handleChange}
                        >
                          <option value="">Select family type</option>

                          {familyList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>{" "}
                      <FormGroup>
                        <Label for="familyValues" style={{fontWeight:"600"}}>Select Family Value</Label>
                        <Input
                          name="familyValues"
                          value={data.familyValues}
                          type="select"
                          id="familyValues"
                          onChange={handleChange}
                        >
                          <option value="">Select family value</option>

                          {familyValue.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="noOfBrothers" style={{fontWeight:"600"}}>Number of Brothers</Label>
                        <Input
                          name="noOfBrothers"
                          value={data.noOfBrothers}
                          type="select"
                          id="noOfBrothers"
                          onChange={handleChange}
                        >
                          <option value="">Select No of Brothers</option>

                          {numberOfList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      {data.noOfBrothers > 0 ? (
                        <FormGroup tag="fieldset" style={{fontWeight:"600"}}>
                          <legend>Brothers Married</legend>
                          <FormGroup
                            style={{ marginLeft: "25px" }}
                            name="brothersMarried"
                            value={data.brothersMarried}
                            onChange={handleChange}
                          >
                            <Input
                              style={{ border: "1px solid black" }}
                              name="brothersMarried"
                              value={"1"}
                              type="radio"
                            />{" "}
                            <Label style={{ marginTop: "10px" }}>Yes</Label>
                            <br />
                            <Input
                              style={{ border: "1px solid black" }}
                              name="brothersMarried"
                              value={"0"}
                              type="radio"
                            />{" "}
                            <Label style={{ marginTop: "10px" }}>No</Label>
                          </FormGroup>
                        </FormGroup>
                      ) : null}
                      <FormGroup>
                        <Label for="noOfSisters" style={{fontWeight:"600"}}>Number of Sisters</Label>
                        <Input
                          name="noOfSisters"
                          value={data.noOfSisters}
                          type="select"
                          id="noOfSisters"
                          onChange={handleChange}
                        >
                          <option value="">Select No of Sisters</option>

                          {numberOfList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      {data.noOfSisters > 0 ? (
                        <FormGroup tag="fieldset" style={{fontWeight:"600"}}>
                          <legend>Sisters Married</legend>
                          <FormGroup
                            style={{ marginLeft: "25px" }}
                            name="sistersMarried"
                            value={data.sistersMarried}
                            onChange={handleChange}
                          >
                            <Input
                              style={{ border: "1px solid black" }}
                              name="sistersMarried"
                              value={"1"}
                              type="radio"
                            />{" "}
                            <Label style={{ marginTop: "10px" }}>Yes</Label>
                            <br />
                            <Input
                              style={{ border: "1px solid black" }}
                              name="sistersMarried"
                              value={"0"}
                              type="radio"
                            />{" "}
                            <Label style={{ marginTop: "10px" }}>No</Label>
                          </FormGroup>
                        </FormGroup>
                      ) : null}
                      <FormGroup>
                        <Label for=""  style={{fontWeight:"600"}}>About Family</Label>
                        <Input
                          name="aboutFamily"
                          value={data.aboutFamily}
                          placeholder="About Family"
                          type="textarea"
                          id="aboutFamily"
                          onChange={handleChange}
                        />
                      </FormGroup>
                      <Button style={{ background: "black" }}>Submit</Button>
                    </Box>
                  </Form>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UpdateFamily;
