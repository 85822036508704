import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import api from "../../Axios";
import "./members.css";
import Usercard from "../../components/Usercard";
import SearchField from "../../components/SearchField";
const TerminatedMember = () => {
  const [data, setData] = useState([]);
  const [render, setRerender] = useState(false);
  const [terminated, setTerminated] = useState([]);
  useEffect(() => {
    api.get("/admin/adminDashboard/terminatedMembers").then((res) => {
    
      setTerminated(res.data.data);
    });
  }, []);
  function deletee(i) {
    api.post("/admin/manageuserProfile/deleteUser", { id: i.id }).then(() => {
      api.get("/admin/allUsers/getRecentUsers").then((res) => {
        setTerminated(res.data.data);
      });
    });
  }

  const searchMember = () => {
    let search = document.getElementById("search_id").value;
    api
      .post("/admin/manageuserprofile/searchUser", {
        searchData: search,
        query: "active",
      })
      .then((v) => {
        setTerminated(v.data.data);
      });
  };
  function activeinactive(i) {
    api.post("/admin/manageuserProfile/deleteUser", { id: i.id }).then(() => {
      api.get("/admin/adminDashboard/activeMembers").then((res) => {
        setTerminated(res.data.data);
      });
    });
  }
  return (
    <div>
      <div className="content">
        <div className="notification-popup"></div>
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title" style={{ textTransform: "capitilize" }}>
                  Terminated Members
                </h1>
              </div>
              <SearchField onSearch={() => searchMember()} />
            </div>
            <Row>
              {Array.isArray(terminated) && terminated.length > 0 ? (
                terminated.map((user, index) => (
                  <Usercard
                    key={index}
                    id={user.id}
                    profileId={user.profileId}
                    name={user.userName}
                    image={user.images}
                    age={user.age}
                    gender={user.gender}
                    dob={user.dob}
                    status={user.status}
                    state={user.state}
                    deletedReason={user.deletedReason}
                    onActive={() => activeinactive(user)}
                    onDelete={() => deletee(user)}
                  >
              
                  </Usercard>
                ))
              ) : (
                <div style={{ marginLeft: "500px", marginTop: "250px" }}>
                  <h3> Profile Not Found</h3>
                </div>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TerminatedMember;
