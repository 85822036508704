import React, { useEffect, useState, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col, Container } from "reactstrap";
import "./ReactQuills";
import "../Banner/banner.css";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import Swal from "sweetalert2";

const AddMembership = () => {
  const notiRef = useRef();
  const [data, setData] = useState({
    selectPlan: "",
    name: "",
    duration: "",
    period: "",
    descriptions: [],
    total: "",
    gst: "",
    discount: "",
    grandTotal: "",
    accessMobile: "",
    chat: "",
  });
  const [inputFields, setInputFields] = useState([
    {
      descriptions: "",
    },
  ]);

  const handleAdd = () => {
    setInputFields([
      ...inputFields,
      {
        descriptions: "",
      },
    ]);
  };

  const handleRemove = (index) => {
    if (inputFields.length !== 1) {
      const values = [...inputFields];
      values.splice(index, 1);
      setInputFields(values);
    }
  };

  const [render, setRender] = useState(false);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (name !== "descriptions") {
      setData({ ...data, [name]: value });
    } else {
      const newDescriptions = [...data.descriptions];
      newDescriptions[index] = value;
      setData({ ...data, descriptions: newDescriptions });
    }
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    if (value.length <= 6) {
      setData({ ...data, [name]: value });
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();

    const postingData = {
      ...data,
      duration: parseInt(data.duration) || 0,
      total: parseInt(data.total) || 0,
      gst: parseInt(data.gst) || 0,
      discount: parseInt(data.discount) || 0,
      grandTotal: parseInt(data.grandTotal) || 0,
      accessMobile: parseInt(data.accessMobile) || 0,
      chat: parseInt(data.chat) || 0,
    };

    api.post("/admin/membership/addMembership", postingData).then((res) => {
      setRender(!render);
      setData({
        selectPlan: "",
        name: "",
        duration: "",
        period: "",
        descriptions: [],
        total: "",
        gst: "",
        discount: "",
        grandTotal: "",
        accessMobile: "",
        chat: "",
      });
      Swal.fire("Added!", "Membership plan has been Added.", "success");
    });
  }

  return (
    <div>
      <div className="content">
        <div className="notification-popup">
          <NotificationAlert ref={notiRef} />
        </div>
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title" style={{fontWeight:"600"}}>Membership Plan</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box pt-3">
      
                <div className="content-body">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
                      <Form onSubmit={handleSubmit}>
                        <FormGroup>
                          <label style={{fontWeight:"600"}}>Select Plan</label>
                          <select
                            onChange={handleChange}
                            name="selectPlan"
                            className="form-select"
                            aria-label="Default select example"
                          >
                            <option></option>
                            <option value="regular">Regular</option>
                            <option value="prime">Prime</option>
                          </select>
                        </FormGroup>
                        <FormGroup>
                          <Label style={{fontWeight:"600"}}>Name</Label>
                          <Input
                            type="text"
                            onChange={handleChange}
                            name="name"
                            value={data.name}
                          />
                        </FormGroup>
                        <FormGroup>
                          <label style={{fontWeight:"600"}}>Period</label>
                          <select
                            onChange={handleChange}
                            name="period"
                            className="form-select"
                            aria-label="Default select example"
                          >
                            <option></option>
                            <option value="day">Day</option>
                            <option value="month">Month</option>
                            <option value="year">Year</option>
                          </select>
                        </FormGroup>
                        <FormGroup>
                          <Label style={{fontWeight:"600"}}>Duration</Label>
                          <Input
                            type="number"
                            onChange={handleChange}
                            name="duration"
                            value={data.duration}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label style={{fontWeight:"600"}}> No of Access Mobile</Label>
                          <Input
                            type="number"
                            onChange={handleChange}
                            name="accessMobile"
                            value={data.accessMobile}
                          />
                        </FormGroup>{" "}
                        <FormGroup>
                          <Label style={{fontWeight:"600"}}> No of Chats</Label>
                          <Input
                            type="number"
                            onChange={handleChange}
                            name="chat"
                            value={data.chat}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label style={{fontWeight:"600"}}>Description</Label>
                          {inputFields.map((item, index) => (
                            <div key={index} style={{ padding: "10px" }}>
                              <Input
                                name="descriptions"
                                value={data.descriptions[index] || ""}
                                onChange={(event) => {
                                  handleChange(event, index);
                                }}
                              />
                            </div>
                          ))}
                          <div>
                            <button
                              type="button"
                              onClick={() =>
                                handleRemove(inputFields.length - 1)
                              }
                              className="btn "
                              style={{
                                padding: "5px 10px",
                                background: "black",
                                color: "white",
                                marginTop: "10px",
                                fontWeight: "500",
                              }}
                            >
                              Remove
                            </button>
                            <button
                              type="button"
                              onClick={handleAdd}
                              className="btn "
                              style={{
                                padding: "5px 10px",
                                background: "black",
                                color: "white",
                                float: "right",
                                fontWeight: "500",
                              }}
                            >
                              Add
                            </button>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label>Total</Label>
                          <Input
                            type="number"
                            value={data.total}
                            onChange={handleNumberChange}
                            name="total"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>GST</Label>
                          <Input
                            type="number"
                            value={data.gst}
                            onChange={handleNumberChange}
                            name="gst"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Discount</Label>
                          <Input
                            type="number"
                            value={data.discount}
                            onChange={handleNumberChange}
                            name="discount"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Grand Total</Label>
                          <Input
                            type="number"
                            value={data.grandTotal}
                            onChange={handleNumberChange}
                            name="grandTotal"
                          />
                        </FormGroup>
                        <FormGroup style={{ marginBottom: "0px" }}>
                          <button
                            type="submit"
                            className="btn "
                            style={{
                              padding: "5px 10px",
                              background: "black",
                              color: "white",
                              fontWeight: "500",
                            }}
                          >
                            Submit
                          </button>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddMembership;
