import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import api from "../../Axios";
import NotificationAlert from "react-notification-alert";
import Usercard from "../../components/Usercard";
import SearchField from "../../components/SearchField";
import CancelledUser from "../../components/CancelledUser";
import Swal from "sweetalert2";
import style from "../../assets/css/navtab.module.css";
import {
  Form,
  Label,
  Input,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  FormGroup,
  CardTitle,
  CardText,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Box, Modal, Typography } from "@mui/material";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const Allmembers = () => {
  const rootRef = useRef(null);
  const maritalstatusList = [
    "Never Married",
    "Married",
    "Seprated",
    "Divorced",
    "Widow/Widower",
  ];
  const motherTongueList = [
    "Hindi",
    "Bengali",
    "Telugu",
    "Marathi",
    "Tamil",
    "Urdu",
    "Gujarati",
    "Kannada",
    "Malayalam",
    "Oriya",
    "Punjabi",
    "Assamese",
    "Maithili",
    "Bhili/Bhilodi",
    "Santali",
    "Kashmiri",
    "Nepali",
    "Gondi",
    "Sindhi",
    "Konkani",
    "Dogri",
    "Khandeshi",
    "Kurukh",
    "Tulu",
    "Meitei/Manipuri",
    "Bodo",
    "Khasi",
    "Mundari",
    "Ho",
    "Garo",
    "Tripuri",
  ];
  const profileCreatedForList = [
    "myself",
    "son",
    "daughter",
    "brother",
    "sister",
    "relative",
    "friend",
  ];
  const religionList = [
    "hindu",
    "christian",
    "muslim",
    "jain",
    "sikh",
    "buddhist",
  ];
  let hinduCastes = ["Arunthathiyar"];
  let christianCastes = [
    "adventist",
    "anglican Episcopal",
    "anglo-Indian",
    "apostolic",
    "assembly of God (AG)",
    "baptist",
    "born Again",
    "brethren",
    "calvinist",
    "chaldean Syrian (Assyrian)",
    "church of Christ",
    "church of God",
    "church of North India",
    "church of South India",
    "congregational",
    "evangelist",
    "jacobite",
    "jehovah's Witnesses",
    "knanaya",
    "knanaya Catholic",
    "knanaya Jacobite",
    "latin Catholic",
    "latter day saints",
    "lutheran",
    "malabar Independent Syrian Church",
    "malankara Catholic",
    "marthoma",
    "melkite",
    "mennonite",
    "methodist",
    "moravian",
    "orthodox",
    "pentecost",
    "presbyterian",
    "protestant",
    "reformed Baptist",
    "reformed Presbyterian",
    "roman Catholic",
    "seventh-day Adventist",
    "st. Thomas Evangelical",
    "syrian Catholic",
    "syrian Jacobite",
    "syrian Orthodox",
    "syro Malabar",
    "christian - Others",
    "don't wish to specify",
  ];
  let muslimCastes = [
    "muslim - Qureshi",
    "muslim - Rajput",
    "muslim - Rowther",
    "muslim - Shafi",
    "muslim - Sheikh",
    "muslim - Siddiqui",
    "muslim - Syed",
    "muslim - UnSpecified",
    "muslim - Khoja",
    "muslim - Lebbai",
    "muslim - Malik",
    "muslim - Mapila",
    "muslim - Maraicar",
    "muslim - Memon",
    "muslim - Mughal",
    "muslim - Others",
    "muslim - Pathan",
    "muslim - Ansari",
    "muslim - Arain",
    "muslim - Awan",
    "muslim - Bohra",
    "muslim - Dekkani",
    "muslim - Dudekula",
    "muslim - Hanafi",
    "muslim - Jat",
  ];
  let jainCastes = [
    "jain - Agarwal",
    "jain - Asati",
    "jain - Ayodhyavasi",
    "jain - Bagherwal",
    "jain - Bania",
    "jain - Barhiya",
    "jain - Charanagare",
    "jain - Chaturtha",
    "jain - Dhakada",
    "jain - Gahoi/Grihapati",
    "jain - Golalare / Kharaua",
    "jain - Golapurva",
    "jain - Golsinghare",
    "jain - Harada",
    "jain - Humad / Humbad",
    "jain - Intercaste",
    "jain - Jaiswal",
    "jain - KVO",
    "jain - Kambhoja",
    "jain - Kasar",
    "jain - Kathanere",
    "jain - Khandelwal",
    "jain - Kharaua",
    "jain - Kutchi",
    "jain - Lamechu",
    "jain - Nema",
    "jain - Oswal",
    "jain - Others",
    "jain - Padmavati Porwal",
    "jain - Palliwal",
    "jain - Panchama",
    "jain - Parmar",
    "jain - Parwar / Paravara",
    "jain - Porwad / Porwal",
    "jain - Porwal",
    "jain - Saitwal",
    "jain - Samanar / Nayinar",
    "jain - Samiya",
    "jain - Sarak",
    "jain - Shrimal",
    "jain - Unspecified",
    "jain - Upadhyaya",
    "jain - Vaishya",
    "jain - Veerwal",
    "don't wish to specify",
  ];

  let sikhCastes = [
    "sikh - Ahluwalia",
    "sikh - Arora",
    "sikh - Bhatia",
    "sikh - Bhatra",
    "sikh - Ghumar",
    "sikh - Intercaste",
    "sikh - Jat",
    "sikh - Kamboj",
    "sikh - Khatri",
    "sikh - Kshatriya",
    "sikh - Lubana",
    "sikh - Majabi",
    "sikh - Nai",
    "sikh - Others",
    "sikh - Rai",
    "sikh - Rajput",
    "sikh - Ramdasia",
    "sikh - Ramgharia",
    "sikh - Ravidasia",
    "sikh - Saini",
    "sikh - Tonk Kshatriya",
    "sikh - Unspecified",
    "don't wish to specify",
  ];
  let buddhistCastes = [
    "neo Buddhist",
    "others",
    "tibetan Buddhist",
    "others",
    "don't wish to specify",
  ];
  let subcas = [
    "Tamil Arunthathiyar",
    "Reddy Arunthathiyar",
    "Golla Arunthathiyar",
    "Pannatti Arunthathiyar",

    "Kannada Arunthathiyar",
    "Koyya Arunthathiyar",
    "Pathakattiyan - Vattigan",
    "Amma Pirivu",
    "Avva Pirivu",
    "Janakilai - Thasarikilai",
    "Arunthathiyar - Sakkiliyar",
  ];
  let subsubcaste = [
    "Murasu Arunthathiyar",
    "Anuppa Arunthathiyar",
    "Don't know",
  ];
  let kulam = {
    "Reddy Arunthathiyar": [
      "Kevviyan",
      "Kamanooran",
      "Yenthalan",
      "Vemparan / Vemparaiyan",
      "Kontakattiyan",
      "Gothapillaiyan",
      "Pantheri",
      "Tiruvannamalaiyan",
      "Thanakontalam",
      "Kattakalalan",
      "Kattaran",
      "Kattakattaalu",
      "Nelapalelu",
      "Mavarathan",
      "Neranjalan / Tharalan",
      "Nanthalaiyan",
      "Kuralan",
      "Vijanagiriyan",
      "Pallagollan",
      "Vattharayadu",
      "Other Optional",
    ],
    "Pannatti Arunthathiyar": [
      "Mattumolu",
      "Pallamolu",
      "Nalimalelu",
      "Kalimalolu",
      "Parathalolu",
      "Kalrolu",
      "Thellakoorelu",
      "Moppurolu",
      "Thasurolu",
      "Pelletolu",
      "Kappurolu",
      "Kuthulurolu",
      "Other",
    ],
    "Murasu Arunthathiyar": [
      "Yerithu Kulam",
      "Azhagar / Alaiyar Kulam",
      "Manjar Kulam",
      "Nagar Kulam",
      "Aantar Kulam",
      "Kadai Kulam",
      "Pooran Koottam",
      "Yemmar Kulam",
      "Sengakannan Kulam",
      "Murasu Koottam",
      "Masarakulam",
      "Kuriyeethu Kulam",
      "Athiyan Kulam",
      "Other",
    ],
    "Anuppa Arunthathiyar": [
      "Sannor",
      "Pothanor",
      "Mooppanor",
      "Yethuppanar",
      "Keerthanar",
      "Olamosharan",
      "Other",
    ],
    "Pathakattiyan - Vattigan": [
      "Santhamuthulu",
      "Pagutugethulu / Pagitikathulu",
      "Sullirivarlu / Sullikarlu",
      "Pittavarlu / Pittakarlu",
      "Konagavar / Konakarlu",
      "Mathigapillivar / Mathigapelli",
      "Peeragavarlu / Peerakatu",
      "Ovilavar / Ovilan / Ovilalu",
      "Settisellivarlu",
      "Pulipothulu",
      "Jallivarlu",
      "Sinthalavarlu",
      "Rama Jothilu",
      "Alli Anthuvar",
      "Sappitavar / Saptikarlu / Sappitan",
      "Utthavar",
      "Rasakavar / Rasakarlu",
      "Kenchakavalu",
      "Kosangivar",
      "Patamasaraluvalu",
      "Kutakalavar",
      "Pallakavar",
      "Nellimvarlu / Nallimvalu",
      "Marikatthuluvar",
      "Thasirivar / Thasarlu",
      "Kommalavar / Komluvarlu",
      "Eantakavar / Eantakarlu",
      "Vattikavar / Vattikan / Vattukulu",
      "Eeramvaru",
      "Paarvar / Parlikarlu",
      "Kummitivar / Kummitiyan / Kummitivalu",
      "Nellampullavar",
      "Earramvar / Earamalavatu / Earamalavarlu",
      "Neelamvarlu",
      "Jonakavar",
      "Rama Jokiluvar",
      "Sathilvar / Sathuluvarlu",
      "Soothagavar / Soothakarlu",
      "Bothuvar",
      "Pegatavar / Pagatakarlu",
      "Paliyavar",
      "Pathagattiyan / Pathakattulu",
      "Kuntalavar / Kuntakarlu",
      "Murrakaru / Murkaru",
      "Mitukarlu / Mitakarlu",
      "Janakarlu / Janakan",
      "Mellakatu / Mellakan",
      "Thiva kootu / Thivva Koottan",
      "Palakarlu",
      "Pangittarlu / Pangittan",
      "Eallettulu",
      "Naramuthulu",
      "Alughuvarlu / Alughuvar",
      "Sullalvarlu",
      "Vandikarlu",
      "Thosakararlu",
      "Kothikethulu",
      "Sittakaralu",
      "Puthakaralu",
      "Kambuguthi kaatu",
      "Mandhapellai",
      "Sappa Rarlu / Sappal",
      "Koshakarlu / Koshakavar",
      "Puritala",
      "Sillapan",
      "Muthikarlu",
      "Podu Thasa Rarlu",
      "Ittu Thasa Rarlu",
      "Puduthu Thasa Rarlu",
      "Kaali Nadhu",
      "Mallikalu",
      "Nettimallumulu",
      "Poolagaaru",
      "Thottakaaru",
      "Mothamokkaluvar",
      "Kolmamaru",
      "Savakeedthulu",
      "Rathankatu",
      "Poovaluru",
      "Kammalavarlu",
      "Kakagattu",
      "Mitakaarlu",
      "Pulagattu",
      "Madakalavar",
      "Jonnakonda",
      "Masalavar / Masaluvar",
      "Jothikalu",
      "Kuyyaluvaru",
      "Vetagarlu",
      "Kaaseekar",
      "Chattalugaaru",
      "Jadhikaaru",
      "Muthukadathanu",
      "Thidakaaru",
      "Sauligaaru",
      "Kaartadu",
      "Kaaraalu",
      "Sama Joki",
      "Seeyam Pokkal",
      "Narithivarlu",
      "Pachakulatdu",
      "Monakaaru",
      "Ponmalavarlu",
      "Matravai",
    ],
    "Avva Pirivu": [
      "Pullanadu",
      "Moganadu",
      "Pagutikethedu",
      "Eanjanadu",
      "Patchedu",
      "Maravanadu",
      "Kenjavadu",
      "Soyyakadu",
      "Kommaladu",
      "Eeravadu / Eravadu",
      "Pangitadu",
      "Kalavanadu",
      "Palikadu",
      "Oviladu",
      "Mudikinadu",
      "Pantedu",
      "Poolanadu",
      "Soothaladu",
      "Deepanadu",
      "Mitukinadu",
      "Marikatthanadu",
      "Bothuvadu",
      "Ketthanadu",
      "Other",
    ],
  };
  const [filter, setFilter] = useState({
    maritalStatus: [],
    motherTongue: [],
    profileCreated: [],
    religion: [],
    caste: [],
    subCaste: [],
  });

  const [data, setData] = useState([]);
  const [render, setRerender] = useState(false);
  const [getuser, setGetuser] = useState([]);
  const [filteruser, setFilteruser] = useState([]);
  const [subSubCaste, setSubSubCaste] = useState([]);
  const [kulams, setKulams] = useState([]);

  const [verified, setVerified] = useState("Verify");
  const [userCount, setUserCount] = useState(0);
  const [femaleCount, setFemaleCount] = useState(0);
  const [maleCount, setMaleCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all users
        const allUsersRes = await api.get("/admin/allUsers/getAllUsers");
        const allUsers = allUsersRes.data.data;

        if (Array.isArray(allUsers)) {
          // Filter users by gender
          const femaleUsers = allUsers.filter(
            (user) => user.gender === "female"
          );
          const maleUsers = allUsers.filter((user) => user.gender === "male");

          // Set state
          setGetuser(allUsers);
          setUserCount(allUsers.length);
          setFemaleCount(femaleUsers.length);
          setMaleCount(maleUsers.length);
        } else {
          console.error("Fetched allUsers is not an array", allUsers);
        }

        // Fetch other user counts
        const terminatedMembersRes = await api.get(
          "/admin/adminDashboard/terminatedMembers"
        );
        setDeleteCount(terminatedMembersRes.data.data.length);

        const activeMembersRes = await api.get(
          "/admin/adminDashboard/activeMembers"
        );
        setActiveCount(activeMembersRes.data.data.length);

        const inactiveMembersRes = await api.get(
          "/admin/adminDashboard/inactiveMembers"
        );
        setInactiveCount(inactiveMembersRes.data.data.length);

        const paidUsersRes = await api.get("/admin/allUsers/getPaidUsers");
        setPaidCount(paidUsersRes.data.data.length);

        const unpaidUsersRes = await api.get("/admin/allUsers/getUnpaidUsers");
        setUnpaidCount(unpaidUsersRes.data.data.length);

        const cancelledUsersRes = await api.get(
          "/admin/adminDashboard/cancelledUsers"
        );
        setCancelCount(cancelledUsersRes.data.data.length);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  const searchMember = () => {
    let search = document.getElementById("search_id").value;
    api
      .post("/admin/manageuserprofile/searchUser", {
        searchData: search,
        query: "all",
      })
      .then((v) => {
        setGetuser(v.data.data);
      });
  };
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    let value;

    if (selectedValue === "name a-z") {
      value = 1;
    } else if (selectedValue === "name z-a") {
      value = 2;
    } else if (selectedValue === "profileid asc") {
      value = 3;
    } else if (selectedValue === "profileid desc") {
      value = 4;
    } else if (selectedValue === "age asc") {
      value = 5;
    } else if (selectedValue === "age desc") {
      value = 6;
    } else if (selectedValue === "createdat asc") {
      value = 7;
    } else if (selectedValue === "createdat desc") {
      value = 8;
    } else if (selectedValue === "updatedat asc") {
      value = 9;
    } else if (selectedValue === "updatedat desc") {
      value = 10;
    }

    api
      .get(`/admin/adminDashboard/sort?${value}`)
      .then((response) => {
        setGetuser(response.data.data);
      })
      .catch((error) => {
        console.error("There was an error sorting the data!", error);
      });
  };

  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: [value], // Ensure each attribute is an array
    }));
  };

  function handleSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();

    api.post("/admin/adminDashboard/filter", filter).then((res) => {
      setFilter("");
      setGetuser(res.data.data);
      setOpen(false);
    });
  }

  const [activeTab, setActiveTab] = useState(1);

  // function verify(i) {
  //   api.post("/admin/adminDashboard/imageVerified", { id: i.id }).then(() => {
  //     api.get("/admin/allUsers/getAllUsers").then((res) => {
  //       setGetuser(res.data.data);
  //       setVerified("Verified");
  //     });
  //   });
  // }
  function deletee(i) {
    api
      .post("/admin/manageuserProfile/terminateUser", { id: i.id })
      .then(() => {
        api.get("/admin/allUsers/getAllUsers").then((res) => {
          setGetuser(res.data.data);
        });
      });
  }
  function activeinactive(i) {
    api.post("/admin/manageuserProfile/deleteUser", { id: i.id }).then(() => {
      api.get("/admin/allUsers/getAllUsers").then((res) => {
        setGetuser(res.data.data);
      });
    });
  }
  const [activeLists, setActiveLists] = useState([]);
  const [activeCount, setActiveCount] = useState(0);

  function activeList() {
    api.get("/admin/adminDashboard/activeMembers").then((res) => {
      setActiveCount(res.data.data.length);
      setActiveLists(res.data.data);
    });
  }
  const [inactiveCount, setInactiveCount] = useState(0);
  const [inactiveLists, setInactiveLists] = useState([]);
  function inactiveList() {
    api.get("/admin/adminDashboard/inactiveMembers").then((res) => {
      setInactiveLists(res.data.data);
      setInactiveCount(res.data.data.length);
    });
  }
  const [paidCount, setPaidCount] = useState(0);
  const [paidLists, setPaidLists] = useState([]);
  function paidList() {
    api.get("/admin/allUsers/getPaidUsers").then((res) => {
      setPaidLists(res.data.data);
      setPaidCount(res.data.data.length);
    });
  }
  const [unpaidCount, setUnpaidCount] = useState(0);
  const [unpaidLists, setUnpaidLists] = useState([]);
  function unpaidList() {
    api.get("/admin/allUsers/getUnpaidUsers").then((res) => {
      setUnpaidLists(res.data.data);
      setUnpaidCount(res.data.data.length);
    });
  }
  const [deleteCount, setDeleteCount] = useState(0);
  const [deleteLists, setDeleteists] = useState([]);
  function deleteList() {
    api.get("/admin/adminDashboard/terminatedMembers").then((res) => {
      setDeleteists(res.data.data);
      setDeleteCount(res.data.data.length);
    });
  }
  const [cancelCount, setCancelCount] = useState(0);
  const [cancelLists, setCancelists] = useState([]);
  function cancelList() {
    api.get("/admin/adminDashboard/cancelledUsers").then((res) => {
      setCancelists(res.data.data);
      setCancelCount(res.data.data.length);
    });
  }
  console.log(cancelCount);

  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [sortuser, setSortuser] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="content">
        <div className="notification-popup"></div>
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h1 className="title" style={{ textTransform: "capitilize" }}>
                  All Members
                </h1>{" "}
                <h1 className="title" style={{ textTransform: "capitilize" }}>
                  <Link
                    to="/register"
                    className="btn"
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      background: "#000000",
                      padding: "10px 15px",
                      color: "white",
                    }}
                  >
                    Add New Members
                  </Link>
                </h1>{" "}
              </div>{" "}
              <div style={{ display: "flex", gap: "30px" }}>
                <SearchField onSearch={() => searchMember()} />{" "}
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  {" "}
                  <span
                    style={{ fontSize: "16px", textTransform: "capitalize" }}
                  >
                    Sort
                  </span>{" "}
                  <FormGroup>
                    <select
                      onChange={handleChange}
                      name="period"
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option value="name a-z">Name a-z</option>
                      <option value="name z-a">Name z-a</option>
                      <option value="profilei dasc">ProfileId Ascending</option>
                      <option value="profildid desc">
                        ProfildId Descending
                      </option>
                      <option value="age asc">Age Ascending</option>
                      <option value="age desc">Age Descending</option>
                      <option value="createdat asc">Register Ascending</option>
                      <option value="createdat desc">
                        Register Descending
                      </option>
                      <option value="updatedat asc">
                        Any update Ascending
                      </option>
                      <option value="updatedat desc">
                        Any update Descending
                      </option>
                    </select>
                  </FormGroup>
                </div>{" "}
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "baseline",
                  }}
                >
                  {" "}
                  <span
                    style={{ fontSize: "16px", textTransform: "capitalize" }}
                  >
                    <Button onClick={() => handleOpen()}>Filter</Button>

                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={modalStyle}>
                        <Form onSubmit={handleSubmit}>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <p
                                style={{
                                  marginRight: "1rem",
                                  minWidth: "150px",
                                }}
                              >
                                Marital Status
                              </p>
                              <FormGroup>
                                <Input
                                  name="maritalStatus"
                                  type="select"
                                  id="maritalStatus"
                                  onChange={handleFilter}
                                  style={{ width: "500px" }}
                                >
                                  <option value="">Select MaritalStatus</option>
                                  {maritalstatusList.map((x) => (
                                    <option value={x} key={x}>
                                      {x}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <p
                                style={{
                                  marginRight: "1rem",
                                  minWidth: "150px",
                                }}
                              >
                                Mother Tongue
                              </p>
                              <FormGroup>
                                <Input
                                  name="motherTongue"
                                  type="select"
                                  id="motherTongue"
                                  onChange={handleFilter}
                                  style={{
                                    width: "500px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <option value="">Select MotherTongue</option>
                                  {motherTongueList.map((x) => (
                                    <option value={x} key={x}>
                                      {x}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <p
                                style={{
                                  marginRight: "1rem",
                                  minWidth: "150px",
                                }}
                              >
                                Profile Created
                              </p>
                              <FormGroup>
                                <Input
                                  name="profileCreated"
                                  type="select"
                                  id="createdFor"
                                  onChange={handleFilter}
                                  style={{
                                    width: "500px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <option value="">
                                    Select Profile created for
                                  </option>
                                  {profileCreatedForList.map((x) => (
                                    <option value={x} key={x}>
                                      {x}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <p
                                style={{
                                  marginRight: "1rem",
                                  minWidth: "150px",
                                }}
                              >
                                Religion
                              </p>
                              <FormGroup>
                                <Input
                                  name="religion"
                                  onChange={handleFilter}
                                  type="select"
                                  value={data.religion}
                                  id="religion"
                                  style={{
                                    width: "500px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <option value="">Select Religion</option>
                                  {religionList.map((x) => (
                                    <option value={x} key={x}>
                                      {x}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <p
                                style={{
                                  marginRight: "1rem",
                                  minWidth: "150px",
                                }}
                              >
                                Caste
                              </p>
                              <FormGroup>
                                <Label for="caste">Caste</Label>
                                <Input
                                  name="caste"
                                  type="select"
                                  id="caste"
                                  value={data.caste}
                                  style={{ textTransform: "capitalize" }}
                                  onChange={handleChange}
                                >
                                  <option value="">Select Caste</option>
                                  {data.religion === "hindu" &&
                                    hinduCastes.map((caste) => (
                                      <option value={caste} key={caste}>
                                        {caste}
                                      </option>
                                    ))}
                                  {data.religion === "christian" &&
                                    christianCastes.map((caste) => (
                                      <option value={caste} key={caste}>
                                        {caste}
                                      </option>
                                    ))}
                                  {data.religion === "muslim" &&
                                    muslimCastes.map((caste) => (
                                      <option value={caste} key={caste}>
                                        {caste}
                                      </option>
                                    ))}
                                  {data.religion === "jain" &&
                                    jainCastes.map((caste) => (
                                      <option value={caste} key={caste}>
                                        {caste}
                                      </option>
                                    ))}
                                  {data.religion === "sikh" &&
                                    sikhCastes.map((caste) => (
                                      <option value={caste} key={caste}>
                                        {caste}
                                      </option>
                                    ))}
                                  {data.religion === "buddhist" &&
                                    buddhistCastes.map((caste) => (
                                      <option value={caste} key={caste}>
                                        {caste}
                                      </option>
                                    ))}
                                </Input>
                              </FormGroup>
                              {data.religion === "hindu" && (
                                <FormGroup>
                                  <Label for="subCaste">Sub-Caste</Label>
                                  <Input
                                    name="subCaste"
                                    type="select"
                                    id="subCaste"
                                    value={data.subCaste}
                                    style={{ textTransform: "capitalize" }}
                                    onChange={handleChange}
                                  >
                                    <option value="">Select Sub-Caste</option>
                                    {subcas.map((subCaste) => (
                                      <option value={subCaste} key={subCaste}>
                                        {subCaste}
                                      </option>
                                    ))}
                                  </Input>
                                </FormGroup>
                              )}
                              {data.religion !== "hindu" && (
                                <Input
                                  name="subCaste"
                                  placeholder="Enter your Sub-Caste"
                                  type="text"
                                  value={data.subCaste}
                                  id="subCaste"
                                  onChange={handleChange}
                                  style={{ margin: "20px 0px" }}
                                />
                              )}
                              {data.subCaste === "Kannada Arunthathiyar" && (
                                <FormGroup>
                                  <Label for="subSubCaste">Sub-Sub-Caste</Label>
                                  <Input
                                    name="subSubCaste"
                                    type="select"
                                    id="subSubCaste"
                                    value={subsubcaste}
                                    onChange={(e) =>
                                      setSubSubCaste(e.target.value)
                                    }
                                  >
                                    <option value="">
                                      Select Sub-Sub-Caste
                                    </option>
                                    {subsubcaste.map((subSubCaste) => (
                                      <option
                                        value={subSubCaste}
                                        key={subSubCaste}
                                      >
                                        {subSubCaste}
                                      </option>
                                    ))}
                                  </Input>
                                </FormGroup>
                              )}
                              {data.subCaste && kulam[data.subCaste] && (
                                <FormGroup>
                                  <Label for="kulam">Kulam</Label>
                                  <Input
                                    name="kulam"
                                    type="select"
                                    id="kulam"
                                    value={kulam}
                                    onChange={(e) => setKulams(e.target.value)}
                                  >
                                    <option value="">Select Kulam</option>
                                    {kulam[data.subCaste].map((kulamOption) => (
                                      <option
                                        value={kulamOption}
                                        key={kulamOption}
                                      >
                                        {kulamOption}
                                      </option>
                                    ))}
                                  </Input>
                                </FormGroup>
                              )}
                            </div>
                          </div>
                          <div>
                            {" "}
                            <FormGroup style={{ marginBottom: "0px" }}>
                              <button
                                type="submit"
                                className="btn "
                                style={{
                                  padding: "5px 10px",
                                  background: "black",
                                  color: "white",
                                  fontWeight: "500",
                                }}
                              >
                                Submit
                              </button>
                            </FormGroup>
                          </div>
                        </Form>
                      </Box>
                    </Modal>
                  </span>{" "}
                </div>{" "}
              </div>
              <div>
                <p style={{ margin: "20px 0px", fontSize: "20px" }}>
                  Sort Members:{" "}
                </p>
                <Nav tabs id={style.navPane} style={{ paddingBottom: "2px" }}>
                  <NavItem>
                    <NavLink
                      className="active"
                      id={style.tab1}
                      style={
                        activeTab === 1
                          ? {
                              color: "black",
                              borderBottom: "2px solid black",
                            }
                          : {}
                      }
                      onClick={() => setActiveTab(1)}
                    >
                      All Members(
                      {filteruser.length === 0 ? userCount : filteruser.length})
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="active"
                      id={style.tab4}
                      style={
                        activeTab === 2
                          ? {
                              color: "black",
                              borderBottom: "2px solid black",
                            }
                          : {}
                      }
                      onClick={() => {
                        setActiveTab(2);
                        activeList();
                      }}
                    >
                      Active Members({activeCount})
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="active"
                      id={style.tab6}
                      style={
                        activeTab === 3
                          ? {
                              color: "black",
                              borderBottom: "2px solid black",
                            }
                          : {}
                      }
                      onClick={() => {
                        setActiveTab(3);
                        inactiveList();
                      }}
                    >
                      Inactive Members({inactiveCount})
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="active"
                      id={style.tab8}
                      style={
                        activeTab === 4
                          ? {
                              color: "black",
                              borderBottom: "2px solid black",
                            }
                          : {}
                      }
                      onClick={() => {
                        setActiveTab(4);
                        paidList();
                      }}
                    >
                      Paid Members({paidCount})
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="active"
                      id={style.tab8}
                      style={
                        activeTab === 5
                          ? {
                              color: "black",
                              borderBottom: "2px solid black",
                            }
                          : {}
                      }
                      onClick={() => {
                        setActiveTab(5);
                        unpaidList();
                      }}
                    >
                      Unpaid Members({unpaidCount})
                    </NavLink>
                  </NavItem>{" "}
                  <NavItem>
                    <NavLink
                      className="active"
                      id={style.tab8}
                      style={
                        activeTab === 6
                          ? {
                              color: "black",
                              borderBottom: "2px solid black",
                            }
                          : {}
                      }
                      onClick={() => {
                        setActiveTab(6);
                        cancelList();
                      }}
                    >
                      Cancelled Members({cancelCount})
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="active"
                      id={style.tab8}
                      style={
                        activeTab === 7
                          ? {
                              color: "black",
                              borderBottom: "2px solid black",
                            }
                          : {}
                      }
                      onClick={() => {
                        setActiveTab(7);
                        deleteList();
                      }}
                    >
                      Deleted Members({deleteCount})
                    </NavLink>
                  </NavItem>{" "}
                  <NavItem>
                    <NavLink
                      className="active"
                      id={style.tab8}
                      style={
                        activeTab === 8
                          ? {
                              color: "black",
                              borderBottom: "2px solid black",
                            }
                          : {}
                      }
                      onClick={() => {
                        setActiveTab(8);
                        deleteList();
                      }}
                    >
                      Female Members({femaleCount})
                    </NavLink>
                  </NavItem>{" "}
                  <NavItem>
                    <NavLink
                      className="active"
                      id={style.tab8}
                      style={
                        activeTab === 9
                          ? {
                              color: "black",
                              borderBottom: "2px solid black",
                            }
                          : {}
                      }
                      onClick={() => {
                        setActiveTab(9);
                        deleteList();
                      }}
                    >
                      Male Members({maleCount})
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  activeTab={`${activeTab}`}
                  style={{ borderColor: "#fff0", borderTop: "1px solid #fff9" }}
                >
                  <TabPane tabId="1">
                    <Row>
                      {Array.isArray(sortuser) && sortuser.length > 0 ? (
                        sortuser.map((user, index) => (
                          <Usercard
                            key={index}
                            id={user.id}
                            profileId={user.profileId}
                            name={user.userName}
                            image={user.images}
                            email={user.email}
                            countryCode={user.countryCode}
                            phone={user.phone}
                            district={user.district}
                            state={user.state}
                            dob={user.dob}
                            gender={user.gender}
                            age={user.age}
                            status={user.status}
                            onActive={() => activeinactive(user)}
                            onDelete={() => deletee(user)}
                          />
                        ))
                      ) : Array.isArray(filteruser) && filteruser.length > 0 ? (
                        filteruser.map((user, index) => (
                          <Usercard
                            key={index}
                            id={user.id}
                            profileId={user.profileId}
                            name={user.userName}
                            image={user.images}
                            email={user.email}
                            countryCode={user.countryCode}
                            phone={user.phone}
                            district={user.district}
                            state={user.state}
                            dob={user.dob}
                            gender={user.gender}
                            age={user.age}
                            status={user.status}
                            onActive={() => activeinactive(user)}
                            onDelete={() => deletee(user)}
                          />
                        ))
                      ) : Array.isArray(getuser) && getuser.length > 0 ? (
                        getuser.map((user, index) => (
                          <Usercard
                            key={index}
                            id={user.id}
                            profileId={user.profileId}
                            name={user.userName}
                            image={user.images}
                            email={user.email}
                            countryCode={user.countryCode}
                            phone={user.phone}
                            district={user.district}
                            state={user.state}
                            dob={user.dob}
                            gender={user.gender}
                            age={user.age}
                            status={user.status}
                            onActive={() => activeinactive(user)}
                            onDelete={() => deletee(user)}
                          />
                        ))
                      ) : (
                        <div
                          style={{ marginLeft: "500px", marginTop: "250px" }}
                        >
                          <h3>Profile Not Found</h3>
                        </div>
                      )}
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    {" "}
                    <Row>
                      {Array.isArray(activeLists) && activeLists.length > 0 ? (
                        activeLists.map((user, index) => (
                          <Usercard
                            key={index}
                            profileId={user.profileId}
                            id={user.id}
                            name={user.userName}
                            image={user.images}
                            age={user.age}
                            gender={user.gender}
                            dob={user.dob}
                            status={user.status}
                            state={user.state}
                            onActive={() => activeinactive(user)}
                            onDelete={() => deletee(user)}
                          />
                        ))
                      ) : (
                        <div
                          style={{ marginLeft: "500px", marginTop: "250px" }}
                        >
                          <h3> Profile Not Found</h3>
                        </div>
                      )}
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    {" "}
                    <Row>
                      {Array.isArray(inactiveLists) &&
                      inactiveLists.length > 0 ? (
                        inactiveLists.map((user, index) => {
                          return (
                            <Usercard
                              key={index}
                              profileId={user.profileId}
                              id={user.id}
                              name={user.userName}
                              image={user.images}
                              age={user.age}
                              gender={user.gender}
                              dob={user.dob}
                              state={user.state}
                              status={user.status}
                              onActive={() => activeinactive(user)}
                              onDelete={() => deletee(user)}
                            />
                          );
                        })
                      ) : (
                        <div
                          style={{ marginLeft: "500px", marginTop: "250px" }}
                        >
                          <h3> Profile Not Found</h3>
                        </div>
                      )}
                    </Row>
                  </TabPane>
                  <TabPane tabId="4">
                    {" "}
                    <Row>
                      {Array.isArray(paidLists) && paidLists.length > 0 ? (
                        paidLists.map((user, index) => {
                          return (
                            <Usercard
                              image={user.images}
                              profileId={user.profileId}
                              age={user.age}
                              id={user.id}
                              dob={user.dob}
                              name={user.userName}
                              gender={user.gender}
                              state={user.state}
                              status={user.status}
                              key={user.id}
                              onActive={() => activeinactive(user)}
                              onDelete={() => deletee(user)}
                            />
                          );
                        })
                      ) : (
                        <div
                          style={{ marginLeft: "500px", marginTop: "250px" }}
                        >
                          <h3> Profile Not Found</h3>
                        </div>
                      )}
                    </Row>
                  </TabPane>
                  <TabPane tabId="5">
                    {" "}
                    <Row>
                      {Array.isArray(unpaidLists) && unpaidLists.length > 0 ? (
                        unpaidLists.map((user, index) => {
                          return (
                            <Usercard
                              key={index}
                              profileId={user.profileId}
                              name={user.userName}
                              image={user.images}
                              age={user.age}
                              gender={user.gender}
                              dob={user.dob}
                              state={user.state}
                              status={user.status}
                              onActive={() => activeinactive(user)}
                              onDelete={() => deletee(user)}
                            />
                          );
                        })
                      ) : (
                        <div
                          style={{ marginLeft: "500px", marginTop: "250px" }}
                        >
                          <h3> Profile Not Found</h3>
                        </div>
                      )}
                    </Row>
                  </TabPane>
                  <TabPane tabId="6">
                    {" "}
                    <Row>
                      {Array.isArray(cancelLists) && cancelLists.length > 0 ? (
                        cancelLists.map((user, index) => {
                          return (
                            <CancelledUser
                              key={index}
                              id={user.id}
                              name={user.userName}
                              profileId={user.profileId}
                              image={user.images}
                              email={user.email}
                              phone={user.phone}
                              // status={user.status}
                            />
                          );
                        })
                      ) : (
                        <div
                          style={{ marginLeft: "500px", marginTop: "250px" }}
                        >
                          <h3> Profile Not Found</h3>
                        </div>
                      )}
                    </Row>
                  </TabPane>{" "}
                  <TabPane tabId="7">
                    {" "}
                    <Row>
                      {Array.isArray(deleteLists) && deleteLists.length > 0 ? (
                        deleteLists.map((user, index) => (
                          <Usercard
                            key={index}
                            id={user.id}
                            profileId={user.profileId}
                            name={user.userName}
                            image={user.images}
                            age={user.age}
                            gender={user.gender}
                            dob={user.dob}
                            status={user.status}
                            state={user.state}
                            deletedReason={user.deletedReason}
                            onActive={() => activeinactive(user)}
                            onDelete={() => deletee(user)}
                          ></Usercard>
                        ))
                      ) : (
                        <div
                          style={{ marginLeft: "500px", marginTop: "250px" }}
                        >
                          <h3> Profile Not Found</h3>
                        </div>
                      )}
                    </Row>
                  </TabPane>{" "}
                  <TabPane tabId="8">
                    {" "}
                    <Row>
                      {Array.isArray(getuser) && getuser.length > 0 ? (
                        getuser
                          .filter((user) => user.gender === "female")
                          .map((user, index) => (
                            <Usercard
                              key={index}
                              id={user.id}
                              profileId={user.profileId}
                              name={user.userName}
                              image={user.images}
                              age={user.age}
                              gender={user.gender}
                              dob={user.dob}
                              status={user.status}
                              state={user.state}
                              deletedReason={user.deletedReason}
                              onActive={() => activeinactive(user)}
                              onDelete={() => deletee(user)}
                            />
                          ))
                      ) : (
                        <div
                          style={{ marginLeft: "500px", marginTop: "250px" }}
                        >
                          <h3>Profile Not Found</h3>
                        </div>
                      )}
                    </Row>
                  </TabPane>{" "}
                  <TabPane tabId="9">
                    {" "}
                    <Row>
                      {Array.isArray(getuser) && getuser.length > 0 ? (
                        getuser
                          .filter((user) => user.gender === "male")
                          .map((user, index) => (
                            <Usercard
                              key={index}
                              id={user.id}
                              profileId={user.profileId}
                              name={user.userName}
                              image={user.images}
                              age={user.age}
                              gender={user.gender}
                              dob={user.dob}
                              status={user.status}
                              state={user.state}
                              deletedReason={user.deletedReason}
                              onActive={() => activeinactive(user)}
                              onDelete={() => deletee(user)}
                            />
                          ))
                      ) : (
                        <div
                          style={{ marginLeft: "500px", marginTop: "250px" }}
                        >
                          <h3>Profile Not Found</h3>
                        </div>
                      )}
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Allmembers;
