import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import api from "../../Axios";
import Swal from "sweetalert2";

import "react-phone-input-2/lib/style.css";

const AddFamily = () => {
  const history = useHistory();

  const financialStatus = [
    "Rich",
    "Very Rich",
    "Upper Middle class",
    "Middle class",
    "Lower Middle class",
    "Affluent",
  ];
  const familyList = ["Join Family", "Nuclear Family"];
  const familyValue = ["Orthodox", "Traditional", "Moderate", "Liberal"];
  const OccupationList = [
    "Govt employed",
    "private employed",
    "business man",
    "self employed",
    "Retired",
    "Not employed",
    "Passed Away",
  ];
  const numberOfList = [0, 1, 2, 3, 4, 5];

  const [data, setData] = useState({
    fianacialStatus: "",
    familyType: "",
    familyValues: "",
    fatherOccupation: "",
    motherOccupation: "",
    noOfBrothers: "",
    noOfSisters: "",
    brothersMarried: "",
    sistersMarried: "",
    aboutFamily: "",
  });
  const [render, setRender] = useState(false);
  let { id } = useParams();
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  function handleSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();
    const fullData = { ...data, aboutFamily,id: Number(id) };
    api.post("/admin/manageUserProfile/addFamily", fullData).then((res) => {
      setRender(!render);
      setData("");
      Swal.fire(res.data.data);
      history.push(`/addpersonality/${id}`);
    });
  }
  const [aboutFamily, setAboutFamily] = useState("");

  const sentences = [
    " My family values honesty, kindness, and togetherness, which guide our actions and decisions in life",
   "We always stand by each other through thick and thin, providing emotional and practical support when needed",
  "We cherish our cultural heritage and celebrate festivals and traditions with great enthusiasm, bringing us closer together",
    "Each member of my family has unique qualities and interests, which make our interactions diverse and enriching",
 "Despite busy schedules, we prioritize spending time together, whether it’s during meals, outings, or simply sharing stories"
    
    
    
    
    
    
  ];

  // Function to select a random sentence
  const getRandomSentence = () => {
    const randomIndex = Math.floor(Math.random() * sentences.length);
    return sentences[randomIndex];
  };
  const handleFamilyChange = (e) => {
    setAboutFamily(e.target.value);
  };
  // Automatically set a random sentence when the component mounts
  useEffect(() => {
    setAboutFamily(getRandomSentence());
  }, []);
  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title" style={{fontWeight:"600"}}>Add Family Details</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <div className="content-body">
                  <Form onSubmit={handleSubmit} className="form pt-5">
                    <Box sx={{ minWidth: 120 }}>
                      <FormGroup>
                        <Label for="" style={{fontWeight:"600"}}>Father Occuption</Label>
                        <Input
                          name="fatherOccupation"
                          type="select"
                          id="fatherOccupation"
                          onChange={handleChange}
                          style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          <option value="">Select Father Occupation</option>
                          {OccupationList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="" style={{fontWeight:"600"}}>Mother Occuption</Label>
                        <Input
                          name="motherOccupation"
                          type="select"
                          id="motherOccupation"
                          onChange={handleChange}
                          style={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          <option value="">Select Mother Occupation</option>
                          {OccupationList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="fianacialStatus" style={{fontWeight:"600"}}>
                          Select your Finacial Status
                        </Label>
                        <Input
                          name="fianacialStatus"
                          type="select"
                          id="fianacialStatus"
                          onChange={handleChange}
                          style={{ textTransform: "capitalize" }}
                        >
                          <option value="">Select Financial Status</option>
                          {financialStatus.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="familyType" style={{fontWeight:"600"}}>Select Family Type</Label>
                        <Input
                          name="familyType"
                          type="select"
                          id="familyType"
                          onChange={handleChange}
                        >
                          <option value="">Select Family Type</option>

                          {familyList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>{" "}
                      <FormGroup>
                        <Label for="familyValues" style={{fontWeight:"600"}}>Select Family Value</Label>
                        <Input
                          name="familyValues"
                          type="select"
                          id="familyValues"
                          onChange={handleChange}
                        >
                          <option value="">Select Family Value</option>

                          {familyValue.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="noOfBrothers" style={{fontWeight:"600"}}>Number of Brothers</Label>
                        <Input
                          name="noOfBrothers"
                          type="select"
                          id="noOfBrothers"
                          onChange={handleChange}
                        >
                          <option value="">Select No of Brothers</option>

                          {numberOfList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      {data.noOfBrothers > 0 ? (
                        <FormGroup tag="fieldset">
                          <Label style={{fontWeight:"600"}}>Brothers Married</Label>
                          <FormGroup>
                            <Input
                              name="brothersMarried"
                              type="select"
                              id="noOfBrothers"
                              onChange={handleChange}
                            >
                              <option value="">Select No of Brothers</option>

                              {numberOfList.map((x) => {
                                return <option value={x}>{x}</option>;
                              })}
                            </Input>
                          </FormGroup>
                        </FormGroup>
                      ) : null}
                      <FormGroup>
                        <Label for="noOfSisters" style={{fontWeight:"600"}}>Number of Sisters</Label>
                        <Input
                          name="noOfSisters"
                          type="select"
                          id="noOfSisters"
                          onChange={handleChange}
                        >
                          <option value="">Select No of Sisters</option>

                          {numberOfList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      {data.noOfSisters > 0 ? (
                        <FormGroup tag="fieldset" style={{fontWeight:"600"}}>
                          <Label style={{fontWeight:"600"}}>Sisters Married</Label>
                          <FormGroup>
                            <Input
                              style={{ border: "1px solid black" }}
                              name="sistersMarried"
                              type="select"
                              id="noOfSisters"
                              onChange={handleChange}
                            >
                              <option value="">
                                Select No of Sisters Married
                              </option>

                              {numberOfList.map((x) => {
                                return <option value={x}>{x}</option>;
                              })}
                            </Input>
                          </FormGroup>
                        </FormGroup>
                      ) : null}
                      <FormGroup>
                        <Label for="" style={{fontWeight:"600"}}>About Family</Label>
                        <Input
                          name="aboutFamily"
                          placeholder="About Family"
                          type="textarea"
                          id="aboutFamily"
                          value={aboutFamily}
                          onChange={handleFamilyChange}
                        />
                      </FormGroup>
                      <Button style={{ background: "black" }}>Submit</Button>
                    </Box>
                  </Form>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddFamily;
