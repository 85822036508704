import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Container } from "reactstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import api from "../../Axios";
import "./members.css";
import Usercard from "../../components/Usercard";
import SearchField from "../../components/SearchField";
const DashboardMember = () => {
  const [data, setData] = useState({});
  const params = useParams();

  const [render, setRerender] = useState(false);
  const [active, setActive] = useState([]);
  const { name: userName } = useParams();

  useEffect(() => {
    api.get("/admin/adminDashboard/count").then((res) => {
      const fetchedData = res.data.data;
      setData(fetchedData);
      console.log(fetchedData);

      // Dynamically set the active state based on the userName parameter
      if (userName in fetchedData) {
        setActive(fetchedData[userName]);
      }
    });
  }, [userName]);
  console.log(active);
  function deletee(i) {
    api.post("/admin/manageuserProfile/deleteUser", { id: i.id }).then(() => {
      api.get("/admin/allUsers/getRecentUsers").then((res) => {
        setActive(res.data.data);
      });
    });
  }

  const searchMember = () => {
    let search = document.getElementById("search_id").value;
    api
      .post("/admin/manageuserprofile/searchUser", {
        searchData: search,
        query: "active",
      })
      .then((v) => {
        setActive(v.data.data);
      });
  };
  function activeinactive(i) {
    api.post("/admin/manageuserProfile/deleteUser", { id: i.id }).then(() => {
      api.get("/admin/adminDashboard/activeMembers").then((res) => {
        setActive(res.data.data);
      });
    });
  }
  return (
    <div>
      <div className="content">
        <div className="notification-popup"></div>
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title" style={{display:"flex",justifyContent:"space-between",margin:"30px 0px"}}>
              <div className="float-left">
                <h3 className="title" style={{ textTransform: "capitilize" }}>
                  {userName} Members
                </h3>
              </div>
              <SearchField onSearch={() => searchMember()} />
            </div>
            <Row>
              {Array.isArray(active) && active.length > 0 ? (
                active.map((user, index) => (
                  <Usercard
                    key={index}
                    profileId={user.profileId}
                    id={user.id}
                    name={user.userName}
                    image={user.images}
                    age={user.age}
                    gender={user.gender}
                    dob={user.dob}
                    status={user.status}
                    state={user.state}
                    onActive={() => activeinactive(user)}
                    onDelete={() => deletee(user)}
                  />
                ))
              ) : (
                <div style={{ marginLeft: "500px", marginTop: "250px" }}>
                  <h3> Profile Not Found</h3>
                </div>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DashboardMember;
