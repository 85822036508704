import React, { useEffect, useState, useRef } from "react";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
// import Cropper from "react-easy-crop";
// import getCroppedImg from "./cropImage";
import { useParams, Link } from "react-router-dom";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import Usercard from "../../../components/Usercard";
import Swal from "sweetalert2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { RiEdit2Fill } from "react-icons/ri";
import api, { baseURL } from "../../../Axios";
import axios from "axios";
import style from "../../../assets/css/navtab.module.css";
import exportAsImage from "./exportToImage";
import Grid from "@mui/material/Grid";
import {
  Form,
  Label,
  Input,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardTitle,
  CardText,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import logo from "../../../assets/img/logos.jpeg";
import qr from "../../../assets/img/a1-matrimony.png";

import { InputLabel, MenuItem, Select } from "@mui/material";

const dashboard8 = () => {
  const notiRef = useRef();
  const exportRef = useRef();
  const [userdetails, setUserdetails] = useState("");
  const [familydetails, setFamilyDetails] = useState("");
  const [horoscopedetails, setHoroscopeDetails] = useState("");
  const [partnerdetails, setPartnerDetails] = useState("");
  const [profile, setProfile] = useState("");
  const [change, setChange] = useState(false);
  const [educhange, setEduchange] = useState(false);
  const [horo, setHoro] = useState(false);
  const [salarychange, setSalaryChange] = useState(false);
  const [selfiechange, setSelfieChange] = useState(false);
  const [familychange, setFamilyChange] = useState("");
  const [familyverify, setFamilyverify] = useState("0");

  const [aboutchange, setaboutchange] = useState("");
  const [aboutverify, setaboutverify] = useState("0");

  const [subCastechange, setSubCastechange] = useState("");
  const [subverify, setSubverify] = useState("0");
  const [gothramchange, setGothramchange] = useState("");
  const [gothramverify, setGothramverify] = useState("0");

  const [uploadedImage1, setUploadedImage1] = useState(null);
  const [uploadedImage2, setUploadedImage2] = useState(null);
  const [uploadedImage3, setUploadedImage3] = useState(null);
  const [uploadedImage4, setUploadedImage4] = useState(null);
  const [uploadedImage5, setUploadedImage5] = useState(null);
  const [uploadedImage6, setUploadedImage6] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);

  const [personalitydetails, setPersonalityDetails] = useState("");
  const [userdocumentdetails, setUserdocumentDetails] = useState("");
  const [salaryverify, setSalaryVerify] = useState("");
  const [deletedReason, setDeletedReason] = useState("");
  const [membershipType, setMembershipType] = useState("0");
  const [croppedArea, setCroppedArea] = useState(null);
  // const [isCropping, setIsCropping] = useState(true);
  // const [uploadedImage, setUploadedImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  // const [croppedImage, setCroppedImage] = useState(null);
  const [croppedImage1, setCroppedImage1] = useState(null);
  const [croppedImage2, setCroppedImage2] = useState(null);
  const [croppedImage3, setCroppedImage3] = useState(null);
  const [croppedImage4, setCroppedImage4] = useState(null);
  const [croppedImage5, setCroppedImage5] = useState(null);
  const [croppedImage6, setCroppedImage6] = useState(null);

  const [isCropping, setIsCropping] = useState(true);
  const [isCropping1, setIsCropping1] = useState(true);
  const [isCropping2, setIsCropping2] = useState(true);
  const [isCropping3, setIsCropping3] = useState(true);
  const [isCropping4, setIsCropping4] = useState(true);
  const [isCropping5, setIsCropping5] = useState(true);
  const [isCropping6, setIsCropping6] = useState(true);

  const cropperRef = useRef(null);
  const cropperRef1 = useRef(null);
  const cropperRef2 = useRef(null);
  const cropperRef3 = useRef(null);
  const cropperRef4 = useRef(null);
  const cropperRef5 = useRef(null);
  const cropperRef6 = useRef(null);

  const imageRef = useRef(null);
  const imageRef1 = useRef(null);
  const imageRef2 = useRef(null);
  const imageRef3 = useRef(null);
  const imageRef4 = useRef(null);
  const imageRef5 = useRef(null);
  const imageRef6 = useRef(null);

  const [activeTab, setActiveTab] = useState(1);
  function convertLongitudeToDMS(longitude) {
    // Convert longitude from radians to degrees
    const degrees = longitude * (180 / Math.PI);

    // Extract degrees
    const d = Math.floor(degrees);

    // Calculate minutes and seconds
    const minutes = Math.floor((degrees - d) * 60);
    const seconds = Math.round((degrees - d - minutes / 60) * 3600);

    // Format degrees, minutes, and seconds to desired length
    const formattedDegrees = d.toString().padStart(3, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    // Return formatted results
    return {
      degrees: formattedDegrees,
      minutes: formattedMinutes,
      seconds: formattedSeconds,
    };
  }
  const handleEditImage = () => {
    setIsCropping(true);
    setCroppedImage(null); // Clear the cropped image when re-editing
  };
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append("images", file, "croppedImage.png");

      try {
        const response = await axios.post(
          `/admin/manageUserProfile/croppedImages?id=${id}`,
          formData
        );
        if (response.status === 200) {
          console.log("Image uploaded successfully:", response.data);
          // Update state or provide feedback if needed
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        // Handle error, provide feedback to the user
      }
    }
  };
  const handleImageUpload1 = async (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage1(reader.result);
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append("image1", file, "croppedImage.png");

      try {
        const response = await axios.post(
          `/admin/manageUserProfile/croppedImages?id=${id}`,
          formData
        );
        if (response.status === 200) {
          console.log("Image uploaded successfully:", response.data);
          // Update state or provide feedback if needed
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        // Handle error, provide feedback to the user
      }
    }
  };
  const handleImageUpload2 = async (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage2(reader.result);
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append("image2", file, "croppedImage.png");

      try {
        const response = await axios.post(
          `/admin/manageUserProfile/croppedImages?id=${id}`,
          formData
        );
        if (response.status === 200) {
          console.log("Image uploaded successfully:", response.data);
          // Update state or provide feedback if needed
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        // Handle error, provide feedback to the user
      }
    }
  };
  const handleImageUpload3 = async (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage3(reader.result);
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append("image3", file, "croppedImage.png");

      try {
        const response = await axios.post(
          `/admin/manageUserProfile/croppedImages?id=${id}`,
          formData
        );
        if (response.status === 200) {
          console.log("Image uploaded successfully:", response.data);
          // Update state or provide feedback if needed
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        // Handle error, provide feedback to the user
      }
    }
  };
  const handleImageUpload4 = async (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage4(reader.result);
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append("image4", file, "croppedImage.png");

      try {
        const response = await axios.post(
          `/admin/manageUserProfile/croppedImages?id=${id}`,
          formData
        );
        if (response.status === 200) {
          console.log("Image uploaded successfully:", response.data);
          // Update state or provide feedback if needed
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        // Handle error, provide feedback to the user
      }
    }
  };
  const handleImageUpload5 = async (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage5(reader.result);
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append("image5", file, "croppedImage.png");

      try {
        const response = await axios.post(
          `/admin/manageUserProfile/croppedImages?id=${id}`,
          formData
        );
        if (response.status === 200) {
          console.log("Image uploaded successfully:", response.data);
          // Update state or provide feedback if needed
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        // Handle error, provide feedback to the user
      }
    }
  };
  const handleImageUpload6 = async (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage6(reader.result);
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append("image6", file, "croppedImage.png");

      try {
        const response = await axios.post(
          `/admin/manageUserProfile/croppedImages?id=${id}`,
          formData
        );
        if (response.status === 200) {
          console.log("Image uploaded successfully:", response.data);
          // Update state or provide feedback if needed
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        // Handle error, provide feedback to the user
      }
    }
  };
  // const [data, setData] = useState([]);
  // const [render, setRerender] = useState(false);
  const { id } = useParams();
  const [active, setActive] = useState(false);
  const [load, setLoad] = useState(false);

  const [state, setState] = React.useState({
    image1: false,
    image2: false,
    image3: false,
    image4: false,
    image5: false,
    image6: false,
  });
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const [membershiplist, setMembershipList] = useState();
  const [render, setRender] = useState(false);
  const { image1, image2, image3, image4, image5, image6 } = state;
  useEffect(() => {
    api
      .post("/admin/adminDashboard/userFullDetails", { id: parseInt(id) })
      .then((res) => {
        setUserdetails(res.data.data.userDetails);
        setFamilyDetails(res.data.data.familyDetails);
        setHoroscopeDetails(res.data.data.horoscopeDetails);
        setPartnerDetails(res.data.data.partnerDetails);
        setProfile(res.data.data.profile);
        setPersonalityDetails(res.data.data.personalityDetails);
        if (res.data.data.userDocumentDetails) {
          setUserdocumentDetails(res.data.data.userDocumentDetails);
        }
        // Check if profile exists before accessing deletedReason
        if (res.data.data.profile) {
          setDeletedReason(res.data.data.profile.deletedReason);
        }
        setaboutchange(res.data.data.personalityDetails.aboutUser1);
        setaboutverify(res.data.data.personalityDetails.aboutVerified);
        setFamilyChange(res.data.data.personalityDetails.aboutFamily1);
        setFamilyverify(res.data.data.personalityDetails.familyVerified);
        setSubCastechange(res.data.data.userDetails.subCaste);
        setSubverify(res.data.data.userDetails.subcasteApproved);
        setGothramchange(res.data.data.horoscopeDetails.gothram);
        setGothramverify(res.data.data.horoscopeDetails.gothramApproved);
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  }, []);
  const [view, setView] = useState({});
  const handleExport = () => {
    const element = document.getElementById("elementToExport");
    if (element) {
      exportAsImage(element, "horoscope.png");
    } else {
      console.error("Element with ID 'elementToExport' not found");
    }
  };
  useEffect(() => {
    api.post("/admin/adminDashboard/pdf", { id: parseInt(id) }).then((res) => {
      // console.log(res.data.data);
      setView(res.data.data);
    });
  }, [id]);
  useEffect(() => {
    api.get("/admin/membership/viewAllMembership").then((res) => {
      // console.log(res.data.data);
      setMembershipList(res.data.data);
    });
  }, [render]);

  function Imgverify(id, value) {
    api
      .post("/admin/adminDashboard/imageVerified", { id, verified: value })
      .then((res) => {
        // setChange(!change);
        api
          .post("/admin/adminDashboard/userFullDetails", { id })
          .then((res) => {
            setUserdetails(res.data.data.userDetails);
            setFamilyDetails(res.data.data.familyDetails);
            setHoroscopeDetails(res.data.data.horoscopeDetails);
            setPartnerDetails(res.data.data.partnerDetails);
            setProfile(res.data.data.profile);
            setPersonalityDetails(res.data.data.personalityDetails);
            if (res.data.data.userDocumentDetails) {
              setUserdocumentDetails(res.data.data.userDocumentDetails);
            }
            // Check if profile exists before accessing deletedReason
            if (res.data.data.profile) {
              setDeletedReason(res.data.data.profile.deletedReason);
            }
            setaboutchange(res.data.data.personalityDetails.aboutUser1);
            setaboutverify(res.data.data.personalityDetails.aboutVerified);
            setFamilyChange(res.data.data.personalityDetails.aboutFamily1);
            setFamilyverify(res.data.data.personalityDetails.familyVerified);
            setSubCastechange(res.data.data.userDetails.subCaste);
            setSubverify(res.data.data.userDetails.subcasteApproved);
            setGothramchange(res.data.data.horoscopeDetails.gothram);
            setGothramverify(res.data.data.horoscopeDetails.gothramApproved);
          });
      });
  }

  useEffect(() => {
    if (profile && imageRef.current && isCropping) {
      const cropper = new Cropper(imageRef.current, {
        aspectRatio: 4 / 3,
        viewMode: 2,
        dragMode: "move",
        autoCropArea: 1,
        crop() {
          if (cropperRef.current) {
            const canvas = cropperRef.current.getCroppedCanvas();
            if (canvas) {
              const croppedDataUrl = canvas.toDataURL();
              setCroppedImage(croppedDataUrl);
            }
          }
        },
      });
      cropperRef.current = cropper;
    }
    if (profile && imageRef1.current && isCropping1) {
      const cropper = new Cropper(imageRef1.current, {
        aspectRatio: 4 / 3,
        viewMode: 2,
        dragMode: "move",
        autoCropArea: 1,
        crop() {
          if (cropperRef1.current) {
            const canvas = cropperRef1.current.getCroppedCanvas();
            if (canvas) {
              const croppedDataUrl = canvas.toDataURL();
              setCroppedImage1(croppedDataUrl);
            }
          }
        },
      });
      cropperRef1.current = cropper;
    }
    if (profile && imageRef2.current && isCropping2) {
      const cropper = new Cropper(imageRef2.current, {
        aspectRatio: 4 / 3,
        viewMode: 2,
        dragMode: "move",
        autoCropArea: 1,
        crop() {
          if (cropperRef2.current) {
            const canvas = cropperRef2.current.getCroppedCanvas();
            if (canvas) {
              const croppedDataUrl = canvas.toDataURL();
              setCroppedImage2(croppedDataUrl);
            }
          }
        },
      });
      cropperRef2.current = cropper;
    }
    if (profile && imageRef3.current && isCropping3) {
      const cropper = new Cropper(imageRef3.current, {
        aspectRatio: 4 / 3,
        viewMode: 2,
        dragMode: "move",
        autoCropArea: 1,
        crop() {
          if (cropperRef3.current) {
            const canvas = cropperRef3.current.getCroppedCanvas();
            if (canvas) {
              const croppedDataUrl = canvas.toDataURL();
              setCroppedImage3(croppedDataUrl);
            }
          }
        },
      });
      cropperRef3.current = cropper;
    }
    if (profile && imageRef4.current && isCropping4) {
      const cropper = new Cropper(imageRef4.current, {
        aspectRatio: 4 / 3,
        viewMode: 2,
        dragMode: "move",
        autoCropArea: 1,
        crop() {
          if (cropperRef4.current) {
            const canvas = cropperRef4.current.getCroppedCanvas();
            if (canvas) {
              const croppedDataUrl = canvas.toDataURL();
              setCroppedImage4(croppedDataUrl);
            }
          }
        },
      });
      cropperRef4.current = cropper;
    }
    if (profile && imageRef5.current && isCropping5) {
      const cropper = new Cropper(imageRef5.current, {
        aspectRatio: 4 / 3,
        viewMode: 2,
        dragMode: "move",
        autoCropArea: 1,
        crop() {
          if (cropperRef5.current) {
            const canvas = cropperRef5.current.getCroppedCanvas();
            if (canvas) {
              const croppedDataUrl = canvas.toDataURL();
              setCroppedImage5(croppedDataUrl);
            }
          }
        },
      });
      cropperRef5.current = cropper;
    }
    if (profile && imageRef6.current && isCropping6) {
      const cropper = new Cropper(imageRef6.current, {
        aspectRatio: 4 / 3,
        viewMode: 2,
        dragMode: "move",
        autoCropArea: 1,
        crop() {
          if (cropperRef6.current) {
            const canvas = cropperRef6.current.getCroppedCanvas();
            if (canvas) {
              const croppedDataUrl = canvas.toDataURL();
              setCroppedImage6(croppedDataUrl);
            }
          }
        },
      });
      cropperRef6.current = cropper;
    }
  }, [
    profile,
    isCropping,
    isCropping1,
    isCropping2,
    isCropping3,
    isCropping4,
    isCropping5,
    isCropping6,
  ]);
  useEffect(() => {
    const savedTab = sessionStorage.getItem("activeTab");

    if (savedTab) {
      setActiveTab(parseInt(savedTab, 10)); // Set active tab from sessionStorage
      sessionStorage.removeItem("activeTab"); // Clear the saved tab after setting
    }
  }, []);
  const cropImage = async () => {
    if (!cropperRef.current) {
      console.error("Cropper is not initialized");
      return;
    }

    const canvas = cropperRef.current.getCroppedCanvas();
    if (!canvas) {
      console.error("Canvas is null");
      return;
    }

    // Convert the canvas to a Blob
    canvas.toBlob(async (blob) => {
      if (!blob) {
        console.error("Blob is null");
        return;
      }

      const formData = new FormData();
      formData.append("images", blob, "croppedImage.png");

      // Create a blob URL for previewing the cropped image
      const blobUrl = URL.createObjectURL(blob);
      setCroppedImage(blobUrl); // Update the preview image immediately

      try {
        // Upload the cropped image
        const res = await api.post(
          `/admin/manageUserProfile/croppedImages?id=${id}`,
          formData
        );
        window.location.reload();
        // Trigger a re-fetch of user details after the image upload
        setEduchange((prevEduChange) => !prevEduChange); // This likely triggers a re-fetch

        const userDetailsRes = await api.post(
          "/admin/adminDashboard/userFullDetails",
          { id }
        );

        const userData = userDetailsRes.data.data;

        // Update user details after fetching them
        setUserdetails(userData.userDetails);
        setFamilyDetails(userData.familyDetails);
        setHoroscopeDetails(userData.horoscopeDetails);
        setPartnerDetails(userData.partnerDetails);
        setProfile(userData.profile);
        setPersonalityDetails(userData.personalityDetails);
        setUserdocumentDetails(userData.userDocumentDetails);
        setDeletedReason(userData.profile.deletedReason);
        sessionStorage.setItem("activeTab", "6"); // Store Tab 6
        window.location.reload();
      } catch (error) {
        console.error(
          "Error uploading cropped image or fetching user details",
          error
        );
      }
    });
  };

  const cropImage6 = async () => {
    if (!cropperRef6.current) return;

    const canvas = cropperRef6.current.getCroppedCanvas();
    if (!canvas) {
      console.error("Canvas is null");
      return;
    }

    canvas.toBlob(async (blob) => {
      if (!blob) return;

      const formData = new FormData();
      formData.append("image6", blob, "croppedImage.png");

      const blobUrl = URL.createObjectURL(blob);
      setCroppedImage6(blobUrl);

      try {
        const res = await api.post(
          `/admin/manageUserProfile/croppedImages?id=${id}`,
          formData
        );
        window.location.reload();
        setEduchange((prevEduChange) => !prevEduChange);

        const userDetailsRes = await api.post(
          "/admin/adminDashboard/userFullDetails",
          { id }
        );
        const userData = userDetailsRes.data.data;

        setUserdetails(userData.userDetails);
        setFamilyDetails(userData.familyDetails);
        setHoroscopeDetails(userData.horoscopeDetails);
        setPartnerDetails(userData.partnerDetails);
        setProfile(userData.profile);
        setPersonalityDetails(userData.personalityDetails);
        setUserdocumentDetails(userData.userDocumentDetails);
        setDeletedReason(userData.profile.deletedReason);
      } catch (error) {
        console.error(
          "Error uploading cropped image or fetching user details",
          error
        );
      } finally {
        setIsCropping6(false);
      }
    });
  };
  const cropImage5 = async () => {
    if (!cropperRef5.current) return;

    const canvas = cropperRef5.current.getCroppedCanvas();
    if (!canvas) {
      console.error("Canvas is null");
      return;
    }

    canvas.toBlob(async (blob) => {
      if (!blob) return;

      const formData = new FormData();
      formData.append("image5", blob, "croppedImage.png");

      const blobUrl = URL.createObjectURL(blob);
      setCroppedImage5(blobUrl);

      try {
        const res = await api.post(
          `/admin/manageUserProfile/croppedImages?id=${id}`,
          formData
        );
        window.location.reload();
        setEduchange((prevEduChange) => !prevEduChange);

        const userDetailsRes = await api.post(
          "/admin/adminDashboard/userFullDetails",
          { id }
        );
        const userData = userDetailsRes.data.data;

        setUserdetails(userData.userDetails);
        setFamilyDetails(userData.familyDetails);
        setHoroscopeDetails(userData.horoscopeDetails);
        setPartnerDetails(userData.partnerDetails);
        setProfile(userData.profile);
        setPersonalityDetails(userData.personalityDetails);
        setUserdocumentDetails(userData.userDocumentDetails);
        setDeletedReason(userData.profile.deletedReason);
      } catch (error) {
        console.error(
          "Error uploading cropped image or fetching user details",
          error
        );
      } finally {
        setIsCropping5(false);
      }
    });
  };
  const cropImage4 = async () => {
    if (!cropperRef4.current) return;

    const canvas = cropperRef4.current.getCroppedCanvas();
    if (!canvas) {
      console.error("Canvas is null");
      return;
    }

    canvas.toBlob(async (blob) => {
      if (!blob) return;

      const formData = new FormData();
      formData.append("image4", blob, "croppedImage.png");

      const blobUrl = URL.createObjectURL(blob);
      setCroppedImage4(blobUrl);

      try {
        const res = await api.post(
          `/admin/manageUserProfile/croppedImages?id=${id}`,
          formData
        );
        window.location.reload();
        setEduchange((prevEduChange) => !prevEduChange);

        const userDetailsRes = await api.post(
          "/admin/adminDashboard/userFullDetails",
          { id }
        );
        const userData = userDetailsRes.data.data;

        setUserdetails(userData.userDetails);
        setFamilyDetails(userData.familyDetails);
        setHoroscopeDetails(userData.horoscopeDetails);
        setPartnerDetails(userData.partnerDetails);
        setProfile(userData.profile);
        setPersonalityDetails(userData.personalityDetails);
        setUserdocumentDetails(userData.userDocumentDetails);
        setDeletedReason(userData.profile.deletedReason);
      } catch (error) {
        console.error(
          "Error uploading cropped image or fetching user details",
          error
        );
      } finally {
        setIsCropping4(false);
      }
    });
  };
  const cropImage3 = async () => {
    if (!cropperRef3.current) return;

    const canvas = cropperRef3.current.getCroppedCanvas();
    if (!canvas) {
      console.error("Canvas is null");
      return;
    }

    canvas.toBlob(async (blob) => {
      if (!blob) return;

      const formData = new FormData();
      formData.append("image3", blob, "croppedImage.png");

      const blobUrl = URL.createObjectURL(blob);
      setCroppedImage3(blobUrl);

      try {
        const res = await api.post(
          `/admin/manageUserProfile/croppedImages?id=${id}`,
          formData
        );
        window.location.reload();
        setEduchange((prevEduChange) => !prevEduChange);

        const userDetailsRes = await api.post(
          "/admin/adminDashboard/userFullDetails",
          { id }
        );
        const userData = userDetailsRes.data.data;

        setUserdetails(userData.userDetails);
        setFamilyDetails(userData.familyDetails);
        setHoroscopeDetails(userData.horoscopeDetails);
        setPartnerDetails(userData.partnerDetails);
        setProfile(userData.profile);
        setPersonalityDetails(userData.personalityDetails);
        setUserdocumentDetails(userData.userDocumentDetails);
        setDeletedReason(userData.profile.deletedReason);
      } catch (error) {
        console.error(
          "Error uploading cropped image or fetching user details",
          error
        );
      } finally {
        setIsCropping3(false);
      }
    });
  };
  const cropImage2 = async () => {
    if (!cropperRef2.current) return;

    const canvas = cropperRef2.current.getCroppedCanvas();
    if (!canvas) {
      console.error("Canvas is null");
      return;
    }

    canvas.toBlob(async (blob) => {
      if (!blob) return;

      const formData = new FormData();
      formData.append("image2", blob, "croppedImage.png");

      const blobUrl = URL.createObjectURL(blob);
      setCroppedImage2(blobUrl);

      try {
        const res = await api.post(
          `/admin/manageUserProfile/croppedImages?id=${id}`,
          formData
        );
        window.location.reload();
        setEduchange((prevEduChange) => !prevEduChange);

        const userDetailsRes = await api.post(
          "/admin/adminDashboard/userFullDetails",
          { id }
        );
        const userData = userDetailsRes.data.data;

        setUserdetails(userData.userDetails);
        setFamilyDetails(userData.familyDetails);
        setHoroscopeDetails(userData.horoscopeDetails);
        setPartnerDetails(userData.partnerDetails);
        setProfile(userData.profile);
        setPersonalityDetails(userData.personalityDetails);
        setUserdocumentDetails(userData.userDocumentDetails);
        setDeletedReason(userData.profile.deletedReason);
      } catch (error) {
        console.error(
          "Error uploading cropped image or fetching user details",
          error
        );
      } finally {
        setIsCropping2(false);
      }
    });
  };
  const cropImage1 = async () => {
    if (!cropperRef1.current) return;

    const canvas = cropperRef1.current.getCroppedCanvas();
    if (!canvas) {
      console.error("Canvas is null");
      return;
    }

    canvas.toBlob(async (blob) => {
      if (!blob) return;

      const formData = new FormData();
      formData.append("image1", blob, "croppedImage.png");

      const blobUrl = URL.createObjectURL(blob);
      setCroppedImage1(blobUrl);

      try {
        const res = await api.post(
          `/admin/manageUserProfile/croppedImages?id=${id}`,
          formData
        );
        window.location.reload();
        setEduchange((prevEduChange) => !prevEduChange);
        const multipleImageVerified = [
          image1 ? 1 : image1 === 2 ? 2 : 0,
          image2 ? 1 : image2 === 2 ? 2 : 0,
          image3 ? 1 : image3 === 2 ? 2 : 0,
          image4 ? 1 : image4 === 2 ? 2 : 0,
          image5 ? 1 : image5 === 2 ? 2 : 0,
          image6 ? 1 : image6 === 2 ? 2 : 0,
        ];
        api
          .post("/admin/adminDashboard/multipleImageVerified", {
            id,
            multipleImageVerified,
          })
          .then((res) => {
            Swal.fire(res.data.data);
            setChange(!change);
          });
        const userDetailsRes = await api.post(
          "/admin/adminDashboard/userFullDetails",
          { id }
        );
        const userData = userDetailsRes.data.data;

        setUserdetails(userData.userDetails);
        setFamilyDetails(userData.familyDetails);
        setHoroscopeDetails(userData.horoscopeDetails);
        setPartnerDetails(userData.partnerDetails);
        setProfile(userData.profile);
        setPersonalityDetails(userData.personalityDetails);
        setUserdocumentDetails(userData.userDocumentDetails);
        setDeletedReason(userData.profile.deletedReason);
      } catch (error) {
        console.error(
          "Error uploading cropped image or fetching user details",
          error
        );
      } finally {
        setIsCropping1(false);
      }
    });
  };

  const [membershipid, setMembershipid] = useState("");
  const [remarks, setRemarks] = useState("");

  function activeMembershipManual() {
    api
      .post("/admin/membership/activateMembershipManualy", {
        id: profile.id,
        "mambership Id": membershipid,
        remarks: remarks,
      })
      .then((res) => {
        Swal.fire(res.data.data);
        setMembershipType(membershipid);
        api
          .post("/admin/adminDashboard/userFullDetails", { id })
          .then((res) => {
            setUserdetails(res.data.data.userDetails);
            setFamilyDetails(res.data.data.familyDetails);
            setHoroscopeDetails(res.data.data.horoscopeDetails);
            setPartnerDetails(res.data.data.partnerDetails);
            setProfile(res.data.data.profile);
            setPersonalityDetails(res.data.data.personalityDetails);
            if (res.data.data.userDocumentDetails) {
              setUserdocumentDetails(res.data.data.userDocumentDetails);
            }
            // Check if profile exists before accessing deletedReason
            if (res.data.data.profile) {
              setDeletedReason(res.data.data.profile.deletedReason);
            }
            setaboutchange(res.data.data.personalityDetails.aboutUser1);
            setaboutverify(res.data.data.personalityDetails.aboutVerified);
            setFamilyChange(res.data.data.personalityDetails.aboutFamily1);
            setFamilyverify(res.data.data.personalityDetails.familyVerified);
            setSubCastechange(res.data.data.userDetails.subCaste);
            setSubverify(res.data.data.userDetails.subcasteApproved);
            setGothramchange(res.data.data.horoscopeDetails.gothram);
            setGothramverify(res.data.data.horoscopeDetails.gothramApproved);
          });
      });
  }
  function verifySubcaste(id, about) {
    var context = {
      id: id,
      verified: subverify === "1" ? "0" : "1",
      subCaste: subCastechange,
    };
    api.post("/admin/adminDashboard/subcasteApproved", context).then((res) => {
      Swal.fire(res.data.data);

      // setaboutchange(about);
      api.post("/admin/adminDashboard/userFullDetails", { id }).then((res) => {
        setUserdetails(res.data.data.userDetails);
        setFamilyDetails(res.data.data.familyDetails);
        setHoroscopeDetails(res.data.data.horoscopeDetails);
        setPartnerDetails(res.data.data.partnerDetails);
        setProfile(res.data.data.profile);
        setPersonalityDetails(res.data.data.personalityDetails);
        if (res.data.data.userDocumentDetails) {
          setUserdocumentDetails(res.data.data.userDocumentDetails);
        }
        // Check if profile exists before accessing deletedReason
        if (res.data.data.profile) {
          setDeletedReason(res.data.data.profile.deletedReason);
        }
        setaboutchange(res.data.data.personalityDetails.aboutUser1);
        setaboutverify(res.data.data.personalityDetails.aboutVerified);
        setFamilyChange(res.data.data.personalityDetails.aboutFamily1);
        setFamilyverify(res.data.data.personalityDetails.familyVerified);
        setSubCastechange(res.data.data.userDetails.subCaste);
        setSubverify(res.data.data.userDetails.subcasteApproved);
        setGothramchange(res.data.data.horoscopeDetails.gothram);
        setGothramverify(res.data.data.horoscopeDetails.gothramApproved);
      });
    });
  }
  function verifygothram(id, about) {
    var context = {
      id: id,
      verified: gothramverify === "1" ? "0" : "1",
      gothram: gothramchange,
    };
    api.post("/admin/adminDashboard/gothramApproved", context).then((res) => {
      Swal.fire(res.data.data);

      // setaboutchange(about);
      api.post("/admin/adminDashboard/userFullDetails", { id }).then((res) => {
        setUserdetails(res.data.data.userDetails);
        setFamilyDetails(res.data.data.familyDetails);
        setHoroscopeDetails(res.data.data.horoscopeDetails);
        setPartnerDetails(res.data.data.partnerDetails);
        setProfile(res.data.data.profile);
        setPersonalityDetails(res.data.data.personalityDetails);
        if (res.data.data.userDocumentDetails) {
          setUserdocumentDetails(res.data.data.userDocumentDetails);
        }
        // Check if profile exists before accessing deletedReason
        if (res.data.data.profile) {
          setDeletedReason(res.data.data.profile.deletedReason);
        }
        setaboutchange(res.data.data.personalityDetails.aboutUser1);
        setaboutverify(res.data.data.personalityDetails.aboutVerified);
        setFamilyChange(res.data.data.personalityDetails.aboutFamily1);
        setFamilyverify(res.data.data.personalityDetails.familyVerified);
        setSubCastechange(res.data.data.userDetails.subCaste);
        setSubverify(res.data.data.userDetails.subcasteApproved);
        setGothramchange(res.data.data.horoscopeDetails.gothram);
        setGothramverify(res.data.data.horoscopeDetails.gothramApproved);
      });
    });
  }
  function verifyAbout(id, about) {
    var context = {
      id: id,
      verified: aboutverify === "1" ? "0" : "1",
      aboutUser: aboutchange,
    };
    api.post("/admin/adminDashboard/aboutUserVerified", context).then((res) => {
      Swal.fire(res.data.data);

      // setaboutchange(about);
      api.post("/admin/adminDashboard/userFullDetails", { id }).then((res) => {
        setUserdetails(res.data.data.userDetails);
        setFamilyDetails(res.data.data.familyDetails);
        setHoroscopeDetails(res.data.data.horoscopeDetails);
        setPartnerDetails(res.data.data.partnerDetails);
        setProfile(res.data.data.profile);
        setPersonalityDetails(res.data.data.personalityDetails);
        if (res.data.data.userDocumentDetails) {
          setUserdocumentDetails(res.data.data.userDocumentDetails);
        }
        // Check if profile exists before accessing deletedReason
        if (res.data.data.profile) {
          setDeletedReason(res.data.data.profile.deletedReason);
        }
        setaboutchange(res.data.data.personalityDetails.aboutUser1);
        setaboutverify(res.data.data.personalityDetails.aboutVerified);
        setFamilyChange(res.data.data.personalityDetails.aboutFamily1);
        setFamilyverify(res.data.data.personalityDetails.familyVerified);
        setSubCastechange(res.data.data.userDetails.subCaste);
        setSubverify(res.data.data.userDetails.subcasteApproved);
        setGothramchange(res.data.data.horoscopeDetails.gothram);
        setGothramverify(res.data.data.horoscopeDetails.gothramApproved);
      });
    });
  }
  function verifyFamily(id) {
    var context = {
      id: id,
      verified: familyverify === "1" ? "0" : "1",
      aboutFamily: familychange,
    };
    api.post("admin/adminDashboard/familyVerified", context).then((res) => {
      Swal.fire(res.data.data);
      // setFamilyChange(family);

      api.post("/admin/adminDashboard/userFullDetails", { id }).then((res) => {
        setUserdetails(res.data.data.userDetails);
        setFamilyDetails(res.data.data.familyDetails);
        setHoroscopeDetails(res.data.data.horoscopeDetails);
        setPartnerDetails(res.data.data.partnerDetails);
        setProfile(res.data.data.profile);
        setPersonalityDetails(res.data.data.personalityDetails);
        if (res.data.data.userDocumentDetails) {
          setUserdocumentDetails(res.data.data.userDocumentDetails);
        }
        // Check if profile exists before accessing deletedReason
        if (res.data.data.profile) {
          setDeletedReason(res.data.data.profile.deletedReason);
        }
        setaboutchange(res.data.data.personalityDetails.aboutUser1);
        setaboutverify(res.data.data.personalityDetails.aboutVerified);
        setFamilyChange(res.data.data.personalityDetails.aboutFamily1);
        setFamilyverify(res.data.data.personalityDetails.familyVerified);
        setSubCastechange(res.data.data.userDetails.subCaste);
        setSubverify(res.data.data.userDetails.subcasteApproved);
        setGothramchange(res.data.data.horoscopeDetails.gothram);
        setGothramverify(res.data.data.horoscopeDetails.gothramApproved);
      });
    });
  }
  function imageveriy(id, value) {
    const multipleImageVerified = [
      image1 ? 1 : image1 === 2 ? 2 : 0,
      image2 ? 1 : image2 === 2 ? 2 : 0,
      image3 ? 1 : image3 === 2 ? 2 : 0,
      image4 ? 1 : image4 === 2 ? 2 : 0,
      image5 ? 1 : image5 === 2 ? 2 : 0,
      image6 ? 1 : image6 === 2 ? 2 : 0,
    ];

    // Make API call with the imagesToVerify array
    api
      .post("/admin/adminDashboard/multipleImageVerified", {
        id,
        multipleImageVerified,
        verified: value,
      })
      .then((res) => {
        Swal.fire(res.data.data);
        setChange(!change);
        api
          .post("/admin/adminDashboard/userFullDetails", { id })
          .then((res) => {
            setUserdetails(res.data.data.userDetails);
            setFamilyDetails(res.data.data.familyDetails);
            setHoroscopeDetails(res.data.data.horoscopeDetails);
            setPartnerDetails(res.data.data.partnerDetails);
            setProfile(res.data.data.profile);
            setPersonalityDetails(res.data.data.personalityDetails);
            if (res.data.data.userDocumentDetails) {
              setUserdocumentDetails(res.data.data.userDocumentDetails);
            }
            // Check if profile exists before accessing deletedReason
            if (res.data.data.profile) {
              setDeletedReason(res.data.data.profile.deletedReason);
            }
            setaboutchange(res.data.data.personalityDetails.aboutUser1);
            setaboutverify(res.data.data.personalityDetails.aboutVerified);
            setFamilyChange(res.data.data.personalityDetails.aboutFamily1);
            setFamilyverify(res.data.data.personalityDetails.familyVerified);
            setSubCastechange(res.data.data.userDetails.subCaste);
            setSubverify(res.data.data.userDetails.subcasteApproved);
            setGothramchange(res.data.data.horoscopeDetails.gothram);
            setGothramverify(res.data.data.horoscopeDetails.gothramApproved);
          });
      });
  }

  function verifieds(id) {
    api
      .post("/admin/adminDashboard/educationDetailsVerified", {
        id,
      })
      .then((res) => {
        setEduchange(!educhange);
        api
          .post("/admin/adminDashboard/userFullDetails", { id })
          .then((res) => {
            setUserdetails(res.data.data.userDetails);
            setFamilyDetails(res.data.data.familyDetails);
            setHoroscopeDetails(res.data.data.horoscopeDetails);
            setPartnerDetails(res.data.data.partnerDetails);
            setProfile(res.data.data.profile);
            setPersonalityDetails(res.data.data.personalityDetails);
            if (res.data.data.userDocumentDetails) {
              setUserdocumentDetails(res.data.data.userDocumentDetails);
            }
            // Check if profile exists before accessing deletedReason
            if (res.data.data.profile) {
              setDeletedReason(res.data.data.profile.deletedReason);
            }
            setaboutchange(res.data.data.personalityDetails.aboutUser1);
            setaboutverify(res.data.data.personalityDetails.aboutVerified);
            setFamilyChange(res.data.data.personalityDetails.aboutFamily1);
            setFamilyverify(res.data.data.personalityDetails.familyVerified);
            setSubCastechange(res.data.data.userDetails.subCaste);
            setSubverify(res.data.data.userDetails.subcasteApproved);
            setGothramchange(res.data.data.horoscopeDetails.gothram);
            setGothramverify(res.data.data.horoscopeDetails.gothramApproved);
          });
      });
  }
  function verifihoro(id) {
    api
      .post("/admin/adminDashboard/horoscopeVerified", {
        id,
      })
      .then((res) => {
        setHoro(!horo);
        api
          .post("/admin/adminDashboard/userFullDetails", { id })
          .then((res) => {
            setUserdetails(res.data.data.userDetails);
            setFamilyDetails(res.data.data.familyDetails);
            setHoroscopeDetails(res.data.data.horoscopeDetails);
            setPartnerDetails(res.data.data.partnerDetails);
            setProfile(res.data.data.profile);
            setPersonalityDetails(res.data.data.personalityDetails);
            if (res.data.data.userDocumentDetails) {
              setUserdocumentDetails(res.data.data.userDocumentDetails);
            }
            // Check if profile exists before accessing deletedReason
            if (res.data.data.profile) {
              setDeletedReason(res.data.data.profile.deletedReason);
            }
            setaboutchange(res.data.data.personalityDetails.aboutUser1);
            setaboutverify(res.data.data.personalityDetails.aboutVerified);
            setFamilyChange(res.data.data.personalityDetails.aboutFamily1);
            setFamilyverify(res.data.data.personalityDetails.familyVerified);
            setSubCastechange(res.data.data.userDetails.subCaste);
            setSubverify(res.data.data.userDetails.subcasteApproved);
            setGothramchange(res.data.data.horoscopeDetails.gothram);
            setGothramverify(res.data.data.horoscopeDetails.gothramApproved);
          });
      });
  }
  function salaryVerifys(id) {
    api.post("/admin/adminDashboard/salaryVerified", { id }).then((res) => {
      setSalaryChange(!salarychange);
      api.post("/admin/adminDashboard/userFullDetails", { id }).then((res) => {
        setUserdetails(res.data.data.userDetails);
        setFamilyDetails(res.data.data.familyDetails);
        setHoroscopeDetails(res.data.data.horoscopeDetails);
        setPartnerDetails(res.data.data.partnerDetails);
        setProfile(res.data.data.profile);
        setPersonalityDetails(res.data.data.personalityDetails);
        if (res.data.data.userDocumentDetails) {
          setUserdocumentDetails(res.data.data.userDocumentDetails);
        }
        // Check if profile exists before accessing deletedReason
        if (res.data.data.profile) {
          setDeletedReason(res.data.data.profile.deletedReason);
        }
        setaboutchange(res.data.data.personalityDetails.aboutUser1);
        setaboutverify(res.data.data.personalityDetails.aboutVerified);
        setFamilyChange(res.data.data.personalityDetails.aboutFamily1);
        setFamilyverify(res.data.data.personalityDetails.familyVerified);
        setSubCastechange(res.data.data.userDetails.subCaste);
        setSubverify(res.data.data.userDetails.subcasteApproved);
        setGothramchange(res.data.data.horoscopeDetails.gothram);
        setGothramverify(res.data.data.horoscopeDetails.gothramApproved);
      });
    });
  }
  function selfieVerify(id) {
    api.post("/admin/adminDashboard/selfieVerified", { id }).then((res) => {
      setSelfieChange(!selfiechange);
      api.post("/admin/adminDashboard/userFullDetails", { id }).then((res) => {
        setUserdetails(res.data.data.userDetails);
        setFamilyDetails(res.data.data.familyDetails);
        setHoroscopeDetails(res.data.data.horoscopeDetails);
        setPartnerDetails(res.data.data.partnerDetails);
        setProfile(res.data.data.profile);
        setPersonalityDetails(res.data.data.personalityDetails);
        if (res.data.data.userDocumentDetails) {
          setUserdocumentDetails(res.data.data.userDocumentDetails);
        }
        // Check if profile exists before accessing deletedReason
        if (res.data.data.profile) {
          setDeletedReason(res.data.data.profile.deletedReason);
        }
        setaboutchange(res.data.data.personalityDetails.aboutUser1);
        setaboutverify(res.data.data.personalityDetails.aboutVerified);
        setFamilyChange(res.data.data.personalityDetails.aboutFamily1);
        setFamilyverify(res.data.data.personalityDetails.familyVerified);
        setSubCastechange(res.data.data.userDetails.subCaste);
        setSubverify(res.data.data.userDetails.subcasteApproved);
        setGothramchange(res.data.data.horoscopeDetails.gothram);
        setGothramverify(res.data.data.horoscopeDetails.gothramApproved);
      });
    });
  }
  function activee(id) {
    api.post("/admin/manageuserProfile/deleteUser", { id }).then(() => {
      setActive(!active);
      api.post("/admin/adminDashboard/userFullDetails", { id }).then((res) => {
        setUserdetails(res.data.data.userDetails);
        setFamilyDetails(res.data.data.familyDetails);
        setHoroscopeDetails(res.data.data.horoscopeDetails);
        setPartnerDetails(res.data.data.partnerDetails);
        setProfile(res.data.data.profile);
        setPersonalityDetails(res.data.data.personalityDetails);
        if (res.data.data.userDocumentDetails) {
          setUserdocumentDetails(res.data.data.userDocumentDetails);
        }
        // Check if profile exists before accessing deletedReason
        if (res.data.data.profile) {
          setDeletedReason(res.data.data.profile.deletedReason);
        }
        setaboutchange(res.data.data.personalityDetails.aboutUser1);
        setaboutverify(res.data.data.personalityDetails.aboutVerified);
        setFamilyChange(res.data.data.personalityDetails.aboutFamily1);
        setFamilyverify(res.data.data.personalityDetails.familyVerified);
        setSubCastechange(res.data.data.userDetails.subCaste);
        setSubverify(res.data.data.userDetails.subcasteApproved);
        setGothramchange(res.data.data.horoscopeDetails.gothram);
        setGothramverify(res.data.data.horoscopeDetails.gothramApproved);
      });
    });
  }

  function deletee(id) {
    api.post("/admin/manageuserProfile/terminateUser", { id }).then(() => {
      api.post("/admin/adminDashboard/userFullDetails", { id });
    });
  }
  const scrollContainerRef = useRef(null);
  const handleScroll = (scrollOffset) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: scrollOffset,
        behavior: "smooth",
      });
    }
  };
  const [proShortList, setProShortList] = useState([]);
  function profileShortlist() {
    api
      .post("/admin/adminDashboard/userActivity", {
        id: profile.id,
        value: "shortlistedMyProfile",
      })
      .then((res) => {
        setProShortList(res.data.data);
      });
  }
  const [shortList, setShortList] = useState([]);
  function shortlist() {
    api
      .post("/admin/adminDashboard/userActivity", {
        id: profile.id,
        value: "shortlistedProfiles",
      })
      .then((res) => {
        setShortList(res.data.data);
      });
  }
  const [likeProfile, setLikedProfiles] = useState([]);
  function likedProfile() {
    api
      .post("/admin/adminDashboard/userActivity", {
        id: profile.id,
        value: "likedProfiles",
      })
      .then((res) => {
        setLikedProfiles(res.data.data);
      });
  }
  const [likeMyProfile, setLikedMyProfiles] = useState([]);
  function likedMyProfile() {
    api
      .post("/admin/adminDashboard/userActivity", {
        id: profile.id,
        value: "likedMyProfile",
      })
      .then((res) => {
        setLikedMyProfiles(res.data.data);
      });
  }
  const [intrestMyProfile, setIntrestMyProfiles] = useState([]);
  function interestedMyProfile() {
    api
      .post("/admin/adminDashboard/userActivity", {
        id: profile.id,
        value: "interestedMyProfile",
      })
      .then((res) => {
        setIntrestMyProfiles(res.data.data);
      });
  }
  const [intrestProfile, setIntrestProfiles] = useState([]);
  function interestedProfiles() {
    api
      .post("/admin/adminDashboard/userActivity", {
        id: profile.id,
        value: "interestedProfiles",
      })
      .then((res) => {
        setIntrestProfiles(res.data.data);
      });
  }
  let hobbiesAndInterests = "None";
  let music = "None";
  let reading = "None";
  let food = "None";
  let moviesAndTvShows = "None";
  let sportsAndFitness = "None";
  let spokenLanguages = "None";

  if (horoscopedetails && horoscopedetails.hobbies) {
    hobbiesAndInterests = horoscopedetails.hobbies["Hobbies & interests"]
      ? horoscopedetails.hobbies["Hobbies & interests"].join(", ")
      : "None";
    music = horoscopedetails.hobbies["Music"]
      ? horoscopedetails.hobbies["Music"].join(", ")
      : "None";
    reading = horoscopedetails.hobbies["Reading"]
      ? horoscopedetails.hobbies["Reading"].join(", ")
      : "None";
    food = horoscopedetails.hobbies["Food"]
      ? horoscopedetails.hobbies["Food"].join(", ")
      : "None";
    moviesAndTvShows = horoscopedetails.hobbies["Movies & Tv Shows"]
      ? horoscopedetails.hobbies["Movies & Tv Shows"].join(", ")
      : "None";
    sportsAndFitness = horoscopedetails.hobbies["Sports & Fitness"]
      ? horoscopedetails.hobbies["Sports & Fitness"].join(", ")
      : "None";
    spokenLanguages = horoscopedetails.hobbies["Spoken Languages"]
      ? horoscopedetails.hobbies["Spoken Languages"].join(", ")
      : "None";
  }
  const [aadhar, setAadhar] = useState({});
  const [education, setEducation] = useState({});
  const [salarySlip, setSalarySlip] = useState({});
  const handleDocChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("aadhar", file); // Append the file directly

      const url = `/admin/manageUserProfile/addUserDocs?id=${id}`;

      api.post(url, formData).then((res) => {
        setRender(!render);
        setAadhar({}); // Reset the aadhar state
      });
    }
  };
  const handleEducationChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("education", file);
      const url = `/admin/manageUserProfile/addUserDocs?id=${id}`;

      api.post(url, formData).then((res) => {
        setRender(!render);
        setAadhar(null);
      });
    }
  };
  const handleSalaryChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("salarySlip", file);

      const url = `/admin/manageUserProfile/addUserDocs?id=${id}`;

      api.post(url, formData).then((res) => {
        setRender(!render);
        setAadhar(null);
      });
    }
  };
  const [show, setShow] = useState(false);

  const cardRef = useRef();
  const handlePreview = () => {
    setShow(true); // Show the content first
  };
  const handleDownload = () => {
    if (show) {
      exportAsPDF();
    } else {
      setShow(true); // Show the content first
    }
  };

  const exportAsPDF = () => {
    if (!cardRef.current) {
      console.error("cardRef is not assigned to any DOM element.");
      return; // Exit early if there's nothing to export
    }

    html2canvas(cardRef.current)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 0, 0);
        pdf.save("profile.pdf");
      })
      .catch((error) => {
        console.error("Error capturing the element:", error);
      });
    setShow(false);
  };
  const [showModal, setShowModal] = useState(false);
  const [imageToShow, setImageToShow] = useState("");

  const handleImageClick = (imageSrc) => {
    setImageToShow(imageSrc);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className="col-12">
      <Row>
        <Col xs={12} md={12}>
          <div className="page-title">
            <div className="float-left">
              <h1 className="title">Members Full Profile</h1>
            </div>
          </div>
          <div className="col-12">
            <section className="box ">
              <header
                className="panel_header"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 2rem",
                  }}
                >
                  <h2
                    style={{ textTransform: "capitalize", marginRight: "2px" }}
                  >
                    {profile ? profile.userName : "Name"}
                  </h2>
                  <span
                    className="badge"
                    style={{
                      borderRadius: "10px",
                      fontWeight: "500",
                      backgroundColor: "black",
                    }}
                  >
                    {profile && profile.profileId
                      ? profile.profileId
                      : "Profile ID not available"}
                  </span>
                </div>
                <div style={{ marginTop: "50px" }}>
                  <a href="/dashboard">
                    <button
                      onClick={() => deletee(profile.id)}
                      style={{
                        backgroundColor: "#eb3143",
                        color: "white",
                        border: "None",
                        fontSize: "14px",
                        padding: "2px 10px",
                        marginRight: "20px",
                        fontWeight: "500",
                      }}
                    >
                      Delete
                    </button>
                  </a>
                  <button
                    onClick={() => profile && profile.id && activee(profile.id)}
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      border: "None",
                      fontSize: "14px",
                      padding: "2px 10px",
                      marginRight: "20px",
                      fontWeight: "500",
                    }}
                  >
                    {profile && profile.status === "active"
                      ? "Active"
                      : "Inactive"}
                  </button>
                </div>
              </header>
              <div style={{ backgroundColor: "white", padding: "25px" }}>
                <div id={style.scrollContainer} ref={scrollContainerRef}>
                  <Nav tabs id={style.navPane} style={{ paddingBottom: "2px" }}>
                    <NavItem>
                      <NavLink
                        className="active"
                        id={style.tab1}
                        style={
                          activeTab === 1
                            ? {
                                color: "black",
                                borderBottom: "2px solid black",fontWeight:"600"
                              }
                            : {}
                        }
                        onClick={() => setActiveTab(1)}
                      >
                        Profile Details
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className="active"
                        id={style.tab4}
                        style={
                          activeTab === 2
                            ? {
                                color: "black",
                                borderBottom: "2px solid black",fontWeight:"600"
                              }
                            : {}
                        }
                        onClick={() => setActiveTab(2)}
                      >
                        Partner Preference
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className="active"
                        id={style.tab6}
                        style={
                          activeTab === 3
                            ? {
                                color: "black",
                                borderBottom: "2px solid black",fontWeight:"600"
                              }
                            : {}
                        }
                        onClick={() => setActiveTab(3)}
                      >
                        Horoscope Detials
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className="active"
                        id={style.tab8}
                        style={
                          activeTab === 4
                            ? {
                                color: "black",
                                borderBottom: "2px solid black",fontWeight:"600"
                              }
                            : {}
                        }
                        onClick={() => setActiveTab(4)}
                      >
                        Verification
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className="active"
                        id={style.tab8}
                        style={
                          activeTab === 5
                            ? {
                                color: "black",
                                borderBottom: "2px solid black",fontWeight:"600"
                              }
                            : {}
                        }
                        onClick={() => setActiveTab(5)}
                      >
                        Membership plan
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="active"
                        id={style.tab8}
                        style={
                          activeTab === 6
                            ? {
                                color: "black",
                                borderBottom: "2px solid black",fontWeight:"600"
                              }
                            : {}
                        }
                        onClick={() => {
                          setActiveTab(6);
                        }}
                      >
                        Photo Verification
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="active"
                        id={style.tab8}
                        style={
                          activeTab === 12
                            ? {
                                color: "black",
                                borderBottom: "2px solid black",fontWeight:"600"
                              }
                            : {}
                        }
                        onClick={() => {
                          setActiveTab(12);
                          shortlist();
                        }}
                      >
                        Shortlisted My Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="active"
                        id={style.tab8}
                        style={
                          activeTab === 13
                            ? {
                                color: "black",
                                borderBottom: "2px solid black",fontWeight:"600"
                              }
                            : {}
                        }
                        onClick={() => {
                          setActiveTab(13);
                          likedMyProfile();
                        }}
                      >
                        Liked Profiles
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="active"
                        id={style.tab8}
                        style={
                          activeTab === 14
                            ? {
                                color: "black",
                                borderBottom: "2px solid black",fontWeight:"600"
                              }
                            : {}
                        }
                        onClick={() => {
                          setActiveTab(14);
                          likedProfile();
                        }}
                      >
                        Liked My Profile
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className="active"
                        id={style.tab8}
                        style={
                          activeTab === 15
                            ? {
                                color: "black",
                                borderBottom: "2px solid black",
                              }
                            : {}
                        }
                        onClick={() => {
                          setActiveTab(15);
                          interestedMyProfile();
                        }}
                      >
                        Interested in my Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="active"
                        id={style.tab8}
                        style={
                          activeTab === 16
                            ? {
                                color: "black",
                                borderBottom: "2px solid black",
                              }
                            : {}
                        }
                        onClick={() => {
                          setActiveTab(16);
                          interestedProfiles();
                        }}
                      >
                        Interested Profiles
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </div>
                <div id={style.navpanButtons}>
                  <button
                    id={style.scrollLeft}
                    onClick={() => handleScroll(-100)}
                  >
                    &lt;
                  </button>
                  <button
                    id={style.scrollRight}
                    onClick={() => handleScroll(100)}
                  >
                    &gt;
                  </button>
                </div>

                <TabContent
                  activeTab={`${activeTab}`}
                  style={{ borderColor: "#fff0", borderTop: "1px solid #fff9" }}
                >
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {show ? (
                            false
                          ) : (
                            <button
                              className="btn btn-primary"
                              onClick={handleDownload}
                            >
                              Preview & Download Profile
                            </button>
                          )}
                        </div>
                        <div
                          ref={cardRef}
                          style={{ display: show ? "block" : "none" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              className="btn btn-primary"
                              onClick={handleDownload}
                            >
                              Download Profile
                            </button>
                          </div>
                          <Card style={{ padding: "40px" }}>
                            {" "}
                            <div className="row">
                              {" "}
                              <div className="col-sm-12 col-md-12 col-lg-6">
                                <table
                                  className="table  table-borderless"
                                  style={{ border: "None" }}
                                >
                                  <tbody>
                                    <tr>
                                      {profile && profile.userName ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Name
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          paddingLeft: "100px",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {profile && profile.userName
                                          ? profile.userName
                                          : false}
                                      </td>
                                    </tr>
                                    <tr>
                                      {profile && profile.dob ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Date of Birth
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          paddingLeft: "100px",
                                        }}
                                      >
                                        {profile && profile.dob
                                          ? moment(profile.dob).format(
                                              "DD-MM-YYYY"
                                            )
                                          : false}
                                      </td>
                                    </tr>
                                    <tr>
                                      {profile && profile.phone ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Phone no.
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          paddingLeft: "100px",
                                        }}
                                      >
                                        {profile &&
                                        profile.countryCode &&
                                        profile.phone ? (
                                          <span className="me-1">
                                            {profile.countryCode}
                                          </span>
                                        ) : null}
                                        {profile && profile.phone
                                          ? profile.phone
                                          : false}
                                      </td>
                                    </tr>
                                    <tr>
                                      {profile && profile.gender ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Gender
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          paddingLeft: "100px",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {profile && profile.gender
                                          ? profile.gender
                                          : false}
                                      </td>
                                    </tr>
                                    {profile && (
                                      <tr>
                                        {profile && profile.email ? (
                                          <td
                                            style={{
                                              borderTop: "none",
                                              fontWeight: "600",
                                              width: "420px",
                                            }}
                                          >
                                            Email
                                          </td>
                                        ) : (
                                          false
                                        )}
                                        <td
                                          style={{
                                            borderTop: "none",
                                            paddingLeft: "50px",
                                          }}
                                        >
                                          {profile && profile.email
                                            ? profile.email
                                            : false}
                                        </td>
                                      </tr>
                                    )}
                                    <tr>
                                      {userdetails.profileCreatedBy ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          ProfileCreatedBy
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails.profileCreatedBy
                                          ? userdetails.profileCreatedBy
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {personalitydetails.height ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Height
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {personalitydetails
                                          ? personalitydetails.height
                                          : false}
                                      </td>
                                    </tr>
                                    <tr>
                                      {personalitydetails.weight ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Weight
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {personalitydetails
                                          ? personalitydetails.weight
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {userdetails &&
                                      userdetails.maritalStatus ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Marital Status
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails &&
                                        userdetails.maritalStatus
                                          ? userdetails.maritalStatus
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {userdetails.numberOfChildren ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Number Of Children
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails &&
                                        userdetails.numberOfChildren
                                          ? userdetails.numberOfChildren
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {userdetails.childrenStayingWithMe ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Children Staying With Me
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails &&
                                        userdetails.childrenStayingWithMe
                                          ? userdetails.childrenStayingWithMe
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {partnerdetails &&
                                      partnerdetails.motherTongue ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Mother Toungue
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {partnerdetails &&
                                        partnerdetails.motherTongue &&
                                        partnerdetails
                                          ? partnerdetails.motherTongue
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {personalitydetails.eatingHabits ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Eating Habits
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {personalitydetails
                                          ? personalitydetails.eatingHabits
                                          : false}
                                      </td>
                                    </tr>
                                    <tr>
                                      {personalitydetails.drinkingHabits ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Drinking Habits
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {personalitydetails
                                          ? personalitydetails.drinkingHabits
                                          : false}
                                      </td>
                                    </tr>
                                    <tr>
                                      {personalitydetails.smokingHabits ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Smoking Habits
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {personalitydetails
                                          ? personalitydetails.smokingHabits
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {personalitydetails.bodyType ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Body Type
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {personalitydetails
                                          ? personalitydetails.bodyType
                                          : false}
                                      </td>
                                    </tr>
                                    <tr>
                                      {personalitydetails.physicalStatus ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Physical Status
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {personalitydetails
                                          ? personalitydetails.physicalStatus
                                          : false}
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody>
                                    {" "}
                                    <tr>
                                      {userdetails.religion ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Religion
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          paddingLeft: "600px",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails.religion
                                          ? userdetails.religion
                                          : false}
                                      </td>
                                    </tr>
                                    <tr>
                                      {userdetails.caste ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Caste
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {userdetails.caste
                                          ? userdetails.caste
                                          : false}
                                      </td>
                                    </tr>
                                    <tr>
                                      {userdetails.subCaste ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Sub Caste
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {userdetails.subCaste
                                          ? userdetails.subCaste
                                          : false}
                                      </td>
                                    </tr>
                                  </tbody>{" "}
                                  <tbody>
                                    {" "}
                                    <tr>
                                      {userdetails.educationalQualification ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Educational Category
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {userdetails.educationalQualification
                                          ? userdetails.educationalQualification
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {userdetails.institutionName ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Institution Name
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {userdetails.institutionName
                                          ? userdetails.institutionName
                                          : false}
                                      </td>
                                    </tr>
                                    <tr>
                                      {userdetails.occupation ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Occupation
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {userdetails.occupation
                                          ? userdetails.occupation
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {userdetails.organization ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Organization
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {userdetails.organization
                                          ? userdetails.organization
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {userdetails.employedIn ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Employed In
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {userdetails && userdetails.employedIn
                                          ? userdetails.employedIn
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {userdetails.annualIncome ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Annual Income
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {userdetails.annualIncome
                                          ? userdetails.annualIncome
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {userdetails.workingCountry ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Working Country
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {userdetails.workingCountry
                                          ? userdetails.workingCountry
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {userdetails.workingState ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Working State
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails.workingState
                                          ? userdetails.workingState
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {userdetails.workingDistrict ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Working District
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails.workingDistrict
                                          ? userdetails.workingDistrict
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {userdetails.additionalDegree ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Additional Degree
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails.additionalDegree
                                          ? userdetails.additionalDegree
                                          : false}
                                      </td>
                                    </tr>
                                    <tr></tr>
                                  </tbody>{" "}
                                  <tbody>
                                    {" "}
                                    <tr>
                                      {profile.country ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Country
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {profile.country
                                          ? profile.country
                                          : false}
                                      </td>
                                    </tr>
                                    <tr>
                                      {profile.state ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          State
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {profile.state ? profile.state : false}
                                      </td>
                                    </tr>
                                    <tr>
                                      {profile.city ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          City
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {profile.city ? profile.city : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {userdetails.citizenship ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Citizenship
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails.citizenship
                                          ? userdetails.citizenship
                                          : false}
                                      </td>
                                    </tr>
                                  </tbody>{" "}
                                  <tbody>
                                    {" "}
                                    <tr>
                                      {familydetails.familyValues ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Family Values
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {familydetails
                                          ? familydetails.familyValues
                                          : false}
                                      </td>
                                    </tr>
                                    <tr>
                                      {familydetails &&
                                      familydetails.financialStatus ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Family Status
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {familydetails &&
                                        familydetails.financialStatus &&
                                        familydetails
                                          ? familydetails.financialStatus
                                          : false}
                                      </td>
                                    </tr>
                                    <tr>
                                      {familydetails.familyType ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Family Type
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {familydetails
                                          ? familydetails.familyType
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {familydetails.fatherOccupation ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Father's Occupation
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {familydetails
                                          ? familydetails.fatherOccupation
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {familydetails.motherOccupation ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Mothers's Occupation
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {familydetails
                                          ? familydetails.motherOccupation
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {familydetails.noOfBrothers ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          No.Of.Brother(s)
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {familydetails
                                          ? familydetails.noOfBrothers
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {familydetails.brothersMarried ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Brother(s) Married
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {familydetails
                                          ? familydetails.brothersMarried
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {familydetails.noOfSisters ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          No.Of.Sister(s)
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {familydetails
                                          ? familydetails.noOfSisters
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {familydetails.sistersMarried ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Sister(s) Married
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {familydetails
                                          ? familydetails.sistersMarried
                                          : false}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      {familydetails &&
                                      familydetails.aboutFamily ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          About Family
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {familydetails &&
                                        familydetails.aboutFamily
                                          ? familydetails.aboutFamily
                                          : false}
                                      </td>
                                    </tr>{" "}
                                  </tbody>
                                </table>{" "}
                              </div>
                            </div>
                          </Card>
                        </div>

                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col-sm-12 col-md-12 col-lg-6">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "15px",
                                      marginBottom: "10px",
                                      fontWeight: "800",
                                    }}
                                  >
                                    Basic Details
                                  </div>
                                  <div>
                                    {" "}
                                    <Link to={"/updatepersonality/" + id}>
                                      <button
                                        style={{
                                          fontWeight: "500",
                                          background: "green",
                                          color: "white",
                                          border: "None",
                                        }}
                                      >
                                        {" "}
                                        Edit
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                                <table
                                  className="table  table-borderless"
                                  style={{ border: "None" }}
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Name
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          paddingLeft: "100px",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {profile && profile.userName
                                          ? profile.userName
                                          : "UserName not available"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Date of Birth
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          paddingLeft: "100px",
                                        }}
                                      >
                                        {profile && profile.dob
                                          ? moment(profile.dob).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "None"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Phone no.
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          paddingLeft: "100px",
                                        }}
                                      >
                                        {profile &&
                                        profile.countryCode &&
                                        profile.phone ? (
                                          <span className="me-1">
                                            {profile.countryCode}
                                          </span>
                                        ) : null}
                                        {profile && profile.phone
                                          ? profile.phone
                                          : "None"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Gender
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          paddingLeft: "100px",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {profile && profile.gender
                                          ? profile.gender
                                          : "None"}
                                      </td>
                                    </tr>
                                    {profile && (
                                      <tr>
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          Email
                                        </td>
                                        <td
                                          style={{
                                            borderTop: "none",
                                            paddingLeft: "50px",
                                          }}
                                        >
                                          {profile && profile.email
                                            ? profile.email
                                            : "None"}
                                        </td>
                                      </tr>
                                    )}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        ProfileCreatedBy
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails &&
                                        userdetails.profileCreatedBy
                                          ? userdetails.profileCreatedBy
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Height
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {personalitydetails
                                          ? personalitydetails.height
                                          : "None"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Weight
                                      </td>
                                      <td style={{ borderTop: "none" }}>
                                        {personalitydetails
                                          ? personalitydetails.weight
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Marital Status
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails &&
                                        userdetails.maritalStatus
                                          ? userdetails.maritalStatus
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Number Of Children
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails &&
                                        userdetails.numberOfChildren
                                          ? userdetails.numberOfChildren
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Children Staying With Me
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails &&
                                        userdetails.childrenStayingWithMe
                                          ? userdetails.childrenStayingWithMe
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Mother Toungue
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails &&
                                        userdetails.motherTongue &&
                                        userdetails
                                          ? userdetails.motherTongue
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Eating Habits
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {personalitydetails
                                          ? personalitydetails.eatingHabits
                                          : "None"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Drinking Habits
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {personalitydetails
                                          ? personalitydetails.drinkingHabits
                                          : "None"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Smoking Habits
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {personalitydetails
                                          ? personalitydetails.smokingHabits
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Body Type
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {personalitydetails
                                          ? personalitydetails.bodyType
                                          : "None"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Physical Status
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {personalitydetails
                                          ? personalitydetails.physicalStatus
                                          : "None"}
                                      </td>
                                    </tr>
                                    <tr>
                                      {personalitydetails &&
                                      personalitydetails.aboutUser ? (
                                        <td
                                          style={{
                                            borderTop: "none",
                                            fontWeight: "600",
                                            width: "420px",
                                          }}
                                        >
                                          About User
                                        </td>
                                      ) : (
                                        false
                                      )}
                                      <td style={{ borderTop: "none" }}>
                                        {personalitydetails &&
                                        personalitydetails.aboutUser
                                          ? personalitydetails.aboutUser
                                          : false}
                                      </td>
                                    </tr>{" "}
                                  </tbody>
                                </table>
                              </div>{" "}
                              <div className="col-sm-12 col-md-12 col-lg-6"></div>
                              <div className="col-sm-12 col-md-12 col-lg-6">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "15px",
                                      marginBottom: "10px",
                                      fontWeight: "800",
                                    }}
                                  >
                                    Religious Information
                                  </div>
                                  <div>
                                    {" "}
                                    <Link to={"/updatereligion/" + id}>
                                      <button
                                        style={{
                                          fontWeight: "500",
                                          background: "green",
                                          color: "white",
                                          border: "None",
                                        }}
                                      >
                                        {" "}
                                        Edit
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                                <table
                                  className="table  table-borderless"
                                  style={{ border: "None" }}
                                >
                                  <tbody>
                                    {" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Religion
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          paddingLeft: "600px",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails && userdetails.religion
                                          ? userdetails.religion
                                          : "None"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Caste
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails && userdetails.caste
                                          ? userdetails.caste
                                          : "None"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Sub Caste
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails && userdetails.subCaste
                                          ? userdetails.subCaste
                                          : "None"}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-sm-12 col-md-12 col-lg-6"></div>
                              <div className="col-sm-12 col-md-12 col-lg-6">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "15px",
                                      marginBottom: "10px",
                                      fontWeight: "800",
                                    }}
                                  >
                                    Educational Information
                                  </div>
                                  <div>
                                    {" "}
                                    <Link to={"/updateduserdetails/" + id}>
                                      <button
                                        style={{
                                          fontWeight: "500",
                                          background: "green",
                                          color: "white",
                                          border: "None",
                                        }}
                                      >
                                        {" "}
                                        Edit
                                      </button>
                                    </Link>{" "}
                                  </div>
                                </div>{" "}
                                <table
                                  className="table  table-borderless"
                                  style={{ border: "None" }}
                                >
                                  <tbody>
                                    {" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Educational Category
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails &&
                                        userdetails.educationalQualification
                                          ? userdetails.educationalQualification
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Institution Name
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails &&
                                        userdetails.institutionName
                                          ? userdetails.institutionName
                                          : "None"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Occupation
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails && userdetails.occupation
                                          ? userdetails.occupation
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Organization
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails && userdetails.organization
                                          ? userdetails.organization
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Employed In
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails && userdetails.employedIn
                                          ? userdetails.employedIn
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Annual Income
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails && userdetails.annualIncome
                                          ? userdetails.annualIncome
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Working Country
                                      </td>
                                      <td style={{ borderTop: "none" }}>
                                        {userdetails &&
                                        userdetails.workingCountry
                                          ? userdetails.workingCountry
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Working State
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails && userdetails.workingState
                                          ? userdetails.workingState
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Working District
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails &&
                                        userdetails.workingDistrict
                                          ? userdetails.workingDistrict
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Additional Degree
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails &&
                                        userdetails.additionalDegree
                                          ? userdetails.additionalDegree
                                          : "None"}
                                      </td>
                                    </tr>
                                    <tr></tr>
                                  </tbody>
                                </table>
                              </div>{" "}
                              <div className="col-sm-12 col-md-12 col-lg-6"></div>
                              <div className="col-sm-12 col-md-12 col-lg-6">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "15px",
                                      marginBottom: "10px",
                                      fontWeight: "800",
                                    }}
                                  >
                                    Location Information
                                  </div>
                                  <div>
                                    {" "}
                                    <Link to={"/updatelocation/" + id}>
                                      <button
                                        style={{
                                          fontWeight: "500",
                                          background: "green",
                                          color: "white",
                                          border: "None",
                                        }}
                                      >
                                        {" "}
                                        Edit
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                                <table
                                  className="table  table-borderless"
                                  style={{ border: "None" }}
                                >
                                  <tbody>
                                    {" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Country
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {profile && profile.country
                                          ? profile.country
                                          : "None"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        State
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {profile && profile.state
                                          ? profile.state
                                          : "None"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        City
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {profile && profile.city
                                          ? profile.city
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Citizenship
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {userdetails && userdetails.citizenship
                                          ? userdetails.citizenship
                                          : "None"}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>{" "}
                              <div className="col-sm-12 col-md-12 col-lg-6"></div>{" "}
                              <div className="col-sm-12 col-md-12 col-lg-6">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "15px",
                                      marginBottom: "10px",
                                      fontWeight: "800",
                                    }}
                                  >
                                    Family Information
                                  </div>
                                  <div>
                                    {" "}
                                    <Link to={"/updatefamily/" + id}>
                                      <button
                                        style={{
                                          fontWeight: "500",
                                          background: "green",
                                          color: "white",
                                          border: "None",
                                        }}
                                      >
                                        {" "}
                                        Edit
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                                <table
                                  className="table  table-borderless"
                                  style={{ border: "None" }}
                                >
                                  <tbody>
                                    {" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Family Values
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {familydetails && familydetails
                                          ? familydetails.familyValues
                                          : "None"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Family Status
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {familydetails &&
                                        familydetails.financialStatus &&
                                        familydetails
                                          ? familydetails.financialStatus
                                          : "None"}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Family Type
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {familydetails
                                          ? familydetails.familyType
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Father's Occupation
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {familydetails
                                          ? familydetails.fatherOccupation
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Mothers's Occupation
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {familydetails
                                          ? familydetails.motherOccupation
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        No.Of.Brother(s)
                                      </td>
                                      <td style={{ borderTop: "none" }}>
                                        {familydetails
                                          ? familydetails.noOfBrothers
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Brother(s) Married
                                      </td>
                                      <td style={{ borderTop: "none" }}>
                                        {familydetails.brothersMarried !== ""
                                          ? familydetails.brothersMarried
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        No.Of.Sister(s)
                                      </td>
                                      <td style={{ borderTop: "none" }}>
                                        {familydetails
                                          ? familydetails.noOfSisters
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Sister(s) Married
                                      </td>
                                      <td style={{ borderTop: "none" }}>
                                        {familydetails.sistersMarried !== ""
                                          ? familydetails.sistersMarried
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        About Family
                                      </td>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {familydetails &&
                                        familydetails.aboutFamily
                                          ? familydetails.aboutFamily
                                          : "None"}
                                      </td>
                                    </tr>{" "}
                                  </tbody>
                                </table>
                              </div>{" "}
                              <div className="col-sm-12 col-md-12 col-lg-6"></div>
                              <div className="col-sm-12 col-md-12 col-lg-6">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "15px",
                                      marginBottom: "10px",
                                      fontWeight: "800",
                                    }}
                                  >
                                    Hobbies And Interests{" "}
                                  </div>
                                  {/* <div>
                                    {" "}
                                    <Link to={"/updatehobbies/" + id}>
                                      <button
                                        style={{
                                          fontWeight: "500",
                                          background: "green",
                                          color: "white",
                                          border: "None",
                                        }}
                                      >
                                        {" "}
                                        Edit
                                      </button>
                                    </Link>
                                  </div> */}
                                </div>
                                <table
                                  className="table  table-borderless"
                                  style={{ border: "None" }}
                                >
                                  <tbody>
                                    {" "}
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Hobbies & Interests
                                      </td>
                                      <td
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {hobbiesAndInterests}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Music
                                      </td>
                                      <td>{music}</td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Reading
                                      </td>
                                      <td>{reading}</td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Food
                                      </td>
                                      <td>{food}</td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Movies & TV Shows
                                      </td>
                                      <td>{moviesAndTvShows}</td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Sports & Fitness
                                      </td>
                                      <td>{sportsAndFitness}</td>
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          borderTop: "none",
                                          fontWeight: "600",
                                          width: "420px",
                                        }}
                                      >
                                        Spoken Languages
                                      </td>
                                      <td>{spokenLanguages}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        {deletedReason ? (
                          <div>
                            {" "}
                            <div
                              style={{
                                fontSize: "15px",
                                marginBottom: "10px",
                                fontWeight: "800",
                              }}
                            >
                              Deleted Reason:
                            </div>{" "}
                            <p>{deletedReason}</p>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {" "}
                        <Link to={"/updatepartner/" + id}>
                          <button
                            style={{
                              fontWeight: "500",
                              background: "green",
                              color: "white",
                              border: "None",
                            }}
                          >
                            {" "}
                            Edit
                          </button>
                        </Link>
                      </div>
                      <Col sm="12">
                        {partnerdetails === "Partner details not found" ? (
                          <div
                            style={{
                              textAlign: "center",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            Partner details not found
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6">
                              <table
                                className="table  table-borderless"
                                style={{ border: "None" }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Family Status
                                    </td>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {partnerdetails
                                        ? partnerdetails.familyStatus
                                        : "None"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Religion
                                    </td>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {partnerdetails
                                        ? partnerdetails.religion
                                        : "None"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Dhosam
                                    </td>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {partnerdetails
                                        ? partnerdetails.dhosam
                                        : "None"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Zodiac Star
                                    </td>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {partnerdetails
                                        ? partnerdetails.zodiacStar
                                        : "None"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Education
                                    </td>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {partnerdetails
                                        ? partnerdetails.education
                                        : "None"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Employee In
                                    </td>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {partnerdetails && partnerdetails
                                        ? partnerdetails.employedIn
                                        : "None"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Annual Income
                                    </td>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {partnerdetails
                                        ? partnerdetails.annualIncome
                                        : "None"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        fontWeight: "600",
                                      }}
                                    >
                                      City
                                    </td>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {partnerdetails
                                        ? partnerdetails.city
                                        : "None"}
                                    </td>
                                  </tr>{" "}
                                  <tr>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        fontWeight: "600",
                                      }}
                                    >
                                      State
                                    </td>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {partnerdetails
                                        ? partnerdetails.state
                                        : "None"}
                                    </td>
                                  </tr>{" "}
                                  <tr>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Country
                                    </td>
                                    <td
                                      style={{
                                        borderTop: "none",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {partnerdetails
                                        ? partnerdetails.country
                                        : "None"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {" "}
                        <Link to={"/updatehoro/" + id}>
                          <button
                            style={{
                              fontWeight: "500",
                              background: "green",
                              color: "white",
                              border: "None",
                            }}
                          >
                            {" "}
                            Edit
                          </button>
                        </Link>
                      </div>
                      <Col sm="12">
                        {horoscopedetails ===
                        "Horoscope details not completed" ? (
                          <div
                            style={{
                              textAlign: "center",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            Horoscope details not found
                          </div>
                        ) : (
                          <div className="row" style={{ display: "flex" }}>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                              <table
                                className="table  table-borderless"
                                style={{ border: "None" }}
                              >
                                <tbody>
                                  <tr>
                                    <td style={{ fontWeight: "600" }}>
                                      Time Of Birth
                                    </td>
                                    <td>
                                      {horoscopedetails
                                        ? horoscopedetails.timeOfBirth
                                        : "None"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ fontWeight: "600" }}>
                                      Date Of Birth
                                    </td>
                                    <td>
                                      {horoscopedetails
                                        ? horoscopedetails.dateOfBirth
                                        : "None"}
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td style={{ fontWeight: "600" }}>
                                      Place Of Birth
                                    </td>
                                    <td>
                                      {horoscopedetails
                                        ? horoscopedetails.placeOfBirth
                                        : "None"}
                                    </td>
                                  </tr> */}
                                  <tr>
                                    <td style={{ fontWeight: "600" }}>
                                      Zodiac Sign
                                    </td>
                                    <td style={{ textTransform: "capitalize" }}>
                                      {horoscopedetails
                                        ? horoscopedetails.zodiacSign
                                        : "None"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ fontWeight: "600" }}>
                                      Zodiac Star
                                    </td>
                                    <td style={{ textTransform: "capitalize" }}>
                                      {horoscopedetails
                                        ? horoscopedetails.zodiacStar
                                        : "None"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ fontWeight: "600" }}>
                                      Gothram
                                    </td>
                                    <td style={{ textTransform: "capitalize" }}>
                                      {horoscopedetails &&
                                      horoscopedetails.gothram
                                        ? horoscopedetails.gothram
                                        : "None"}
                                    </td>
                                  </tr>{" "}
                                  <tr>
                                    <td style={{ fontWeight: "600" }}>
                                      Dhosam
                                    </td>
                                    <td style={{ textTransform: "capitalize" }}>
                                      {horoscopedetails
                                        ? horoscopedetails.dhosam
                                        : "None"}
                                    </td>
                                  </tr>{" "}
                                  <tr>
                                    <td style={{ fontWeight: "600" }}>
                                      Birth City
                                    </td>
                                    <td style={{ textTransform: "capitalize" }}>
                                      {horoscopedetails
                                        ? horoscopedetails.birthCity
                                        : "None"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ fontWeight: "600" }}>
                                      Birth State
                                    </td>
                                    <td style={{ textTransform: "capitalize" }}>
                                      {horoscopedetails
                                        ? horoscopedetails.birthState
                                        : "None"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{ fontWeight: "600" }}>
                                      Birth Country
                                    </td>
                                    <td style={{ textTransform: "capitalize" }}>
                                      {horoscopedetails
                                        ? horoscopedetails.birthCountry
                                        : "None"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6"></div>
                          </div>
                        )}
                      </Col>{" "}
                      {horoscopedetails && horoscopedetails.horoscopeImage ? (
                        <a
                          href={baseURL + horoscopedetails.horoscopeImage}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button>Click to View Horoscope</button>
                        </a>
                      ) : (
                        <Col sm="12">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              margin: "30px 0px",
                            }}
                          >
                            <Button
                              style={{
                                backgroundColor: "#000000",
                                marginTop: "10px",
                                marginBottom: "20px",
                                paddingTop: "10px",
                                color: "white",
                                paddingBottom: "10px",

                                paddingLeft: "20px",
                                paddingRight: "20px",
                                fontWeight: "bolder",
                                alignSelf: "center",

                                textAlign: "center",
                                fontSize: "16px",
                              }}
                              onClick={handleExport}
                            >
                              Download Horoscope
                            </Button>
                          </div>
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Card
                                ref={exportRef}
                                id="elementToExport"
                                className="watermark-card"
                                style={{
                                  width: "1024px",
                                  padding: "50px",
                                  border: "1px solid #000",
                                  position: "relative",
                                  zIndex: 2, // Ensure card content is above the watermark
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <img
                                      src={logo}
                                      alt="SCA Matrimony"
                                      style={{ width: "80px", height: "80px" }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center", // Add this line to center items horizontally
                                      textAlign: "center", // Add this line to center text inside the elements
                                    }}
                                  >
                                    <h2>SCA Matrimony</h2>
                                    <p>
                                      38/4, Rajiv Nagar Cross Road, opp. SPM
                                      Hospital,
                                      <br /> pattaraimedu, Tiruchengode
                                    </p>
                                  </div>

                                  <div>
                                    {" "}
                                    <img
                                      src={qr}
                                      alt="VVK Matrimony"
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                    />
                                  </div>
                                </div>
                                <Grid container gap={1} className="detail_grid">
                                  <Grid
                                    className="card-split"
                                    item
                                    xs={12}
                                    lg={12}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      rowGap: "10px",
                                    }}
                                  >
                                    <Grid
                                      container
                                      sx={{
                                        border: "1px solid #555",
                                        padding: "10px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        lg={6}
                                        sx={{
                                          paddingRight: "5px",
                                          display: "flex",
                                          flexDirection: "column",
                                          rowGap: "15px",
                                        }}
                                      >
                                        <div
                                          className="forColumnCap"
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            //   width: "100%",
                                            // columnGap: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "40%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            <b>Name</b>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "60%",
                                              wordBreak: "break-word",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <b>{view.userName}</b>
                                          </div>
                                        </div>
                                        <div
                                          className="forColumnCap"
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            //   width: "100%",
                                            // columnGap: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "40%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            Birth Date
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "60%",
                                              wordBreak: "break-word",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {view.dateOfBirth}
                                          </div>
                                        </div>
                                        <div
                                          className="forColumnCap"
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            //   width: "100%",
                                            // columnGap: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "40%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            Birth Day
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "60%",
                                              wordBreak: "break-word",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {view.panchangDetails &&
                                            view.panchangDetails.data
                                              ? view.panchangDetails.data.vaara
                                              : false}
                                          </div>
                                        </div>
                                        <div
                                          className="forColumnCap"
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            //   width: "100%",
                                            // columnGap: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "40%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            Birth Time
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "60%",
                                              wordBreak: "break-word",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {view.timeOfBirth}
                                          </div>
                                        </div>
                                        <div
                                          className="forColumnCap"
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            //   width: "100%",
                                            // columnGap: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "40%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            Birth City
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "60%",
                                              wordBreak: "break-word",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {view.birthCity}
                                          </div>
                                        </div>

                                        <div
                                          className="forColumnCap"
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            //   width: "100%",
                                            // columnGap: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "40%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            Education
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "60%",
                                              wordBreak: "break-word",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {view.educationalQualification}
                                          </div>
                                        </div>
                                        <div
                                          className="forColumnCap"
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            //   width: "100%",
                                            // columnGap: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "40%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            Profession
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "60%",
                                              wordBreak: "break-word",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {view.profession}
                                          </div>
                                        </div>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={6}
                                        lg={6}
                                        sx={{
                                          paddingLeft: "5px",
                                          display: "flex",
                                          flexDirection: "column",
                                          rowGap: "15px",
                                        }}
                                      >
                                        {" "}
                                        <div
                                          className="forColumnCap"
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            //   width: "100%",
                                            // columnGap: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "40%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            ராசி
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "60%",
                                              wordBreak: "break-word",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {view.rasi}
                                          </div>
                                        </div>{" "}
                                        <div
                                          className="forColumnCap"
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            //   width: "100%",
                                            // columnGap: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "40%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            நட்சத்திரம்
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "60%",
                                              wordBreak: "break-word",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {view.star}
                                          </div>
                                        </div>{" "}
                                        <div
                                          className="forColumnCap"
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            //   width: "100%",
                                            // columnGap: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "40%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            சூரிய உதயம்
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "60%",
                                              wordBreak: "break-word",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {view.panchangDetails &&
                                            view.panchangDetails.data
                                              ? moment(
                                                  view.panchangDetails.data
                                                    .sunrise
                                                ).format("DD-MM-YYYY HH:mm")
                                              : false}
                                          </div>
                                        </div>{" "}
                                        <div
                                          className="forColumnCap"
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            //   width: "100%",
                                            // columnGap: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "40%",
                                            }}
                                          >
                                            சூரிய அஸ்தமனம்
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "60%",
                                              wordBreak: "break-word",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {view.panchangDetails &&
                                            view.panchangDetails.data
                                              ? moment(
                                                  view.panchangDetails.data
                                                    .sunset
                                                ).format("DD-MM-YYYY HH:mm")
                                              : false}
                                          </div>
                                        </div>
                                        <div
                                          className="forColumnCap"
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            //   width: "100%",
                                            // columnGap: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "40%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            திதி
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "60%",
                                              wordBreak: "break-word",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {view.panchangDetails &&
                                            view.panchangDetails.data
                                              ? view.panchangDetails.data
                                                  .tithi[0].name
                                              : false}
                                          </div>
                                        </div>{" "}
                                        <div
                                          className="forColumnCap"
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            //   width: "100%",
                                            // columnGap: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "40%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            கிரணம்
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "60%",
                                              wordBreak: "break-word",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {view.panchangDetails &&
                                            view.panchangDetails.data
                                              ? view.panchangDetails.data
                                                  .karana[0].name
                                              : false}
                                          </div>
                                        </div>
                                        <div
                                          className="forColumnCap"
                                          style={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            //   width: "100%",
                                            // columnGap: "20px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "40%",
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            யோகம்
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "60%",
                                              wordBreak: "break-word",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {view.panchangDetails &&
                                            view.panchangDetails.data
                                              ? view.panchangDetails.data
                                                  .yoga[0].name
                                              : false}
                                          </div>
                                        </div>{" "}
                                      </Grid>
                                    </Grid>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      திருக்கணித கிரக ஸ்புடம்(நிராயணம்)
                                    </div>
                                    <Grid item xs={12} lg={12}>
                                      <Grid
                                        container
                                        sx={{
                                          border: "1px solid #555",
                                          padding: "10px",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={2.9}
                                          lg={2.9}
                                          sx={{
                                            paddingRight: "5px",
                                            display: "flex",
                                            flexDirection: "column",
                                            rowGap: "15px",
                                          }}
                                        >
                                          {[
                                            "சூரியன்",
                                            "சந்திரன்",
                                            "புதன்",
                                            "சுக்ரன்",
                                            "செவ்வாய்",
                                          ].map(function (planet, index) {
                                            const planetPosition =
                                              view.planetPosition &&
                                              view.planetPosition
                                                .planet_position;
                                            const planetData = planetPosition
                                              ? planetPosition[index]
                                              : null;

                                            const longitude = planetData
                                              ? planetData.longitude
                                              : null;
                                            const convertedLongitude = longitude
                                              ? convertLongitudeToDMS(longitude)
                                              : null;

                                            return (
                                              <div
                                                key={index}
                                                className="forColumnCap"
                                                style={{
                                                  display: "flex",
                                                  flexWrap: "nowrap",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "40%",
                                                    wordBreak: "break-word",
                                                  }}
                                                >
                                                  {planet}
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "60%",
                                                    justifyContent:
                                                      "space-around",
                                                    wordBreak: "break-word",
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  {planetData ? (
                                                    <>
                                                      {convertedLongitude.degrees
                                                        .toString()
                                                        .substring(0, 3)}
                                                      °{" "}
                                                      {
                                                        convertedLongitude.minutes
                                                      }
                                                      '{" "}
                                                      {
                                                        convertedLongitude.seconds
                                                      }
                                                      "
                                                    </>
                                                  ) : (
                                                    "No Data"
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2.9}
                                          lg={2.9}
                                          sx={{
                                            paddingRight: "5px",
                                            display: "flex",
                                            flexDirection: "column",
                                            rowGap: "15px",
                                          }}
                                        >
                                          {[
                                            "சூரியன்",
                                            "சந்திரன்",
                                            "புதன்",
                                            "சுக்ரன்",
                                            "செவ்வாய்",
                                          ].map(function (planet, index) {
                                            const planetPosition =
                                              view.planetPosition &&
                                              view.planetPosition
                                                .planet_position;
                                            const planetData = planetPosition
                                              ? planetPosition[index]
                                              : null;

                                            const rasi = planetData
                                              ? planetData.rasi.name
                                              : null;
                                            const position = planetData
                                              ? planetData.position
                                              : null;

                                            return (
                                              <div
                                                key={index}
                                                className="forColumnCap"
                                                style={{
                                                  display: "flex",
                                                  flexWrap: "nowrap",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "60%",
                                                    justifyContent:
                                                      "space-around",
                                                    wordBreak: "break-word",
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  {planetData ? (
                                                    <>{rasi}</>
                                                  ) : (
                                                    "No Data"
                                                  )}
                                                </div>{" "}
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "40%",
                                                    wordBreak: "break-word",
                                                  }}
                                                >
                                                  {position}
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2.9}
                                          lg={2.9}
                                          sx={{
                                            paddingRight: "5px",
                                            display: "flex",
                                            flexDirection: "column",
                                            rowGap: "15px",
                                          }}
                                        >
                                          {[
                                            "குரு",
                                            "சனி",
                                            "லக்கினம்",
                                            "ராகு",
                                            "கேது",
                                          ].map(function (planet, index) {
                                            const planetPosition =
                                              view.planetPosition &&
                                              view.planetPosition
                                                .planet_position;

                                            const planetData = planetPosition
                                              ? planetPosition.find(
                                                  (p) => p.name === planet
                                                )
                                              : null;

                                            const longitude = planetData
                                              ? planetData.longitude
                                              : null;
                                            const convertedLongitude = longitude
                                              ? convertLongitudeToDMS(longitude)
                                              : null;

                                            return (
                                              <div
                                                key={index}
                                                className="forColumnCap"
                                                style={{
                                                  display: "flex",
                                                  flexWrap: "nowrap",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "40%",
                                                    wordBreak: "break-word",
                                                  }}
                                                >
                                                  {planet}
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "60%",
                                                    justifyContent:
                                                      "space-around",
                                                    wordBreak: "break-word",
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  {planetData ? (
                                                    <>
                                                      {convertedLongitude.degrees
                                                        .toString()
                                                        .substring(0, 3)}
                                                      °{" "}
                                                      {
                                                        convertedLongitude.minutes
                                                      }
                                                      '{" "}
                                                      {
                                                        convertedLongitude.seconds
                                                      }
                                                      "
                                                    </>
                                                  ) : (
                                                    "No Data"
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </Grid>

                                        <Grid
                                          item
                                          xs={2.9}
                                          lg={2.9}
                                          sx={{
                                            paddingRight: "5px",
                                            display: "flex",
                                            flexDirection: "column",
                                            rowGap: "15px",
                                          }}
                                        >
                                          {[
                                            "குரு",
                                            "சனி",
                                            "லக்கினம்",
                                            "ராகு",
                                            "கேது",
                                          ].map(function (planet, index) {
                                            const planetPosition =
                                              view.planetPosition &&
                                              view.planetPosition
                                                .planet_position;

                                            // Find the planet data by matching the name
                                            const planetData = planetPosition
                                              ? planetPosition.find(
                                                  (p) => p.name === planet
                                                )
                                              : null;

                                            const rasi = planetData
                                              ? planetData.rasi.name
                                              : null;
                                            const position = planetData
                                              ? planetData.position
                                              : null;

                                            return (
                                              <div
                                                key={index}
                                                className="forColumnCap"
                                                style={{
                                                  display: "flex",
                                                  flexWrap: "nowrap",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "60%",
                                                    justifyContent:
                                                      "space-around",
                                                    wordBreak: "break-word",
                                                    textTransform: "capitalize",
                                                  }}
                                                >
                                                  {planetData ? (
                                                    <>{rasi}</>
                                                  ) : (
                                                    "No Data"
                                                  )}
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "40%",
                                                    wordBreak: "break-word",
                                                  }}
                                                >
                                                  {position}
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid container className="astroCards mt-3">
                                    <Grid
                                      item
                                      xs={6}
                                      lg={6}
                                      sm={6}
                                      // sx={{ background: "red" }}
                                    >
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: view.rasiChart,
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      lg={6}
                                      sm={6}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      {" "}
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: view.amsamChart,
                                        }}
                                      />
                                    </Grid>
                                  </Grid>

                                  {/* ***************************** */}
                                </Grid>

                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "50px",
                                  }}
                                >
                                  சுபம்
                                </div>
                              </Card>
                            </div>
                          </>
                        </Col>
                      )}
                    </Row>
                  </TabPane>
                  <TabPane tabId="4">
                    {/* <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {" "}
                      <Link to={"/updatedoc/" + id}>
                        <button
                          style={{
                            fontWeight: "500",
                            background: "green",
                            color: "white",
                            border: "None",
                          }}
                        >
                          {" "}
                          Edit
                        </button>
                      </Link>
                    </div> */}
                    <Row>
                      <div
                        className="row"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Col sm="12">
                          <div className="col ">
                            <div
                              className="mt-5"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <h5 style={{fontWeight:"600"}}>
                                  Aadhar
                                  {userdocumentdetails && (
                                    <span
                                      className="badge"
                                      style={{
                                        borderRadius: "10px",
                                        fontWeight: "500",
                                        backgroundColor:
                                          userdocumentdetails.aadharVerified ===
                                          "0"
                                            ? "orange"
                                            : "green",
                                        color: "white",
                                      }}
                                    >
                                      {userdocumentdetails.aadharVerified ===
                                      "0"
                                        ? "Waiting"
                                        : userdocumentdetails.aadharVerified ===
                                          "1"
                                        ? "Verified"
                                        : "Rejected"}
                                    </span>
                                  )}
                                </h5>
                                {userdocumentdetails && (
                                  <p style={{fontWeight:"600"}}>
                                    Aadhar Upload Date :{" "}
                                    {userdocumentdetails.aadharUpload}
                                  </p>
                                )}
                                <p>( File Type Should be PDF)</p>
                                <div
                                  className="form-outline mb-4"
                                  style={{ border: "solid 1px" }}
                                >
                                  <input
                                    type="file"
                                    name="aadhar"
                                    accept=".pdf"
                                    onChange={handleDocChange}
                                    required
                                    id="form2Example1"
                                    className="form-control"
                                    placeholder="Username"
                                    style={{ padding: "10px" }}
                                  />
                                </div>
                              </div>
                              <>
                                <div>
                                  {profile && profile.images && (
                                    <img
                                      src={baseURL + profile.images}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        cursor: "pointer",
                                      }}
                                      alt="Profile Image"
                                      onClick={handleImageClick} // Trigger full-screen modal on click
                                    />
                                  )}
                                </div>

                                {/* Modal for full-screen image view */}
                                {showModal && (
                                  <div
                                    style={{
                                      position: "fixed",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      zIndex: 1000,
                                    }}
                                    onClick={handleCloseModal} // Close the modal when clicking outside the image
                                  >
                                    <img
                                      src={baseURL + profile.images}
                                      alt="Full screen"
                                      style={{
                                        maxWidth: "90%",
                                        maxHeight: "90%",
                                        borderRadius: "8px",
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                              <div>
                                {" "}
                                {userdocumentdetails &&
                                  userdocumentdetails.aadhar && (
                                    <a
                                      href={
                                        baseURL + userdocumentdetails.aadhar
                                      }
                                      target="_blank"
                                    >
                                      <button>Click to View Aadhar</button>
                                    </a>
                                  )}
                              </div>
                            </div>
                            <hr />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <h5 style={{fontWeight:"600"}}>
                                  Salary Slip:
                                  {userdocumentdetails &&
                                    userdocumentdetails.salarySlipVerified && (
                                      <span
                                        className="badge"
                                        style={{
                                          borderRadius: "10px",
                                          fontWeight: "500",
                                          backgroundColor:
                                            userdocumentdetails.salarySlipVerified ===
                                            "0"
                                              ? "orange"
                                              : "green",
                                          color: "white", // To make sure the text is readable
                                        }}
                                      >
                                        {userdocumentdetails.salarySlipVerified ===
                                        "0"
                                          ? "Waiting"
                                          : "Verified"}
                                      </span>
                                    )}
                                </h5>{" "}
                                {userdocumentdetails && (
                                  <p style={{fontWeight:"600"}}>
                                    Salary Slip Upload Date :{" "}
                                    {userdocumentdetails.salaryUpload}
                                  </p>
                                )}
                                <p>( File Type Should be PDF)</p>
                                <div
                                  className="form-outline mb-4"
                                  style={{ border: "solid 1px" }}
                                >
                                  <input
                                    type="file"
                                    name="salarySlip"
                                    accept=".pdf"
                                    onChange={handleSalaryChange}
                                    required
                                    id="form2Example1"
                                    className="form-control"
                                    placeholder="Username"
                                    style={{ padding: "10px" }}
                                  />
                                </div>
                              </div>
                              <div>
                                {" "}
                                <p style={{fontWeight:"600"}}>
                                  Employee In :{" "}
                                  {userdetails && userdetails.employedIn
                                    ? userdetails.employedIn
                                    : "None"}{" "}
                                </p>
                                <p style={{fontWeight:"600"}}>
                                  Occupation:{" "}
                                  {userdetails && userdetails.occupation
                                    ? userdetails.occupation
                                    : "None"}
                                </p>
                                <p style={{fontWeight:"600"}}>
                                  Annual Income:{" "}
                                  {userdetails && userdetails.annualIncome
                                    ? userdetails.annualIncome
                                    : "None"}
                                </p>
                                <p style={{fontWeight:"600"}}> 
                                  Work Location:{" "}
                                  {userdetails && userdetails.workingDistrict
                                    ? userdetails.workingDistrict
                                    : "None"}
                                  ,
                                  {userdetails && userdetails.workingState
                                    ? userdetails.workingState
                                    : "None"}
                                  ,
                                  {userdetails && userdetails.workingCountry
                                    ? userdetails.workingCountry
                                    : "None"}
                                  ,
                                </p>
                              </div>
                              <div>
                                <div>
                                  <h5>
                                    <select
                                      className="form-select"
                                      onChange={() => salaryVerifys(profile.id)}
                                      value={
                                        userdocumentdetails.salarySlipVerified ===
                                          "0" ||
                                        userdocumentdetails.salarySlipVerified ===
                                          "1"
                                          ? userdocumentdetails.salarySlipVerified
                                          : ""
                                      } // Handle the placeholder
                                      aria-label="Default select example"
                                    >
                                      <option>Change Status</option>
                                      <option value="0">Waiting</option>
                                      <option value="1">Verify</option>
                                    </select>
                                  </h5>
                                </div>
                                <div>
                                  {" "}
                                  {userdocumentdetails &&
                                    userdocumentdetails.salarySlip && (
                                      <a
                                        href={
                                          baseURL +
                                          userdocumentdetails.salarySlip
                                        }
                                        target="_blank"
                                      >
                                        <button>
                                          Click to View Salary Slip
                                        </button>
                                      </a>
                                    )}
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                {" "}
                                <h5 style={{fontWeight:"600"}}>
                                  Education Qualification:
                                  <span
                                    className="badge"
                                    style={{
                                      borderRadius: "10px",
                                      fontWeight: "500",
                                      backgroundColor:
                                        userdocumentdetails.educationDetailsVerified ===
                                        "0"
                                          ? "orange"
                                          : "green",
                                      color: "white",
                                    }}
                                  >
                                    {userdocumentdetails &&
                                    userdocumentdetails.educationDetailsVerified ==
                                      "0"
                                      ? "Waiting"
                                      : "Verified"}
                                  </span>{" "}
                                </h5>{" "}
                                {userdocumentdetails && (
                                  <p style={{fontWeight:"600"}}>
                                    Education details Upload Date :{" "}
                                    {userdocumentdetails.educationUpload}
                                  </p>
                                )}
                                <p>( File Type Should be PDF)</p>
                                <div
                                  className="form-outline mb-4"
                                  style={{ border: "solid 1px" }}
                                >
                                  <input
                                    type="file"
                                    name="education"
                                    accept=".pdf"
                                    onChange={handleEducationChange}
                                    required
                                    id="form2Example1"
                                    className="form-control"
                                    placeholder="Username"
                                    style={{ padding: "10px" }}
                                  />
                                </div>
                              </div>
                              <div>
                                <p style={{fontWeight:"600"}}>
                                  Institution Name:
                                  {userdetails && userdetails.institutionName
                                    ? userdetails.institutionName
                                    : "None"}
                                </p>
                              </div>
                              <div>
                                <div>
                                  <h5>
                                    {" "}
                                    <select
                                      className="form-select"
                                      onChange={() => verifieds(profile.id)}
                                      value={
                                        userdocumentdetails.educationDetailsVerified ===
                                          "0" ||
                                        userdocumentdetails.educationDetailsVerified ===
                                          "1"
                                          ? userdocumentdetails.educationDetailsVerified
                                          : ""
                                      } // Handle the placeholder
                                      aria-label="Default select example"
                                    >
                                      <option>Change Status</option>
                                      <option value="0">Waiting</option>
                                      <option value="1">Verify</option>
                                    </select>
                                  </h5>
                                </div>
                                <div>
                                  {" "}
                                  {userdocumentdetails &&
                                    userdocumentdetails.educationDetails && (
                                      <a
                                        href={
                                          baseURL +
                                          userdocumentdetails.educationDetails
                                        }
                                        target="_blank"
                                      >
                                        <button>
                                          Click to View Education Details
                                        </button>
                                      </a>
                                    )}
                                </div>
                              </div>
                            </div>
                            <hr />
                            {horoscopedetails.horoscopeImage && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  {" "}
                                  <h5 style={{fontWeight:"600"}}>
                                    Horoscope Verification:
                                    <span
                                      className="badge"
                                      style={{
                                        borderRadius: "10px",
                                        fontWeight: "500",
                                        backgroundColor:
                                          horoscopedetails.horoscopeApproved ===
                                          "0"
                                            ? "orange"
                                            : "green",
                                        color: "white",
                                      }}
                                    >
                                      {horoscopedetails &&
                                      horoscopedetails.horoscopeApproved == "0"
                                        ? "Waiting"
                                        : "Verified"}
                                    </span>{" "}
                                  </h5>{" "}
                                </div>

                                <div>
                                  <div>
                                    <h5>
                                      {" "}
                                      <select
                                        className="form-select"
                                        onChange={() => verifihoro(profile.id)}
                                        value={
                                          horoscopedetails.horoscopeApproved ===
                                            "0" ||
                                          horoscopedetails.horoscopeApproved ===
                                            "1"
                                            ? horoscopedetails.horoscopeApproved
                                            : ""
                                        } // Handle the placeholder
                                        aria-label="Default select example"
                                      >
                                        <option>Change Status</option>
                                        <option value="0">Waiting</option>
                                        <option value="1">Verify</option>
                                      </select>
                                    </h5>
                                  </div>
                                  <div>
                                    {" "}
                                    {horoscopedetails &&
                                      horoscopedetails.horoscopeImage && (
                                        <a
                                          href={
                                            baseURL +
                                            horoscopedetails.horoscopeImage
                                          }
                                          target="_blank"
                                        >
                                          <button>
                                            Click to View Horoscope
                                          </button>
                                        </a>
                                      )}
                                  </div>
                                </div>
                              </div>
                            )}
                            <hr />
                            {userdocumentdetails.selfiePic ? (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <h5 style={{fontWeight:"600"}}>
                                      Selfie Image Verification
                                      <span
                                        className="badge"
                                        style={{
                                          borderRadius: "10px",
                                          fontWeight: "500",
                                          backgroundColor:
                                            userdocumentdetails.selfiePicVerified ===
                                            "0"
                                              ? "orange"
                                              : "green",
                                          color: "white",
                                        }}
                                      >
                                        {userdocumentdetails &&
                                        userdocumentdetails.selfiePicVerified ===
                                          "0"
                                          ? "Waiting"
                                          : "Verified"}
                                      </span>
                                    </h5>
                                    {userdocumentdetails && (
                                      <p style={{fontWeight:"600"}}>
                                        Selfie Image Upload Date :{" "}
                                        {userdocumentdetails.selfieUpload}
                                      </p>
                                    )}
                                    <h5>
                                      <select
                                        className="form-select"
                                        onChange={() =>
                                          selfieVerify(profile.id)
                                        }
                                        value={
                                          userdocumentdetails.selfiePicVerified ===
                                            "0" ||
                                          userdocumentdetails.selfiePicVerified ===
                                            "1"
                                            ? userdocumentdetails.selfiePicVerified
                                            : ""
                                        }
                                        aria-label="Default select example"
                                      >
                                        <option>Change Status</option>
                                        <option value="0">Waiting</option>
                                        <option value="1">Verify</option>
                                      </select>
                                      <div></div>
                                    </h5>
                                  </div>

                                  {/* Clickable images */}
                                  <div>
                                    <img
                                      src={baseURL + profile.images}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        cursor: "pointer",
                                      }}
                                      alt="Profile Image"
                                      onClick={() =>
                                        handleImageClick(
                                          baseURL + profile.images
                                        )
                                      }
                                    />
                                  </div>
                                  <div>
                                    <img
                                      src={
                                        baseURL + userdocumentdetails.selfiePic
                                      }
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        cursor: "pointer",
                                      }}
                                      alt="Selfie Pic"
                                      onClick={() =>
                                        handleImageClick(
                                          baseURL +
                                            userdocumentdetails.selfiePic
                                        )
                                      }
                                    />
                                  </div>
                                </div>

                                <hr />

                                {/* Modal for full-screen image view */}
                                {showModal && (
                                  <div
                                    style={{
                                      position: "fixed",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      backgroundColor: "rgba(0, 0, 0, 0.8)",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      zIndex: 1000,
                                    }}
                                    onClick={handleCloseModal} // Close the modal when clicking outside the image
                                  >
                                    <img
                                      src={imageToShow}
                                      alt="Full screen"
                                      style={{
                                        maxWidth: "90%",
                                        maxHeight: "90%",
                                        borderRadius: "8px",
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              false
                            )}{" "}
                          </div>
                          {userdetails && userdetails.subcasteExisting ? (
                            <>
                              {" "}
                              <div
                              // style={{
                              //   display: "flex",
                              //   alignItems: "center",
                              //   justifyContent: "space-between",
                              // }}
                              >
                                <div>
                                  {" "}
                                  <h5 style={{fontWeight:"600"}}>Sub-Caste Verification</h5>{" "}
                                  {userdetails.subCasteUpload && (
                                    <p style={{fontWeight:"600"}}>
                                      SubCaste Upload Date :{" "}
                                      {userdetails.subCasteUpload}
                                    </p>
                                  )}
                                  {userdetails.caste && (
                                    <p style={{fontWeight:"600"}}>Caste: {userdetails.caste}</p>
                                  )}
                                </div>
                                <div>
                                  <FormControl>
                                    <Label for="subcaste " style={{fontWeight:"600"}}> SubCaste</Label>
                                    <Input
                                      name="createdfor"
                                      value={userdetails.subCaste}
                                      type="text"
                                      id="subcaste"
                                      style={{
                                        textTransform: "capitalize",
                                        marginBottom: "10px",
                                      }}
                                      onChange={(event) => {
                                        setSubCastechange(event.target.value);
                                      }}
                                    ></Input>
                                  </FormControl>
                                </div>
                                <div>
                                  {" "}
                                  <select
                                    className="form-select"
                                    onChange={() => verifySubcaste(profile.id)}
                                    value={
                                      userdetails.subcasteApproved === "0" ||
                                      userdetails.subcasteApproved === "1"
                                        ? userdetails.subcasteApproved
                                        : ""
                                    } // Handle the placeholder
                                    aria-label="Default select example"
                                  >
                                    <option>Change Status</option>
                                    <option value="0">Waiting</option>
                                    <option value="1">Verify</option>
                                  </select>
                                </div>
                              </div>
                              <hr />{" "}
                            </>
                          ) : (
                            false
                          )}

                          {horoscopedetails &&
                          horoscopedetails.gothramExisting ? (
                            <>
                              {" "}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  {" "}
                                  <h5 style={{fontWeight:"600"}}>Gothram Verification</h5>{" "}
                                  {horoscopedetails.gothramUpload && (
                                    <p style={{fontWeight:"600"}}>
                                      Gothram Upload Date :{" "}
                                      {horoscopedetails.gothramUpload}
                                    </p>
                                  )}
                                </div>
                                <div>
                                  <FormControl>
                                    <Label for="gothram" style={{fontWeight:"600"}}> Gothram</Label>
                                    <Input
                                      name="createdfor"
                                      value={horoscopedetails.gothram}
                                      type="text"
                                      id="gothram"
                                      style={{
                                        textTransform: "capitalize",
                                        marginBottom: "10px",
                                      }}
                                      onChange={(event) => {
                                        setGothramchange(event.target.value);
                                      }}
                                    ></Input>
                                  </FormControl>
                                </div>
                                <div>
                                  {" "}
                                  <div>
                                    <select
                                      className="form-select"
                                      onChange={() => verifygothram(profile.id)}
                                      value={
                                        horoscopedetails.gothramApproved ===
                                          "0" ||
                                        horoscopedetails.gothramApproved === "1"
                                          ? horoscopedetails.gothramApproved
                                          : ""
                                      } // Handle the placeholder
                                      aria-label="Default select example"
                                    >
                                      <option>Change Status</option>
                                      <option value="0">Waiting</option>
                                      <option value="1">Verify</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </>
                          ) : (
                            false
                          )}

                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <div>
                                  {" "}
                                  <h5 style={{fontWeight:"600"}}>About Myself Verification</h5>{" "}
                                  {personalitydetails &&
                                    personalitydetails.aboutUserUploaded && (
                                      <p style={{fontWeight:"600"}}>
                                        About User Upload Date :{" "}
                                        {personalitydetails &&
                                          personalitydetails.aboutUserUploaded}
                                      </p>
                                    )}
                                </div>
                                <div>
                                  {personalitydetails &&
                                  personalitydetails.aboutUser1 ? (
                                    <FormControl>
                                      <Label for="aboutUser" style={{fontWeight:"600"}}>
                                        Existing Myself
                                      </Label>
                                      <Input
                                        name="createdfor"
                                        value={personalitydetails.aboutUser}
                                        type="textarea"
                                        id="aboutUser"
                                        disabled
                                        style={{
                                          textTransform: "capitalize",
                                          marginBottom: "10px",
                                          width: "1000px",
                                          height: "100px",
                                        }}
                                        // onChange={(e) => {
                                        //   setRemarks(e.target.value);
                                        // }}
                                      ></Input>
                                    </FormControl>
                                  ) : (
                                    <FormControl>
                                      <Label for="aboutUser" style={{fontWeight:"600"}}>
                                        About Myself
                                      </Label>
                                      <Input
                                        name="createdfor"
                                        value={
                                          personalitydetails &&
                                          personalitydetails.aboutUser
                                            ? personalitydetails.aboutUser
                                            : ""
                                        }
                                        type="textarea"
                                        id="aboutUser"
                                        style={{
                                          textTransform: "capitalize",
                                          marginBottom: "10px",
                                          width: "1000px",
                                          height: "100px",
                                        }}
                                        onChange={(event) => {
                                          setaboutchange(event.target.value);
                                        }}
                                      />
                                    </FormControl>
                                  )}
                                  {personalitydetails &&
                                  personalitydetails.aboutUser1 ? (
                                    <FormControl>
                                      <Label for="aboutUser" style={{fontWeight:"600"}}        >
                                        About Myself
                                      </Label>
                                      <Input
                                        name="createdfor"
                                        value={
                                          personalitydetails &&
                                          personalitydetails.aboutUser1
                                            ? personalitydetails.aboutUser1
                                            : ""
                                        }
                                        type="textarea"
                                        id="aboutUser"
                                        style={{
                                          textTransform: "capitalize",
                                          marginBottom: "10px",
                                          width: "1000px",
                                          height: "100px",
                                        }}
                                        onChange={(event) => {
                                          setaboutchange(event.target.value);
                                        }}
                                      ></Input>
                                    </FormControl>
                                  ) : (
                                    false
                                  )}
                                </div>
                              </div>
                              <div>
                                {" "}
                                <div>
                                  <select
                                    className="form-select"
                                    onChange={() => verifyAbout(profile.id)}
                                    value={
                                      personalitydetails.aboutVerified ===
                                        "0" ||
                                      personalitydetails.aboutVerified === "1"
                                        ? personalitydetails.aboutVerified
                                        : ""
                                    } // Handle the placeholder
                                    aria-label="Default select example"
                                  >
                                    <option>Change Status</option>
                                    <option value="0">Waiting</option>
                                    <option value="1">Verify</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <div>
                                {" "}
                                <h5 style={{fontWeight:"600"}} >About Family Verification</h5>
                                {familydetails &&
                                  familydetails.familyUploaded && (
                                    <p style={{fontWeight:"600"}} >
                                      About Family Upload Date :{" "}
                                      {familydetails &&
                                        familydetails.familyUploaded}
                                    </p>
                                  )}
                              </div>
                              <div>
                                {familydetails && familydetails.aboutFamily1 ? (
                                  <FormControl>
                                    <Label for="aboutUser" style={{fontWeight:"600"}} >
                                      Existing About family
                                    </Label>
                                    <Input
                                      name="createdfor"
                                      value={familydetails.aboutFamily}
                                      type="textarea"
                                      id="aboutUser"
                                      disabled
                                      style={{
                                        textTransform: "capitalize",
                                        marginBottom: "10px",
                                        width: "1000px",
                                        height: "100px",
                                      }}
                                      // onChange={(e) => {
                                      //   setRemarks(e.target.value);
                                      // }}
                                    ></Input>
                                  </FormControl>
                                ) : (
                                  <FormControl>
                                    <Label for="aboutUser" style={{fontWeight:"600"}} >About Family</Label>
                                    <Input
                                      name="createdfor"
                                      value={
                                        familydetails &&
                                        familydetails.aboutFamily
                                          ? familydetails.aboutFamily
                                          : ""
                                      }
                                      type="textarea"
                                      id="aboutUser"
                                      style={{
                                        textTransform: "capitalize",
                                        marginBottom: "10px",
                                        width: "1000px",
                                        height: "100px",
                                      }}
                                      onChange={(event) => {
                                        setFamilyChange(event.target.value);
                                      }}
                                    />
                                  </FormControl>
                                )}
                                {familydetails && familydetails.aboutFamily1 ? (
                                  <FormControl>
                                    <Label for="aboutUser" style={{fontWeight:"600"}} >About Family</Label>
                                    <Input
                                      name="createdfor"
                                      value={familydetails.aboutFamily1}
                                      type="textarea"
                                      id="aboutUser"
                                      style={{
                                        textTransform: "capitalize",
                                        marginBottom: "10px",
                                        width: "1000px",
                                        height: "100px",
                                      }}
                                      onChange={(event) => {
                                        setFamilyChange(event.target.value);
                                      }}
                                    ></Input>
                                  </FormControl>
                                ) : (
                                  false
                                )}
                              </div>
                            </div>
                            <div>
                              {" "}
                              <div>
                                <select
                                  className="form-select"
                                  onChange={(e) =>
                                    verifyFamily(profile.id, e.target.value)
                                  } // Pass selected value
                                  value={
                                    familydetails.familyVerified === "0" ||
                                    familydetails.familyVerified === "1"
                                      ? familydetails.familyVerified
                                      : ""
                                  } // Handle the placeholder
                                  aria-label="Default select example"
                                >
                                  <option value="" disabled>
                                    Change Status
                                  </option>{" "}
                                  {/* Placeholder option */}
                                  <option value="0">Waiting</option>{" "}
                                  {/* '0' means 'Waiting' */}
                                  <option value="1">Verify</option>{" "}
                                  {/* '1' means 'Verify' */}
                                </select>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </div>{" "}
                    </Row>
                  </TabPane>
                  <TabPane tabId="6">
                    <Row>
                      <div className="row">
                        <div className="col">
                          <h5 style={{ textAlign: "justify" }}>
                            {userdetails ? userdetails.weLookingFor : false}
                          </h5>
                          <div>
                            <h5 style={{fontWeight:"600"}} >
                              Profile Image Verification:
                              {profile && (
                                <span
                                  className="badge"
                                  style={{
                                    borderRadius: "10px",
                                    fontWeight: "500",
                                    backgroundColor:
                                      profile.imageVerified === "0"
                                        ? "orange"
                                        : "green",
                                    color: "white",
                                  }}
                                >
                                  {profile.imageVerified === "0"
                                    ? "Waiting"
                                    : profile.imageVerified === "1"
                                    ? "Verified"
                                    : "Rejected"}
                                </span>
                              )}
                            </h5>
                            <h6 style={{fontWeight:"600"}} >
                              Image Upload Date:{" "}
                              {profile && profile.imageUpload}
                            </h6>
                            <div
                              className=""
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                {profile && (
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "30px ",
                                    }}
                                  >
                                    <div>
                                      <img
                                        ref={imageRef}
                                        src={
                                          uploadedImage ||
                                          baseURL + profile.images
                                        }
                                        style={{
                                          width: "200px",
                                          height: "200px",
                                        }}
                                        alt="Profile Image"
                                      />
                                    </div>
                                    <div>
                                      {!isCropping && (
                                        <button
                                          onClick={handleEditImage}
                                          style={{
                                            backgroundColor: "#43740b",
                                            borderRadius: "4px",
                                            border: "none",
                                            color: "#fff",
                                            marginTop: "100px",
                                          }}
                                        >
                                          Edit Image
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )}
                                <div>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    style={{
                                      marginTop: "20px",
                                    }}
                                  />
                                </div>
                              </div>
                              <div style={{ marginTop: "-20px" }}>
                                {croppedImage && (
                                  <div>
                                    <h6 style={{fontWeight:"600"}} >Cropped Image Preview</h6>
                                    <img
                                      src={croppedImage}
                                      alt="Cropped Image"
                                      style={{
                                        width: "200px",
                                        height: "150px",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              <div>
                                {isCropping && (
                                  <button
                                    onClick={cropImage}
                                    style={{
                                      backgroundColor: "#43740b",
                                      borderRadius: "4px",
                                      border: "none",
                                      color: "#fff",
                                      marginTop: "100px",
                                    }}
                                  >
                                    Crop Image
                                  </button>
                                )}
                              </div>
                              <h5>
                                <FormControl fullWidth>
                                  {profile && (
                                    <select
                                      onChange={(event) => {
                                        Imgverify(
                                          profile.id,
                                          event.target.value
                                        );
                                      }}
                                      className="form-select"
                                      value={profile.imageVerified}
                                      aria-label="Default select example"
                                    >
                                      <option></option>
                                      <option value="0">Waiting</option>
                                      <option value="1">Verify</option>
                                    </select>
                                  )}
                                </FormControl>
                              </h5>
                            </div>
                          </div>
                          <div>
                            <h4 style={{fontWeight:"600"}} >Image verification </h4>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <FormControl
                                component="fieldset"
                                variant="standard"
                              >
                                <FormGroup
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns:
                                      "repeat(1, minmax(200px, 1fr))",
                                  }}
                                >
                                  {profile && profile.image1 && (
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-around",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <div>
                                          {profile && (
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "30px",
                                              }}
                                            >
                                              <div>
                                                <img
                                                  ref={imageRef1}
                                                  src={
                                                    uploadedImage1 ||
                                                    baseURL + profile.image1
                                                  }
                                                  style={{
                                                    width: "200px",
                                                    height: "200px",
                                                    objectFit: "cover",
                                                  }}
                                                  alt="Profile Image"
                                                />
                                              </div>
                                              <div>
                                                {!isCropping1 && (
                                                  <button
                                                    onClick={() =>
                                                      setIsCropping1(true)
                                                    }
                                                    style={{
                                                      backgroundColor:
                                                        "#43740b",
                                                      borderRadius: "4px",
                                                      border: "none",
                                                      color: "#fff",
                                                      marginTop: "100px",
                                                    }}
                                                  >
                                                    Edit Image
                                                  </button>
                                                )}
                                              </div>
                                              <div
                                                style={{ marginTop: "-20px" }}
                                              >
                                                {croppedImage1 && (
                                                  <div>
                                                    <h6>
                                                      Cropped Image Preview
                                                    </h6>
                                                    <img
                                                      src={croppedImage1}
                                                      alt="Cropped Image"
                                                      style={{
                                                        width: "200px",
                                                        height: "150px",
                                                        objectFit: "cover",
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                              <div>
                                                {" "}
                                                {isCropping1 && (
                                                  <button
                                                    onClick={(event) => {
                                                      cropImage1(profile.id);
                                                    }}
                                                    style={{
                                                      backgroundColor:
                                                        "#43740b",
                                                      borderRadius: "4px",
                                                      border: "none",
                                                      color: "#fff",
                                                      marginTop: "100px",
                                                    }}
                                                  >
                                                    Crop Image
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>

                                        {/* <div style={{ marginTop: "100px" }}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={image1}
                                                onChange={handleChange}
                                                name="image1"
                                              />
                                            }
                                            label="Verify"
                                          />
                                        </div> */}
                                      </div>
                                      {profile.image1Upload && (
                                        <div>
                                          <h6 style={{fontWeight:"600"}} >
                                            Image Upload Date:{" "}
                                            {profile.image1Upload}
                                          </h6>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {/* Upload Button */}
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload1}
                                    style={{ marginTop: "20px" }}
                                  />
                                  {profile && profile.image2 && (
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-around",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <div>
                                          {profile && (
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "30px",
                                              }}
                                            >
                                              <div>
                                                <img
                                                  ref={imageRef2}
                                                  src={baseURL + profile.image2}
                                                  style={{
                                                    width: "200px",
                                                    height: "200px",
                                                    objectFit: "cover",
                                                  }}
                                                  alt="Profile Image"
                                                />
                                              </div>

                                              <div>
                                                {!isCropping2 && (
                                                  <button
                                                    onClick={() =>
                                                      setIsCropping2(true)
                                                    }
                                                    style={{
                                                      backgroundColor:
                                                        "#43740b",
                                                      borderRadius: "4px",
                                                      border: "none",
                                                      color: "#fff",
                                                      marginTop: "100px",
                                                    }}
                                                  >
                                                    Edit Image
                                                  </button>
                                                )}
                                              </div>

                                              <div
                                                style={{ marginTop: "-20px" }}
                                              >
                                                {croppedImage2 && (
                                                  <div>
                                                    <h6>
                                                      Cropped Image Preview
                                                    </h6>
                                                    <img
                                                      src={croppedImage2}
                                                      alt="Cropped Image"
                                                      style={{
                                                        width: "200px",
                                                        height: "150px",
                                                        objectFit: "cover",
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                              <div>
                                                {isCropping2 && (
                                                  <button
                                                    onClick={cropImage2}
                                                    style={{
                                                      backgroundColor:
                                                        "#43740b",
                                                      borderRadius: "4px",
                                                      border: "none",
                                                      color: "#fff",
                                                      marginTop: "100px",
                                                    }}
                                                  >
                                                    Crop Image
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        {/* <div style={{ marginTop: "100px" }}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={image2}
                                                onChange={handleChange}
                                                name="image2"
                                              />
                                            }
                                            label="Verify"
                                          /> */}
                                        {/* </div> */}
                                      </div>{" "}
                                      {profile.image2Upload && (
                                        <div>
                                          {" "}
                                          <h6 style={{fontWeight:"600"}} >
                                            Image Upload Date:{" "}
                                            {profile.image2Upload}
                                          </h6>
                                        </div>
                                      )}
                                    </div>
                                  )}{" "}
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload2}
                                    style={{ marginTop: "20px" }}
                                  />
                                  {profile && profile.image3 && (
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-around",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <div>
                                          {profile && (
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "30px ",
                                              }}
                                            >
                                              <div>
                                                <img
                                                  ref={imageRef3}
                                                  src={baseURL + profile.image3}
                                                  style={{
                                                    width: "200px",
                                                    height: "200px",
                                                  }}
                                                  alt="Profile Image"
                                                />
                                              </div>
                                              <div>
                                                {!isCropping3 && (
                                                  <button
                                                    onClick={() =>
                                                      setIsCropping3(true)
                                                    }
                                                    style={{
                                                      backgroundColor:
                                                        "#43740b",
                                                      borderRadius: "4px",
                                                      border: "none",
                                                      color: "#fff",
                                                      marginTop: "100px",
                                                    }}
                                                  >
                                                    Edit Image
                                                  </button>
                                                )}
                                              </div>{" "}
                                              <div
                                                style={{ marginTop: "-20px" }}
                                              >
                                                {croppedImage3 && (
                                                  <div>
                                                    <h6>
                                                      Cropped Image Preview
                                                    </h6>
                                                    <img
                                                      src={croppedImage3}
                                                      alt="Cropped Image"
                                                      style={{
                                                        width: "200px",
                                                        height: "150px",
                                                        objectFit: "cover",
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                              </div>{" "}
                                              <div>
                                                {isCropping3 && (
                                                  <button
                                                    onClick={cropImage3}
                                                    style={{
                                                      backgroundColor:
                                                        "#43740b",
                                                      borderRadius: "4px",
                                                      border: "none",
                                                      color: "#fff",
                                                      marginTop: "100px",
                                                    }}
                                                  >
                                                    Crop Image
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        {/* <div style={{ marginTop: "100px" }}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={image3}
                                                onChange={handleChange}
                                                name="image3"
                                              />
                                            }
                                            label="Verify"
                                          />
                                        </div> */}
                                      </div>{" "}
                                      {profile.image3Upload && (
                                        <div>
                                          {" "}
                                          <h6 style={{fontWeight:"600"}} >
                                            Image Upload Date:{" "}
                                            {profile.image3Upload}
                                          </h6>
                                        </div>
                                      )}
                                    </div>
                                  )}{" "}
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload3}
                                    style={{ marginTop: "20px" }}
                                  />
                                  {profile && profile.image4 && (
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-around",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <div>
                                          {profile && (
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "30px ",
                                              }}
                                            >
                                              <div>
                                                <img
                                                  ref={imageRef4}
                                                  src={baseURL + profile.image4}
                                                  style={{
                                                    width: "200px",
                                                    height: "200px",
                                                  }}
                                                  alt="Profile Image"
                                                />
                                              </div>
                                              <div>
                                                {!isCropping4 && (
                                                  <button
                                                    onClick={() =>
                                                      setIsCropping4(true)
                                                    }
                                                    style={{
                                                      backgroundColor:
                                                        "#43740b",
                                                      borderRadius: "4px",
                                                      border: "none",
                                                      color: "#fff",
                                                      marginTop: "100px",
                                                    }}
                                                  >
                                                    Edit Image
                                                  </button>
                                                )}
                                              </div>{" "}
                                              <div
                                                style={{ marginTop: "-20px" }}
                                              >
                                                {croppedImage4 && (
                                                  <div>
                                                    <h6>
                                                      Cropped Image Preview
                                                    </h6>
                                                    <img
                                                      src={croppedImage4}
                                                      alt="Cropped Image"
                                                      style={{
                                                        width: "200px",
                                                        height: "150px",
                                                        objectFit: "cover",
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                              </div>{" "}
                                              <div>
                                                {isCropping4 && (
                                                  <button
                                                    onClick={cropImage4}
                                                    style={{
                                                      backgroundColor:
                                                        "#43740b",
                                                      borderRadius: "4px",
                                                      border: "none",
                                                      color: "#fff",
                                                      marginTop: "100px",
                                                    }}
                                                  >
                                                    Crop Image
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        {/* <div style={{ marginTop: "100px" }}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={image4}
                                                onChange={handleChange}
                                                name="image4"
                                              />
                                            }
                                            label="Verify"
                                          />
                                        </div> */}
                                      </div>{" "}
                                      {profile.image4Upload && (
                                        <div>
                                          {" "}
                                          <h6 style={{fontWeight:"600"}} >
                                            Image Upload Date:{" "}
                                            {profile.image4Upload}
                                          </h6>
                                        </div>
                                      )}
                                    </div>
                                  )}{" "}
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload4}
                                    style={{ marginTop: "20px" }}
                                  />
                                  {profile && profile.image5 && (
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-around",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <div>
                                          {profile && (
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "30px ",
                                              }}
                                            >
                                              <div>
                                                <img
                                                  ref={imageRef5}
                                                  src={baseURL + profile.image5}
                                                  style={{
                                                    width: "200px",
                                                    height: "200px",
                                                  }}
                                                  alt="Profile Image"
                                                />
                                              </div>
                                              <div>
                                                {!isCropping5 && (
                                                  <button
                                                    onClick={() =>
                                                      setIsCropping5(true)
                                                    }
                                                    style={{
                                                      backgroundColor:
                                                        "#43740b",
                                                      borderRadius: "4px",
                                                      border: "none",
                                                      color: "#fff",
                                                      marginTop: "100px",
                                                    }}
                                                  >
                                                    Edit Image
                                                  </button>
                                                )}
                                              </div>{" "}
                                              <div
                                                style={{ marginTop: "-20px" }}
                                              >
                                                {croppedImage5 && (
                                                  <div>
                                                    <h6>
                                                      Cropped Image Preview
                                                    </h6>
                                                    <img
                                                      src={croppedImage5}
                                                      alt="Cropped Image"
                                                      style={{
                                                        width: "200px",
                                                        height: "150px",
                                                        objectFit: "cover",
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                              </div>{" "}
                                              <div>
                                                {isCropping5 && (
                                                  <button
                                                    onClick={cropImage5}
                                                    style={{
                                                      backgroundColor:
                                                        "#43740b",
                                                      borderRadius: "4px",
                                                      border: "none",
                                                      color: "#fff",
                                                      marginTop: "100px",
                                                    }}
                                                  >
                                                    Crop Image
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        {/* <div style={{ marginTop: "100px" }}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={image5}
                                                onChange={handleChange}
                                                name="image1"
                                              />
                                            }
                                            label="Verify"
                                          />
                                        </div> */}
                                      </div>{" "}
                                      {profile.image5Upload && (
                                        <div>
                                          {" "}
                                          <h6 style={{fontWeight:"600"}} >
                                            Image Upload Date:{" "}
                                            {profile.image5Upload}
                                          </h6>
                                        </div>
                                      )}
                                    </div>
                                  )}{" "}
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload5}
                                    style={{ marginTop: "20px" }}
                                  />
                                  {profile && profile.image6 && (
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-around",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <div>
                                          {profile && (
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "30px ",
                                              }}
                                            >
                                              <div>
                                                <img
                                                  ref={imageRef6}
                                                  src={baseURL + profile.image6}
                                                  style={{
                                                    width: "200px",
                                                    height: "200px",
                                                  }}
                                                  alt="Profile Image"
                                                />
                                              </div>
                                              <div>
                                                {!isCropping6 && (
                                                  <button
                                                    onClick={() =>
                                                      setIsCropping6(true)
                                                    }
                                                    style={{
                                                      backgroundColor:
                                                        "#43740b",
                                                      borderRadius: "4px",
                                                      border: "none",
                                                      color: "#fff",
                                                      marginTop: "100px",
                                                    }}
                                                  >
                                                    Edit Image
                                                  </button>
                                                )}
                                              </div>{" "}
                                              <div
                                                style={{ marginTop: "-20px" }}
                                              >
                                                {croppedImage6 && (
                                                  <div>
                                                    <h6>
                                                      Cropped Image Preview
                                                    </h6>
                                                    <img
                                                      src={croppedImage6}
                                                      alt="Cropped Image"
                                                      style={{
                                                        width: "200px",
                                                        height: "150px",
                                                        objectFit: "cover",
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                              </div>{" "}
                                              <div>
                                                {isCropping6 && (
                                                  <button
                                                    onClick={cropImage6}
                                                    style={{
                                                      backgroundColor:
                                                        "#43740b",
                                                      borderRadius: "4px",
                                                      border: "none",
                                                      color: "#fff",
                                                      marginTop: "100px",
                                                    }}
                                                  >
                                                    Crop Image
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        {/* <div style={{ marginTop: "100px" }}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={image6}
                                                onChange={handleChange}
                                                name="image6"
                                              />
                                            }
                                            label="Verify"
                                          />
                                        </div> */}
                                      </div>{" "}
                                      {profile.image6Upload && (
                                        <div>
                                          {" "}
                                          <h6>
                                            Image Upload Date:{" "}
                                            {profile.image6Upload}
                                          </h6>
                                        </div>
                                      )}
                                    </div>
                                  )}{" "}
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload6}
                                    style={{ marginTop: "20px" }}
                                  />
                                </FormGroup>
                              </FormControl>
                              {/* <div>
                                <select
                                  className="form-select"
                                  onChange={(event) => {
                                    imageveriy(profile.id, event.target.value);
                                  }}
                                  aria-label="Default select example"
                                >
                                  <option></option>
                                  <option value="0">Waiting</option>
                                  <option value="1">Verify</option>
                                  <option value="2">Reject</option>
                                </select>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </Row>
                  </TabPane>
                  <TabPane tabId="5">
                    <Row>
                      <h5 style={{fontWeight:"600"}} >Update MemberShip Plan</h5>
                      <div className="row">
                        <div>
                          <h5 style={{fontWeight:"600"}} >
                            Membership plan :
                            <span
                              className="badge"
                              style={{
                                borderRadius: "10px",
                                fontWeight: "500",
                                backgroundColor: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              {profile && profile.membershipType
                                ? profile.membershipType
                                : false}
                            </span>{" "}
                          </h5>
                          <h5>
                            <p style={{fontWeight:"600"}} >
                              Accessed Mobile Number :{" "}
                              {profile && profile.numberOfAccess}
                            </p>
                            <span>
                              <FormControl>
                                <select
                                  className="form-select mt-3 mb-3"
                                  onChange={(event) => {
                                    setMembershipid(event.target.value);
                                  }}
                                >
                                  {membershiplist &&
                                    Array.isArray(membershiplist) &&
                                    membershiplist.map((membership) => (
                                      <option
                                        key={membership.id}
                                        value={membership.id.toString()}
                                      >
                                        {membership.name}
                                      </option>
                                    ))}
                                </select>
                              </FormControl>
                            </span>
                          </h5>
                          <h5>
                            <span>
                              <FormControl>
                                <Label for="createdFor" style={{fontWeight:"600"}} >Remarks</Label>
                                <Input
                                  name="createdfor"
                                  type="textarea"
                                  id="createdFor"
                                  style={{
                                    textTransform: "capitalize",
                                    marginBottom: "10px",
                                  }}
                                  onChange={(e) => {
                                    setRemarks(e.target.value);
                                  }}
                                ></Input>
                              </FormControl>
                            </span>
                          </h5>
                          <Button
                            style={{ background: "black" }}
                            onClick={activeMembershipManual}
                          >
                            Submit
                          </Button>
                        </div>
                        {/* 
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            style={{ background: "black" }}
                            onClick={() => {}}
                          >
                            Submit
                          </Button>
                        </div> */}
                      </div>{" "}
                    </Row>
                  </TabPane>{" "}
                  <TabPane tabId="11">
                    <Row>
                      {Array.isArray(proShortList) &&
                      proShortList.length > 0 ? (
                        proShortList.map((user, index) => {
                          return (
                            <Usercard
                              key={index}
                              profileId={user.profileId}
                              id={user.id}
                              name={user.userName}
                              image={user.images}
                              age={user.age}
                              gender={user.gender}
                              dob={user.dob}
                              // status={user.status}
                              // state={user.state}
                            />
                          );
                        })
                      ) : (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <h3> Profile Not Found</h3>
                        </div>
                      )}
                    </Row>
                  </TabPane>{" "}
                  <TabPane tabId="12">
                    <Row>
                      {" "}
                      {Array.isArray(shortList) && shortList.length > 0 ? (
                        shortList.map((user, index) => {
                          return (
                            <Usercard
                              key={index}
                              profileId={user.profileId}
                              id={user.id}
                              name={user.userName}
                              image={user.images}
                              age={user.age}
                              gender={user.gender}
                              dob={user.dob}
                              // status={user.status}
                              // state={user.state}
                            />
                          );
                        })
                      ) : (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <h3> Profile Not Found</h3>
                        </div>
                      )}
                    </Row>
                  </TabPane>{" "}
                  <TabPane tabId="13">
                    <Row>
                      {" "}
                      {Array.isArray(likeMyProfile) &&
                      likeMyProfile.length > 0 ? (
                        likeMyProfile.map((user, index) => {
                          return (
                            <Usercard
                              key={index}
                              id={user.id}
                              profileId={user.profileId}
                              name={user.userName}
                              image={user.images}
                              age={user.age}
                              gender={user.gender}
                              dob={user.dob}
                              // status={user.status}
                              // state={user.state}
                            />
                          );
                        })
                      ) : (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <h3> Profile Not Found</h3>
                        </div>
                      )}
                    </Row>
                  </TabPane>{" "}
                  <TabPane tabId="14">
                    <Row>
                      {" "}
                      {Array.isArray(likeProfile) && likeProfile.length > 0 ? (
                        likeProfile.map((user, index) => {
                          return (
                            <Usercard
                              key={index}
                              id={user.id}
                              profileId={user.profileId}
                              name={user.userName}
                              image={user.images}
                              age={user.age}
                              gender={user.gender}
                              dob={user.dob}
                              // status={user.status}
                              // state={user.state}
                            />
                          );
                        })
                      ) : (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <h3> Profile Not Found</h3>
                        </div>
                      )}
                    </Row>
                  </TabPane>{" "}
                </TabContent>
              </div>
            </section>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default dashboard8;
