import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import api from "../../Axios";
import Swal from "sweetalert2";

import "react-phone-input-2/lib/style.css";

const UpdateDocument = () => {
  const history = useHistory();

  const [render, setRender] = useState(false);

  const [data, setData] = useState({
    aadhar: null,
    education: null,
    salarySlip: null,
  });
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  let { id } = useParams();
  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value); // Append the specific key
    });

    const url = `/admin/manageuserProfile/addUserDocs?id=${id}`;

    api.post(url, formData).then((res) => {
      setRender(!render);
      setData("");
      Swal.fire(res.data.data).then(() => {
        history.goBack(); // Navigate back to the previous page after Swal confirmation
      });
    });
  }
  useEffect(() => {
    api.post("/admin/manageUserProfile/userDocs", { id: id }).then((res) => {
      if (res.data.data !== "Data not found") {
        setData(res.data.data);
      }
    });
  }, []);
  // console.log(data);
  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Update Document</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <div className="content-body">
                  <Form onSubmit={handleSubmit} className="form">
                    {/* <!-- Email input --> */}
                    {/* <h3 id="reply-title" className="comment-reply-title">
                      Lets Create Your Profile{" "}
                      <small>
                        <a
                          rel="nofollow"
                          id="cancel-comment-reply-link"
                          href="#"
                          style={{ display: "none" }}
                        >
                          Cancel reply
                        </a>
                      </small>
                    </h3> */}
                    <label className="form-label" for="form2Example1">
                      <h5 style={{ paddingBottom: "5px" }}>
                        ID Proof - Aadhar Card
                      </h5>
                    </label>

                    <p>( File Type Should be PDF)</p>
                    <div
                      className="form-outline mb-4"
                      style={{ border: "solid 1px" }}
                    >
                      <input
                        type="file"
                        name="aadhar"
                        accept=".pdf"
                        onChange={handleChange}
                        required
                        id="form2Example1"
                        className="form-control"
                        style={{ padding: "10px" }}
                      />
                    </div>
                    <label className="form-label" for="form2Example1">
                      <h5 style={{ paddingBottom: "5px" }}>
                        Education Details
                      </h5>
                    </label>
                    <p>( File Type Should be PDF)</p>
                    <div
                      className="form-outline mb-4"
                      style={{ border: "solid 1px" }}
                    >
                      <input
                        type="file"
                        name="education"
                        accept=".pdf"
                        onChange={handleChange}
                        required
                        id="form2Example1"
                        className="form-control"
                        style={{ padding: "10px" }}
                      />
                    </div>
                    <label className="form-label" for="form2Example1">
                      <h5 style={{ paddingBottom: "5px" }}>Salary Slip</h5>
                    </label>
                    <p>( File Type Should be PDF)</p>
                    <div
                      className="form-outline mb-4"
                      style={{ border: "solid 1px" }}
                    >
                      <input
                        type="file"
                        name="salarySlip"
                        accept=".pdf"
                        onChange={handleChange}
                        required
                        id="form2Example1"
                        className="form-control"
                        style={{ padding: "10px" }}
                      />
                    </div>
                    <Button style={{ background: "black" }}>Submit</Button>
                  </Form>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UpdateDocument;
