import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import api from "../../Axios";
import Swal from "sweetalert2";

import "react-phone-input-2/lib/style.css";

const AddPersonality = () => {
  let heightList = [
    "4 ft",
    "4 ft 1 inch",
    "4 ft 2 inch",
    "4 ft 3 inch",
    "4 ft 4 inch",
    "4 ft 5 inch",
    "4 ft 6 inch",
    "4 ft 7 inch",
    "4 ft 8 inch",
    "4 ft 9 inch",
    "4 ft 10 inch",
    "4 ft 11 inch",
    "4 ft 12 inch",
    "5 ft",
    "5 ft 2 inch",
    "5 ft 1 inch",
    "5 ft 3 inch",
    "5 ft 4 inch",
    "5 ft 5 inch",
    "5 ft 6 inch",
    "5 ft 7 inch",
    "5 ft 8 inch",
    "5 ft 9 inch",
    "5 ft 10 inch",
    "5 ft 11 inch",
    "5 ft 12 inch",
    "6 ft",
    "6 ft 1 inch",
    "6 ft 2 inch",
    "6 ft 3 inch",
    "6 ft 4 inch",
    "6 ft 5 inch",
    "6 ft 6 inch",
    "6 ft 7 inch",
    "6 ft 8 inch",
    "6 ft 9 inch",
    "6 ft 10 inch",
    "6 ft 11 inch",
    "6 ft 12 inch",
    "7 ft",
    "7 ft 1 inch",
    "7 ft 2 inch",
    "7 ft 3 inch",
    "7 ft 4 inch",
    "7 ft 5 inch",
    "7 ft 6 inch",
    "7 ft 7 inch",
    "7 ft 8 inch",
    "7 ft 9 inch",
    "7 ft 10 inch",
    "7 ft 11 inch",
    "7 ft 12 inch",
  ];
  let weightList = [
    "40 Kgs",
    "41 Kgs",
    "42 Kgs",
    "43 Kgs",
    "44 Kgs",
    "45 Kgs",
    "46 Kgs",
    "47 Kgs",
    "48 Kgs",
    "49 Kgs",
    "50 Kgs",
    "51 Kgs",
    "52 Kgs",
    "53 Kgs",
    "54 Kgs",
    "55 Kgs",
    "56 Kgs",
    "57 Kgs",
    "58 Kgs",
    "59 Kgs",
    "60 Kgs",
    "61 Kgs",
    "62 Kgs",
    "63 Kgs",
    "64 Kgs",
    "65 Kgs",
    "66 Kgs",
    "67 Kgs",
    "68 Kgs",
    "69 Kgs",
    "70 Kgs",
    "71 Kgs",
    "72 Kgs",
    "73 Kgs",
    "74 Kgs",
    "75 Kgs",
    "76 Kgs",
    "77 Kgs",
    "78 Kgs",
    "79 Kgs",
    "80 Kgs",
    "81 Kgs",
    "82 Kgs",
    "83 Kgs",
    "84 Kgs",
    "85 Kgs",
    "86 Kgs",
    "87 Kgs",
    "88 Kgs",
    "89 Kgs",
    "90 Kgs",
    "91 Kgs",
    "92 Kgs",
    "93 Kgs",
    "94 Kgs",
    "95 Kgs",
    "96 Kgs",
    "97 Kgs",
    "98 Kgs",
    "99 Kgs",
    "100 Kgs",
    "101 Kgs",
    "102 Kgs",
    "103 Kgs",
    "104 Kgs",
    "105 Kgs",
    "106 Kgs",
    "107 Kgs",
    "108 Kgs",
    "109 Kgs",
    "110 Kgs",
  ];

  let bodyTypeList = ["Average", "Athletic", "Slim", "Heavy"];

  let physicalStatusList = ["Normal", "Physically Challenged"];
  const smoke = [
    "Never Smokes",
    "Smokes Occasionally",
    "Smokes Regularly",
    "Not Specified",
  ];
  const drink = [
    "Never Drinks",
    "Drinks Socially",
    "Drinks Regularly",
    "Not Specified",
  ];
  const eat = ["Vegetarian", "Non Vegetarian", "Eggetarian", "Not Specified"];
  const [data, setData] = useState({
    height: "",
    weight: "",
    bodyType: "",
    physicalStatus: "",
    eatingHabits: "",
    drinkingHabits: "",
    smokingHabits: "",
    aboutUser: "",
  });
  const history = useHistory();
  const [aboutUser, setAboutUser] = useState("");
  const handleAboutUserChange = (e) => {
    setAboutUser(e.target.value);
  };
  const [render, setRender] = useState(false);
  let { id } = useParams();
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  function handleSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();
    const fullData = { ...data, aboutUser, id: Number(id) };

    api
      .post("/admin/manageUserProfile/addPersonality", fullData)
      .then((res) => {
        setRender(!render);
        setData("");
        Swal.fire(res.data.data);
        history.push(`/addhoro/${id}`);
      });
  }
  //   function handlePhoneChange(value, country) {
  //     const phoneNumber = value.replace(/\D/g, ""); // Remove all non-digit characters from the value
  //     const countryCode = country.dialCode;
  //     const countryCodeIndex = phoneNumber.indexOf(countryCode);

  //     let extractedCountryCode = "";
  //     let extractedPhoneNumber = "";

  //     if (countryCodeIndex !== -1) {
  //       // Check if country code is found in the phone number
  //       extractedCountryCode = phoneNumber.slice(
  //         0,
  //         countryCodeIndex + countryCode.length
  //       );
  //       extractedPhoneNumber = phoneNumber.slice(
  //         countryCodeIndex + countryCode.length
  //       );
  //     } else {
  //       // If country code is not found, set the entire phone number as the extracted phone number
  //       extractedPhoneNumber = phoneNumber;
  //     }

  //     setData({
  //       ...data,
  //       countryCode: countryCode,
  //       phone: extractedPhoneNumber || "",
  //     });
  //   }

  // console.log(phone);
  // console.log(data);

  const sentences = [
    "I am a passionate developer who loves coding.",
    "I enjoy learning new technologies and improving my skills.",
    "I have a strong background in React and front-end development.",
    "In my free time, I like to work on open-source projects.",
    "I strive to create efficient and user-friendly web applications.",
  ];

  // Function to select a random sentence
  const getRandomSentence = () => {
    const randomIndex = Math.floor(Math.random() * sentences.length);
    return sentences[randomIndex];
  };

  // Automatically set a random sentence when the component mounts
  useEffect(() => {
    setAboutUser(getRandomSentence());
  }, []);
  return (
    <div>
      <div className="content">
        {" "}
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title" style={{fontWeight:"600"}}>Add Personality Details</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <div className="content-body">
                  <Form onSubmit={handleSubmit} className="form pt-5">
                    <Box sx={{ minWidth: 120 }}>
                      <FormGroup>
                        <Label for="height" style={{fontWeight:"600"}}>Select Height</Label>
                        <Input
                          name="height"
                          type="select"
                          id="height"
                          onChange={handleChange}
                          style={{ textTransform: "capitalize" }}
                        >
                          <option value="">Select Height</option>
                          {heightList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="weight" style={{fontWeight:"600"}}>Select Weight</Label>
                        <Input
                          name="weight"
                          type="select"
                          id="weight"
                          onChange={handleChange}
                        >
                          <option value="">Select Weight</option>

                          {weightList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>{" "}
                      <FormGroup>
                        <Label for="bodyType" style={{fontWeight:"600"}}>Select Body Type</Label>
                        <Input
                          name="bodyType"
                          type="select"
                          id="bodyType"
                          onChange={handleChange}
                        >
                          <option value="">Select Body Type</option>

                          {bodyTypeList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="physicalStatus" style={{fontWeight:"600"}}>
                          Select Physical Status
                        </Label>
                        <Input
                          name="physicalStatus"
                          type="select"
                          id="physicalStatus"
                          onChange={handleChange}
                          style={{ textTransform: "capitalize" }}
                        >
                          <option value="">Select 
                            Physical Status</option>
                          {physicalStatusList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="eatingHabits" style={{fontWeight:"600"}}>Select Eating Habit</Label>
                        <Input
                          name="eatingHabits"
                          type="select"
                          id="eatingHabits"
                          onChange={handleChange}
                        >
                          <option value="">Select Eating Habits</option>

                          {eat.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>{" "}
                      <FormGroup>
                        <Label for="drinkingHabits" style={{fontWeight:"600"}}>
                          Select Drinking Habit
                        </Label>
                        <Input
                          name="drinkingHabits"
                          type="select"
                          id="drinkingHabits"
                          onChange={handleChange}
                        >
                          <option value="">Select Drinking Habits</option>

                          {drink.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="smokingHabits" style={{fontWeight:"600"}}>Select Smoking Habit</Label>
                        <Input
                          name="smokingHabits"
                          type="select"
                          id="smokingHabits"
                          onChange={handleChange}
                        >
                          <option value="">Select Smoking Habit</option>

                          {smoke.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="aboutUser" style={{fontWeight:"600"}}>About User</Label>
                        <Input
                          name="aboutUser"
                          placeholder="About User"
                          type="textarea"
                          id="aboutUser"
                          value={aboutUser}
                          onChange={handleAboutUserChange}
                        />
                      </FormGroup>
                      <Button style={{ background: "black" }}>Submit</Button>
                    </Box>
                  </Form>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AddPersonality;
