import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import api from "../../Axios";
import Swal from "sweetalert2";

import "react-phone-input-2/lib/style.css";

const UpdateHobbies = () => {
  const history = useHistory();
  const hobbiesList = {
    "Hobbies & Interests": [
      "Acting",
      "Adventure Sports",
      "Alternative Healing / Medicine",
      "Art / Handicraft",
      "Astrology",
      "Baking",
      "Bikes / Cars",
      "Bird Watching",
      "Blogging / Video Blogging",
      "Board Games",
      "Book Clubs",
      "Calligraphy",
      "Clubbing",
      "Collectibles",
      "Cooking",
      "Crosswords",
      "Dancing",
      "DIY (Do It Yourself) Projects",
      "Fashion Design",
      "Film Making",
      "Fishing",
      "Gardening / Landscape",
      "Going On Long Drives",
      "Home / Interior Decoration",
      "Learning New Languages",
      "Listening To Podcasts",
      "Mimicry",
      "Museums / Galleries / Exhibitions",
      "Numerology",
      "Painting / Sketching / Drawing",
      "Palmistry",
      "Pets",
      "Photography",
      "Playing Cards",
      "Playing Musical Instruments",
      "Politics",
      "Scrabble",
      "Shopping",
      "Sightseeing",
      "Singing",
      "Social Media",
      "Social Service",
      "Solving Puzzles",
      "Stargazing",
      "Stitching",
      "Tailoring",
      "Technology",
      "Travel",
      "Touring",
      "Video / Computer Gaming",
      "Volunteer Work",
      "Wine Tasting",
      "Writing",
    ],
    Music: [
      "Bhangra",
      "Bluegrass",
      "Blues",
      "Christian / Gospel",
      "Classical",
      "Classical - Carnatic",
      "Country",
      "Death Metal",
      "Devotional",
      "Disco",
      "Electric Dance Music",
      "Film Songs",
      "Folk Music",
      "Fusion",
      "Ghazals",
      "Heavy Metal",
      "Hip Hop",
      "House Music",
      "Indian",
      "Indic Rock",
      "Indipop",
      "Jazz",
      "K-Pop",
      "Latin Music",
      "Melodies",
      "Opera",
      "Orchestra",
      "Pop",
      "Progressive Metal",
      "Punk Rock",
      "Qawalis",
      "Rap",
      "Reggae",
      "Remixes",
      "Rhythm & Blues",
      "Rock",
      "Sufi",
      "Techno",
      "Trance Music",
      "Western",
    ],
    Reading: [
      "Autobiographies / Biographies",
      "Business / Professional",
      "Classics",
      "Comics / Graphic Novels",
      "Fantasy",
      "Fiction",
      "History",
      "Humor",
      "Literature",
      "Love Reading Almost Anything",
      "Magazines / Newspapers",
      "Poetry",
      "Philosophy / Spiritual",
      "Romance",
      "Science Fiction",
      "Self - Help",
      "Short Stories",
      "Thriller / Suspense",
      "Travelogs / Blogs",
    ],
    "Movies & TV Shows": [
      "Action",
      "Anime",
      "Classics",
      "Comedy",
      "Documentary",
      "Drama",
      "Epics",
      "Fantasy",
      "Horror",
      "Movie Fanatic",
      "Neo - Noir",
      "Non - Commercial / Art",
      "Romantic",
      "Romantic Comedies",
      "Sci - Fi",
      "Short Films",
      "Thriller / Suspense",
      "World Cinema",
    ],
    "Sports & Fitness": [
      "Aerobic",
      "Archery",
      "Badminton",
      "Baseball",
      "Basketball",
      "Billiards / Snooker / Pool",
      "Bowling",
      "Boxing",
      "Cricket",
      "Cycling",
      "Football",
      "Golf",
      "Gym / Bodybuilding",
      "Handball",
      "Hockey",
      "Horseback Riding",
      "Jogging / Walking / Running",
      "Kabbadi",
      "Kho Kho",
      "Martial Arts",
      "Motor Racing",
      "Pilates",
      "Polo",
      "Racquetball",
      "Rugby",
      "Sailing / Boating / Rowing",
      "Scuba Diving",
      "Shooting",
      "Skating / Snowboarding / Skiing",
      "Squash",
      "Swimming / Water Sports",
      "Table Tennis",
      "Tennis",
      "Throwball",
      "Volleyball",
      "Weight Lifting",
      "Winter / Rink Sports",
      "Wrestling / Kushti",
      "Yoga / Meditation",
      "Zumba",
    ],
    Food: [
      "American",
      "Arabic",
      "Bengali",
      "Brazilian",
      "Chinese",
      "Continental",
      "Ethiopian",
      "Fast food",
      "French",
      "Greek",
      "Gujarati",
      "Italian",
      "Japanese",
      "Konkan",
      "Korean",
      "Lebanese",
      "Malaysian",
      "Marathi",
      "Mediterranean",
      "Mexican",
      "Mughlai",
      "North Indian",
      "Odia",
      "Oriental",
      "Parsi",
      "Punjabi",
      "Rajasthani",
      "Soul Food",
      "South Indian",
      "Spanish",
      "Srilankan",
      "Thai",
      "Turkish",
      "Vietnamese",
    ],
    "Spoken Languages": [
      "Afrikaans",
      "Angika",
      "Arabic",
      "Assamese",
      "Awadhi",
      "Bahasa",
      "Bengali",
      "Bhojpuri",
      "Bihari",
      "Burmese",
      "Cantonese",
      "Chhattisgarhi",
      "Croatian",
      "Danish",
      "Dorgi",
      "Dutch",
      "English",
      "Finnish",
      "French",
      "Garhwali",
      "German",
      "Greek",
      "Gujarati",
      "Haryanvi",
      "Hebrew",
      "Himachali",
      "Hindi",
      "Italian",
      "Japanese",
      "Kannada",
      "Kashmiri",
      "Konkani",
      "Korean",
      "Kumaoni",
      "Kutchi",
      "Lambadi",
      "Maithili",
      "Malay",
      "Malayalam",
      "Malvani",
      "Mandarin",
      "Manipuri",
      "Marathi",
      "Marwadi",
      "Mizo",
      "Nepalese",
      "Norwegian",
      "Oriya",
      "Pahari",
      "Persian",
      "Polish",
      "Pushto",
      "Portuguese",
      "Punjabi",
      "Rajasthani",
      "Russian",
      "Sambalpuri",
      "Sanskrit",
      "Sindhi",
      "Sinhala",
      "Sourashtra",
      "Spanish",
      "Swahili",
      "Swedish",
      "Tagalog",
      "Tamil",
      "Telugu",
      "Thai",
      "Tulu",
      "Turkish",
      "Urdu",
      "Vietnamese",
    ],
  };
  const handleCheckboxChange = (category, value) => {
    setData((prevData) => {
      const newHobbies = [...prevData.hobbies];
      if (newHobbies.includes(value)) {
        return {
          ...prevData,
          hobbies: newHobbies.filter((hobby) => hobby !== value),
        };
      } else {
        return { ...prevData, hobbies: [...newHobbies, value] };
      }
    });
  };

  const [data, setData] = useState({
    hobbies: {
      "Hobbies & Interests": [],
      Music: [],
      Reading: [],
      "Movies & TV Shows": [],
      "Sports & Fitness": [],
      Food: [],
      "Spoken Languages": [],
    },
  });

  const [render, setRender] = useState(false);
  let { id } = useParams();
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  function handleSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();
    const hobbiesData = {};
    Object.entries(hobbiesList).forEach(([category, items]) => {
      const selectedItems = data.hobbies.filter((hobby) =>
        items.includes(hobby)
      );
      if (selectedItems.length > 0) {
        hobbiesData[category] = selectedItems;
      }
    });

    const fullData = { id: Number(id), hobbies: hobbiesData };

    api.post("/admin/manageUserProfile/editHobbies.", fullData).then((res) => {
      setRender(!render);
      setData("");
      Swal.fire(res.data.data);
      history.push(`/adddoc/${id}`);
    });
  }
  useEffect(() => {
    api
      .post("admin/adminDashboard/userFullDetails", { id: id })
      .then((res) => {
        if (
          res.data.data &&
          res.data.data.horoscopeDetails &&
          res.data.data.horoscopeDetails.hobbies
        ) {
          setData({ hobbies: res.data.data.horoscopeDetails.hobbies });
        } else {
          setData({
            hobbies: {
              "Hobbies & Interests": [],
              Music: [],
              Reading: [],
              "Movies & TV Shows": [],
              "Sports & Fitness": [],
              Food: [],
              "Spoken Languages": [],
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  }, [id]);

  // console.log(data);
  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Update Hobbies Details</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <div className="content-body">
                  <Form onSubmit={handleSubmit} className="form">
                    <Box sx={{ minWidth: 120 }}>
                      <FormGroup>
                        <Label for="Hobbies">Hobbies</Label>
                        {Object.entries(hobbiesList).map(
                          ([category, items], categoryIndex) => (
                            <div
                              key={categoryIndex}
                              className="category-container"
                            >
                              <p>{category}</p>
                              <div className="checkbox-container">
                                {items.map((item, itemIndex) => (
                                  <div key={itemIndex}>
                                    <input
                                      type="checkbox"
                                      id={`${category}-${itemIndex}`}
                                      name={item}
                                      value={item}
                                      checked={
                                        Array.isArray(data.hobbies) &&
                                        data.hobbies.includes(item)
                                      }
                                      onChange={() =>
                                        handleCheckboxChange(category, item)
                                      }
                                    />
                                    <label htmlFor={`${category}-${itemIndex}`}>
                                      {item}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )
                        )}
                      </FormGroup>

                      <Button style={{ background: "black" }}>Submit</Button>
                    </Box>
                  </Form>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UpdateHobbies;
