import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import api from "../../Axios";
import Swal from "sweetalert2";

import "react-phone-input-2/lib/style.css";

const UpdateHoroscope = () => {
  const history = useHistory();

  const capitalize = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  let stateData = {
    "Andaman & Nicobar": {
      districts: ["nicobar", "north and Middle Andaman", "south Andaman"].map(
        capitalize
      ),

      cities: [
        "alipur",
        "andaman Island",
        "anderson Island",
        "arainj-Laka-Punga",
        "austinabad",
        "bamboo Flat",
        "barren Island",
        "beadonabad",
        "betapur",
        "bindraban",
        "bonington",
        "brookesabad",
        "cadell Point",
        "calicut",
        "chetamale",
        "cinque Islands",
        "defence Island",
        "digilpur",
        "dolyganj",
        "flat Island",
        "geinyale",
        "great Coco Island",
        "haddo",
        "havelock Island",
        "henry Lawrence Island",
        "herbertabad",
        "hobdaypur",
        "ilichar",
        "ingoie",
        "inteview Island",
        "jangli Ghat",
        "jhon Lawrence Island",
        "karen",
        "kartara",
        "kYD Islannd",
        "landfall Island",
        "little Andmand",
        "little Coco Island",
        "long Island",
        "maimyo",
        "malappuram",
        "manglutan",
        "manpur",
        "mitha Khari",
        "neill Island",
        "nicobar Island",
        "north Brother Island",
        "north Passage Island",
        "north Sentinel Island",
        "nothen Reef Island",
        "outram Island",
        "pahlagaon",
        "palalankwe",
        "passage Island",
        "phaiapong",
        "phoenix Island",
        "port Blair",
        "preparis Island",
        "protheroepur",
        "rangachang",
        "rongat",
        "rutland Island",
        "sabari",
        "saddle Peak",
        "shadipur",
        "smith Island",
        "sound Island",
        "south Sentinel Island",
        "spike Island",
        "tarmugli Island",
        "taylerabad",
        "titaije",
        "toibalawe",
        "tusonabad",
        "west Island",
        "wimberleyganj",
        "yadita",
      ].map(capitalize),
    },
    "Andhra Pradesh": {
      districts: [
        "alluri Sitharama Raju",
        "anakapalli",
        "ananthapuramu",
        "annamayya",
        "bapatla",
        "chittoor",
        "dr. B.R. Ambedkar Konaseema",
        "east Godavari",
        "eluru",
        "guntur",
        "kakinada",
        "krishna",
        "kurnool",
        "nandyal",
        "nTR",
        "palnadu",
        "parvathipuram Manyam",
        "prakasam",
        "srikakulam",
        "sri Potti Sriramulu Nellore",
        "sri Sathya Sai",
        "tirupati",
        "visakhapatnam",
        "vizianagaram",
        "west Godavari",
        "ySR",
      ].map(capitalize),
      cities: [
        "achampet",
        "adilabad",
        "adoni",
        "alampur",
        "allagadda",
        "alur",
        "amalapuram",
        "amangallu",
        "anakapalle",
        "anantapur",
        "andole",
        "araku",
        "armoor",
        "asifabad",
        "aswaraopet",
        "atmakur",
        "b. Kothakota",
        "badvel",
        "banaganapalle",
        "bandar",
        "bangarupalem",
        "banswada",
        "bapatla",
        "bellampalli",
        "bhadrachalam",
        "bhainsa",
        "bheemunipatnam",
        "bhimadole",
        "bhimavaram",
        "bhongir",
        "bhooragamphad",
        "boath",
        "bobbili",
        "bodhan",
        "chandoor",
        "chavitidibbalu",
        "chejerla",
        "chepurupalli",
        "cherial",
        "chevella",
        "chinnor",
        "chintalapudi",
        "chintapalle",
        "chirala",
        "chittoor",
        "chodavaram",
        "cuddapah",
        "cumbum",
        "darsi",
        "devarakonda",
        "dharmavaram",
        "dichpalli",
        "divi",
        "donakonda",
        "dronachalam",
        "east Godavari",
        "eluru",
        "eturnagaram",
        "gadwal",
        "gajapathinagaram",
        "gajwel",
        "garladinne",
        "giddalur",
        "godavari",
        "gooty",
        "gudivada",
        "gudur",
        "guntur",
        "hindupur",
        "hunsabad",
        "huzurabad",
        "huzurnagar",
        "hyderabad",
        "ibrahimpatnam",
        "jaggayyapet",
        "jagtial",
        "jammalamadugu",
        "jangaon",
        "jangareddygudem",
        "jannaram",
        "kadiri",
        "kaikaluru",
        "kakinada",
        "kalwakurthy",
        "kalyandurg",
        "kamalapuram",
        "kamareddy",
        "kambadur",
        "kanaganapalle",
        "kandukuru",
        "kanigiri",
        "karimnagar",
        "kavali",
        "khammam",
        "khanapur (AP)",
        "kodangal",
        "koduru",
        "koilkuntla",
        "kollapur",
        "kothagudem",
        "kovvur",
        "krishna",
        "krosuru",
        "kuppam",
        "kurnool",
        "lakkireddipalli",
        "madakasira",
        "madanapalli",
        "madhira",
        "madnur",
        "mahabubabad",
        "mahabubnagar",
        "mahadevapur",
        "makthal",
        "mancherial",
        "mandapeta",
        "mangalagiri",
        "manthani",
        "markapur",
        "marturu",
        "medachal",
        "medak",
        "medarmetla",
        "metpalli",
        "mriyalguda",
        "mulug",
        "mylavaram",
        "nagarkurnool",
        "nalgonda",
        "nallacheruvu",
        "nampalle",
        "nandigama",
        "nandikotkur",
        "nandyal",
        "narasampet",
        "narasaraopet",
        "narayanakhed",
        "narayanpet",
        "narsapur",
        "narsipatnam",
        "nazvidu",
        "nelloe",
        "nellore",
        "nidamanur",
        "nirmal",
        "nizamabad",
        "nuguru",
        "ongole",
        "outsarangapalle",
        "paderu",
        "pakala",
        "palakonda",
        "paland",
        "palmaneru",
        "pamuru",
        "pargi",
        "parkal",
        "parvathipuram",
        "pathapatnam",
        "pattikonda",
        "peapalle",
        "peddapalli",
        "peddapuram",
        "penukonda",
        "piduguralla",
        "piler",
        "pithapuram",
        "podili",
        "polavaram",
        "prakasam",
        "proddatur",
        "pulivendla",
        "punganur",
        "putturu",
        "rajahmundri",
        "rajampeta",
        "ramachandrapuram",
        "ramannapet",
        "rampachodavaram",
        "rangareddy",
        "rapur",
        "rayachoti",
        "rayadurg",
        "razole",
        "repalle",
        "saluru",
        "sangareddy",
        "sathupalli",
        "sattenapalle",
        "satyavedu",
        "shadnagar",
        "siddavattam",
        "siddipet",
        "sileru",
        "sircilla",
        "sirpur Kagaznagar",
        "sodam",
        "sompeta",
        "srikakulam",
        "srikalahasthi",
        "srisailam",
        "srungavarapukota",
        "sudhimalla",
        "sullarpet",
        "tadepalligudem",
        "tadipatri",
        "tanduru",
        "tanuku",
        "tekkali",
        "tenali",
        "thungaturthy",
        "tirivuru",
        "tirupathi",
        "tuni",
        "udaygiri",
        "ulvapadu",
        "uravakonda",
        "utnor",
        "v.R. Puram",
        "vaimpalli",
        "vayalpad",
        "venkatgiri",
        "venkatgirikota",
        "vijayawada",
        "vikrabad",
        "vinjamuru",
        "vinukonda",
        "visakhapatnam",
        "vizayanagaram",
        "vizianagaram",
        "vuyyuru",
        "wanaparthy",
        "warangal",
        "wardhannapet",
        "yelamanchili",
        "yelavaram",
        "yeleswaram",
        "yellandu",
        "yellanuru",
        "yellareddy",
        "yerragondapalem",
        "zahirabad",
      ].map(capitalize),
    },
    "Arunachal Pradesh": {
      districts: [
        "anjaw",
        "changlang",
        "east Kameng",
        "east Siang",
        "itanagar capital complex",
        "kamle",
        "kra Daadi",
        "kurung Kumey",
        "lepa Rada",
        "lohit",
        "longding",
        "lower Dibang Valley",
        "lower Siang",
        "lower Subansiri",
        "namsai",
        "pakke-Kessang",
        "papum Pare",
        "shi Yomi",
        "siang",
        "tawang",
        "tirap",
        "upper Dibang Valley",
        "upper Siang",
        "upper Subansiri",
        "west Kameng",
        "west Siang",
      ].map(capitalize),
      cities: [
        "along",
        "anini",
        "anjaw",
        "bameng",
        "basar",
        "changlang",
        "chowkhem",
        "daporizo",
        "dibang Valley",
        "dirang",
        "hayuliang",
        "huri",
        "itanagar",
        "jairampur",
        "kalaktung",
        "kameng",
        "khonsa",
        "kolaring",
        "kurung Kumey",
        "lohit",
        "lower Dibang Valley",
        "lower Subansiri",
        "mariyang",
        "mechuka",
        "miao",
        "nefra",
        "pakkekesang",
        "pangin",
        "papum Pare",
        "passighat",
        "roing",
        "sagalee",
        "seppa",
        "siang",
        "tali",
        "taliha",
        "tawang",
        "tezu",
        "tirap",
        "tuting",
        "upper Siang",
        "upper Subansiri",
        "yiang Kiag",
      ].map(capitalize),
    },
    Assam: {
      districts: [
        "baksa",
        "barpeta",
        "bongaigaon",
        "cachar",
        "charaideo",
        "chirang",
        "darrang",
        "dhemaji",
        "dhubri",
        "dibrugarh",
        "dima Hasao",
        "goalpara",
        "golaghat",
        "hailakandi",
        "jorhat",
        "kamrup",
        "kamrup Metropolitan",
        "karbi Anglong",
        "karimganj",
        "kokrajhar",
        "lakhimpur",
        "majuli",
        "morigaon",
        "nagaon",
        "nalbari",
        "sivasagar",
        "sonitpur",
        "south Salmara Mankachar",
        "tinsukia",
        "udalguri",
        "west Karbi Anglong",
      ].map(capitalize),
      cities: [
        "abhayapuri",
        "baithalangshu",
        "barama",
        "barpeta Road",
        "bihupuria",
        "bijni",
        "bilasipara",
        "bokajan",
        "bokakhat",
        "boko",
        "bongaigaon",
        "cachar",
        "cachar Hills",
        "darrang",
        "dhakuakhana",
        "dhemaji",
        "dhubri",
        "dibrugarh",
        "digboi",
        "diphu",
        "goalpara",
        "gohpur",
        "golaghat",
        "guwahati",
        "hailakandi",
        "hajo",
        "halflong",
        "hojai",
        "howraghat",
        "jorhat",
        "kamrup",
        "karbi Anglong",
        "karimganj",
        "kokarajhar",
        "kokrajhar",
        "lakhimpur",
        "maibong",
        "majuli",
        "mangaldoi",
        "mariani",
        "marigaon",
        "moranhat",
        "morigaon",
        "nagaon",
        "nalbari",
        "rangapara",
        "sadiya",
        "sibsagar",
        "silchar",
        "sivasagar",
        "sonitpur",
        "tarabarihat",
        "tezpur",
        "tinsukia",
        "udalgiri",
        "udalguri",
        "udarbondhBarpeta",
      ].map(capitalize),
    },
    Bihar: {
      districts: [
        "araria",
        "arwal",
        "aurangabad",
        "banka",
        "begusarai",
        "bhagalpur",
        "bhojpur",
        "buxar",
        "darbhanga",
        "east Champaran",
        "gaya",
        "gopalganj",
        "jamui",
        "jehanabad",
        "kaimur",
        "katihar",
        "khagaria",
        "kishanganj",
        "lakhisarai",
        "madhepura",
        "madhubani",
        "munger",
        "muzaffarpur",
        "nalanda",
        "nawada",
        "patna",
        "purnia",
        "rohtas",
        "saharsa",
        "samastipur",
        "saran",
        "sheikhpura",
        "sheohar",
        "sitamarhi",
        "siwan",
        "supaul",
        "vaishali",
        "west Champaran",
      ].map(capitalize),
      cities: [
        "adhaura",
        "amarpur",
        "araria",
        "areraj",
        "arrah",
        "arwal",
        "aurangabad",
        "bagaha",
        "banka",
        "banmankhi",
        "barachakia",
        "barauni",
        "barh",
        "barosi",
        "begusarai",
        "benipatti",
        "benipur",
        "bettiah",
        "bhabhua",
        "bhagalpur",
        "bhojpur",
        "bidupur",
        "biharsharif",
        "bikram",
        "bikramganj",
        "birpur",
        "buxar",
        "chakai",
        "champaran",
        "chapara",
        "dalsinghsarai",
        "danapur",
        "darbhanga",
        "daudnagar",
        "dhaka",
        "dhamdaha",
        "dumraon",
        "ekma",
        "forbesganj",
        "gaya",
        "gogri",
        "gopalganj",
        "h.Kharagpur",
        "hajipur",
        "hathua",
        "hilsa",
        "imamganj",
        "jahanabad",
        "jainagar",
        "jamshedpur",
        "jamui",
        "jehanabad",
        "jhajha",
        "jhanjharpur",
        "kahalgaon",
        "kaimur (Bhabua)",
        "katihar",
        "katoria",
        "khagaria",
        "kishanganj",
        "korha",
        "lakhisarai",
        "madhepura",
        "madhubani",
        "maharajganj",
        "mahua",
        "mairwa",
        "mallehpur",
        "masrakh",
        "mohania",
        "monghyr",
        "motihari",
        "motipur",
        "munger",
        "muzaffarpur",
        "nabinagar",
        "nalanda",
        "narkatiaganj",
        "naugachia",
        "nawada",
        "pakribarwan",
        "pakridayal",
        "patna",
        "phulparas",
        "piro",
        "pupri",
        "purena",
        "purnia",
        "rafiganj",
        "rajauli",
        "ramnagar",
        "raniganj",
        "raxaul",
        "rohtas",
        "rosera",
        "s.Bakhtiarpur",
        "saharsa",
        "samastipur",
        "saran",
        "sasaram",
        "seikhpura",
        "sheikhpura",
        "sheohar",
        "sherghati",
        "sidhawalia",
        "singhwara",
        "sitamarhi",
        "siwan",
        "sonepur",
        "supaul",
        "thakurganj",
        "triveniganj",
        "udakishanganj",
        "vaishali",
        "wazirganj",
      ].map(capitalize),
    },
    Chandigarh: {
      districts: ["chandigarh"].map(capitalize),
      cities: ["chandigarh", "mani Marja"].map(capitalize),
    },
    Chhattisgarh: {
      districts: [
        "balod",
        "baloda Bazar",
        "balrampur-Ramanujganj",
        "bastar",
        "bemetara",
        "bijapur",
        "bilaspur",
        "dantewada",
        "dhamtari",
        "durg",
        "gariaband",
        "gaurela-Pendra-Marwahi",
        "janjgir-Champa",
        "jashpur",
        "kabirdham",
        "kanker",
        "khairagarh-Chhuikhadan-Gandai",
        "kondagaon",
        "korba",
        "korea",
        "mahasamund",
        "manendragarh-Chirmiri-Bharatpur",
        "mohla-Manpur-Ambagarh Chowki",
        "mungeli",
        "narayanpur",
        "raigarh",
        "raipur",
        "rajnandgaon",
        "sarangarh-Bilaigarh",
        "shakti",
        "sukma",
        "surajpur",
        "surguja",
      ].map(capitalize),
      cities: [
        "ambikapur",
        "antagarh",
        "arang",
        "bacheli",
        "bagbahera",
        "bagicha",
        "baikunthpur",
        "balod",
        "balodabazar",
        "balrampur",
        "barpalli",
        "basana",
        "bastanar",
        "bastar",
        "bderajpur",
        "bemetara",
        "berla",
        "bhairongarh",
        "bhanupratappur",
        "bharathpur",
        "bhatapara",
        "bhilai",
        "bhilaigarh",
        "bhopalpatnam",
        "bijapur",
        "bilaspur",
        "bodla",
        "bokaband",
        "chandipara",
        "chhinagarh",
        "chhuriakala",
        "chingmut",
        "chuikhadan",
        "dabhara",
        "dallirajhara",
        "dantewada",
        "deobhog",
        "dhamda",
        "dhamtari",
        "dharamjaigarh",
        "dongargarh",
        "durg",
        "durgakondal",
        "fingeshwar",
        "gariaband",
        "garpa",
        "gharghoda",
        "gogunda",
        "ilamidi",
        "jagdalpur",
        "janjgir",
        "janjgir-Champa",
        "jarwa",
        "jashpur",
        "jashpurnagar",
        "kabirdham-Kawardha",
        "kanker",
        "kasdol",
        "kathdol",
        "kathghora",
        "kawardha",
        "keskal",
        "khairgarh",
        "kondagaon",
        "konta",
        "korba",
        "korea",
        "kota",
        "koyelibeda",
        "kuakunda",
        "kunkuri",
        "kurud",
        "lohadigundah",
        "lormi",
        "luckwada",
        "mahasamund",
        "makodi",
        "manendragarh",
        "manpur",
        "marwahi",
        "mohla",
        "mungeli",
        "nagri",
        "narainpur",
        "narayanpur",
        "neora",
        "netanar",
        "odgi",
        "padamkot",
        "pakhanjur",
        "pali",
        "pandaria",
        "pandishankar",
        "parasgaon",
        "pasan",
        "patan",
        "pathalgaon",
        "pendra",
        "pratappur",
        "premnagar",
        "raigarh",
        "raipur",
        "rajnandgaon",
        "rajpur",
        "ramchandrapur",
        "saraipali",
        "saranggarh",
        "sarona",
        "semaria",
        "shakti",
        "sitapur",
        "sukma",
        "surajpur",
        "surguja",
        "tapkara",
        "toynar",
        "udaipur",
        "uproda",
        "wadrainagar",
      ].map(capitalize),
    },
    "Dadra & Nagar Haveli": {
      districts: ["dadra  & Nagar Haveli"].map(capitalize),
      cities: [
        "amal",
        "amli",
        "bedpa",
        "chikhli",
        "dadra & Nagar Haveli",
        "dahikhed",
        "dolara",
        "galonda",
        "kanadi",
        "karchond",
        "khadoli",
        "kharadpada",
        "kherabari",
        "kherdi",
        "kothar",
        "luari",
        "mashat",
        "rakholi",
        "rudana",
        "saili",
        "sili",
        "silvassa",
        "sindavni",
        "udva",
        "umbarkoi",
        "vansda",
        "vasona",
        "velugam",
      ].map(capitalize),
    },
    "Daman & Diu": {
      districts: ["daman", "diu"].map(capitalize),
      cities: [
        "brancavare",
        "dagasi",
        "daman",
        "diu",
        "magarvara",
        "nagwa",
        "pariali",
        "passo Covo",
      ].map(capitalize),
    },
    Delhi: {
      districts: [
        "central Delhi",
        "east Delhi",
        "new Delhi",
        "north Delhi",
        "north East Delhi",
        "north West Delhi",
        "shahdara district",
        "south Delhi",
        "south East Delhi",
        "south West Delhi",
        "west Delhi",
      ].map(capitalize),
      cities: [
        "central Delhi",
        "east Delhi",
        "new Delhi",
        "north Delhi",
        "north East Delhi",
        "north West Delhi",
        "south Delhi",
        "south West Delhi",
        "west Delhi",
      ].map(capitalize),
    },
    Goa: {
      districts: ["north Goa", "south Goa"].map(capitalize),
      cities: [
        "canacona",
        "candolim",
        "chinchinim",
        "cortalim",
        "goa",
        "jua",
        "madgaon",
        "mahem",
        "mapuca",
        "marmagao",
        "panji",
        "ponda",
        "sanvordem",
        "terekhol",
      ].map(capitalize),
    },
    Gujarat: {
      districts: [
        "ahmedabad",
        "amreli",
        "anand",
        "aravalli",
        "banaskantha",
        "bharuch",
        "bhavnagar",
        "botad",
        "chhota Udaipur",
        "dahod",
        "dang",
        "devbhumi Dwarka",
        "gandhinagar",
        "gir Somnath",
        "jamnagar",
        "junagadh",
        "kheda",
        "kutch",
        "mahisagar",
        "mehsana",
        "morbi",
        "narmada",
        "navsari",
        "panchmahal",
        "patan",
        "porbandar",
        "rajkot",
        "sabarkantha",
        "surat",
        "surendranagar",
        "tapi",
        "vadodara",
        "valsad",
      ].map(capitalize),
      cities: [
        "ahmedabad",
        "ahwa",
        "amod",
        "amreli",
        "anand",
        "anjar",
        "ankaleshwar",
        "babra",
        "balasinor",
        "banaskantha",
        "bansada",
        "bardoli",
        "bareja",
        "baroda",
        "barwala",
        "bayad",
        "bhachav",
        "bhanvad",
        "bharuch",
        "bhavnagar",
        "bhiloda",
        "bhuj",
        "billimora",
        "borsad",
        "botad",
        "chanasma",
        "chhota Udaipur",
        "chotila",
        "dabhoi",
        "dahod",
        "damnagar",
        "dang",
        "danta",
        "dasada",
        "dediapada",
        "deesa",
        "dehgam",
        "deodar",
        "devgadhbaria",
        "dhandhuka",
        "dhanera",
        "dharampur",
        "dhari",
        "dholka",
        "dhoraji",
        "dhrangadhra",
        "dhrol",
        "dwarka",
        "fortsongadh",
        "gadhada",
        "gandhi Nagar",
        "gariadhar",
        "godhra",
        "gogodar",
        "gondal",
        "halol",
        "halvad",
        "harij",
        "himatnagar",
        "idar",
        "jambusar",
        "jamjodhpur",
        "jamkalyanpur",
        "jamnagar",
        "jasdan",
        "jetpur",
        "jhagadia",
        "jhalod",
        "jodia",
        "junagadh",
        "junagarh",
        "kalawad",
        "kalol",
        "kapad Wanj",
        "keshod",
        "khambat",
        "khambhalia",
        "khavda",
        "kheda",
        "khedbrahma",
        "kheralu",
        "kodinar",
        "kotdasanghani",
        "kunkawav",
        "kutch",
        "kutchmandvi",
        "kutiyana",
        "lakhpat",
        "lakhtar",
        "lalpur",
        "limbdi",
        "limkheda",
        "lunavada",
        "m.M.Mangrol",
        "mahuva",
        "malia-Hatina",
        "maliya",
        "malpur",
        "manavadar",
        "mandvi",
        "mangrol",
        "mehmedabad",
        "mehsana",
        "miyagam",
        "modasa",
        "morvi",
        "muli",
        "mundra",
        "nadiad",
        "nakhatrana",
        "nalia",
        "narmada",
        "naswadi",
        "navasari",
        "nizar",
        "okha",
        "paddhari",
        "padra",
        "palanpur",
        "palitana",
        "panchmahals",
        "patan",
        "pavijetpur",
        "porbandar",
        "prantij",
        "radhanpur",
        "rahpar",
        "rajaula",
        "rajkot",
        "rajpipla",
        "ranavav",
        "sabarkantha",
        "sanand",
        "sankheda",
        "santalpur",
        "santrampur",
        "savarkundla",
        "savli",
        "sayan",
        "sayla",
        "shehra",
        "sidhpur",
        "sihor",
        "sojitra",
        "sumrasar",
        "surat",
        "surendranagar",
        "talaja",
        "thara",
        "tharad",
        "thasra",
        "una-Diu",
        "upleta",
        "vadgam",
        "vadodara",
        "valia",
        "vallabhipur",
        "valod",
        "valsad",
        "vanthali",
        "vapi",
        "vav",
        "veraval",
        "vijapur",
        "viramgam",
        "visavadar",
        "visnagar",
        "vyara",
        "waghodia",
        "wankaner",
      ].map(capitalize),
    },
    Haryana: {
      districts: [
        "ambala",
        "bhiwani",
        "charkhi Dadri",
        "faridabad",
        "fatehabad",
        "gurugram",
        "hisar",
        "jhajjar",
        "jind",
        "kaithal",
        "karnal",
        "kurukshetra",
        "mahendragarh",
        "nuh",
        "palwal",
        "panchkula",
        "panipat",
        "rewari",
        "rohtak",
        "sirsa",
        "sonipat",
        "yamunanagar",
      ],
      cities: [
        "adampur Mandi",
        "ambala",
        "assandh",
        "bahadurgarh",
        "barara",
        "barwala",
        "bawal",
        "bawanikhera",
        "bhiwani",
        "charkhidadri",
        "cheeka",
        "chhachrauli",
        "dabwali",
        "ellenabad",
        "faridabad",
        "fatehabad",
        "ferojpur Jhirka",
        "gharaunda",
        "gohana",
        "gurgaon",
        "hansi",
        "hisar",
        "jagadhari",
        "jatusana",
        "jhajjar",
        "jind",
        "julana",
        "kaithal",
        "kalanaur",
        "kalanwali",
        "kalka",
        "karnal",
        "kosli",
        "kurukshetra",
        "loharu",
        "mahendragarh",
        "meham",
        "mewat",
        "mohindergarh",
        "naraingarh",
        "narnaul",
        "narwana",
        "nilokheri",
        "nuh",
        "palwal",
        "panchkula",
        "panipat",
        "pehowa",
        "ratia",
        "rewari",
        "rohtak",
        "safidon",
        "sirsa",
        "siwani",
        "sonipat",
        "tohana",
        "tohsam",
        "yamunanagar",
      ].map(capitalize),
    },
    "Himachal Pradesh": {
      districts: [
        "bilaspur",
        "chamba",
        "hamirpur",
        "kangra",
        "kinnaur",
        "kullu",
        "lahaul and Spiti",
        "mandi",
        "shimla",
        "sirmaur",
        "solan",
        "una",
      ].map(capitalize),
      cities: [
        "amb",
        "arki",
        "banjar",
        "bharmour",
        "bilaspur",
        "chamba",
        "churah",
        "dalhousie",
        "dehra Gopipur",
        "hamirpur",
        "jogindernagar",
        "kalpa",
        "kangra",
        "kinnaur",
        "kullu",
        "lahaul",
        "mandi",
        "nahan",
        "nalagarh",
        "nirmand",
        "nurpur",
        "palampur",
        "pangi",
        "paonta",
        "pooh",
        "rajgarh",
        "rampur Bushahar",
        "rohru",
        "shimla",
        "sirmaur",
        "solan",
        "spiti",
        "sundernagar",
        "theog",
        "udaipur",
        "una",
      ].map(capitalize),
    },
    "Jammu & Kashmir": {
      districts: [
        "anantnag",
        "budgam",
        "bandipore",
        "baramulla",
        "doda",
        "ganderbal",
        "jammu",
        "kathua",
        "kishtwar",
        "kulgam",
        "kupwara",
        "poonch",
        "pulwama",
        "rajouri",
        "ramban",
        "reasi",
        "samba",
        "shopian",
        "srinagar",
        "udhampur",
      ].map(capitalize),
      cities: [
        "akhnoor",
        "anantnag",
        "badgam",
        "bandipur",
        "baramulla",
        "basholi",
        "bedarwah",
        "budgam",
        "doda",
        "gulmarg",
        "jammu",
        "kalakot",
        "kargil",
        "karnah",
        "kathua",
        "kishtwar",
        "kulgam",
        "kupwara",
        "leh",
        "mahore",
        "nagrota",
        "nobra",
        "nowshera",
        "nyoma",
        "padam",
        "pahalgam",
        "patnitop",
        "poonch",
        "pulwama",
        "rajouri",
        "ramban",
        "ramnagar",
        "reasi",
        "samba",
        "srinagar",
        "udhampur",
        "vaishno Devi",
      ].map(capitalize),
    },
    Jharkhand: {
      districts: [
        "bokaro",
        "chatra",
        "deoghar",
        "dhanbad",
        "dumka",
        "east Singhbhum",
        "garhwa",
        "giridih",
        "godda",
        "gumla",
        "hazaribag",
        "jamtara",
        "khunti",
        "koderma",
        "latehar",
        "lohardaga",
        "pakur",
        "palamu",
        "ramgarh",
        "ranchi",
        "sahibganj",
        "seraikela-Kharsawan",
        "simdega",
        "west Singhbhum",
      ].map(capitalize),
      cities: [
        "bagodar",
        "baharagora",
        "balumath",
        "barhi",
        "barkagaon",
        "barwadih",
        "basia",
        "bermo",
        "bhandaria",
        "bhawanathpur",
        "bishrampur",
        "bokaro",
        "bolwa",
        "bundu",
        "chaibasa",
        "chainpur",
        "chakardharpur",
        "chandil",
        "chatra",
        "chavparan",
        "daltonganj",
        "deoghar",
        "dhanbad",
        "dumka",
        "dumri",
        "garhwa",
        "garu",
        "ghaghra",
        "ghatsila",
        "giridih",
        "godda",
        "gomia",
        "govindpur",
        "gumla",
        "hazaribagh",
        "hunterganj",
        "ichak",
        "itki",
        "jagarnathpur",
        "jamshedpur",
        "jamtara",
        "japla",
        "jharmundi",
        "jhinkpani",
        "jhumaritalaiya",
        "kathikund",
        "kharsawa",
        "khunti",
        "koderma",
        "kolebira",
        "latehar",
        "lohardaga",
        "madhupur",
        "mahagama",
        "maheshpur Raj",
        "mandar",
        "mandu",
        "manoharpur",
        "muri",
        "nagarutatri",
        "nala",
        "noamundi",
        "pakur",
        "palamu",
        "palkot",
        "patan",
        "rajdhanwar",
        "rajmahal",
        "ramgarh",
        "ranchi",
        "sahibganj",
        "saraikela",
        "simaria",
        "simdega",
        "singhbhum",
        "tisri",
        "torpa",
      ].map(capitalize),
    },
    Karnataka: {
      districts: [
        "bagalakote",
        "ballari",
        "belagavi",
        "bengaluru Rural",
        "bengaluru Urban",
        "bidar",
        "chamarajanagara",
        "chikkaballapura",
        "chikkamagaluru",
        "chitradurga",
        "dakshina Kannada",
        "davanagere",
        "dharwada",
        "gadaga",
        "kalaburagi",
        "hassan",
        "haveri",
        "kodagu",
        "kolar",
        "koppala",
        "mandya",
        "mysuru",
        "raichuru",
        "ramanagara",
        "shivamogga",
        "tumakuru",
        "udupi",
        "uttara Kannada",
        "vijayanagara",
        "vijayapura",
        "yadgiri",
      ].map(capitalize),
      cities: [
        "afzalpur",
        "ainapur",
        "aland",
        "alur",
        "anekal",
        "ankola",
        "arsikere",
        "athani",
        "aurad",
        "bableshwar",
        "badami",
        "bagalkot",
        "bagepalli",
        "bailhongal",
        "bangalore",
        "bangalore Rural",
        "bangarpet",
        "bantwal",
        "basavakalyan",
        "basavanabagewadi",
        "basavapatna",
        "belgaum",
        "bellary",
        "belthangady",
        "belur",
        "bhadravati",
        "bhalki",
        "bhatkal",
        "bidar",
        "bijapur",
        "biligi",
        "chadchan",
        "challakere",
        "chamrajnagar",
        "channagiri",
        "channapatna",
        "channarayapatna",
        "chickmagalur",
        "chikballapur",
        "chikkaballapur",
        "chikkanayakanahalli",
        "chikkodi",
        "chikmagalur",
        "chincholi",
        "chintamani",
        "chitradurga",
        "chittapur",
        "cowdahalli",
        "davanagere",
        "deodurga",
        "devangere",
        "devarahippargi",
        "dharwad",
        "doddaballapur",
        "gadag",
        "gangavathi",
        "gokak",
        "gowribdanpur",
        "gubbi",
        "gulbarga",
        "gundlupet",
        "h.B.Halli",
        "h.D. Kote",
        "haliyal",
        "hampi",
        "hangal",
        "harapanahalli",
        "hassan",
        "haveri",
        "hebri",
        "hirekerur",
        "hiriyur",
        "holalkere",
        "holenarsipur",
        "honnali",
        "honnavar",
        "hosadurga",
        "hosakote",
        "hosanagara",
        "hospet",
        "hubli",
        "hukkeri",
        "humnabad",
        "hungund",
        "hunsagi",
        "hunsur",
        "huvinahadagali",
        "indi",
        "jagalur",
        "jamkhandi",
        "jewargi",
        "joida",
        "k.R. Nagar",
        "kadur",
        "kalghatagi",
        "kamalapur",
        "kanakapura",
        "kannada",
        "kargal",
        "karkala",
        "karwar",
        "khanapur",
        "kodagu",
        "kolar",
        "kollegal",
        "koppa",
        "koppal",
        "koratageri",
        "krishnarajapet",
        "kudligi",
        "kumta",
        "kundapur",
        "kundgol",
        "kunigal",
        "kurugodu",
        "kustagi",
        "lingsugur",
        "madikeri",
        "madugiri",
        "malavalli",
        "malur",
        "mandya",
        "mangalore",
        "manipal",
        "manvi",
        "mashal",
        "molkalmuru",
        "mudalgi",
        "muddebihal",
        "mudhol",
        "mudigere",
        "mulbagal",
        "mundagod",
        "mundargi",
        "murugod",
        "mysore",
        "nagamangala",
        "nanjangud",
        "nargund",
        "narsimrajapur",
        "navalgund",
        "nelamangala",
        "nimburga",
        "pandavapura",
        "pavagada",
        "puttur",
        "raibag",
        "raichur",
        "ramdurg",
        "ranebennur",
        "ron",
        "sagar",
        "sakleshpur",
        "salkani",
        "sandur",
        "saundatti",
        "savanur",
        "sedam",
        "shahapur",
        "shankarnarayana",
        "shikaripura",
        "shimoga",
        "shirahatti",
        "shorapur",
        "siddapur",
        "sidlaghatta",
        "sindagi",
        "sindhanur",
        "sira",
        "sirsi",
        "siruguppa",
        "somwarpet",
        "sorab",
        "sringeri",
        "sriniwaspur",
        "srirangapatna",
        "sullia",
        "t. Narsipur",
        "tallak",
        "tarikere",
        "telgi",
        "thirthahalli",
        "tiptur",
        "tumkur",
        "turuvekere",
        "udupi",
        "virajpet",
        "wadi",
        "yadgiri",
        "yelburga",
        "yellapur",
      ].map(capitalize),
    },
    Kerala: {
      districts: [
        "alappuzha",
        "ernakulam",
        "idukki",
        "kannur",
        "kasaragod",
        "kollam",
        "kottayam",
        "kozhikode",
        "malappuram",
        "palakkad",
        "pathanamthitta",
        "thiruvananthapuram",
        "thrissur",
        "wayanad",
      ].map(capitalize),
      cities: [
        "adimaly",
        "adoor",
        "agathy",
        "alappuzha",
        "alathur",
        "alleppey",
        "alwaye",
        "amini",
        "androth",
        "attingal",
        "badagara",
        "bitra",
        "calicut",
        "cannanore",
        "chetlet",
        "ernakulam",
        "idukki",
        "irinjalakuda",
        "kadamath",
        "kalpeni",
        "kalpetta",
        "kanhangad",
        "kanjirapally",
        "kannur",
        "karungapally",
        "kasargode",
        "kavarathy",
        "kiltan",
        "kochi",
        "koduvayur",
        "kollam",
        "kottayam",
        "kovalam",
        "kozhikode",
        "kunnamkulam",
        "malappuram",
        "mananthodi",
        "manjeri",
        "mannarghat",
        "mavelikkara",
        "minicoy",
        "munnar",
        "muvattupuzha",
        "nedumandad",
        "nedumgandam",
        "nilambur",
        "palai",
        "palakkad",
        "palghat",
        "pathaanamthitta",
        "pathanamthitta",
        "payyanur",
        "peermedu",
        "perinthalmanna",
        "perumbavoor",
        "punalur",
        "quilon",
        "ranni",
        "shertallai",
        "shoranur",
        "taliparamba",
        "tellicherry",
        "thiruvananthapuram",
        "thodupuzha",
        "thrissur",
        "tirur",
        "tiruvalla",
        "trichur",
        "trivandrum",
        "uppala",
        "vadakkanchery",
        "vikom",
        "wayanad",
      ].map(capitalize),
    },
    Ladakh: {
      districts: ["kargil", "leh"].map(capitalize),
      cities: ["leh", "kargil"].map(capitalize),
    },
    Lakshadweep: {
      districts: ["lakshadweep"].map(capitalize),
      cities: [
        "agatti Island",
        "bingaram Island",
        "bitra Island",
        "chetlat Island",
        "kadmat Island",
        "kalpeni Island",
        "kavaratti Island",
        "kiltan Island",
        "lakshadweep Sea",
        "minicoy Island",
        "north Island",
        "south Island",
      ].map(capitalize),
    },
    "Madhya Pradesh": {
      districts: [
        "agar Malwa",
        "alirajpur",
        "anuppur",
        "ashoknagar",
        "balaghat",
        "barwani",
        "betul",
        "bhind",
        "bhopal",
        "burhanpur",
        "chhatarpur",
        "chhindwara",
        "damoh",
        "datia",
        "dewas",
        "dhar",
        "dindori",
        "guna",
        "gwalior",
        "harda",
        "hoshangabad",
        "indore",
        "jabalpur",
        "jhabua",
        "katni",
        "khandwa",
        "khargone",
        "mandla",
        "mandsaur",
        "morena",
        "narsinghpur",
        "neemuch",
        "niwari",
        "panna",
        "raisen",
        "rajgarh",
        "ratlam",
        "rewa",
        "sagar",
        "satna",
        "sehore",
        "seoni",
        "shahdol",
        "shajapur",
        "sheopur",
        "shivpuri",
        "sidhi",
        "singrauli",
        "tikamgarh",
        "ujjain",
        "umaria",
        "vidisha",
      ].map(capitalize),
      cities: [
        "agar",
        "ajaigarh",
        "alirajpur",
        "amarpatan",
        "amarwada",
        "ambah",
        "anuppur",
        "arone",
        "ashoknagar",
        "ashta",
        "atner",
        "babaichichli",
        "badamalhera",
        "badarwsas",
        "badnagar",
        "badnawar",
        "badwani",
        "bagli",
        "baihar",
        "balaghat",
        "baldeogarh",
        "baldi",
        "bamori",
        "banda",
        "bandhavgarh",
        "bareli",
        "baroda",
        "barwaha",
        "barwani",
        "batkakhapa",
        "begamganj",
        "beohari",
        "berasia",
        "berchha",
        "betul",
        "bhainsdehi",
        "bhander",
        "bhanpura",
        "bhikangaon",
        "bhimpur",
        "bhind",
        "bhitarwar",
        "bhopal",
        "biaora",
        "bijadandi",
        "bijawar",
        "bijaypur",
        "bina",
        "birsa",
        "birsinghpur",
        "budhni",
        "burhanpur",
        "buxwaha",
        "chachaura",
        "chanderi",
        "chaurai",
        "chhapara",
        "chhatarpur",
        "chhindwara",
        "chicholi",
        "chitrangi",
        "churhat",
        "dabra",
        "damoh",
        "datia",
        "deori",
        "deosar",
        "depalpur",
        "dewas",
        "dhar",
        "dharampuri",
        "dindori",
        "gadarwara",
        "gairatganj",
        "ganjbasoda",
        "garoth",
        "ghansour",
        "ghatia",
        "ghatigaon",
        "ghorandogri",
        "ghughari",
        "gogaon",
        "gohad",
        "goharganj",
        "gopalganj",
        "gotegaon",
        "gourihar",
        "guna",
        "gunnore",
        "gwalior",
        "gyraspur",
        "hanumana",
        "harda",
        "harrai",
        "harsud",
        "hatta",
        "hoshangabad",
        "ichhawar",
        "indore",
        "isagarh",
        "itarsi",
        "jabalpur",
        "jabera",
        "jagdalpur",
        "jaisinghnagar",
        "jaithari",
        "jaitpur",
        "jaitwara",
        "jamai",
        "jaora",
        "jatara",
        "jawad",
        "jhabua",
        "jobat",
        "jora",
        "kakaiya",
        "kannod",
        "kannodi",
        "karanjia",
        "kareli",
        "karera",
        "karhal",
        "karpa",
        "kasrawad",
        "katangi",
        "katni",
        "keolari",
        "khachrod",
        "khajuraho",
        "khakner",
        "khalwa",
        "khandwa",
        "khaniadhana",
        "khargone",
        "khategaon",
        "khetia",
        "khilchipur",
        "khirkiya",
        "khurai",
        "kolaras",
        "kotma",
        "kukshi",
        "kundam",
        "kurwai",
        "kusmi",
        "laher",
        "lakhnadon",
        "lamta",
        "lanji",
        "lateri",
        "laundi",
        "maheshwar",
        "mahidpurcity",
        "maihar",
        "majhagwan",
        "majholi",
        "malhargarh",
        "manasa",
        "manawar",
        "mandla",
        "mandsaur",
        "manpur",
        "mauganj",
        "mawai",
        "mehgaon",
        "mhow",
        "morena",
        "multai",
        "mungaoli",
        "nagod",
        "nainpur",
        "narsingarh",
        "narsinghpur",
        "narwar",
        "nasrullaganj",
        "nateran",
        "neemuch",
        "niwari",
        "niwas",
        "nowgaon",
        "pachmarhi",
        "pandhana",
        "pandhurna",
        "panna",
        "parasia",
        "patan",
        "patera",
        "patharia",
        "pawai",
        "petlawad",
        "pichhore",
        "piparia",
        "pohari",
        "prabhapattan",
        "punasa",
        "pushprajgarh",
        "raghogarh",
        "raghunathpur",
        "rahatgarh",
        "raisen",
        "rajgarh",
        "rajpur",
        "ratlam",
        "rehli",
        "rewa",
        "sabalgarh",
        "sagar",
        "sailana",
        "sanwer",
        "sarangpur",
        "sardarpur",
        "satna",
        "saunsar",
        "sehore",
        "sendhwa",
        "seondha",
        "seoni",
        "seonimalwa",
        "shahdol",
        "shahnagar",
        "shahpur",
        "shajapur",
        "sheopur",
        "sheopurkalan",
        "shivpuri",
        "shujalpur",
        "sidhi",
        "sihora",
        "silwani",
        "singrauli",
        "sirmour",
        "sironj",
        "sitamau",
        "sohagpur",
        "sondhwa",
        "sonkatch",
        "susner",
        "tamia",
        "tarana",
        "tendukheda",
        "teonthar",
        "thandla",
        "tikamgarh",
        "timarani",
        "udaipura",
        "ujjain",
        "umaria",
        "umariapan",
        "vidisha",
        "vijayraghogarh",
        "waraseoni",
        "zhirnia",
      ].map(capitalize),
    },
    Maharashtra: {
      districts: [
        "ahmednagar",
        "akola",
        "amravati",
        "beed",
        "bhandara",
        "buldhana",
        "chandrapur",
        "osmanabad",
        "dhule",
        "gadchiroli",
        "gondia",
        "hingoli",
        "jalgaon",
        "jalna",
        "kolhapur",
        "latur",
        "mumbai City",
        "mumbai Suburban",
        "nanded",
        "nandurbar",
        "nagpur",
        "nashik",
        "palghar",
        "parbhani",
        "pune",
        "raigad",
        "ratnagiri",
        "aurangabad",
        "sangli",
        "satara",
        "sindhudurg",
        "solapur",
        "thane",
        "wardha",
        "washim",
        "yavatmal",
      ].map(capitalize),
      cities: [
        "achalpur",
        "aheri",
        "ahmednagar",
        "ahmedpur",
        "ajara",
        "akkalkot",
        "akola",
        "akole",
        "akot",
        "alibagh",
        "amagaon",
        "amalner",
        "ambad",
        "ambejogai",
        "amravati",
        "arjuni Merogaon",
        "arvi",
        "ashti",
        "atpadi",
        "aurangabad",
        "ausa",
        "babhulgaon",
        "balapur",
        "baramati",
        "barshi Takli",
        "barsi",
        "basmatnagar",
        "bassein",
        "beed",
        "bhadrawati",
        "bhamregadh",
        "bhandara",
        "bhir",
        "bhiwandi",
        "bhiwapur",
        "bhokar",
        "bhokardan",
        "bhoom",
        "bhor",
        "bhudargad",
        "bhusawal",
        "billoli",
        "brahmapuri",
        "buldhana",
        "butibori",
        "chalisgaon",
        "chamorshi",
        "chandgad",
        "chandrapur",
        "chandur",
        "chanwad",
        "chhikaldara",
        "chikhali",
        "chinchwad",
        "chiplun",
        "chopda",
        "chumur",
        "dahanu",
        "dapoli",
        "darwaha",
        "daryapur",
        "daund",
        "degloor",
        "delhi Tanda",
        "deogad",
        "deolgaonraja",
        "deori",
        "desaiganj",
        "dhadgaon",
        "dhanora",
        "dharani",
        "dhiwadi",
        "dhule",
        "dhulia",
        "digras",
        "dindori",
        "edalabad",
        "erandul",
        "etapalli",
        "gadhchiroli",
        "gadhinglaj",
        "gaganbavada",
        "gangakhed",
        "gangapur",
        "gevrai",
        "ghatanji",
        "golegaon",
        "gondia",
        "gondpipri",
        "goregaon",
        "guhagar",
        "hadgaon",
        "hatkangale",
        "hinganghat",
        "hingoli",
        "hingua",
        "igatpuri",
        "indapur",
        "islampur",
        "jalgaon",
        "jalna",
        "jamkhed",
        "jamner",
        "jath",
        "jawahar",
        "jintdor",
        "junnar",
        "kagal",
        "kaij",
        "kalamb",
        "kalamnuri",
        "kallam",
        "kalmeshwar",
        "kalwan",
        "kalyan",
        "kamptee",
        "kandhar",
        "kankavali",
        "kannad",
        "karad",
        "karjat",
        "karmala",
        "katol",
        "kavathemankal",
        "kedgaon",
        "khadakwasala",
        "khamgaon",
        "khed",
        "khopoli",
        "khultabad",
        "kinwat",
        "kolhapur",
        "kopargaon",
        "koregaon",
        "kudal",
        "kuhi",
        "kurkheda",
        "kusumba",
        "lakhandur",
        "langa",
        "latur",
        "lonar",
        "lonavala",
        "madangad",
        "madha",
        "mahabaleshwar",
        "mahad",
        "mahagaon",
        "mahasala",
        "mahaswad",
        "malegaon",
        "malgaon",
        "malgund",
        "malkapur",
        "malsuras",
        "malwan",
        "mancher",
        "mangalwedha",
        "mangaon",
        "mangrulpur",
        "manjalegaon",
        "manmad",
        "maregaon",
        "mehda",
        "mekhar",
        "mohadi",
        "mohol",
        "mokhada",
        "morshi",
        "mouda",
        "mukhed",
        "mul",
        "mumbai",
        "murbad",
        "murtizapur",
        "murud",
        "nagbhir",
        "nagpur",
        "nahavara",
        "nanded",
        "nandgaon",
        "nandnva",
        "nandurbar",
        "narkhed",
        "nashik",
        "navapur",
        "ner",
        "newasa",
        "nilanga",
        "niphad",
        "omerga",
        "osmanabad",
        "pachora",
        "paithan",
        "palghar",
        "pali",
        "pandharkawada",
        "pandharpur",
        "panhala",
        "paranda",
        "parbhani",
        "parner",
        "parola",
        "parseoni",
        "partur",
        "patan",
        "pathardi",
        "pathari",
        "patoda",
        "pauni",
        "peint",
        "pen",
        "phaltan",
        "pimpalner",
        "pirangut",
        "poladpur",
        "pune",
        "pusad",
        "pusegaon",
        "radhanagar",
        "rahuri",
        "raigad",
        "rajapur",
        "rajgurunagar",
        "rajura",
        "ralegaon",
        "ramtek",
        "ratnagiri",
        "raver",
        "risod",
        "roha",
        "sakarwadi",
        "sakoli",
        "sakri",
        "salekasa",
        "samudrapur",
        "sangamner",
        "sanganeshwar",
        "sangli",
        "sangola",
        "sanguem",
        "saoner",
        "saswad",
        "satana",
        "satara",
        "sawantwadi",
        "seloo",
        "shahada",
        "shahapur",
        "shahuwadi",
        "shevgaon",
        "shirala",
        "shirol",
        "shirpur",
        "shirur",
        "shirwal",
        "sholapur",
        "shri Rampur",
        "shrigonda",
        "shrivardhan",
        "sillod",
        "sinderwahi",
        "sindhudurg",
        "sindkheda",
        "sindkhedaraja",
        "sinnar",
        "sironcha",
        "soyegaon",
        "surgena",
        "talasari",
        "talegaon S.Ji Pant",
        "taloda",
        "tasgaon",
        "thane",
        "tirora",
        "tiwasa",
        "trimbak",
        "tuljapur",
        "tumsar",
        "udgir",
        "umarkhed",
        "umrane",
        "umrer",
        "urlikanchan",
        "vaduj",
        "velhe",
        "vengurla",
        "vijapur",
        "vita",
        "wada",
        "wai",
        "walchandnagar",
        "wani",
        "wardha",
        "warlydwarud",
        "warora",
        "washim",
        "wathar",
        "yavatmal",
        "yawal",
        "yeola",
        "yeotmal",
      ].map(capitalize),
    },
    Manipur: {
      districts: [
        "bishnupur",
        "chandel",
        "churachandpur",
        "imphal East",
        "imphal West",
        "jiribam",
        "kakching",
        "kamjong",
        "kangpokpi",
        "noney",
        "pherzawl",
        "senapati",
        "tamenglong",
        "tengnoupal",
        "thoubal",
        "ukhrul",
      ].map(capitalize),
      cities: [
        "bishnupur",
        "chakpikarong",
        "chandel",
        "chattrik",
        "churachandpur",
        "imphal",
        "jiribam",
        "kakching",
        "kalapahar",
        "mao",
        "mulam",
        "parbung",
        "sadarhills",
        "saibom",
        "sempang",
        "senapati",
        "sochumer",
        "taloulong",
        "tamenglong",
        "thinghat",
        "thoubal",
        "ukhrul",
      ].map(capitalize),
    },
    Meghalaya: {
      districts: [
        "east Garo Hills",
        "east Khasi Hills",
        "east Jaintia Hills",
        "eastern West Khasi Hills",
        "north Garo Hills",
        "ri Bhoi",
        "south Garo Hills",
        "south West Garo Hills",
        "south West Khasi Hills",
        "west Garo Hills",
        "west Jaintia Hills",
        "west Khasi Hills",
      ].map(capitalize),
      cities: [
        "amlaren",
        "baghmara",
        "cherrapunjee",
        "dadengiri",
        "garo Hills",
        "jaintia Hills",
        "jowai",
        "khasi Hills",
        "khliehriat",
        "mariang",
        "mawkyrwat",
        "nongpoh",
        "nongstoin",
        "resubelpara",
        "ri Bhoi",
        "shillong",
        "tura",
        "williamnagar",
      ].map(capitalize),
    },
    Mizoram: {
      districts: [
        "aizawl",
        "champhai",
        "hnahthial",
        "khawzawl",
        "kolasib",
        "lawngtlai",
        "lunglei",
        "mamit",
        "saiha",
        "saitual",
        "serchhip",
      ].map(capitalize),
      cities: [
        "aizawl",
        "champhai",
        "demagiri",
        "kolasib",
        "lawngtlai",
        "lunglei",
        "mamit",
        "saiha",
        "serchhip",
      ].map(capitalize),
    },
    Nagaland: {
      districts: [
        "chümoukedima",
        "dimapur",
        "kiphire",
        "kohima",
        "longleng",
        "mokokchung",
        "mon",
        "niuland",
        "noklak",
        "peren",
        "phek",
        "shamator",
        "tseminyü",
        "tuensang",
        "wokha",
        "zunheboto",
      ].map(capitalize),
      cities: [
        "dimapur",
        "jalukie",
        "kiphire",
        "kohima",
        "mokokchung",
        "mon",
        "phek",
        "tuensang",
        "wokha",
        "zunheboto",
      ].map(capitalize),
    },
    Odisha: {
      districts: [
        "angul",
        "boudh",
        "bhadrak",
        "balangir",
        "bargarh",
        "balasore",
        "cuttack",
        "debagarh",
        "dhenkanal",
        "ganjam",
        "gajapati",
        "jharsuguda",
        "jajpur",
        "jagatsinghpur",
        "khordha",
        "kendujhar",
        "kalahandi",
        "kandhamal",
        "koraput",
        "kendrapara",
        "malkangiri",
        "mayurbhanj",
        "nabarangpur",
        "nuapada",
        "nayagarh",
        "puri",
        "rayagada",
        "sambalpur",
        "subarnapur",
        "sundargarh",
      ].map(capitalize),
      cities: [
        "anandapur",
        "angul",
        "anugul",
        "aska",
        "athgarh",
        "athmallik",
        "attabira",
        "bagdihi",
        "balangir",
        "balasore",
        "baleswar",
        "baliguda",
        "balugaon",
        "banaigarh",
        "bangiriposi",
        "barbil",
        "bargarh",
        "baripada",
        "barkot",
        "basta",
        "berhampur",
        "betanati",
        "bhadrak",
        "bhanjanagar",
        "bhawanipatna",
        "bhubaneswar",
        "birmaharajpur",
        "bisam Cuttack",
        "boriguma",
        "boudh",
        "buguda",
        "chandbali",
        "chhatrapur",
        "chhendipada",
        "cuttack",
        "daringbadi",
        "daspalla",
        "deodgarh",
        "deogarh",
        "dhanmandal",
        "dharamgarh",
        "dhenkanal",
        "digapahandi",
        "dunguripali",
        "g. Udayagiri",
        "gajapati",
        "ganjam",
        "ghatgaon",
        "gudari",
        "gunupur",
        "hemgiri",
        "hindol",
        "jagatsinghapur",
        "jajpur",
        "jamankira",
        "jashipur",
        "jayapatna",
        "jeypur",
        "jharigan",
        "jharsuguda",
        "jujumura",
        "kalahandi",
        "kalimela",
        "kamakhyanagar",
        "kandhamal",
        "kantabhanji",
        "kantamal",
        "karanjia",
        "kashipur",
        "kendrapara",
        "kendujhar",
        "keonjhar",
        "khalikote",
        "khordha",
        "khurda",
        "komana",
        "koraput",
        "kotagarh",
        "kuchinda",
        "lahunipara",
        "laxmipur",
        "m. Rampur",
        "malkangiri",
        "mathili",
        "mayurbhanj",
        "mohana",
        "motu",
        "nabarangapur",
        "naktideul",
        "nandapur",
        "narlaroad",
        "narsinghpur",
        "nayagarh",
        "nimapara",
        "nowparatan",
        "nowrangapur",
        "nuapada",
        "padampur",
        "paikamal",
        "palla Hara",
        "papadhandi",
        "parajang",
        "pardip",
        "parlakhemundi",
        "patnagarh",
        "pattamundai",
        "phiringia",
        "phulbani",
        "puri",
        "puruna Katak",
        "r. Udayigiri",
        "rairakhol",
        "rairangpur",
        "rajgangpur",
        "rajkhariar",
        "rayagada",
        "rourkela",
        "sambalpur",
        "sohela",
        "sonapur",
        "soro",
        "subarnapur",
        "sunabeda",
        "sundergarh",
        "surada",
        "t. Rampur",
        "talcher",
        "telkoi",
        "titlagarh",
        "tumudibandha",
        "udala",
        "umerkote",
      ].map(capitalize),
    },
    Puducherry: {
      districts: ["karaikal", "mahé", "puducherry", "yanam"].map(capitalize),
      cities: [
        "bahur",
        "karaikal",
        "mahe",
        "pondicherry",
        "purnankuppam",
        "valudavur",
        "villianur",
        "yanam",
      ].map(capitalize),
    },
    Punjab: {
      districts: [
        "amritsar",
        "barnala",
        "bathinda",
        "firozpur",
        "faridkot",
        "fatehgarh Sahib",
        "fazilka",
        "gurdaspur",
        "hoshiarpur",
        "jalandhar",
        "kapurthala",
        "ludhiana",
        "malerkotla",
        "mansa",
        "moga",
        "sri Muktsar Sahib",
        "pathankot",
        "patiala",
        "rupnagar",
        "sahibzada Ajit Singh Nagar",
        "sangrur",
        "shahid Bhagat Singh Nagar",
        "tarn Taran",
      ].map(capitalize),
      cities: [
        "abohar",
        "ajnala",
        "amritsar",
        "balachaur",
        "barnala",
        "batala",
        "bathinda",
        "chandigarh",
        "dasua",
        "dinanagar",
        "faridkot",
        "fatehgarh Sahib",
        "fazilka",
        "ferozepur",
        "garhashanker",
        "goindwal",
        "gurdaspur",
        "guruharsahai",
        "hoshiarpur",
        "jagraon",
        "jalandhar",
        "jugial",
        "kapurthala",
        "kharar",
        "kotkapura",
        "ludhiana",
        "malaut",
        "malerkotla",
        "mansa",
        "moga",
        "muktasar",
        "nabha",
        "nakodar",
        "nangal",
        "nawanshahar",
        "nawanshahr",
        "pathankot",
        "patiala",
        "patti",
        "phagwara",
        "phillaur",
        "phulmandi",
        "quadian",
        "rajpura",
        "raman",
        "rayya",
        "ropar",
        "rupnagar",
        "samana",
        "samrala",
        "sangrur",
        "sardulgarh",
        "sarhind",
        "sAS Nagar",
        "sultanpur Lodhi",
        "sunam",
        "tanda Urmar",
        "tarn Taran",
        "zira",
      ].map(capitalize),
    },
    Rajasthan: {
      districts: [
        "ajmer",
        "alwar",
        "bikaner",
        "barmer",
        "banswara",
        "bharatpur",
        "baran",
        "bundi",
        "bhilwara",
        "churu",
        "chittorgarh",
        "dausa",
        "dholpur",
        "dungarpur",
        "sri Ganganagar",
        "hanumangarh",
        "jhunjhunu",
        "jalore",
        "jodhpur",
        "jaipur",
        "jaisalmer",
        "jhalawar",
        "karauli",
        "kota",
        "nagaur",
        "pali",
        "pratapgarh",
        "rajsamand",
        "sikar",
        "sawai Madhopur",
        "sirohi",
        "tonk",
        "udaipur",
      ].map(capitalize),
      cities: [
        "abu Road",
        "ahore",
        "ajmer",
        "aklera",
        "alwar",
        "amber",
        "amet",
        "anupgarh",
        "asind",
        "aspur",
        "atru",
        "bagidora",
        "bali",
        "bamanwas",
        "banera",
        "bansur",
        "banswara",
        "baran",
        "bari",
        "barisadri",
        "barmer",
        "baseri",
        "bassi",
        "baswa",
        "bayana",
        "beawar",
        "begun",
        "behror",
        "bhadra",
        "bharatpur",
        "bhilwara",
        "bhim",
        "bhinmal",
        "bikaner",
        "bilara",
        "bundi",
        "chhabra",
        "chhipaborad",
        "chirawa",
        "chittorgarh",
        "chohtan",
        "churu",
        "dantaramgarh",
        "dausa",
        "deedwana",
        "deeg",
        "degana",
        "deogarh",
        "deoli",
        "desuri",
        "dhariawad",
        "dholpur",
        "digod",
        "dudu",
        "dungarpur",
        "dungla",
        "fatehpur",
        "gangapur",
        "gangdhar",
        "gerhi",
        "ghatol",
        "girwa",
        "gogunda",
        "hanumangarh",
        "hindaun",
        "hindoli",
        "hurda",
        "jahazpur",
        "jaipur",
        "jaisalmer",
        "jalore",
        "jhalawar",
        "jhunjhunu",
        "jodhpur",
        "kaman",
        "kapasan",
        "karauli",
        "kekri",
        "keshoraipatan",
        "khandar",
        "kherwara",
        "khetri",
        "kishanganj",
        "kishangarh",
        "kishangarhbas",
        "kolayat",
        "kota",
        "kotputli",
        "kotra",
        "kotri",
        "kumbalgarh",
        "kushalgarh",
        "ladnun",
        "ladpura",
        "lalsot",
        "laxmangarh",
        "lunkaransar",
        "mahuwa",
        "malpura",
        "malvi",
        "mandal",
        "mandalgarh",
        "mandawar",
        "mangrol",
        "marwar-Jn",
        "merta",
        "nadbai",
        "nagaur",
        "nainwa",
        "nasirabad",
        "nathdwara",
        "nawa",
        "neem Ka Thana",
        "newai",
        "nimbahera",
        "nohar",
        "nokha",
        "onli",
        "osian",
        "pachpadara",
        "pachpahar",
        "padampur",
        "pali",
        "parbatsar",
        "phagi",
        "phalodi",
        "pilani",
        "pindwara",
        "pipalda",
        "pirawa",
        "pokaran",
        "pratapgarh",
        "raipur",
        "raisinghnagar",
        "rajgarh",
        "rajsamand",
        "ramganj Mandi",
        "ramgarh",
        "rashmi",
        "ratangarh",
        "reodar",
        "rupbas",
        "sadulshahar",
        "sagwara",
        "sahabad",
        "salumber",
        "sanchore",
        "sangaria",
        "sangod",
        "sapotra",
        "sarada",
        "sardarshahar",
        "sarwar",
        "sawai Madhopur",
        "shahapura",
        "sheo",
        "sheoganj",
        "shergarh",
        "sikar",
        "sirohi",
        "siwana",
        "sojat",
        "sri Dungargarh",
        "sri Ganganagar",
        "sri Karanpur",
        "sri Madhopur",
        "sujangarh",
        "taranagar",
        "thanaghazi",
        "tibbi",
        "tijara",
        "todaraisingh",
        "tonk",
        "udaipur",
        "udaipurwati",
        "uniayara",
        "vallabhnagar",
        "viratnagar",
      ].map(capitalize),
    },
    Sikkim: {
      districts: [
        "east Sikkim",
        "north Sikkim",
        "pakyong",
        "soreng",
        "south Sikkim",
        "west Sikkim",
      ].map(capitalize),
      cities: [
        "barmiak",
        "be",
        "bhurtuk",
        "chhubakha",
        "chidam",
        "chubha",
        "chumikteng",
        "dentam",
        "dikchu",
        "dzongri",
        "gangtok",
        "gauzing",
        "gyalshing",
        "hema",
        "kerung",
        "lachen",
        "lachung",
        "lema",
        "lingtam",
        "lungthu",
        "mangan",
        "namchi",
        "namthang",
        "nanga",
        "nantang",
        "naya Bazar",
        "padamachen",
        "pakhyong",
        "pemayangtse",
        "phensang",
        "rangli",
        "rinchingpong",
        "sakyong",
        "samdong",
        "singtam",
        "siniolchu",
        "sombari",
        "soreng",
        "sosing",
        "tekhug",
        "temi",
        "tsetang",
        "tsomgo",
        "tumlong",
        "yangang",
        "yumtang",
      ].map(capitalize),
    },
    "Tamil Nadu": {
      districts: [
        "ariyalur",
        "chengalpattu",
        "chennai",
        "coimbatore",
        "cuddalore",
        "dharmapuri",
        "dindigul",
        "erode",
        "kallakurichi",
        "kanchipuram",
        "kanyakumari",
        "karur",
        "krishnagiri",
        "madurai",
        "mayiladuthurai",
        "nagapattinam",
        "nilgiris",
        "namakkal",
        "perambalur",
        "pudukkottai",
        "ramanathapuram",
        "ranipet",
        "salem",
        "sivaganga",
        "tenkasi",
        "tiruppur",
        "tiruchirappalli",
        "theni",
        "tirunelveli",
        "thanjavur",
        "thoothukudi",
        "tirupattur",
        "tiruvallur",
        "tiruvarur",
        "tiruvannamalai",
        "vellore",
        "viluppuram",
        "virudhunagar",
      ].map(capitalize),
      cities: [
        "ambasamudram",
        "anamali",
        "arakandanallur",
        "arantangi",
        "aravakurichi",
        "ariyalur",
        "arkonam",
        "arni",
        "aruppukottai",
        "attur",
        "avanashi",
        "batlagundu",
        "bhavani",
        "chengalpattu",
        "chengam",
        "chennai",
        "chidambaram",
        "chingleput",
        "coimbatore",
        "courtallam",
        "cuddalore",
        "cumbum",
        "denkanikoitah",
        "devakottai",
        "dharampuram",
        "dharmapuri",
        "dindigul",
        "erode",
        "gingee",
        "gobichettipalayam",
        "gudalur",
        "gudiyatham",
        "harur",
        "hosur",
        "jayamkondan",
        "kallkurichi",
        "kanchipuram",
        "kangayam",
        "kanyakumari",
        "karaikal",
        "karaikudi",
        "karur",
        "keeranur",
        "kodaikanal",
        "kodumudi",
        "kotagiri",
        "kovilpatti",
        "krishnagiri",
        "kulithalai",
        "kumbakonam",
        "kuzhithurai",
        "madurai",
        "madurantgam",
        "manamadurai",
        "manaparai",
        "mannargudi",
        "mayiladuthurai",
        "mayiladutjurai",
        "mettupalayam",
        "metturdam",
        "mudukulathur",
        "mulanur",
        "musiri",
        "nagapattinam",
        "nagarcoil",
        "namakkal",
        "nanguneri",
        "natham",
        "neyveli",
        "nilgiris",
        "oddanchatram",
        "omalpur",
        "ootacamund",
        "ooty",
        "orathanad",
        "palacode",
        "palani",
        "palladum",
        "papanasam",
        "paramakudi",
        "pattukottai",
        "perambalur",
        "perundurai",
        "pollachi",
        "polur",
        "pondicherry",
        "ponnamaravathi",
        "ponneri",
        "pudukkottai",
        "rajapalayam",
        "ramanathapuram",
        "rameshwaram",
        "ranipet",
        "rasipuram",
        "salem",
        "sankagiri",
        "sankaran",
        "sathiyamangalam",
        "sivaganga",
        "sivakasi",
        "sriperumpudur",
        "srivaikundam",
        "tenkasi",
        "thanjavur",
        "theni",
        "thirumanglam",
        "thiruraipoondi",
        "thoothukudi",
        "thuraiyure",
        "tindivanam",
        "tiruchendur",
        "tiruchengode",
        "tiruchirappalli",
        "tirunelvelli",
        "tirupathur",
        "tirupur",
        "tiruttani",
        "tiruvallur",
        "tiruvannamalai",
        "tiruvarur",
        "tiruvellore",
        "tiruvettipuram",
        "trichy",
        "tuticorin",
        "udumalpet",
        "ulundurpet",
        "usiliampatti",
        "uthangarai",
        "valapady",
        "valliyoor",
        "vaniyambadi",
        "vedasandur",
        "vellore",
        "velur",
        "vilathikulam",
        "villupuram",
        "virudhachalam",
        "virudhunagar",
        "wandiwash",
        "yercaud",
      ].map(capitalize),
    },
    Telangana: {
      districts: [
        "adilabad",
        "bhadradri Kothagudem",
        "hanamkonda",
        "hyderabad",
        "jagtial",
        "jangaon",
        "jayashankar Bhupalpally",
        "jogulamba Gadwal",
        "kamareddy",
        "karimnagar",
        "khammam",
        "kumuram Bheem Asifabad",
        "mahabubabad",
        "mahbubnagar",
        "mancherial",
        "medak",
        "medchal–Malkajgiri",
        "mulugu",
        "nalgonda",
        "narayanpet",
        "nagarkurnool",
        "nirmal",
        "nizamabad",
        "peddapalli",
        "rajanna Sircilla",
        "ranga Reddy",
        "sangareddy",
        "siddipet",
        "suryapet",
        "vikarabad",
        "wanaparthy",
        "warangal",
        "yadadri Bhuvanagiri",
      ].map(capitalize),
      cities: [
        "hyderabad",
        "warangal",
        "nizamabad",
        "khammam",
        "karimnagar",
        "ramagundam",
        "mahabubnagar",
        "nalgonda",
        "adilabad",
        "suryapet",
        "miryalaguda",
        "siddipet",
        "jagtial",
        "nirmal",
      ].map(capitalize),
    },
    Tripura: {
      districts: [
        "dhalai",
        "gomati",
        "khowai",
        "north Tripura",
        "sepahijala",
        "south Tripura",
        "unakoti",
        "west Tripura",
      ].map(capitalize),
      cities: [
        "agartala",
        "ambasa",
        "bampurbari",
        "belonia",
        "dhalai",
        "dharam Nagar",
        "kailashahar",
        "kamal Krishnabari",
        "khopaiyapara",
        "khowai",
        "phuldungsei",
        "radha Kishore Pur",
        "tripura",
      ].map(capitalize),
    },
    "Uttar Pradesh": {
      districts: [
        "agra",
        "aligarh",
        "ambedkar Nagar",
        "amethi",
        "amroha",
        "auraiya",
        "ayodhya",
        "azamgarh",
        "bagpat",
        "bahraich",
        "ballia",
        "balrampur",
        "banda",
        "barabanki",
        "bareilly",
        "basti",
        "bhadohi",
        "bijnor",
        "budaun",
        "bulandshahr",
        "chandauli",
        "chitrakoot",
        "deoria",
        "etah",
        "etawah",
        "farrukhabad",
        "fatehpur",
        "firozabad",
        "gautam Buddha Nagar",
        "ghaziabad",
        "ghazipur",
        "gonda",
        "gorakhpur",
        "hamirpur",
        "hapur",
        "hardoi",
        "hathras",
        "jalaun",
        "jaunpur",
        "jhansi",
        "kannauj",
        "kanpur Dehat",
        "kanpur Nagar",
        "kasganj",
        "kaushambi",
        "kushinagar",
        "lakhimpur Kheri",
        "lalitpur",
        "lucknow",
        "maharajganj",
        "mahoba",
        "mainpuri",
        "mathura",
        "mau",
        "meerut",
        "mirzapur",
        "moradabad",
        "muzaffarnagar",
        "pilibhit",
        "pratapgarh",
        "prayagraj",
        "raebareli",
        "rampur",
        "saharanpur",
        "sambhal",
        "sant Kabir Nagar",
        "shahjahanpur",
        "shamli",
        "shravasti",
        "siddharthnagar",
        "sitapur",
        "sonbhadra",
        "sultanpur",
        "unnao",
        "varanasi",
      ].map(capitalize),
      cities: [
        "achhnera",
        "agra",
        "akbarpur",
        "aliganj",
        "aligarh",
        "allahabad",
        "ambedkar Nagar",
        "amethi",
        "amiliya",
        "amroha",
        "anola",
        "atrauli",
        "auraiya",
        "azamgarh",
        "baberu",
        "badaun",
        "baghpat",
        "bagpat",
        "baheri",
        "bahraich",
        "ballia",
        "balrampur",
        "banda",
        "bansdeeh",
        "bansgaon",
        "bansi",
        "barabanki",
        "bareilly",
        "basti",
        "bhadohi",
        "bharthana",
        "bharwari",
        "bhogaon",
        "bhognipur",
        "bidhuna",
        "bijnore",
        "bikapur",
        "bilari",
        "bilgram",
        "bilhaur",
        "bindki",
        "bisalpur",
        "bisauli",
        "biswan",
        "budaun",
        "budhana",
        "bulandshahar",
        "bulandshahr",
        "capianganj",
        "chakia",
        "chandauli",
        "charkhari",
        "chhata",
        "chhibramau",
        "chirgaon",
        "chitrakoot",
        "chunur",
        "dadri",
        "dalmau",
        "dataganj",
        "debai",
        "deoband",
        "deoria",
        "derapur",
        "dhampur",
        "domariyaganj",
        "dudhi",
        "etah",
        "etawah",
        "faizabad",
        "farrukhabad",
        "fatehpur",
        "firozabad",
        "garauth",
        "garhmukteshwar",
        "gautam Buddha Nagar",
        "ghatampur",
        "ghaziabad",
        "ghazipur",
        "ghosi",
        "gonda",
        "gorakhpur",
        "gunnaur",
        "haidergarh",
        "hamirpur",
        "hapur",
        "hardoi",
        "harraiya",
        "hasanganj",
        "hasanpur",
        "hathras",
        "jalalabad",
        "jalaun",
        "jalesar",
        "jansath",
        "jarar",
        "jasrana",
        "jaunpur",
        "jhansi",
        "jyotiba Phule Nagar",
        "kadipur",
        "kaimganj",
        "kairana",
        "kaisarganj",
        "kalpi",
        "kannauj",
        "kanpur",
        "karchhana",
        "karhal",
        "karvi",
        "kasganj",
        "kaushambi",
        "kerakat",
        "khaga",
        "khair",
        "khalilabad",
        "kheri",
        "konch",
        "kumaon",
        "kunda",
        "kushinagar",
        "lalganj",
        "lalitpur",
        "lucknow",
        "machlishahar",
        "maharajganj",
        "mahoba",
        "mainpuri",
        "malihabad",
        "mariyahu",
        "math",
        "mathura",
        "mau",
        "maudaha",
        "maunathbhanjan",
        "mauranipur",
        "mawana",
        "meerut",
        "mehraun",
        "meja",
        "mirzapur",
        "misrikh",
        "modinagar",
        "mohamdabad",
        "mohamdi",
        "moradabad",
        "musafirkhana",
        "muzaffarnagar",
        "nagina",
        "najibabad",
        "nakur",
        "nanpara",
        "naraini",
        "naugarh",
        "nawabganj",
        "nighasan",
        "noida",
        "orai",
        "padrauna",
        "pahasu",
        "patti",
        "pharenda",
        "phoolpur",
        "phulpur",
        "pilibhit",
        "pitamberpur",
        "powayan",
        "pratapgarh",
        "puranpur",
        "purwa",
        "raibareli",
        "rampur",
        "ramsanehi Ghat",
        "rasara",
        "rath",
        "robertsganj",
        "sadabad",
        "safipur",
        "sagri",
        "saharanpur",
        "sahaswan",
        "sahjahanpur",
        "saidpur",
        "salempur",
        "salon",
        "sambhal",
        "sandila",
        "sant Kabir Nagar",
        "sant Ravidas Nagar",
        "sardhana",
        "shahabad",
        "shahganj",
        "shahjahanpur",
        "shikohabad",
        "shravasti",
        "siddharthnagar",
        "sidhauli",
        "sikandra Rao",
        "sikandrabad",
        "sitapur",
        "siyana",
        "sonbhadra",
        "soraon",
        "sultanpur",
        "tanda",
        "tarabganj",
        "tilhar",
        "unnao",
        "utraula",
        "varanasi",
        "zamania",
      ].map(capitalize),
    },
    Uttarakhand: {
      districts: [
        "almora",
        "bageshwar",
        "chamoli",
        "champawat",
        "dehradun",
        "haridwar",
        "nainital",
        "pauri Garhwal",
        "pithoragarh",
        "rudraprayag",
        "tehri Garhwal",
        "udham Singh Nagar",
        "uttarkashi",
      ].map(capitalize),
      cities: [
        "almora",
        "bageshwar",
        "bhatwari",
        "chakrata",
        "chamoli",
        "champawat",
        "dehradun",
        "deoprayag",
        "dharchula",
        "dunda",
        "haldwani",
        "haridwar",
        "joshimath",
        "karan Prayag",
        "kashipur",
        "khatima",
        "kichha",
        "lansdown",
        "munsiari",
        "mussoorie",
        "nainital",
        "pantnagar",
        "partapnagar",
        "pauri Garhwal",
        "pithoragarh",
        "purola",
        "rajgarh",
        "ranikhet",
        "roorkee",
        "rudraprayag",
        "tehri Garhwal",
        "udham Singh Nagar",
        "ukhimath",
        "uttarkashi",
      ].map(capitalize),
    },
    "West Bengal": {
      districts: [
        "alipurduar",
        "bankura",
        "birbhum",
        "cooch Behar",
        "dakshin Dinajpur",
        "darjeeling",
        "hooghly",
        "howrah",
        "jalpaiguri",
        "jhargram",
        "kalimpong",
        "kolkata",
        "maldah",
        "murshidabad",
        "nadia",
        "north 24 Parganas",
        "paschim Bardhaman",
        "paschim Medinipur",
        "purba Bardhaman",
        "purba Medinipur",
        "purulia",
        "south 24 Parganas",
        "uttar Dinajpur",
      ].map(capitalize),
      cities: [
        "adra",
        "alipurduar",
        "amlagora",
        "arambagh",
        "asansol",
        "balurghat",
        "bankura",
        "bardhaman",
        "basirhat",
        "berhampur",
        "bethuadahari",
        "birbhum",
        "birpara",
        "bishanpur",
        "bolpur",
        "bongoan",
        "bulbulchandi",
        "burdwan",
        "calcutta",
        "canning",
        "champadanga",
        "contai",
        "cooch Behar",
        "daimond Harbour",
        "dalkhola",
        "dantan",
        "darjeeling",
        "dhaniakhali",
        "dhuliyan",
        "dinajpur",
        "dinhata",
        "durgapur",
        "gangajalghati",
        "gangarampur",
        "ghatal",
        "guskara",
        "habra",
        "haldia",
        "harirampur",
        "harishchandrapur",
        "hooghly",
        "howrah",
        "islampur",
        "jagatballavpur",
        "jalpaiguri",
        "jhalda",
        "jhargram",
        "kakdwip",
        "kalchini",
        "kalimpong",
        "kalna",
        "kandi",
        "karimpur",
        "katwa",
        "kharagpur",
        "khatra",
        "krishnanagar",
        "mal Bazar",
        "malda",
        "manbazar",
        "mathabhanga",
        "medinipur",
        "mekhliganj",
        "mirzapur",
        "murshidabad",
        "nadia",
        "nagarakata",
        "nalhati",
        "nayagarh",
        "parganas",
        "purulia",
        "raiganj",
        "rampur Hat",
        "ranaghat",
        "seharabazar",
        "siliguri",
        "suri",
        "takipur",
        "tamluk",
      ].map(capitalize),
    },
  };
  let zodiacStarList = [
    "ashwini",
    "barani",
    "karthikai",
    "rohini",
    "mirugasiridam",
    "thiruvadhirai",
    "punarpoosam",
    "poosam",
    "ayilyam",
    "magam",
    "pooram",
    "uthiram",
    "astham",
    "chithirai",
    "swathi",
    "visagam",
    "anusham",
    "kettai",
    "mulam",
    "puradam",
    "uthiradam",
    "thiruvonam",
    "avittam",
    "sadayam",

    "purattadhi",
    "uttrttadhi",
    "revathi",
  ];
  let dhosamList = [
    "no Dhosam",
    "chevvai Dhosam",
    "rahu Dhosam",
    "kethu Dhosam",
    "kalathra Dhosham",
    "kala Sarpa Dhosam",
    "naga Dosham",
    "don't know",
  ].map(capitalize);
  let gothramList = [
    "aatharvas",
    "agasthi",
    "ahabhunasa",
    "alampayana",
    "angiras",
    "arrishinimi",
    "athreyasa",
    "atri",
    "attarishi",
    "aukshanas",
    "aushanas",
    "babrahvya",
    "badarayana",
    "baijvayas",
    "bashan",
    "bharadwaj",
    "bhargava",
    "bhasyan",
    "bhrigu",
    "birthare",
    "bodhaaynas",
    "chandratri",
    "chikithasa",
    "chyavanasa",
    "daksa",
    "dalabhya",
    "darbhas",
    "devrata",
    "dhananjaya",
    "dhanvantri",
    "dhara Gautam",
    "dharanas",
    "dixit",
    "duttatreyas",
    "galiva",
    "ganganas",

    "gangyanas",
    "garga",
    "gargya Sainasa",
    "gouthama",
    "ghrit Kaushika",
    "gowri Veetham",
    "gardhmukh Sandilya",
    "harithasa",
    "jaiminiyas",
    "jamadagni",
    "jatukarna",
    "kaakavas",
    "kabi",
    "kalabouddasa",
    "kalpangeerasa",
    "kamakayana Vishwamitra",
    "kamsa",
    "kanav",
    "kanva",
    "kapi",
    "kapila Baradwaj",
    "kapinjal",
    "kapishthalas",
    "kaplish",
    "kashish",
    "kashyapa",
    "katyayan",
    "kaundinya",
    "kaunsa",
    "kaushal",
    "kausikasa",
    "keshoryas",
    "koushika Visvamitrasa",
    "krishnatrey",
    "kusa",
    "kutsasa",
    "laakshmanas",
    "laugakshi",
    "lavania",
    "lodwan",
    "lohit",
    "lokaakhyas",
    "lomasha",
    "madelia",
    "maitraya",
    "manava",
    "mandavya",
    "marica",
    "markendeya",
    "maudlas",
    "maunas",
    "mihir",
    "moudgalya",
    "mouna Bhargava",
    "munish",
    "mythravaruna",
    "nagasya",

    "naidrupa Kashyapa",
    "narayanas",
    "nithyandala",
    "paaniyas",
    "pachori",
    "paing",
    "parashara",
    "parthivasa",
    "paulastya",
    "poothamanasa",
    "pourugutsa",
    "prachinas",
    "raghuvanshi",
    "rajoria",
    "rathitar",
    "rohinya",
    "rohita",
    "sakalya",
    "sakhyanasa",
    "salankayanasa",
    "sankash",
    "sankha-Pingala-Kausta",
    "sankrut",
    "sankyanasa",
    "savanaka",
    "savarana",
    "shaalaksha",
    "shadamarshana",
    "shakhanas",
    "shalavatsa",
    "sandilyasa",
    "sharkaras",
    "sharkvas",
    "shaunak",
    "shravanesya",
    "shrimukh Shandilya",
    "shukla Atreyas",
    "sigidha",
    "sri Vatsa/Vatsa",
    "srungi Bharadwajasa",
    "suparnasa",
    "swathantra Kapisa",
    "tharakayanam",
    "titwal",
    "tushar",
    "udbahu",
    "udhalaka",
    "uditha Gautham",
    "udithya",
    "upamanyu",
    "upamanyu Vasishtasa",
    "upathya",
    "vadulasa",
    "vainya",
    "vardheyasa",
    "vashishtha",
    "veethahavya",
    "vishnordhageerasa",
    "vishnu Vridhha",
    "vishwamitra",
    "yaska",
    "others",
    "don't know my gothra (m)",
    "don't wish to specify",
  ];
  const signList = [
    "Mesham",
    "Rishabam",
    "Midhunam",
    "Kadagam",
    "Simmam",
    "Thiruvadhirai",
    "Kanni",
    "Thulam",
    "Viruchigam",
    "Dhanus",
    "Magaram",
    "Kumbam",
    "Meenam",
  ];
  const hobbiesList = {
    "Hobbies & Interests": [
      "Acting",
      "Adventure Sports",
      "Alternative Healing / Medicine",
      "Art / Handicraft",
      "Astrology",
      "Baking",
      "Bikes / Cars",
      "Bird Watching",
      "Blogging / Video Blogging",
      "Board Games",
      "Book Clubs",
      "Calligraphy",
      "Clubbing",
      "Collectibles",
      "Cooking",
      "Crosswords",
      "Dancing",
      "DIY (Do It Yourself) Projects",
      "Fashion Design",
      "Film Making",
      "Fishing",
      "Gardening / Landscape",
      "Going On Long Drives",
      "Home / Interior Decoration",
      "Learning New Languages",
      "Listening To Podcasts",
      "Mimicry",
      "Museums / Galleries / Exhibitions",
      "Numerology",
      "Painting / Sketching / Drawing",
      "Palmistry",
      "Pets",
      "Photography",
      "Playing Cards",
      "Playing Musical Instruments",
      "Politics",
      "Scrabble",
      "Shopping",
      "Sightseeing",
      "Singing",
      "Social Media",
      "Social Service",
      "Solving Puzzles",
      "Stargazing",
      "Stitching",
      "Tailoring",
      "Technology",
      "Travel",
      "Touring",
      "Video / Computer Gaming",
      "Volunteer Work",
      "Wine Tasting",
      "Writing",
    ],
    Music: [
      "Bhangra",
      "Bluegrass",
      "Blues",
      "Christian / Gospel",
      "Classical",
      "Classical - Carnatic",
      "Country",
      "Death Metal",
      "Devotional",
      "Disco",
      "Electric Dance Music",
      "Film Songs",
      "Folk Music",
      "Fusion",
      "Ghazals",
      "Heavy Metal",
      "Hip Hop",
      "House Music",
      "Indian",
      "Indic Rock",
      "Indipop",
      "Jazz",
      "K-Pop",
      "Latin Music",
      "Melodies",
      "Opera",
      "Orchestra",
      "Pop",
      "Progressive Metal",
      "Punk Rock",
      "Qawalis",
      "Rap",
      "Reggae",
      "Remixes",
      "Rhythm & Blues",
      "Rock",
      "Sufi",
      "Techno",
      "Trance Music",
      "Western",
    ],
    Reading: [
      "Autobiographies / Biographies",
      "Business / Professional",
      "Classics",
      "Comics / Graphic Novels",
      "Fantasy",
      "Fiction",
      "History",
      "Humor",
      "Literature",
      "Love Reading Almost Anything",
      "Magazines / Newspapers",
      "Poetry",
      "Philosophy / Spiritual",
      "Romance",
      "Science Fiction",
      "Self - Help",
      "Short Stories",
      "Thriller / Suspense",
      "Travelogs / Blogs",
    ],
    "Movies & TV Shows": [
      "Action",
      "Anime",
      "Classics",
      "Comedy",
      "Documentary",
      "Drama",
      "Epics",
      "Fantasy",
      "Horror",
      "Movie Fanatic",
      "Neo - Noir",
      "Non - Commercial / Art",
      "Romantic",
      "Romantic Comedies",
      "Sci - Fi",
      "Short Films",
      "Thriller / Suspense",
      "World Cinema",
    ],
    "Sports & Fitness": [
      "Aerobic",
      "Archery",
      "Badminton",
      "Baseball",
      "Basketball",
      "Billiards / Snooker / Pool",
      "Bowling",
      "Boxing",
      "Cricket",
      "Cycling",
      "Football",
      "Golf",
      "Gym / Bodybuilding",
      "Handball",
      "Hockey",
      "Horseback Riding",
      "Jogging / Walking / Running",
      "Kabbadi",
      "Kho Kho",
      "Martial Arts",
      "Motor Racing",
      "Pilates",
      "Polo",
      "Racquetball",
      "Rugby",
      "Sailing / Boating / Rowing",
      "Scuba Diving",
      "Shooting",
      "Skating / Snowboarding / Skiing",
      "Squash",
      "Swimming / Water Sports",
      "Table Tennis",
      "Tennis",
      "Throwball",
      "Volleyball",
      "Weight Lifting",
      "Winter / Rink Sports",
      "Wrestling / Kushti",
      "Yoga / Meditation",
      "Zumba",
    ],
    Food: [
      "American",
      "Arabic",
      "Bengali",
      "Brazilian",
      "Chinese",
      "Continental",
      "Ethiopian",
      "Fast food",
      "French",
      "Greek",
      "Gujarati",
      "Italian",
      "Japanese",
      "Konkan",
      "Korean",
      "Lebanese",
      "Malaysian",
      "Marathi",
      "Mediterranean",
      "Mexican",
      "Mughlai",
      "North Indian",
      "Odia",
      "Oriental",
      "Parsi",
      "Punjabi",
      "Rajasthani",
      "Soul Food",
      "South Indian",
      "Spanish",
      "Srilankan",
      "Thai",
      "Turkish",
      "Vietnamese",
    ],
    "Spoken Languages": [
      "Afrikaans",
      "Angika",
      "Arabic",
      "Assamese",
      "Awadhi",
      "Bahasa",
      "Bengali",
      "Bhojpuri",
      "Bihari",
      "Burmese",
      "Cantonese",
      "Chhattisgarhi",
      "Croatian",
      "Danish",
      "Dorgi",
      "Dutch",
      "English",
      "Finnish",
      "French",
      "Garhwali",
      "German",
      "Greek",
      "Gujarati",
      "Haryanvi",
      "Hebrew",
      "Himachali",
      "Hindi",
      "Italian",
      "Japanese",
      "Kannada",
      "Kashmiri",
      "Konkani",
      "Korean",
      "Kumaoni",
      "Kutchi",
      "Lambadi",
      "Maithili",
      "Malay",
      "Malayalam",
      "Malvani",
      "Mandarin",
      "Manipuri",
      "Marathi",
      "Marwadi",
      "Mizo",
      "Nepalese",
      "Norwegian",
      "Oriya",
      "Pahari",
      "Persian",
      "Polish",
      "Pushto",
      "Portuguese",
      "Punjabi",
      "Rajasthani",
      "Russian",
      "Sambalpuri",
      "Sanskrit",
      "Sindhi",
      "Sinhala",
      "Sourashtra",
      "Spanish",
      "Swahili",
      "Swedish",
      "Tagalog",
      "Tamil",
      "Telugu",
      "Thai",
      "Tulu",
      "Turkish",
      "Urdu",
      "Vietnamese",
    ],
  };

  const [data, setData] = useState({
    dateOfBirth: "",
    timeOfBirth: "",
    zodiacSign: "",
    zodiacStar: "",
    gothram: "",
    dhosam: "",
    birthCountry: "India",
    birthState: "",
    birthDistrict: "",
    birthCity: "",
    horoscopeImage: "",
    hobbies: {},
  });
  const [render, setRender] = useState(false);
  let { id } = useParams();
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const [selectedDhosam, setSelectedDhosam] = useState([]);
  const handleCheckboxChange = (event) => {
    const { value } = event.target;

    setSelectedDhosam((prev) => {
      const newSelected = prev.includes(value)
        ? prev.filter((dhosam) => dhosam !== value) // Remove if already selected
        : [...prev, value]; // Add if not selected

      // Update the main data state with the joined string
      setData((prevData) => ({
        ...prevData,
        dhosam: newSelected.join(", "), // Join selected values into a string
      }));

      return newSelected; // Return updated selected values
    });
  };

  function handleSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();
    // const hobbiesData = {};
    // Object.entries(hobbiesList).forEach(([category, items]) => {
    //   const selectedItems = data.hobbies.filter((hobby) =>
    //     items.includes(hobby)
    //   );
    //   if (selectedItems.length > 0) {
    //     hobbiesData[category] = selectedItems;
    //   }
    // });

    const fullData = { ...data, id: Number(id) };
    const formData = new FormData();

    Object.entries(fullData).forEach(([key, value]) => {
      if (key === "image" && value) {
        if (value.type === "application/pdf") {
          // Check if it's a valid PDF file
          formData.append(key, value); // Append the image file (binary data)
        } else {
          console.log("Please upload a valid PDF file");
        }
      } else {
        formData.append(key, value); // Append other values
      }
    });

    api.post("/admin/manageUserProfile/addHoroscope", formData).then((res) => {
      setRender(!render);
      setData("");
      Swal.fire(res.data.data).then(() => {
        history.goBack(); // Navigate back to the previous page after Swal confirmation
      });
    });
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2-digit month
    const day = String(date.getDate()).padStart(2, "0"); // Ensure 2-digit day
    return `${year}-${month}-${day}`;
  };
  const formatTime = (timeString) => {
    if (!timeString) return ""; // Return empty if no time is provided

    const [time, modifier] = timeString.split(" "); // Split into time and AM/PM
    let [hour, minute] = time.split(":"); // Split time into hour and minute

    // Convert to 24-hour format
    if (modifier === "PM" && hour !== "12") {
      hour = String(Number(hour) + 12); // Convert PM hour to 24-hour
    } else if (modifier === "AM" && hour === "12") {
      hour = "00"; // Midnight case
    }

    return `${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`; // Return formatted time
  };

  useEffect(() => {
    if (id) {
      api
        .post("admin/adminDashboard/userFullDetails", { id: id })
        .then((res) => {
          if (res.data.data !== "Data not found") {
            const horoscopeDetails = res.data.data.horoscopeDetails;

            // Format date to ensure YYYY-MM-DD
            const formattedDate = formatDate(horoscopeDetails.dateOfBirth);
            const formattedTime = formatTime(horoscopeDetails.timeOfBirth);
            console.log(formattedTime);

            setData((prevData) => ({
              ...prevData,
              dateOfBirth: formattedDate, // Use the formatted date here
              timeOfBirth: formattedTime,
              zodiacSign:
                capitalizeFirstLetter(horoscopeDetails.zodiacSign) || "",
              zodiacStar: horoscopeDetails.zodiacStar,
              gothram: horoscopeDetails.gothram || "",
              dhosam: capitalizeFirstLetter(horoscopeDetails.dhosam),
              birthCountry: horoscopeDetails.birthCountry,
              birthState: capitalizeFirstLetter(horoscopeDetails.birthState),
              birthDistrict:
                horoscopeDetails.birthDistrict || prevData.birthDistrict,
              birthCity: capitalizeFirstLetter(horoscopeDetails.birthCity) ,
              horoscopeImage: horoscopeDetails.horoscopeImage,
              hobbies: horoscopeDetails.hobbies,
            }));
          }
         
        });
    }
  }, [id]);

  console.log(data.dateOfBirth);
  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Handle undefined, null, or empty strings
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  // console.log(data);
  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title"  style={{fontWeight:"600"}}>Update Horoscope Details</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <div className="content-body">
                  <Form onSubmit={handleSubmit} className="form pt-5">
                    <Box sx={{ minWidth: 120 }}>
                      <FormGroup>
                        <Label for="dateOfBirth"  style={{fontWeight:"600"}}>Date Of Birth</Label>
                        <Input
                          name="dateOfBirth"
                          value={data.dateOfBirth || ""} // Provide a fallback to an empty string
                          type="date"
                          id="dateOfBirth"
                          onChange={handleChange}
                        ></Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="timeOfBirth"  style={{fontWeight:"600"}}>Time of Birth</Label>
                        <Input
                          name="timeOfBirth"
                          value={data.timeOfBirth}
                          placeholder="Enter Time Of Birth"
                          type="time"
                          id="timeOfBirth"
                          onChange={handleChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="zodiacSign"  style={{fontWeight:"600"}}>Select Zodiac Sign</Label>
                        <Input
                          name="zodiacSign"
                          value={data.zodiacSign}
                          type="select"
                          id="zodiacSign"
                          onChange={handleChange}
                        >
                          <option value="">Select zodiacSign</option>

                          {signList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="zodiacStar" style={{fontWeight:"600"}}>Select Zodiac Star</Label>
                        <Input
                          name="zodiacStar"
                          value={data.zodiacStar}
                          type="select"
                          id="zodiacStar"
                          onChange={handleChange}
                          style={{ textTransform: "capitalize" }}
                        >
                          <option value="">Select Zodiac Star</option>
                          {zodiacStarList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      {/* <FormGroup>
                        <Label for="gothram">Gothram</Label>
                        <Input
                          name="gothram"
                          value={data.gothram}
                          type="select"
                          id="gothram"
                          onChange={handleChange}
                          style={{ textTransform: "capitalize" }}
                        >
                          <option value="">Select Gothram</option>
                          {gothramList.map((x) => {
                            return <option value={x}>{x} </option>;
                          })}
                        </Input>
                      </FormGroup> */}
                      <FormGroup>
                        <Label  style={{fontWeight:"600"}}>Select Dhosam</Label>
                        {dhosamList.map((dhosam) => (
                          <div key={dhosam} style={{ marginLeft: "20px" }}>
                            <Input
                              type="checkbox"
                              value={dhosam}
                              checked={data.dhosam.includes(dhosam)} // Check if the dhosam is selected
                              onChange={handleCheckboxChange}
                            />
                            <Label style={{ marginTop: "8px" }}>{dhosam}</Label>
                          </div>
                        ))}
                      </FormGroup>
                      <>
                        <FormGroup>
                          <Label for="countryLivingIn" style={{fontWeight:"600"}}>Country living in</Label>
                          <Input
                            name="birthCountry"
                            value={data.birthCountry}
                            type="select"
                            id="countryLivingIn"
                            onChange={handleChange}
                          >
                            <option value="India">India</option>
                            <option value="Abroad">Abroad</option>
                          </Input>
                        </FormGroup>

                        {data.birthCountry === "India" ? (
                          <>
                            <FormGroup>
                              <Label for="state">State Living in</Label>
                              <Input
                                name="birthState"
                                value={data.birthState}
                                type="select"
                                id="state"
                                style={{ textTransform: "capitalize" }}
                                onChange={handleChange}
                              >
                                {Object.keys(stateData).map((state) => (
                                  <option key={state} value={state}>
                                    {state}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>

                            <FormGroup>
                              <Label for="cityLivingIn">City Living in</Label>
                              <Input
                                name="birthCity"
                                value={data.birthCity}
                                type="select"
                                id="cityLivingIn"
                                style={{ textTransform: "capitalize" }}
                                onChange={handleChange}
                              >
                               {data.birthState &&
                                stateData[data.birthState] &&
                                stateData[data.birthState].cities && (
                                  <>
                                    {
                                      (data.birthCity =
                                        stateData[data.birthState].cities[0])
                                    }
                                    {stateData[data.birthState].cities.map(
                                      (x) => (
                                        <option key={x} value={x}>
                                          {x}
                                        </option>
                                      )
                                    )}
                                  </>
                                )}
                              </Input>
                            </FormGroup>
                          </>
                        ) : (
                          <>
                            <FormGroup>
                              <Label for="countryName">Country Name</Label>
                              <Input
                                name="birthCountry"
                                value={data.birthCountry}
                                placeholder="Enter your country name"
                                type="text"
                                id="countryName"
                                onChange={handleChange}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="state">State Living in</Label>
                              <Input
                                name="birthState"
                                value={data.birthState}
                                placeholder="Enter your state"
                                type="text"
                                id="state"
                                onChange={handleChange}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="districtLivingIn">
                                District Living in
                              </Label>
                              <Input
                                name="birthDistrict"
                                value={data.birthDistrict}
                                placeholder="Enter your district"
                                type="text"
                                id="districtLivingIn"
                                onChange={handleChange}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="cityLivingIn">City Living in</Label>
                              <Input
                                name="birthCity"
                                value={data.birthCity}
                                placeholder="Enter your city"
                                type="text"
                                id="cityLivingIn"
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </>
                        )}
                      </>
                      {/* <FormGroup>
                        <Label for="annualIncome">Hobbies</Label>
                        <Input
                          name="hobbies"
                          value={data.hobbies}

                          type="select"
                          id="annualIncome"
                          style={{ textTransform: "capitalize" }}
                          onChange={handleChange}
                        >
                          {gothramList.map((x) => {
                            return <option value={x}>{x} </option>;
                          })}
                        </Input>
                      </FormGroup> */}
                      <label className="form-label" for="form2Example1">
                        <h5 style={{ paddingBottom: "5px" }}>
                          Horoscope Image
                        </h5>
                      </label>
                      <p>( File Type Should be PDF)</p>
                      <div
                        className="form-outline mb-4"
                        style={{ border: "solid 1px" }}
                      >
                        <input
                          type="file"
                          name="image"
                          accept=".pdf"
                          onChange={handleChange}
                          required
                          id="form2Example1"
                          className="form-control"
                          placeholder="Username"
                          style={{ padding: "10px" }}
                        />
                      </div>
                      <Button style={{ background: "black" }}>Submit</Button>
                    </Box>
                  </Form>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UpdateHoroscope;
