import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import api from "../../Axios";
import Swal from "sweetalert2";

import "react-phone-input-2/lib/style.css";

const UpdateReligion = () => {
  const history = useHistory();

  const religionList = [
    "hindu",
    "christian",
    "muslim",
    "jain",
    "sikh",
    "buddhist",
  ];
  let hinduCastes = ["Arunthathiyar"];
  let christianCastes = [
    "adventist",
    "anglican Episcopal",
    "anglo-Indian",
    "apostolic",
    "assembly of God (AG)",
    "baptist",
    "born Again",
    "brethren",
    "calvinist",
    "chaldean Syrian (Assyrian)",
    "church of Christ",
    "church of God",
    "church of North India",
    "church of South India",
    "congregational",
    "evangelist",
    "jacobite",
    "jehovah's Witnesses",
    "knanaya",
    "knanaya Catholic",
    "knanaya Jacobite",
    "latin Catholic",
    "latter day saints",
    "lutheran",
    "malabar Independent Syrian Church",
    "malankara Catholic",
    "marthoma",
    "melkite",
    "mennonite",
    "methodist",
    "moravian",
    "orthodox",
    "pentecost",
    "presbyterian",
    "protestant",
    "reformed Baptist",
    "reformed Presbyterian",
    "roman Catholic",
    "seventh-day Adventist",
    "st. Thomas Evangelical",
    "syrian Catholic",
    "syrian Jacobite",
    "syrian Orthodox",
    "syro Malabar",
    "christian - Others",
    "don't wish to specify",
  ];
  let muslimCastes = [
    "muslim - Qureshi",
    "muslim - Rajput",
    "muslim - Rowther",
    "muslim - Shafi",
    "muslim - Sheikh",
    "muslim - Siddiqui",
    "muslim - Syed",
    "muslim - UnSpecified",
    "muslim - Khoja",
    "muslim - Lebbai",
    "muslim - Malik",
    "muslim - Mapila",
    "muslim - Maraicar",
    "muslim - Memon",
    "muslim - Mughal",
    "muslim - Others",
    "muslim - Pathan",
    "muslim - Ansari",
    "muslim - Arain",
    "muslim - Awan",
    "muslim - Bohra",
    "muslim - Dekkani",
    "muslim - Dudekula",
    "muslim - Hanafi",
    "muslim - Jat",
  ];
  let jainCastes = [
    "jain - Agarwal",
    "jain - Asati",
    "jain - Ayodhyavasi",
    "jain - Bagherwal",
    "jain - Bania",
    "jain - Barhiya",
    "jain - Charanagare",
    "jain - Chaturtha",
    "jain - Dhakada",
    "jain - Gahoi/Grihapati",
    "jain - Golalare / Kharaua",
    "jain - Golapurva",
    "jain - Golsinghare",
    "jain - Harada",
    "jain - Humad / Humbad",
    "jain - Intercaste",
    "jain - Jaiswal",
    "jain - KVO",
    "jain - Kambhoja",
    "jain - Kasar",
    "jain - Kathanere",
    "jain - Khandelwal",
    "jain - Kharaua",
    "jain - Kutchi",
    "jain - Lamechu",
    "jain - Nema",
    "jain - Oswal",
    "jain - Others",
    "jain - Padmavati Porwal",
    "jain - Palliwal",
    "jain - Panchama",
    "jain - Parmar",
    "jain - Parwar / Paravara",
    "jain - Porwad / Porwal",
    "jain - Porwal",
    "jain - Saitwal",
    "jain - Samanar / Nayinar",
    "jain - Samiya",
    "jain - Sarak",
    "jain - Shrimal",
    "jain - Unspecified",
    "jain - Upadhyaya",
    "jain - Vaishya",
    "jain - Veerwal",
    "don't wish to specify",
  ];

  let sikhCastes = [
    "sikh - Ahluwalia",
    "sikh - Arora",
    "sikh - Bhatia",
    "sikh - Bhatra",
    "sikh - Ghumar",
    "sikh - Intercaste",
    "sikh - Jat",
    "sikh - Kamboj",
    "sikh - Khatri",
    "sikh - Kshatriya",
    "sikh - Lubana",
    "sikh - Majabi",
    "sikh - Nai",
    "sikh - Others",
    "sikh - Rai",
    "sikh - Rajput",
    "sikh - Ramdasia",
    "sikh - Ramgharia",
    "sikh - Ravidasia",
    "sikh - Saini",
    "sikh - Tonk Kshatriya",
    "sikh - Unspecified",
    "don't wish to specify",
  ];

  let buddhistCastes = [
    "neo Buddhist",
    "others",
    "tibetan Buddhist",
    "others",
    "don't wish to specify",
  ];
  let subcas = [
    "Tamil Arunthathiyar",
    "Reddy Arunthathiyar",
    "Golla Arunthathiyar",
    "Pannatti Arunthathiyar",

    "Kannada Arunthathiyar",
    "Koyya Arunthathiyar",
    "Pathakattiyan - Vattigan",
    "Amma Pirivu",
    "Avva Pirivu",
    "Janakilai - Thasarikilai",
    "Arunthathiyar - Sakkiliyar",   "Murasu Arunthathiyar",
    "Anuppa Arunthathiyar",
    "Don't know",
  ];

  let kulam = {
    "Reddy Arunthathiyar": [
      "Kevviyan",
      "Kamanooran",
      "Yenthalan",
      "Vemparan / Vemparaiyan",
      "Kontakattiyan",
      "Gothapillaiyan",
      "Pantheri",
      "Tiruvannamalaiyan",
      "Thanakontalam",
      "Kattakalalan",
      "Kattaran",
      "Kattakattaalu",
      "Nelapalelu",
      "Mavarathan",
      "Neranjalan / Tharalan",
      "Nanthalaiyan",
      "Kuralan",
      "Vijanagiriyan",
      "Pallagollan",
      "Vattharayadu",
      "Other Optional",
    ],
    "Pannatti Arunthathiyar": [
      "Mattumolu",
      "Pallamolu",
      "Nalimalelu",
      "Kalimalolu",
      "Parathalolu",
      "Kalrolu",
      "Thellakoorelu",
      "Moppurolu",
      "Thasurolu",
      "Pelletolu",
      "Kappurolu",
      "Kuthulurolu",
      "Other",
    ],
    "Murasu Arunthathiyar": [
      "Yerithu Kulam",
      "Azhagar / Alaiyar Kulam",
      "Manjar Kulam",
      "Nagar Kulam",
      "Aantar Kulam",
      "Kadai Kulam",
      "Pooran Koottam",
      "Yemmar Kulam",
      "Sengakannan Kulam",
      "Murasu Koottam",
      "Masarakulam",
      "Kuriyeethu Kulam",
      "Athiyan Kulam",
      "Other",
    ],
    "Anuppa Arunthathiyar": [
      "Sannor",
      "Pothanor",
      "Mooppanor",
      "Yethuppanar",
      "Keerthanar",
      "Olamosharan",
      "Other",
    ],
    "Pathakattiyan - Vattigan": [
      "Santhamuthulu",
      "Pagutugethulu / Pagitikathulu",
      "Sullirivarlu / Sullikarlu",
      "Pittavarlu / Pittakarlu",
      "Konagavar / Konakarlu",
      "Mathigapillivar / Mathigapelli",
      "Peeragavarlu / Peerakatu",
      "Ovilavar / Ovilan / Ovilalu",
      "Settisellivarlu",
      "Pulipothulu",
      "Jallivarlu",
      "Sinthalavarlu",
      "Rama Jothilu",
      "Alli Anthuvar",
      "Sappitavar / Saptikarlu / Sappitan",
      "Utthavar",
      "Rasakavar / Rasakarlu",
      "Kenchakavalu",
      "Kosangivar",
      "Patamasaraluvalu",
      "Kutakalavar",
      "Pallakavar",
      "Nellimvarlu / Nallimvalu",
      "Marikatthuluvar",
      "Thasirivar / Thasarlu",
      "Kommalavar / Komluvarlu",
      "Eantakavar / Eantakarlu",
      "Vattikavar / Vattikan / Vattukulu",
      "Eeramvaru",
      "Paarvar / Parlikarlu",
      "Kummitivar / Kummitiyan / Kummitivalu",
      "Nellampullavar",
      "Earramvar / Earamalavatu / Earamalavarlu",
      "Neelamvarlu",
      "Jonakavar",
      "Rama Jokiluvar",
      "Sathilvar / Sathuluvarlu",
      "Soothagavar / Soothakarlu",
      "Bothuvar",
      "Pegatavar / Pagatakarlu",
      "Paliyavar",
      "Pathagattiyan / Pathakattulu",
      "Kuntalavar / Kuntakarlu",
      "Murrakaru / Murkaru",
      "Mitukarlu / Mitakarlu",
      "Janakarlu / Janakan",
      "Mellakatu / Mellakan",
      "Thiva kootu / Thivva Koottan",
      "Palakarlu",
      "Pangittarlu / Pangittan",
      "Eallettulu",
      "Naramuthulu",
      "Alughuvarlu / Alughuvar",
      "Sullalvarlu",
      "Vandikarlu",
      "Thosakararlu",
      "Kothikethulu",
      "Sittakaralu",
      "Puthakaralu",
      "Kambuguthi kaatu",
      "Mandhapellai",
      "Sappa Rarlu / Sappal",
      "Koshakarlu / Koshakavar",
      "Puritala",
      "Sillapan",
      "Muthikarlu",
      "Podu Thasa Rarlu",
      "Ittu Thasa Rarlu",
      "Puduthu Thasa Rarlu",
      "Kaali Nadhu",
      "Mallikalu",
      "Nettimallumulu",
      "Poolagaaru",
      "Thottakaaru",
      "Mothamokkaluvar",
      "Kolmamaru",
      "Savakeedthulu",
      "Rathankatu",
      "Poovaluru",
      "Kammalavarlu",
      "Kakagattu",
      "Mitakaarlu",
      "Pulagattu",
      "Madakalavar",
      "Jonnakonda",
      "Masalavar / Masaluvar",
      "Jothikalu",
      "Kuyyaluvaru",
      "Vetagarlu",
      "Kaaseekar",
      "Chattalugaaru",
      "Jadhikaaru",
      "Muthukadathanu",
      "Thidakaaru",
      "Sauligaaru",
      "Kaartadu",
      "Kaaraalu",
      "Sama Joki",
      "Seeyam Pokkal",
      "Narithivarlu",
      "Pachakulatdu",
      "Monakaaru",
      "Ponmalavarlu",
      "Matravai",
    ],
    "Avva Pirivu": [
      "Pullanadu",
      "Moganadu",
      "Pagutikethedu",
      "Eanjanadu",
      "Patchedu",
      "Maravanadu",
      "Kenjavadu",
      "Soyyakadu",
      "Kommaladu",
      "Eeravadu / Eravadu",
      "Pangitadu",
      "Kalavanadu",
      "Palikadu",
      "Oviladu",
      "Mudikinadu",
      "Pantedu",
      "Poolanadu",
      "Soothaladu",
      "Deepanadu",
      "Mitukinadu",
      "Marikatthanadu",
      "Bothuvadu",
      "Ketthanadu",
      "Other",
    ],
  };
  let zodiacStarList = [
    "ashwini",
    "barani",
    "karthikai",
    "rohini",
    "mirugasiridam",
    "thiruvadhirai",
    "punarpoosam",
    "poosam",
    "ayilyam",
    "magam",
    "pooram",
    "uthiram",
    "astham",
    "chithirai",
    "swathi",
    "visagam",
    "anusham",
    "kettai",
    "mulam",
    "puradam",
    "uthiradam",
    "thiruvonam",
    "avittam",
    "sadayam",

    "purattadhi",
    "uttrttadhi",
    "revathi",
  ];
  let dhosamList = [
    "no Dhosam",
    "chevvai Dhosam",
    "rahu Dhosam",
    "kethu Dhosam",
    "kalathra Dhosham",
    "kala Sarpa Dhosam",
    "naga Dosham",
    "don't know",
  ];
  let gothramList = [
    "aatharvas",
    "agasthi",
    "ahabhunasa",
    "alampayana",
    "angiras",
    "arrishinimi",
    "athreyasa",
    "atri",
    "attarishi",
    "aukshanas",
    "aushanas",
    "babrahvya",
    "badarayana",
    "baijvayas",
    "bashan",
    "bharadwaj",
    "bhargava",
    "bhasyan",
    "bhrigu",
    "birthare",
    "bodhaaynas",
    "chandratri",
    "chikithasa",
    "chyavanasa",
    "daksa",
    "dalabhya",
    "darbhas",
    "devrata",
    "dhananjaya",
    "dhanvantri",
    "dhara Gautam",
    "dharanas",
    "dixit",
    "duttatreyas",
    "galiva",
    "ganganas",

    "gangyanas",
    "garga",
    "gargya Sainasa",
    "gouthama",
    "ghrit Kaushika",
    "gowri Veetham",
    "gardhmukh Sandilya",
    "harithasa",
    "jaiminiyas",
    "jamadagni",
    "jatukarna",
    "kaakavas",
    "kabi",
    "kalabouddasa",
    "kalpangeerasa",
    "kamakayana Vishwamitra",
    "kamsa",
    "kanav",
    "kanva",
    "kapi",
    "kapila Baradwaj",
    "kapinjal",
    "kapishthalas",
    "kaplish",
    "kashish",
    "kashyapa",
    "katyayan",
    "kaundinya",
    "kaunsa",
    "kaushal",
    "kausikasa",
    "keshoryas",
    "koushika Visvamitrasa",
    "krishnatrey",
    "kusa",
    "kutsasa",
    "laakshmanas",
    "laugakshi",
    "lavania",
    "lodwan",
    "lohit",
    "lokaakhyas",
    "lomasha",
    "madelia",
    "maitraya",
    "manava",
    "mandavya",
    "marica",
    "markendeya",
    "maudlas",
    "maunas",
    "mihir",
    "moudgalya",
    "mouna Bhargava",
    "munish",
    "mythravaruna",
    "nagasya",

    "naidrupa Kashyapa",
    "narayanas",
    "nithyandala",
    "paaniyas",
    "pachori",
    "paing",
    "parashara",
    "parthivasa",
    "paulastya",
    "poothamanasa",
    "pourugutsa",
    "prachinas",
    "raghuvanshi",
    "rajoria",
    "rathitar",
    "rohinya",
    "rohita",
    "sakalya",
    "sakhyanasa",
    "salankayanasa",
    "sankash",
    "sankha-Pingala-Kausta",
    "sankrut",
    "sankyanasa",
    "savanaka",
    "savarana",
    "shaalaksha",
    "shadamarshana",
    "shakhanas",
    "shalavatsa",
    "sandilyasa",
    "sharkaras",
    "sharkvas",
    "shaunak",
    "shravanesya",
    "shrimukh Shandilya",
    "shukla Atreyas",
    "sigidha",
    "sri Vatsa/Vatsa",
    "srungi Bharadwajasa",
    "suparnasa",
    "swathantra Kapisa",
    "tharakayanam",
    "titwal",
    "tushar",
    "udbahu",
    "udhalaka",
    "uditha Gautham",
    "udithya",
    "upamanyu",
    "upamanyu Vasishtasa",
    "upathya",
    "vadulasa",
    "vainya",
    "vardheyasa",
    "vashishtha",
    "veethahavya",
    "vishnordhageerasa",
    "vishnu Vridhha",
    "vishwamitra",
    "yaska",
    "others",
    "don't know my gothra (m)",
    "don't wish to specify",
  ];
  const signList = [
    "Mesham",
    "Rishabam",
    "Midhunam",
    "Kadagam",
    "Simmam",
    "Thiruvadhirai",
    "Kanni",
    "Thulam",
    "Viruchigam",
    "Dhanus",
    "Magaram",
    "Kumbam",
    "Meenam",
  ];
  const [data, setData] = useState({
    religion: "",
    caste: "",
    subCaste: "",
    zodiacSign: "",
    zodiacStar: "",
    gothram: "",
    dhosam: "",
  });
  const [render, setRender] = useState(false);
  let { id } = useParams();
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setSelectedKulam(e.target.value);

  };
  function handleSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();
    const fullData = { ...data, id: Number(id) };

    api.post("admin/manageUserProfile/editReligion  ", fullData).then((res) => {
      setRender(!render);
      setData("");
      Swal.fire("Added!", "Profile has been Added.", "success");
    });
    history.goBack();
  }
  useEffect(() => {
    api.post("admin/adminDashboard/userFullDetails", { id: id }).then((res) => {
      if (res.data.data !== "Data not found") {
        const profile = res.data.data.userDetails;
        const horoscopeDetails = res.data.data.horoscopeDetails;

        setData({
          religion: profile.religion || "",
          caste: profile.caste || "",
          subCaste: profile.subCaste || "",
          zodiacSign: capitalizeFirstLetter(horoscopeDetails.zodiacSign) || "",

          zodiacStar: horoscopeDetails.zodiacStar || "",
          gothram: horoscopeDetails.gothram || "",
          dhosam: horoscopeDetails.dhosam || "",
        });
      }
    });
  }, [id]);
  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Return an empty string if `string` is undefined or empty
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // console.log(data);
  const [subSubCaste, setSubSubCaste] = useState([]);
  const [kulams, setKulams] = useState([]);
  const [selectedKulam, setSelectedKulam] = useState('');

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title" style={{fontWeight:"600"}}>Update Religion Details</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <div className="content-body">
                  <Form onSubmit={handleSubmit} className="form pt-3">
                    <Box sx={{ minWidth: 120 }}>
                    <FormGroup>
                        <Label for="caste" style={{fontWeight:"600"}}>Caste</Label>
                        <Input
                          name="caste"
                          type="select"
                          id="caste"
                          value={data.caste}
                          style={{ textTransform: "capitalize" }}
                          onChange={handleChange}
                        >
                          <option value="">Select Caste</option>
                          {data.religion === "hindu" &&
                            hinduCastes.map((caste) => (
                              <option value={caste} key={caste}>
                                {caste}
                              </option>
                            ))}
                          {data.religion === "christian" &&
                            christianCastes.map((caste) => (
                              <option value={caste} key={caste}>
                                {caste}
                              </option>
                            ))}
                          {data.religion === "muslim" &&
                            muslimCastes.map((caste) => (
                              <option value={caste} key={caste}>
                                {caste}
                              </option>
                            ))}
                          {data.religion === "jain" &&
                            jainCastes.map((caste) => (
                              <option value={caste} key={caste}>
                                {caste}
                              </option>
                            ))}
                          {data.religion === "sikh" &&
                            sikhCastes.map((caste) => (
                              <option value={caste} key={caste}>
                                {caste}
                              </option>
                            ))}
                          {data.religion === "buddhist" &&
                            buddhistCastes.map((caste) => (
                              <option value={caste} key={caste}>
                                {caste}
                              </option>
                            ))}
                        </Input>
                      </FormGroup>
                      {data.religion === "hindu" && (
                        <FormGroup>
                          <Label for="subCaste" style={{fontWeight:"600"}}>Sub-Caste</Label>
                          <Input
                            name="subCaste"
                            type="select"
                            id="subCaste"
                            value={data.subCaste}
                            style={{ textTransform: "capitalize" }}
                            onChange={handleChange}
                          >
                            <option value="">Select Sub-Caste</option>
                            {subcas.map((subCaste) => (
                              <option value={subCaste} key={subCaste}>
                                {subCaste}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      )}
                      {data.religion !== "hindu" && (
                        <Input
                          name="subCaste"
                          placeholder="Enter your Sub-Caste"
                          type="text"
                          value={data.subCaste}
                          id="subCaste"
                          onChange={handleChange}
                          style={{ margin: "20px 0px" }}
                        />
                      )}
                    
                      {(kulam[data.subCaste]) ? (
                      <FormGroup>
                      <Label for="kulam" style={{fontWeight:"600"}}>Kulam / Gothram</Label>
                      <Input
                        name="gothram"
                        type="select"
                        id="kulam"
                        value={data.gothram} // Bind to state
                        onChange={handleChange} // Update state on change
                      >
                        <option value="">Select Kulam / Gothram</option>
                        {(kulam[data.subCaste] || []).map((kulamOption) => (
                          <option value={kulamOption} key={kulamOption}>
                            {kulamOption}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                      ) : (
                      
                          <Input
                            name="gothram"
                            placeholder="Enter your Gothram"
                            type="text"
                            value={data.gothram}
                            id="gothram"
                            onChange={handleChange}
                            style={{ margin: "20px 0px" }}
                          />
                      )}
                      
                      <FormGroup>
                        <Label for="zodiacSign" style={{fontWeight:"600"}}>Select Zodiac Sign</Label>
                        <Input
                          name="zodiacSign"
                          value={data.zodiacSign}
                          type="select"
                          id="zodiacSign"
                          onChange={handleChange}
                        >
                          <option value="">Select zodiacSign</option>

                          {signList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="zodiacStar" style={{fontWeight:"600"}}>Select Zodiac Star</Label>
                        <Input
                          name="zodiacStar"
                          value={data.zodiacStar}
                          type="select"
                          id="zodiacStar"
                          onChange={handleChange}
                          style={{ textTransform: "capitalize" }}
                        >
                          <option value="">Select Zodiac Star</option>
                          {zodiacStarList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      {/* <FormGroup>
                        <Label for="gothram">Gothram</Label>
                        <Input
                          name="gothram"
                          value={data.gothram}
                          type="select"
                          id="gothram"
                          onChange={handleChange}
                          style={{ textTransform: "capitalize" }}
                        >
                          {gothramList.map((x) => {
                            return <option value={x}>{x} </option>;
                          })}
                        </Input>
                      </FormGroup> */}
                      <FormGroup>
                        <Label for="dhosam" style={{fontWeight:"600"}}>Select Dhosam</Label>
                        <Input
                          name="dhosam"
                          value={data.dhosam}
                          type="select"
                          id="dhosam"
                          style={{ textTransform: "capitalize" }}
                          onChange={handleChange}
                        >
                          <option value="">Select dhosam</option>

                          {dhosamList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>{" "}
                      <Button style={{ background: "black" }}>Submit</Button>
                    </Box>
                  </Form>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UpdateReligion;
