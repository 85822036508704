import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Chip from "@mui/material/Chip";
import "./style.css";
import api from "../../Axios";

const Feedback = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    api.get("/admin/allUsers/viewAllFeedback").then((res) => {
      if (res.data === "Data not found") {
        setData([]); // Set an empty array if no data is found
      } else {
        setData(res.data.data); // Set the data normally if it's found
      }
    }).catch((err) => {
      console.error("API Error:", err);
      setData([]); // Handle API errors gracefully
    });
  }, []);
  

  const columns = [
    {
      name: "matrimonyId",
      label: "Matrimony ID",
      options: {
        filter: true,
        sort: true,
        filterOptions: { fullWidth: true },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: true,
        sort: false,
      },
      customBodyRender: (value) => (
        <p style={{ textTransform: "capitalize" }}>{value ? value : "N/A"}</p>
      ),
    },
    {
      name: "suggestions",
      label: "Suggestions",
      options: {
        filter: true,
        sort: false,
        filterOptions: { fullWidth: true },
      },
      customBodyRender: (value) =>
        value ? value.charAt(0).toUpperCase() + value.slice(1) : "N/A",
    },
    {
      name: "feedbackStatus",
      label: "FeedbackStatus",
      options: {
        filter: true,
        sort: false,
        filterOptions: { fullWidth: true },

        customBodyRender: (value) => (
          <Chip
            label={value || "unknown"}
            variant="outlined"
            style={{
              backgroundColor: value === "active" ? "green" : "red",
              color: value === "active" ? "white" : "black",
              textTransform: "capitalize",
            }}
          />
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    elevation: 0,
    filter: false,
    viewColumns: false,
    selectableRows: false,
    center: true,
  };

  return (
    <>
      <div className="container" style={{ marginTop: "10%" }}>
        {/* <h3>Feedback</h3> */}
        <div className="row">
        <MUIDataTable
  title={"Feedback"}
  data={data && Array.isArray(data) ? data : []} // Ensure 'data' is an array
  options={options}
  columns={columns}
/>

        </div>
      </div>
    </>
  );
};

export default Feedback;
