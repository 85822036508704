import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import "../../src/assets/css/style.css";
import api, { baseURL } from "../Axios";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const AccountDeletedReason = () => {
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const handleOpen = (img) => {
    setModalImage(img);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [data, setData] = useState([]);
  useEffect(() => {
    api.get("/admin/allUsers/getAllUsers").then((res) => {
      if(res.data.data.deletedReason)
      setData(res.data.data);
    });
  }, []);
  const columns = [
    {
      name: "profileId",
      label: "Profile Id",
      options: {
        filter: true,
        sort: true,
        filterOptions: { fullWidth: true },
      },
    },
    {
      name: "userName",
      label: "Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "phone",
      label: "Phone Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "deletedReason",
      label: "Reasons",
      options: {
        filter: true,
        sort: false,
        filterOptions: { fullWidth: true },
      },
    },
    {
      name: "images",
      label: "Image",
      options: {
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {value ? (
              <Button onClick={() => handleOpen(value)}>
                <img
                  src={baseURL + value}
                  alt="reason"
                  style={{
                    aspectRatio: "16/9",
                    objectFit: "cover",
                    width: "150px",
                  }}
                />
              </Button>
            ) : (
              <span>No Image Available</span>
            )}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <img
                  src={modalImage}
                  alt="stadium_img"
                  style={{ objectFit: "cover" }}
                />
              </Box>
            </Modal>
          </>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    elevation: 0,
    filter: false,
    viewColumns: false,
    selectableRows: false,
    center: true,
  };

  return (
    <>
      <div className="container" style={{ marginTop: "10%" }}>
        {/* <h3>Feedback</h3> */}
        <div className="row">
          <MUIDataTable
            title={"Account Deleted Reasons"}
            data={data}
            options={options}
            columns={columns}
          />
        </div>
      </div>
    </>
  );
};

export default AccountDeletedReason;
