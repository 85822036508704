import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import api from "../../Axios";
import Swal from "sweetalert2";

import "react-phone-input-2/lib/style.css";

const UpdatePartner = () => {
  const history = useHistory();

  let stateData = {
    "andaman & Nicobar": {
      districts: ["nicobar", "north and Middle Andaman", "south Andaman"],
      cities: [
        "alipur",
        "andaman Island",
        "anderson Island",
        "arainj-Laka-Punga",
        "austinabad",
        "bamboo Flat",
        "barren Island",
        "beadonabad",
        "betapur",
        "bindraban",
        "bonington",
        "brookesabad",
        "cadell Point",
        "calicut",
        "chetamale",
        "cinque Islands",
        "defence Island",
        "digilpur",
        "dolyganj",
        "flat Island",
        "geinyale",
        "great Coco Island",
        "haddo",
        "havelock Island",
        "henry Lawrence Island",
        "herbertabad",
        "hobdaypur",
        "ilichar",
        "ingoie",
        "inteview Island",
        "jangli Ghat",
        "jhon Lawrence Island",
        "karen",
        "kartara",
        "kYD Islannd",
        "landfall Island",
        "little Andmand",
        "little Coco Island",
        "long Island",
        "maimyo",
        "malappuram",
        "manglutan",
        "manpur",
        "mitha Khari",
        "neill Island",
        "nicobar Island",
        "north Brother Island",
        "north Passage Island",
        "north Sentinel Island",
        "nothen Reef Island",
        "outram Island",
        "pahlagaon",
        "palalankwe",
        "passage Island",
        "phaiapong",
        "phoenix Island",
        "port Blair",
        "preparis Island",
        "protheroepur",
        "rangachang",
        "rongat",
        "rutland Island",
        "sabari",
        "saddle Peak",
        "shadipur",
        "smith Island",
        "sound Island",
        "south Sentinel Island",
        "spike Island",
        "tarmugli Island",
        "taylerabad",
        "titaije",
        "toibalawe",
        "tusonabad",
        "west Island",
        "wimberleyganj",
        "yadita",
      ],
    },
    "andhra Pradesh": {
      districts: [
        "alluri Sitharama Raju",
        "anakapalli",
        "ananthapuramu",
        "annamayya",
        "bapatla",
        "chittoor",
        "dr. B.R. Ambedkar Konaseema",
        "east Godavari",
        "eluru",
        "guntur",
        "kakinada",
        "krishna",
        "kurnool",
        "nandyal",
        "nTR",
        "palnadu",
        "parvathipuram Manyam",
        "prakasam",
        "srikakulam",
        "sri Potti Sriramulu Nellore",
        "sri Sathya Sai",
        "tirupati",
        "visakhapatnam",
        "vizianagaram",
        "west Godavari",
        "ySR",
      ],
      cities: [
        "achampet",
        "adilabad",
        "adoni",
        "alampur",
        "allagadda",
        "alur",
        "amalapuram",
        "amangallu",
        "anakapalle",
        "anantapur",
        "andole",
        "araku",
        "armoor",
        "asifabad",
        "aswaraopet",
        "atmakur",
        "b. Kothakota",
        "badvel",
        "banaganapalle",
        "bandar",
        "bangarupalem",
        "banswada",
        "bapatla",
        "bellampalli",
        "bhadrachalam",
        "bhainsa",
        "bheemunipatnam",
        "bhimadole",
        "bhimavaram",
        "bhongir",
        "bhooragamphad",
        "boath",
        "bobbili",
        "bodhan",
        "chandoor",
        "chavitidibbalu",
        "chejerla",
        "chepurupalli",
        "cherial",
        "chevella",
        "chinnor",
        "chintalapudi",
        "chintapalle",
        "chirala",
        "chittoor",
        "chodavaram",
        "cuddapah",
        "cumbum",
        "darsi",
        "devarakonda",
        "dharmavaram",
        "dichpalli",
        "divi",
        "donakonda",
        "dronachalam",
        "east Godavari",
        "eluru",
        "eturnagaram",
        "gadwal",
        "gajapathinagaram",
        "gajwel",
        "garladinne",
        "giddalur",
        "godavari",
        "gooty",
        "gudivada",
        "gudur",
        "guntur",
        "hindupur",
        "hunsabad",
        "huzurabad",
        "huzurnagar",
        "hyderabad",
        "ibrahimpatnam",
        "jaggayyapet",
        "jagtial",
        "jammalamadugu",
        "jangaon",
        "jangareddygudem",
        "jannaram",
        "kadiri",
        "kaikaluru",
        "kakinada",
        "kalwakurthy",
        "kalyandurg",
        "kamalapuram",
        "kamareddy",
        "kambadur",
        "kanaganapalle",
        "kandukuru",
        "kanigiri",
        "karimnagar",
        "kavali",
        "khammam",
        "khanapur (AP)",
        "kodangal",
        "koduru",
        "koilkuntla",
        "kollapur",
        "kothagudem",
        "kovvur",
        "krishna",
        "krosuru",
        "kuppam",
        "kurnool",
        "lakkireddipalli",
        "madakasira",
        "madanapalli",
        "madhira",
        "madnur",
        "mahabubabad",
        "mahabubnagar",
        "mahadevapur",
        "makthal",
        "mancherial",
        "mandapeta",
        "mangalagiri",
        "manthani",
        "markapur",
        "marturu",
        "medachal",
        "medak",
        "medarmetla",
        "metpalli",
        "mriyalguda",
        "mulug",
        "mylavaram",
        "nagarkurnool",
        "nalgonda",
        "nallacheruvu",
        "nampalle",
        "nandigama",
        "nandikotkur",
        "nandyal",
        "narasampet",
        "narasaraopet",
        "narayanakhed",
        "narayanpet",
        "narsapur",
        "narsipatnam",
        "nazvidu",
        "nelloe",
        "nellore",
        "nidamanur",
        "nirmal",
        "nizamabad",
        "nuguru",
        "ongole",
        "outsarangapalle",
        "paderu",
        "pakala",
        "palakonda",
        "paland",
        "palmaneru",
        "pamuru",
        "pargi",
        "parkal",
        "parvathipuram",
        "pathapatnam",
        "pattikonda",
        "peapalle",
        "peddapalli",
        "peddapuram",
        "penukonda",
        "piduguralla",
        "piler",
        "pithapuram",
        "podili",
        "polavaram",
        "prakasam",
        "proddatur",
        "pulivendla",
        "punganur",
        "putturu",
        "rajahmundri",
        "rajampeta",
        "ramachandrapuram",
        "ramannapet",
        "rampachodavaram",
        "rangareddy",
        "rapur",
        "rayachoti",
        "rayadurg",
        "razole",
        "repalle",
        "saluru",
        "sangareddy",
        "sathupalli",
        "sattenapalle",
        "satyavedu",
        "shadnagar",
        "siddavattam",
        "siddipet",
        "sileru",
        "sircilla",
        "sirpur Kagaznagar",
        "sodam",
        "sompeta",
        "srikakulam",
        "srikalahasthi",
        "srisailam",
        "srungavarapukota",
        "sudhimalla",
        "sullarpet",
        "tadepalligudem",
        "tadipatri",
        "tanduru",
        "tanuku",
        "tekkali",
        "tenali",
        "thungaturthy",
        "tirivuru",
        "tirupathi",
        "tuni",
        "udaygiri",
        "ulvapadu",
        "uravakonda",
        "utnor",
        "v.R. Puram",
        "vaimpalli",
        "vayalpad",
        "venkatgiri",
        "venkatgirikota",
        "vijayawada",
        "vikrabad",
        "vinjamuru",
        "vinukonda",
        "visakhapatnam",
        "vizayanagaram",
        "vizianagaram",
        "vuyyuru",
        "wanaparthy",
        "warangal",
        "wardhannapet",
        "yelamanchili",
        "yelavaram",
        "yeleswaram",
        "yellandu",
        "yellanuru",
        "yellareddy",
        "yerragondapalem",
        "zahirabad",
      ],
    },
    "arunachal Pradesh": {
      districts: [
        "anjaw",
        "changlang",
        "east Kameng",
        "east Siang",
        "itanagar capital complex",
        "kamle",
        "kra Daadi",
        "kurung Kumey",
        "lepa Rada",
        "lohit",
        "longding",
        "lower Dibang Valley",
        "lower Siang",
        "lower Subansiri",
        "namsai",
        "pakke-Kessang",
        "papum Pare",
        "shi Yomi",
        "siang",
        "tawang",
        "tirap",
        "upper Dibang Valley",
        "upper Siang",
        "upper Subansiri",
        "west Kameng",
        "west Siang",
      ],
      cities: [
        "along",
        "anini",
        "anjaw",
        "bameng",
        "basar",
        "changlang",
        "chowkhem",
        "daporizo",
        "dibang Valley",
        "dirang",
        "hayuliang",
        "huri",
        "itanagar",
        "jairampur",
        "kalaktung",
        "kameng",
        "khonsa",
        "kolaring",
        "kurung Kumey",
        "lohit",
        "lower Dibang Valley",
        "lower Subansiri",
        "mariyang",
        "mechuka",
        "miao",
        "nefra",
        "pakkekesang",
        "pangin",
        "papum Pare",
        "passighat",
        "roing",
        "sagalee",
        "seppa",
        "siang",
        "tali",
        "taliha",
        "tawang",
        "tezu",
        "tirap",
        "tuting",
        "upper Siang",
        "upper Subansiri",
        "yiang Kiag",
      ],
    },
    assam: {
      districts: [
        "baksa",
        "barpeta",
        "bongaigaon",
        "cachar",
        "charaideo",
        "chirang",
        "darrang",
        "dhemaji",
        "dhubri",
        "dibrugarh",
        "dima Hasao",
        "goalpara",
        "golaghat",
        "hailakandi",
        "jorhat",
        "kamrup",
        "kamrup Metropolitan",
        "karbi Anglong",
        "karimganj",
        "kokrajhar",
        "lakhimpur",
        "majuli",
        "morigaon",
        "nagaon",
        "nalbari",
        "sivasagar",
        "sonitpur",
        "south Salmara Mankachar",
        "tinsukia",
        "udalguri",
        "west Karbi Anglong",
      ],
      cities: [
        "abhayapuri",
        "baithalangshu",
        "barama",
        "barpeta Road",
        "bihupuria",
        "bijni",
        "bilasipara",
        "bokajan",
        "bokakhat",
        "boko",
        "bongaigaon",
        "cachar",
        "cachar Hills",
        "darrang",
        "dhakuakhana",
        "dhemaji",
        "dhubri",
        "dibrugarh",
        "digboi",
        "diphu",
        "goalpara",
        "gohpur",
        "golaghat",
        "guwahati",
        "hailakandi",
        "hajo",
        "halflong",
        "hojai",
        "howraghat",
        "jorhat",
        "kamrup",
        "karbi Anglong",
        "karimganj",
        "kokarajhar",
        "kokrajhar",
        "lakhimpur",
        "maibong",
        "majuli",
        "mangaldoi",
        "mariani",
        "marigaon",
        "moranhat",
        "morigaon",
        "nagaon",
        "nalbari",
        "rangapara",
        "sadiya",
        "sibsagar",
        "silchar",
        "sivasagar",
        "sonitpur",
        "tarabarihat",
        "tezpur",
        "tinsukia",
        "udalgiri",
        "udalguri",
        "udarbondhBarpeta",
      ],
    },
    bihar: {
      districts: [
        "araria",
        "arwal",
        "aurangabad",
        "banka",
        "begusarai",
        "bhagalpur",
        "bhojpur",
        "buxar",
        "darbhanga",
        "east Champaran",
        "gaya",
        "gopalganj",
        "jamui",
        "jehanabad",
        "kaimur",
        "katihar",
        "khagaria",
        "kishanganj",
        "lakhisarai",
        "madhepura",
        "madhubani",
        "munger",
        "muzaffarpur",
        "nalanda",
        "nawada",
        "patna",
        "purnia",
        "rohtas",
        "saharsa",
        "samastipur",
        "saran",
        "sheikhpura",
        "sheohar",
        "sitamarhi",
        "siwan",
        "supaul",
        "vaishali",
        "west Champaran",
      ],
      cities: [
        "adhaura",
        "amarpur",
        "araria",
        "areraj",
        "arrah",
        "arwal",
        "aurangabad",
        "bagaha",
        "banka",
        "banmankhi",
        "barachakia",
        "barauni",
        "barh",
        "barosi",
        "begusarai",
        "benipatti",
        "benipur",
        "bettiah",
        "bhabhua",
        "bhagalpur",
        "bhojpur",
        "bidupur",
        "biharsharif",
        "bikram",
        "bikramganj",
        "birpur",
        "buxar",
        "chakai",
        "champaran",
        "chapara",
        "dalsinghsarai",
        "danapur",
        "darbhanga",
        "daudnagar",
        "dhaka",
        "dhamdaha",
        "dumraon",
        "ekma",
        "forbesganj",
        "gaya",
        "gogri",
        "gopalganj",
        "h.Kharagpur",
        "hajipur",
        "hathua",
        "hilsa",
        "imamganj",
        "jahanabad",
        "jainagar",
        "jamshedpur",
        "jamui",
        "jehanabad",
        "jhajha",
        "jhanjharpur",
        "kahalgaon",
        "kaimur (Bhabua)",
        "katihar",
        "katoria",
        "khagaria",
        "kishanganj",
        "korha",
        "lakhisarai",
        "madhepura",
        "madhubani",
        "maharajganj",
        "mahua",
        "mairwa",
        "mallehpur",
        "masrakh",
        "mohania",
        "monghyr",
        "motihari",
        "motipur",
        "munger",
        "muzaffarpur",
        "nabinagar",
        "nalanda",
        "narkatiaganj",
        "naugachia",
        "nawada",
        "pakribarwan",
        "pakridayal",
        "patna",
        "phulparas",
        "piro",
        "pupri",
        "purena",
        "purnia",
        "rafiganj",
        "rajauli",
        "ramnagar",
        "raniganj",
        "raxaul",
        "rohtas",
        "rosera",
        "s.Bakhtiarpur",
        "saharsa",
        "samastipur",
        "saran",
        "sasaram",
        "seikhpura",
        "sheikhpura",
        "sheohar",
        "sherghati",
        "sidhawalia",
        "singhwara",
        "sitamarhi",
        "siwan",
        "sonepur",
        "supaul",
        "thakurganj",
        "triveniganj",
        "udakishanganj",
        "vaishali",
        "wazirganj",
      ],
    },
    chandigarh: {
      districts: ["chandigarh"],
      cities: ["chandigarh", "mani Marja"],
    },
    chhattisgarh: {
      districts: [
        "balod",
        "baloda Bazar",
        "balrampur-Ramanujganj",
        "bastar",
        "bemetara",
        "bijapur",
        "bilaspur",
        "dantewada",
        "dhamtari",
        "durg",
        "gariaband",
        "gaurela-Pendra-Marwahi",
        "janjgir-Champa",
        "jashpur",
        "kabirdham",
        "kanker",
        "khairagarh-Chhuikhadan-Gandai",
        "kondagaon",
        "korba",
        "korea",
        "mahasamund",
        "manendragarh-Chirmiri-Bharatpur",
        "mohla-Manpur-Ambagarh Chowki",
        "mungeli",
        "narayanpur",
        "raigarh",
        "raipur",
        "rajnandgaon",
        "sarangarh-Bilaigarh",
        "shakti",
        "sukma",
        "surajpur",
        "surguja",
      ],
      cities: [
        "ambikapur",
        "antagarh",
        "arang",
        "bacheli",
        "bagbahera",
        "bagicha",
        "baikunthpur",
        "balod",
        "balodabazar",
        "balrampur",
        "barpalli",
        "basana",
        "bastanar",
        "bastar",
        "bderajpur",
        "bemetara",
        "berla",
        "bhairongarh",
        "bhanupratappur",
        "bharathpur",
        "bhatapara",
        "bhilai",
        "bhilaigarh",
        "bhopalpatnam",
        "bijapur",
        "bilaspur",
        "bodla",
        "bokaband",
        "chandipara",
        "chhinagarh",
        "chhuriakala",
        "chingmut",
        "chuikhadan",
        "dabhara",
        "dallirajhara",
        "dantewada",
        "deobhog",
        "dhamda",
        "dhamtari",
        "dharamjaigarh",
        "dongargarh",
        "durg",
        "durgakondal",
        "fingeshwar",
        "gariaband",
        "garpa",
        "gharghoda",
        "gogunda",
        "ilamidi",
        "jagdalpur",
        "janjgir",
        "janjgir-Champa",
        "jarwa",
        "jashpur",
        "jashpurnagar",
        "kabirdham-Kawardha",
        "kanker",
        "kasdol",
        "kathdol",
        "kathghora",
        "kawardha",
        "keskal",
        "khairgarh",
        "kondagaon",
        "konta",
        "korba",
        "korea",
        "kota",
        "koyelibeda",
        "kuakunda",
        "kunkuri",
        "kurud",
        "lohadigundah",
        "lormi",
        "luckwada",
        "mahasamund",
        "makodi",
        "manendragarh",
        "manpur",
        "marwahi",
        "mohla",
        "mungeli",
        "nagri",
        "narainpur",
        "narayanpur",
        "neora",
        "netanar",
        "odgi",
        "padamkot",
        "pakhanjur",
        "pali",
        "pandaria",
        "pandishankar",
        "parasgaon",
        "pasan",
        "patan",
        "pathalgaon",
        "pendra",
        "pratappur",
        "premnagar",
        "raigarh",
        "raipur",
        "rajnandgaon",
        "rajpur",
        "ramchandrapur",
        "saraipali",
        "saranggarh",
        "sarona",
        "semaria",
        "shakti",
        "sitapur",
        "sukma",
        "surajpur",
        "surguja",
        "tapkara",
        "toynar",
        "udaipur",
        "uproda",
        "wadrainagar",
      ],
    },
    "dadra & Nagar Haveli": {
      districts: ["dadra  & Nagar Haveli"],
      cities: [
        "amal",
        "amli",
        "bedpa",
        "chikhli",
        "dadra & Nagar Haveli",
        "dahikhed",
        "dolara",
        "galonda",
        "kanadi",
        "karchond",
        "khadoli",
        "kharadpada",
        "kherabari",
        "kherdi",
        "kothar",
        "luari",
        "mashat",
        "rakholi",
        "rudana",
        "saili",
        "sili",
        "silvassa",
        "sindavni",
        "udva",
        "umbarkoi",
        "vansda",
        "vasona",
        "velugam",
      ],
    },
    "daman & Diu": {
      districts: ["daman", "diu"],
      cities: [
        "brancavare",
        "dagasi",
        "daman",
        "diu",
        "magarvara",
        "nagwa",
        "pariali",
        "passo Covo",
      ],
    },
    delhi: {
      districts: [
        "central Delhi",
        "east Delhi",
        "new Delhi",
        "north Delhi",
        "north East Delhi",
        "north West Delhi",
        "shahdara district",
        "south Delhi",
        "south East Delhi",
        "south West Delhi",
        "west Delhi",
      ],
      cities: [
        "central Delhi",
        "east Delhi",
        "new Delhi",
        "north Delhi",
        "north East Delhi",
        "north West Delhi",
        "south Delhi",
        "south West Delhi",
        "west Delhi",
      ],
    },
    goa: {
      districts: ["north Goa", "south Goa"],
      cities: [
        "canacona",
        "candolim",
        "chinchinim",
        "cortalim",
        "goa",
        "jua",
        "madgaon",
        "mahem",
        "mapuca",
        "marmagao",
        "panji",
        "ponda",
        "sanvordem",
        "terekhol",
      ],
    },
    gujarat: {
      districts: [
        "ahmedabad",
        "amreli",
        "anand",
        "aravalli",
        "banaskantha",
        "bharuch",
        "bhavnagar",
        "botad",
        "chhota Udaipur",
        "dahod",
        "dang",
        "devbhumi Dwarka",
        "gandhinagar",
        "gir Somnath",
        "jamnagar",
        "junagadh",
        "kheda",
        "kutch",
        "mahisagar",
        "mehsana",
        "morbi",
        "narmada",
        "navsari",
        "panchmahal",
        "patan",
        "porbandar",
        "rajkot",
        "sabarkantha",
        "surat",
        "surendranagar",
        "tapi",
        "vadodara",
        "valsad",
      ],
      cities: [
        "ahmedabad",
        "ahwa",
        "amod",
        "amreli",
        "anand",
        "anjar",
        "ankaleshwar",
        "babra",
        "balasinor",
        "banaskantha",
        "bansada",
        "bardoli",
        "bareja",
        "baroda",
        "barwala",
        "bayad",
        "bhachav",
        "bhanvad",
        "bharuch",
        "bhavnagar",
        "bhiloda",
        "bhuj",
        "billimora",
        "borsad",
        "botad",
        "chanasma",
        "chhota Udaipur",
        "chotila",
        "dabhoi",
        "dahod",
        "damnagar",
        "dang",
        "danta",
        "dasada",
        "dediapada",
        "deesa",
        "dehgam",
        "deodar",
        "devgadhbaria",
        "dhandhuka",
        "dhanera",
        "dharampur",
        "dhari",
        "dholka",
        "dhoraji",
        "dhrangadhra",
        "dhrol",
        "dwarka",
        "fortsongadh",
        "gadhada",
        "gandhi Nagar",
        "gariadhar",
        "godhra",
        "gogodar",
        "gondal",
        "halol",
        "halvad",
        "harij",
        "himatnagar",
        "idar",
        "jambusar",
        "jamjodhpur",
        "jamkalyanpur",
        "jamnagar",
        "jasdan",
        "jetpur",
        "jhagadia",
        "jhalod",
        "jodia",
        "junagadh",
        "junagarh",
        "kalawad",
        "kalol",
        "kapad Wanj",
        "keshod",
        "khambat",
        "khambhalia",
        "khavda",
        "kheda",
        "khedbrahma",
        "kheralu",
        "kodinar",
        "kotdasanghani",
        "kunkawav",
        "kutch",
        "kutchmandvi",
        "kutiyana",
        "lakhpat",
        "lakhtar",
        "lalpur",
        "limbdi",
        "limkheda",
        "lunavada",
        "m.M.Mangrol",
        "mahuva",
        "malia-Hatina",
        "maliya",
        "malpur",
        "manavadar",
        "mandvi",
        "mangrol",
        "mehmedabad",
        "mehsana",
        "miyagam",
        "modasa",
        "morvi",
        "muli",
        "mundra",
        "nadiad",
        "nakhatrana",
        "nalia",
        "narmada",
        "naswadi",
        "navasari",
        "nizar",
        "okha",
        "paddhari",
        "padra",
        "palanpur",
        "palitana",
        "panchmahals",
        "patan",
        "pavijetpur",
        "porbandar",
        "prantij",
        "radhanpur",
        "rahpar",
        "rajaula",
        "rajkot",
        "rajpipla",
        "ranavav",
        "sabarkantha",
        "sanand",
        "sankheda",
        "santalpur",
        "santrampur",
        "savarkundla",
        "savli",
        "sayan",
        "sayla",
        "shehra",
        "sidhpur",
        "sihor",
        "sojitra",
        "sumrasar",
        "surat",
        "surendranagar",
        "talaja",
        "thara",
        "tharad",
        "thasra",
        "una-Diu",
        "upleta",
        "vadgam",
        "vadodara",
        "valia",
        "vallabhipur",
        "valod",
        "valsad",
        "vanthali",
        "vapi",
        "vav",
        "veraval",
        "vijapur",
        "viramgam",
        "visavadar",
        "visnagar",
        "vyara",
        "waghodia",
        "wankaner",
      ],
    },
    haryana: {
      districts: [
        "ambala",
        "bhiwani",
        "charkhi Dadri",
        "faridabad",
        "fatehabad",
        "gurugram",
        "hisar",
        "jhajjar",
        "jind",
        "kaithal",
        "karnal",
        "kurukshetra",
        "mahendragarh",
        "nuh",
        "palwal",
        "panchkula",
        "panipat",
        "rewari",
        "rohtak",
        "sirsa",
        "sonipat",
        "yamunanagar",
      ],
      cities: [
        "adampur Mandi",
        "ambala",
        "assandh",
        "bahadurgarh",
        "barara",
        "barwala",
        "bawal",
        "bawanikhera",
        "bhiwani",
        "charkhidadri",
        "cheeka",
        "chhachrauli",
        "dabwali",
        "ellenabad",
        "faridabad",
        "fatehabad",
        "ferojpur Jhirka",
        "gharaunda",
        "gohana",
        "gurgaon",
        "hansi",
        "hisar",
        "jagadhari",
        "jatusana",
        "jhajjar",
        "jind",
        "julana",
        "kaithal",
        "kalanaur",
        "kalanwali",
        "kalka",
        "karnal",
        "kosli",
        "kurukshetra",
        "loharu",
        "mahendragarh",
        "meham",
        "mewat",
        "mohindergarh",
        "naraingarh",
        "narnaul",
        "narwana",
        "nilokheri",
        "nuh",
        "palwal",
        "panchkula",
        "panipat",
        "pehowa",
        "ratia",
        "rewari",
        "rohtak",
        "safidon",
        "sirsa",
        "siwani",
        "sonipat",
        "tohana",
        "tohsam",
        "yamunanagar",
      ],
    },
    "himachal Pradesh": {
      districts: [
        "bilaspur",
        "chamba",
        "hamirpur",
        "kangra",
        "kinnaur",
        "kullu",
        "lahaul and Spiti",
        "mandi",
        "shimla",
        "sirmaur",
        "solan",
        "una",
      ],
      cities: [
        "amb",
        "arki",
        "banjar",
        "bharmour",
        "bilaspur",
        "chamba",
        "churah",
        "dalhousie",
        "dehra Gopipur",
        "hamirpur",
        "jogindernagar",
        "kalpa",
        "kangra",
        "kinnaur",
        "kullu",
        "lahaul",
        "mandi",
        "nahan",
        "nalagarh",
        "nirmand",
        "nurpur",
        "palampur",
        "pangi",
        "paonta",
        "pooh",
        "rajgarh",
        "rampur Bushahar",
        "rohru",
        "shimla",
        "sirmaur",
        "solan",
        "spiti",
        "sundernagar",
        "theog",
        "udaipur",
        "una",
      ],
    },
    "jammu & Kashmir": {
      districts: [
        "anantnag",
        "budgam",
        "bandipore",
        "baramulla",
        "doda",
        "ganderbal",
        "jammu",
        "kathua",
        "kishtwar",
        "kulgam",
        "kupwara",
        "poonch",
        "pulwama",
        "rajouri",
        "ramban",
        "reasi",
        "samba",
        "shopian",
        "srinagar",
        "udhampur",
      ],
      cities: [
        "akhnoor",
        "anantnag",
        "badgam",
        "bandipur",
        "baramulla",
        "basholi",
        "bedarwah",
        "budgam",
        "doda",
        "gulmarg",
        "jammu",
        "kalakot",
        "kargil",
        "karnah",
        "kathua",
        "kishtwar",
        "kulgam",
        "kupwara",
        "leh",
        "mahore",
        "nagrota",
        "nobra",
        "nowshera",
        "nyoma",
        "padam",
        "pahalgam",
        "patnitop",
        "poonch",
        "pulwama",
        "rajouri",
        "ramban",
        "ramnagar",
        "reasi",
        "samba",
        "srinagar",
        "udhampur",
        "vaishno Devi",
      ],
    },
    jharkhand: {
      districts: [
        "bokaro",
        "chatra",
        "deoghar",
        "dhanbad",
        "dumka",
        "east Singhbhum",
        "garhwa",
        "giridih",
        "godda",
        "gumla",
        "hazaribag",
        "jamtara",
        "khunti",
        "koderma",
        "latehar",
        "lohardaga",
        "pakur",
        "palamu",
        "ramgarh",
        "ranchi",
        "sahibganj",
        "seraikela-Kharsawan",
        "simdega",
        "west Singhbhum",
      ],
      cities: [
        "bagodar",
        "baharagora",
        "balumath",
        "barhi",
        "barkagaon",
        "barwadih",
        "basia",
        "bermo",
        "bhandaria",
        "bhawanathpur",
        "bishrampur",
        "bokaro",
        "bolwa",
        "bundu",
        "chaibasa",
        "chainpur",
        "chakardharpur",
        "chandil",
        "chatra",
        "chavparan",
        "daltonganj",
        "deoghar",
        "dhanbad",
        "dumka",
        "dumri",
        "garhwa",
        "garu",
        "ghaghra",
        "ghatsila",
        "giridih",
        "godda",
        "gomia",
        "govindpur",
        "gumla",
        "hazaribagh",
        "hunterganj",
        "ichak",
        "itki",
        "jagarnathpur",
        "jamshedpur",
        "jamtara",
        "japla",
        "jharmundi",
        "jhinkpani",
        "jhumaritalaiya",
        "kathikund",
        "kharsawa",
        "khunti",
        "koderma",
        "kolebira",
        "latehar",
        "lohardaga",
        "madhupur",
        "mahagama",
        "maheshpur Raj",
        "mandar",
        "mandu",
        "manoharpur",
        "muri",
        "nagarutatri",
        "nala",
        "noamundi",
        "pakur",
        "palamu",
        "palkot",
        "patan",
        "rajdhanwar",
        "rajmahal",
        "ramgarh",
        "ranchi",
        "sahibganj",
        "saraikela",
        "simaria",
        "simdega",
        "singhbhum",
        "tisri",
        "torpa",
      ],
    },
    karnataka: {
      districts: [
        "bagalakote",
        "ballari",
        "belagavi",
        "bengaluru Rural",
        "bengaluru Urban",
        "bidar",
        "chamarajanagara",
        "chikkaballapura",
        "chikkamagaluru",
        "chitradurga",
        "dakshina Kannada",
        "davanagere",
        "dharwada",
        "gadaga",
        "kalaburagi",
        "hassan",
        "haveri",
        "kodagu",
        "kolar",
        "koppala",
        "mandya",
        "mysuru",
        "raichuru",
        "ramanagara",
        "shivamogga",
        "tumakuru",
        "udupi",
        "uttara Kannada",
        "vijayanagara",
        "vijayapura",
        "yadgiri",
      ],
      cities: [
        "afzalpur",
        "ainapur",
        "aland",
        "alur",
        "anekal",
        "ankola",
        "arsikere",
        "athani",
        "aurad",
        "bableshwar",
        "badami",
        "bagalkot",
        "bagepalli",
        "bailhongal",
        "bangalore",
        "bangalore Rural",
        "bangarpet",
        "bantwal",
        "basavakalyan",
        "basavanabagewadi",
        "basavapatna",
        "belgaum",
        "bellary",
        "belthangady",
        "belur",
        "bhadravati",
        "bhalki",
        "bhatkal",
        "bidar",
        "bijapur",
        "biligi",
        "chadchan",
        "challakere",
        "chamrajnagar",
        "channagiri",
        "channapatna",
        "channarayapatna",
        "chickmagalur",
        "chikballapur",
        "chikkaballapur",
        "chikkanayakanahalli",
        "chikkodi",
        "chikmagalur",
        "chincholi",
        "chintamani",
        "chitradurga",
        "chittapur",
        "cowdahalli",
        "davanagere",
        "deodurga",
        "devangere",
        "devarahippargi",
        "dharwad",
        "doddaballapur",
        "gadag",
        "gangavathi",
        "gokak",
        "gowribdanpur",
        "gubbi",
        "gulbarga",
        "gundlupet",
        "h.B.Halli",
        "h.D. Kote",
        "haliyal",
        "hampi",
        "hangal",
        "harapanahalli",
        "hassan",
        "haveri",
        "hebri",
        "hirekerur",
        "hiriyur",
        "holalkere",
        "holenarsipur",
        "honnali",
        "honnavar",
        "hosadurga",
        "hosakote",
        "hosanagara",
        "hospet",
        "hubli",
        "hukkeri",
        "humnabad",
        "hungund",
        "hunsagi",
        "hunsur",
        "huvinahadagali",
        "indi",
        "jagalur",
        "jamkhandi",
        "jewargi",
        "joida",
        "k.R. Nagar",
        "kadur",
        "kalghatagi",
        "kamalapur",
        "kanakapura",
        "kannada",
        "kargal",
        "karkala",
        "karwar",
        "khanapur",
        "kodagu",
        "kolar",
        "kollegal",
        "koppa",
        "koppal",
        "koratageri",
        "krishnarajapet",
        "kudligi",
        "kumta",
        "kundapur",
        "kundgol",
        "kunigal",
        "kurugodu",
        "kustagi",
        "lingsugur",
        "madikeri",
        "madugiri",
        "malavalli",
        "malur",
        "mandya",
        "mangalore",
        "manipal",
        "manvi",
        "mashal",
        "molkalmuru",
        "mudalgi",
        "muddebihal",
        "mudhol",
        "mudigere",
        "mulbagal",
        "mundagod",
        "mundargi",
        "murugod",
        "mysore",
        "nagamangala",
        "nanjangud",
        "nargund",
        "narsimrajapur",
        "navalgund",
        "nelamangala",
        "nimburga",
        "pandavapura",
        "pavagada",
        "puttur",
        "raibag",
        "raichur",
        "ramdurg",
        "ranebennur",
        "ron",
        "sagar",
        "sakleshpur",
        "salkani",
        "sandur",
        "saundatti",
        "savanur",
        "sedam",
        "shahapur",
        "shankarnarayana",
        "shikaripura",
        "shimoga",
        "shirahatti",
        "shorapur",
        "siddapur",
        "sidlaghatta",
        "sindagi",
        "sindhanur",
        "sira",
        "sirsi",
        "siruguppa",
        "somwarpet",
        "sorab",
        "sringeri",
        "sriniwaspur",
        "srirangapatna",
        "sullia",
        "t. Narsipur",
        "tallak",
        "tarikere",
        "telgi",
        "thirthahalli",
        "tiptur",
        "tumkur",
        "turuvekere",
        "udupi",
        "virajpet",
        "wadi",
        "yadgiri",
        "yelburga",
        "yellapur",
      ],
    },
    kerala: {
      districts: [
        "alappuzha",
        "ernakulam",
        "idukki",
        "kannur",
        "kasaragod",
        "kollam",
        "kottayam",
        "kozhikode",
        "malappuram",
        "palakkad",
        "pathanamthitta",
        "thiruvananthapuram",
        "thrissur",
        "wayanad",
      ],
      cities: [
        "adimaly",
        "adoor",
        "agathy",
        "alappuzha",
        "alathur",
        "alleppey",
        "alwaye",
        "amini",
        "androth",
        "attingal",
        "badagara",
        "bitra",
        "calicut",
        "cannanore",
        "chetlet",
        "ernakulam",
        "idukki",
        "irinjalakuda",
        "kadamath",
        "kalpeni",
        "kalpetta",
        "kanhangad",
        "kanjirapally",
        "kannur",
        "karungapally",
        "kasargode",
        "kavarathy",
        "kiltan",
        "kochi",
        "koduvayur",
        "kollam",
        "kottayam",
        "kovalam",
        "kozhikode",
        "kunnamkulam",
        "malappuram",
        "mananthodi",
        "manjeri",
        "mannarghat",
        "mavelikkara",
        "minicoy",
        "munnar",
        "muvattupuzha",
        "nedumandad",
        "nedumgandam",
        "nilambur",
        "palai",
        "palakkad",
        "palghat",
        "pathaanamthitta",
        "pathanamthitta",
        "payyanur",
        "peermedu",
        "perinthalmanna",
        "perumbavoor",
        "punalur",
        "quilon",
        "ranni",
        "shertallai",
        "shoranur",
        "taliparamba",
        "tellicherry",
        "thiruvananthapuram",
        "thodupuzha",
        "thrissur",
        "tirur",
        "tiruvalla",
        "trichur",
        "trivandrum",
        "uppala",
        "vadakkanchery",
        "vikom",
        "wayanad",
      ],
    },
    ladakh: {
      districts: ["kargil", "leh"],
      cities: ["leh", "kargil"],
    },
    lakshadweep: {
      districts: ["lakshadweep"],
      cities: [
        "agatti Island",
        "bingaram Island",
        "bitra Island",
        "chetlat Island",
        "kadmat Island",
        "kalpeni Island",
        "kavaratti Island",
        "kiltan Island",
        "lakshadweep Sea",
        "minicoy Island",
        "north Island",
        "south Island",
      ],
    },
    "madhya Pradesh": {
      districts: [
        "agar Malwa",
        "alirajpur",
        "anuppur",
        "ashoknagar",
        "balaghat",
        "barwani",
        "betul",
        "bhind",
        "bhopal",
        "burhanpur",
        "chhatarpur",
        "chhindwara",
        "damoh",
        "datia",
        "dewas",
        "dhar",
        "dindori",
        "guna",
        "gwalior",
        "harda",
        "hoshangabad",
        "indore",
        "jabalpur",
        "jhabua",
        "katni",
        "khandwa",
        "khargone",
        "mandla",
        "mandsaur",
        "morena",
        "narsinghpur",
        "neemuch",
        "niwari",
        "panna",
        "raisen",
        "rajgarh",
        "ratlam",
        "rewa",
        "sagar",
        "satna",
        "sehore",
        "seoni",
        "shahdol",
        "shajapur",
        "sheopur",
        "shivpuri",
        "sidhi",
        "singrauli",
        "tikamgarh",
        "ujjain",
        "umaria",
        "vidisha",
      ],
      cities: [
        "agar",
        "ajaigarh",
        "alirajpur",
        "amarpatan",
        "amarwada",
        "ambah",
        "anuppur",
        "arone",
        "ashoknagar",
        "ashta",
        "atner",
        "babaichichli",
        "badamalhera",
        "badarwsas",
        "badnagar",
        "badnawar",
        "badwani",
        "bagli",
        "baihar",
        "balaghat",
        "baldeogarh",
        "baldi",
        "bamori",
        "banda",
        "bandhavgarh",
        "bareli",
        "baroda",
        "barwaha",
        "barwani",
        "batkakhapa",
        "begamganj",
        "beohari",
        "berasia",
        "berchha",
        "betul",
        "bhainsdehi",
        "bhander",
        "bhanpura",
        "bhikangaon",
        "bhimpur",
        "bhind",
        "bhitarwar",
        "bhopal",
        "biaora",
        "bijadandi",
        "bijawar",
        "bijaypur",
        "bina",
        "birsa",
        "birsinghpur",
        "budhni",
        "burhanpur",
        "buxwaha",
        "chachaura",
        "chanderi",
        "chaurai",
        "chhapara",
        "chhatarpur",
        "chhindwara",
        "chicholi",
        "chitrangi",
        "churhat",
        "dabra",
        "damoh",
        "datia",
        "deori",
        "deosar",
        "depalpur",
        "dewas",
        "dhar",
        "dharampuri",
        "dindori",
        "gadarwara",
        "gairatganj",
        "ganjbasoda",
        "garoth",
        "ghansour",
        "ghatia",
        "ghatigaon",
        "ghorandogri",
        "ghughari",
        "gogaon",
        "gohad",
        "goharganj",
        "gopalganj",
        "gotegaon",
        "gourihar",
        "guna",
        "gunnore",
        "gwalior",
        "gyraspur",
        "hanumana",
        "harda",
        "harrai",
        "harsud",
        "hatta",
        "hoshangabad",
        "ichhawar",
        "indore",
        "isagarh",
        "itarsi",
        "jabalpur",
        "jabera",
        "jagdalpur",
        "jaisinghnagar",
        "jaithari",
        "jaitpur",
        "jaitwara",
        "jamai",
        "jaora",
        "jatara",
        "jawad",
        "jhabua",
        "jobat",
        "jora",
        "kakaiya",
        "kannod",
        "kannodi",
        "karanjia",
        "kareli",
        "karera",
        "karhal",
        "karpa",
        "kasrawad",
        "katangi",
        "katni",
        "keolari",
        "khachrod",
        "khajuraho",
        "khakner",
        "khalwa",
        "khandwa",
        "khaniadhana",
        "khargone",
        "khategaon",
        "khetia",
        "khilchipur",
        "khirkiya",
        "khurai",
        "kolaras",
        "kotma",
        "kukshi",
        "kundam",
        "kurwai",
        "kusmi",
        "laher",
        "lakhnadon",
        "lamta",
        "lanji",
        "lateri",
        "laundi",
        "maheshwar",
        "mahidpurcity",
        "maihar",
        "majhagwan",
        "majholi",
        "malhargarh",
        "manasa",
        "manawar",
        "mandla",
        "mandsaur",
        "manpur",
        "mauganj",
        "mawai",
        "mehgaon",
        "mhow",
        "morena",
        "multai",
        "mungaoli",
        "nagod",
        "nainpur",
        "narsingarh",
        "narsinghpur",
        "narwar",
        "nasrullaganj",
        "nateran",
        "neemuch",
        "niwari",
        "niwas",
        "nowgaon",
        "pachmarhi",
        "pandhana",
        "pandhurna",
        "panna",
        "parasia",
        "patan",
        "patera",
        "patharia",
        "pawai",
        "petlawad",
        "pichhore",
        "piparia",
        "pohari",
        "prabhapattan",
        "punasa",
        "pushprajgarh",
        "raghogarh",
        "raghunathpur",
        "rahatgarh",
        "raisen",
        "rajgarh",
        "rajpur",
        "ratlam",
        "rehli",
        "rewa",
        "sabalgarh",
        "sagar",
        "sailana",
        "sanwer",
        "sarangpur",
        "sardarpur",
        "satna",
        "saunsar",
        "sehore",
        "sendhwa",
        "seondha",
        "seoni",
        "seonimalwa",
        "shahdol",
        "shahnagar",
        "shahpur",
        "shajapur",
        "sheopur",
        "sheopurkalan",
        "shivpuri",
        "shujalpur",
        "sidhi",
        "sihora",
        "silwani",
        "singrauli",
        "sirmour",
        "sironj",
        "sitamau",
        "sohagpur",
        "sondhwa",
        "sonkatch",
        "susner",
        "tamia",
        "tarana",
        "tendukheda",
        "teonthar",
        "thandla",
        "tikamgarh",
        "timarani",
        "udaipura",
        "ujjain",
        "umaria",
        "umariapan",
        "vidisha",
        "vijayraghogarh",
        "waraseoni",
        "zhirnia",
      ],
    },
    maharashtra: {
      districts: [
        "ahmednagar",
        "akola",
        "amravati",
        "beed",
        "bhandara",
        "buldhana",
        "chandrapur",
        "osmanabad",
        "dhule",
        "gadchiroli",
        "gondia",
        "hingoli",
        "jalgaon",
        "jalna",
        "kolhapur",
        "latur",
        "mumbai City",
        "mumbai Suburban",
        "nanded",
        "nandurbar",
        "nagpur",
        "nashik",
        "palghar",
        "parbhani",
        "pune",
        "raigad",
        "ratnagiri",
        "aurangabad",
        "sangli",
        "satara",
        "sindhudurg",
        "solapur",
        "thane",
        "wardha",
        "washim",
        "yavatmal",
      ],
      cities: [
        "achalpur",
        "aheri",
        "ahmednagar",
        "ahmedpur",
        "ajara",
        "akkalkot",
        "akola",
        "akole",
        "akot",
        "alibagh",
        "amagaon",
        "amalner",
        "ambad",
        "ambejogai",
        "amravati",
        "arjuni Merogaon",
        "arvi",
        "ashti",
        "atpadi",
        "aurangabad",
        "ausa",
        "babhulgaon",
        "balapur",
        "baramati",
        "barshi Takli",
        "barsi",
        "basmatnagar",
        "bassein",
        "beed",
        "bhadrawati",
        "bhamregadh",
        "bhandara",
        "bhir",
        "bhiwandi",
        "bhiwapur",
        "bhokar",
        "bhokardan",
        "bhoom",
        "bhor",
        "bhudargad",
        "bhusawal",
        "billoli",
        "brahmapuri",
        "buldhana",
        "butibori",
        "chalisgaon",
        "chamorshi",
        "chandgad",
        "chandrapur",
        "chandur",
        "chanwad",
        "chhikaldara",
        "chikhali",
        "chinchwad",
        "chiplun",
        "chopda",
        "chumur",
        "dahanu",
        "dapoli",
        "darwaha",
        "daryapur",
        "daund",
        "degloor",
        "delhi Tanda",
        "deogad",
        "deolgaonraja",
        "deori",
        "desaiganj",
        "dhadgaon",
        "dhanora",
        "dharani",
        "dhiwadi",
        "dhule",
        "dhulia",
        "digras",
        "dindori",
        "edalabad",
        "erandul",
        "etapalli",
        "gadhchiroli",
        "gadhinglaj",
        "gaganbavada",
        "gangakhed",
        "gangapur",
        "gevrai",
        "ghatanji",
        "golegaon",
        "gondia",
        "gondpipri",
        "goregaon",
        "guhagar",
        "hadgaon",
        "hatkangale",
        "hinganghat",
        "hingoli",
        "hingua",
        "igatpuri",
        "indapur",
        "islampur",
        "jalgaon",
        "jalna",
        "jamkhed",
        "jamner",
        "jath",
        "jawahar",
        "jintdor",
        "junnar",
        "kagal",
        "kaij",
        "kalamb",
        "kalamnuri",
        "kallam",
        "kalmeshwar",
        "kalwan",
        "kalyan",
        "kamptee",
        "kandhar",
        "kankavali",
        "kannad",
        "karad",
        "karjat",
        "karmala",
        "katol",
        "kavathemankal",
        "kedgaon",
        "khadakwasala",
        "khamgaon",
        "khed",
        "khopoli",
        "khultabad",
        "kinwat",
        "kolhapur",
        "kopargaon",
        "koregaon",
        "kudal",
        "kuhi",
        "kurkheda",
        "kusumba",
        "lakhandur",
        "langa",
        "latur",
        "lonar",
        "lonavala",
        "madangad",
        "madha",
        "mahabaleshwar",
        "mahad",
        "mahagaon",
        "mahasala",
        "mahaswad",
        "malegaon",
        "malgaon",
        "malgund",
        "malkapur",
        "malsuras",
        "malwan",
        "mancher",
        "mangalwedha",
        "mangaon",
        "mangrulpur",
        "manjalegaon",
        "manmad",
        "maregaon",
        "mehda",
        "mekhar",
        "mohadi",
        "mohol",
        "mokhada",
        "morshi",
        "mouda",
        "mukhed",
        "mul",
        "mumbai",
        "murbad",
        "murtizapur",
        "murud",
        "nagbhir",
        "nagpur",
        "nahavara",
        "nanded",
        "nandgaon",
        "nandnva",
        "nandurbar",
        "narkhed",
        "nashik",
        "navapur",
        "ner",
        "newasa",
        "nilanga",
        "niphad",
        "omerga",
        "osmanabad",
        "pachora",
        "paithan",
        "palghar",
        "pali",
        "pandharkawada",
        "pandharpur",
        "panhala",
        "paranda",
        "parbhani",
        "parner",
        "parola",
        "parseoni",
        "partur",
        "patan",
        "pathardi",
        "pathari",
        "patoda",
        "pauni",
        "peint",
        "pen",
        "phaltan",
        "pimpalner",
        "pirangut",
        "poladpur",
        "pune",
        "pusad",
        "pusegaon",
        "radhanagar",
        "rahuri",
        "raigad",
        "rajapur",
        "rajgurunagar",
        "rajura",
        "ralegaon",
        "ramtek",
        "ratnagiri",
        "raver",
        "risod",
        "roha",
        "sakarwadi",
        "sakoli",
        "sakri",
        "salekasa",
        "samudrapur",
        "sangamner",
        "sanganeshwar",
        "sangli",
        "sangola",
        "sanguem",
        "saoner",
        "saswad",
        "satana",
        "satara",
        "sawantwadi",
        "seloo",
        "shahada",
        "shahapur",
        "shahuwadi",
        "shevgaon",
        "shirala",
        "shirol",
        "shirpur",
        "shirur",
        "shirwal",
        "sholapur",
        "shri Rampur",
        "shrigonda",
        "shrivardhan",
        "sillod",
        "sinderwahi",
        "sindhudurg",
        "sindkheda",
        "sindkhedaraja",
        "sinnar",
        "sironcha",
        "soyegaon",
        "surgena",
        "talasari",
        "talegaon S.Ji Pant",
        "taloda",
        "tasgaon",
        "thane",
        "tirora",
        "tiwasa",
        "trimbak",
        "tuljapur",
        "tumsar",
        "udgir",
        "umarkhed",
        "umrane",
        "umrer",
        "urlikanchan",
        "vaduj",
        "velhe",
        "vengurla",
        "vijapur",
        "vita",
        "wada",
        "wai",
        "walchandnagar",
        "wani",
        "wardha",
        "warlydwarud",
        "warora",
        "washim",
        "wathar",
        "yavatmal",
        "yawal",
        "yeola",
        "yeotmal",
      ],
    },
    manipur: {
      districts: [
        "bishnupur",
        "chandel",
        "churachandpur",
        "imphal East",
        "imphal West",
        "jiribam",
        "kakching",
        "kamjong",
        "kangpokpi",
        "noney",
        "pherzawl",
        "senapati",
        "tamenglong",
        "tengnoupal",
        "thoubal",
        "ukhrul",
      ],
      cities: [
        "bishnupur",
        "chakpikarong",
        "chandel",
        "chattrik",
        "churachandpur",
        "imphal",
        "jiribam",
        "kakching",
        "kalapahar",
        "mao",
        "mulam",
        "parbung",
        "sadarhills",
        "saibom",
        "sempang",
        "senapati",
        "sochumer",
        "taloulong",
        "tamenglong",
        "thinghat",
        "thoubal",
        "ukhrul",
      ],
    },
    meghalaya: {
      districts: [
        "east Garo Hills",
        "east Khasi Hills",
        "east Jaintia Hills",
        "eastern West Khasi Hills",
        "north Garo Hills",
        "ri Bhoi",
        "south Garo Hills",
        "south West Garo Hills",
        "south West Khasi Hills",
        "west Garo Hills",
        "west Jaintia Hills",
        "west Khasi Hills",
      ],
      cities: [
        "amlaren",
        "baghmara",
        "cherrapunjee",
        "dadengiri",
        "garo Hills",
        "jaintia Hills",
        "jowai",
        "khasi Hills",
        "khliehriat",
        "mariang",
        "mawkyrwat",
        "nongpoh",
        "nongstoin",
        "resubelpara",
        "ri Bhoi",
        "shillong",
        "tura",
        "williamnagar",
      ],
    },
    mizoram: {
      districts: [
        "aizawl",
        "champhai",
        "hnahthial",
        "khawzawl",
        "kolasib",
        "lawngtlai",
        "lunglei",
        "mamit",
        "saiha",
        "saitual",
        "serchhip",
      ],
      cities: [
        "aizawl",
        "champhai",
        "demagiri",
        "kolasib",
        "lawngtlai",
        "lunglei",
        "mamit",
        "saiha",
        "serchhip",
      ],
    },
    nagaland: {
      districts: [
        "chümoukedima",
        "dimapur",
        "kiphire",
        "kohima",
        "longleng",
        "mokokchung",
        "mon",
        "niuland",
        "noklak",
        "peren",
        "phek",
        "shamator",
        "tseminyü",
        "tuensang",
        "wokha",
        "zunheboto",
      ],
      cities: [
        "dimapur",
        "jalukie",
        "kiphire",
        "kohima",
        "mokokchung",
        "mon",
        "phek",
        "tuensang",
        "wokha",
        "zunheboto",
      ],
    },
    odisha: {
      districts: [
        "angul",
        "boudh",
        "bhadrak",
        "balangir",
        "bargarh",
        "balasore",
        "cuttack",
        "debagarh",
        "dhenkanal",
        "ganjam",
        "gajapati",
        "jharsuguda",
        "jajpur",
        "jagatsinghpur",
        "khordha",
        "kendujhar",
        "kalahandi",
        "kandhamal",
        "koraput",
        "kendrapara",
        "malkangiri",
        "mayurbhanj",
        "nabarangpur",
        "nuapada",
        "nayagarh",
        "puri",
        "rayagada",
        "sambalpur",
        "subarnapur",
        "sundargarh",
      ],
      cities: [
        "anandapur",
        "angul",
        "anugul",
        "aska",
        "athgarh",
        "athmallik",
        "attabira",
        "bagdihi",
        "balangir",
        "balasore",
        "baleswar",
        "baliguda",
        "balugaon",
        "banaigarh",
        "bangiriposi",
        "barbil",
        "bargarh",
        "baripada",
        "barkot",
        "basta",
        "berhampur",
        "betanati",
        "bhadrak",
        "bhanjanagar",
        "bhawanipatna",
        "bhubaneswar",
        "birmaharajpur",
        "bisam Cuttack",
        "boriguma",
        "boudh",
        "buguda",
        "chandbali",
        "chhatrapur",
        "chhendipada",
        "cuttack",
        "daringbadi",
        "daspalla",
        "deodgarh",
        "deogarh",
        "dhanmandal",
        "dharamgarh",
        "dhenkanal",
        "digapahandi",
        "dunguripali",
        "g. Udayagiri",
        "gajapati",
        "ganjam",
        "ghatgaon",
        "gudari",
        "gunupur",
        "hemgiri",
        "hindol",
        "jagatsinghapur",
        "jajpur",
        "jamankira",
        "jashipur",
        "jayapatna",
        "jeypur",
        "jharigan",
        "jharsuguda",
        "jujumura",
        "kalahandi",
        "kalimela",
        "kamakhyanagar",
        "kandhamal",
        "kantabhanji",
        "kantamal",
        "karanjia",
        "kashipur",
        "kendrapara",
        "kendujhar",
        "keonjhar",
        "khalikote",
        "khordha",
        "khurda",
        "komana",
        "koraput",
        "kotagarh",
        "kuchinda",
        "lahunipara",
        "laxmipur",
        "m. Rampur",
        "malkangiri",
        "mathili",
        "mayurbhanj",
        "mohana",
        "motu",
        "nabarangapur",
        "naktideul",
        "nandapur",
        "narlaroad",
        "narsinghpur",
        "nayagarh",
        "nimapara",
        "nowparatan",
        "nowrangapur",
        "nuapada",
        "padampur",
        "paikamal",
        "palla Hara",
        "papadhandi",
        "parajang",
        "pardip",
        "parlakhemundi",
        "patnagarh",
        "pattamundai",
        "phiringia",
        "phulbani",
        "puri",
        "puruna Katak",
        "r. Udayigiri",
        "rairakhol",
        "rairangpur",
        "rajgangpur",
        "rajkhariar",
        "rayagada",
        "rourkela",
        "sambalpur",
        "sohela",
        "sonapur",
        "soro",
        "subarnapur",
        "sunabeda",
        "sundergarh",
        "surada",
        "t. Rampur",
        "talcher",
        "telkoi",
        "titlagarh",
        "tumudibandha",
        "udala",
        "umerkote",
      ],
    },
    puducherry: {
      districts: ["karaikal", "mahé", "puducherry", "yanam"],
      cities: [
        "bahur",
        "karaikal",
        "mahe",
        "pondicherry",
        "purnankuppam",
        "valudavur",
        "villianur",
        "yanam",
      ],
    },
    punjab: {
      districts: [
        "amritsar",
        "barnala",
        "bathinda",
        "firozpur",
        "faridkot",
        "fatehgarh Sahib",
        "fazilka",
        "gurdaspur",
        "hoshiarpur",
        "jalandhar",
        "kapurthala",
        "ludhiana",
        "malerkotla",
        "mansa",
        "moga",
        "sri Muktsar Sahib",
        "pathankot",
        "patiala",
        "rupnagar",
        "sahibzada Ajit Singh Nagar",
        "sangrur",
        "shahid Bhagat Singh Nagar",
        "tarn Taran",
      ],
      cities: [
        "abohar",
        "ajnala",
        "amritsar",
        "balachaur",
        "barnala",
        "batala",
        "bathinda",
        "chandigarh",
        "dasua",
        "dinanagar",
        "faridkot",
        "fatehgarh Sahib",
        "fazilka",
        "ferozepur",
        "garhashanker",
        "goindwal",
        "gurdaspur",
        "guruharsahai",
        "hoshiarpur",
        "jagraon",
        "jalandhar",
        "jugial",
        "kapurthala",
        "kharar",
        "kotkapura",
        "ludhiana",
        "malaut",
        "malerkotla",
        "mansa",
        "moga",
        "muktasar",
        "nabha",
        "nakodar",
        "nangal",
        "nawanshahar",
        "nawanshahr",
        "pathankot",
        "patiala",
        "patti",
        "phagwara",
        "phillaur",
        "phulmandi",
        "quadian",
        "rajpura",
        "raman",
        "rayya",
        "ropar",
        "rupnagar",
        "samana",
        "samrala",
        "sangrur",
        "sardulgarh",
        "sarhind",
        "sAS Nagar",
        "sultanpur Lodhi",
        "sunam",
        "tanda Urmar",
        "tarn Taran",
        "zira",
      ],
    },
    rajasthan: {
      districts: [
        "ajmer",
        "alwar",
        "bikaner",
        "barmer",
        "banswara",
        "bharatpur",
        "baran",
        "bundi",
        "bhilwara",
        "churu",
        "chittorgarh",
        "dausa",
        "dholpur",
        "dungarpur",
        "sri Ganganagar",
        "hanumangarh",
        "jhunjhunu",
        "jalore",
        "jodhpur",
        "jaipur",
        "jaisalmer",
        "jhalawar",
        "karauli",
        "kota",
        "nagaur",
        "pali",
        "pratapgarh",
        "rajsamand",
        "sikar",
        "sawai Madhopur",
        "sirohi",
        "tonk",
        "udaipur",
      ],
      cities: [
        "abu Road",
        "ahore",
        "ajmer",
        "aklera",
        "alwar",
        "amber",
        "amet",
        "anupgarh",
        "asind",
        "aspur",
        "atru",
        "bagidora",
        "bali",
        "bamanwas",
        "banera",
        "bansur",
        "banswara",
        "baran",
        "bari",
        "barisadri",
        "barmer",
        "baseri",
        "bassi",
        "baswa",
        "bayana",
        "beawar",
        "begun",
        "behror",
        "bhadra",
        "bharatpur",
        "bhilwara",
        "bhim",
        "bhinmal",
        "bikaner",
        "bilara",
        "bundi",
        "chhabra",
        "chhipaborad",
        "chirawa",
        "chittorgarh",
        "chohtan",
        "churu",
        "dantaramgarh",
        "dausa",
        "deedwana",
        "deeg",
        "degana",
        "deogarh",
        "deoli",
        "desuri",
        "dhariawad",
        "dholpur",
        "digod",
        "dudu",
        "dungarpur",
        "dungla",
        "fatehpur",
        "gangapur",
        "gangdhar",
        "gerhi",
        "ghatol",
        "girwa",
        "gogunda",
        "hanumangarh",
        "hindaun",
        "hindoli",
        "hurda",
        "jahazpur",
        "jaipur",
        "jaisalmer",
        "jalore",
        "jhalawar",
        "jhunjhunu",
        "jodhpur",
        "kaman",
        "kapasan",
        "karauli",
        "kekri",
        "keshoraipatan",
        "khandar",
        "kherwara",
        "khetri",
        "kishanganj",
        "kishangarh",
        "kishangarhbas",
        "kolayat",
        "kota",
        "kotputli",
        "kotra",
        "kotri",
        "kumbalgarh",
        "kushalgarh",
        "ladnun",
        "ladpura",
        "lalsot",
        "laxmangarh",
        "lunkaransar",
        "mahuwa",
        "malpura",
        "malvi",
        "mandal",
        "mandalgarh",
        "mandawar",
        "mangrol",
        "marwar-Jn",
        "merta",
        "nadbai",
        "nagaur",
        "nainwa",
        "nasirabad",
        "nathdwara",
        "nawa",
        "neem Ka Thana",
        "newai",
        "nimbahera",
        "nohar",
        "nokha",
        "onli",
        "osian",
        "pachpadara",
        "pachpahar",
        "padampur",
        "pali",
        "parbatsar",
        "phagi",
        "phalodi",
        "pilani",
        "pindwara",
        "pipalda",
        "pirawa",
        "pokaran",
        "pratapgarh",
        "raipur",
        "raisinghnagar",
        "rajgarh",
        "rajsamand",
        "ramganj Mandi",
        "ramgarh",
        "rashmi",
        "ratangarh",
        "reodar",
        "rupbas",
        "sadulshahar",
        "sagwara",
        "sahabad",
        "salumber",
        "sanchore",
        "sangaria",
        "sangod",
        "sapotra",
        "sarada",
        "sardarshahar",
        "sarwar",
        "sawai Madhopur",
        "shahapura",
        "sheo",
        "sheoganj",
        "shergarh",
        "sikar",
        "sirohi",
        "siwana",
        "sojat",
        "sri Dungargarh",
        "sri Ganganagar",
        "sri Karanpur",
        "sri Madhopur",
        "sujangarh",
        "taranagar",
        "thanaghazi",
        "tibbi",
        "tijara",
        "todaraisingh",
        "tonk",
        "udaipur",
        "udaipurwati",
        "uniayara",
        "vallabhnagar",
        "viratnagar",
      ],
    },
    sikkim: {
      districts: [
        "east Sikkim",
        "north Sikkim",
        "pakyong",
        "soreng",
        "south Sikkim",
        "west Sikkim",
      ],
      cities: [
        "barmiak",
        "be",
        "bhurtuk",
        "chhubakha",
        "chidam",
        "chubha",
        "chumikteng",
        "dentam",
        "dikchu",
        "dzongri",
        "gangtok",
        "gauzing",
        "gyalshing",
        "hema",
        "kerung",
        "lachen",
        "lachung",
        "lema",
        "lingtam",
        "lungthu",
        "mangan",
        "namchi",
        "namthang",
        "nanga",
        "nantang",
        "naya Bazar",
        "padamachen",
        "pakhyong",
        "pemayangtse",
        "phensang",
        "rangli",
        "rinchingpong",
        "sakyong",
        "samdong",
        "singtam",
        "siniolchu",
        "sombari",
        "soreng",
        "sosing",
        "tekhug",
        "temi",
        "tsetang",
        "tsomgo",
        "tumlong",
        "yangang",
        "yumtang",
      ],
    },
    "tamil Nadu": {
      districts: [
        "ariyalur",
        "chengalpattu",
        "chennai",
        "coimbatore",
        "cuddalore",
        "dharmapuri",
        "dindigul",
        "erode",
        "kallakurichi",
        "kanchipuram",
        "kanyakumari",
        "karur",
        "krishnagiri",
        "madurai",
        "mayiladuthurai",
        "nagapattinam",
        "nilgiris",
        "namakkal",
        "perambalur",
        "pudukkottai",
        "ramanathapuram",
        "ranipet",
        "salem",
        "sivaganga",
        "tenkasi",
        "tiruppur",
        "tiruchirappalli",
        "theni",
        "tirunelveli",
        "thanjavur",
        "thoothukudi",
        "tirupattur",
        "tiruvallur",
        "tiruvarur",
        "tiruvannamalai",
        "vellore",
        "viluppuram",
        "virudhunagar",
      ],
      cities: [
        "ambasamudram",
        "anamali",
        "arakandanallur",
        "arantangi",
        "aravakurichi",
        "ariyalur",
        "arkonam",
        "arni",
        "aruppukottai",
        "attur",
        "avanashi",
        "batlagundu",
        "bhavani",
        "chengalpattu",
        "chengam",
        "chennai",
        "chidambaram",
        "chingleput",
        "coimbatore",
        "courtallam",
        "cuddalore",
        "cumbum",
        "denkanikoitah",
        "devakottai",
        "dharampuram",
        "dharmapuri",
        "dindigul",
        "erode",
        "gingee",
        "gobichettipalayam",
        "gudalur",
        "gudiyatham",
        "harur",
        "hosur",
        "jayamkondan",
        "kallkurichi",
        "kanchipuram",
        "kangayam",
        "kanyakumari",
        "karaikal",
        "karaikudi",
        "karur",
        "keeranur",
        "kodaikanal",
        "kodumudi",
        "kotagiri",
        "kovilpatti",
        "krishnagiri",
        "kulithalai",
        "kumbakonam",
        "kuzhithurai",
        "madurai",
        "madurantgam",
        "manamadurai",
        "manaparai",
        "mannargudi",
        "mayiladuthurai",
        "mayiladutjurai",
        "mettupalayam",
        "metturdam",
        "mudukulathur",
        "mulanur",
        "musiri",
        "nagapattinam",
        "nagarcoil",
        "namakkal",
        "nanguneri",
        "natham",
        "neyveli",
        "nilgiris",
        "oddanchatram",
        "omalpur",
        "ootacamund",
        "ooty",
        "orathanad",
        "palacode",
        "palani",
        "palladum",
        "papanasam",
        "paramakudi",
        "pattukottai",
        "perambalur",
        "perundurai",
        "pollachi",
        "polur",
        "pondicherry",
        "ponnamaravathi",
        "ponneri",
        "pudukkottai",
        "rajapalayam",
        "ramanathapuram",
        "rameshwaram",
        "ranipet",
        "rasipuram",
        "salem",
        "sankagiri",
        "sankaran",
        "sathiyamangalam",
        "sivaganga",
        "sivakasi",
        "sriperumpudur",
        "srivaikundam",
        "tenkasi",
        "thanjavur",
        "theni",
        "thirumanglam",
        "thiruraipoondi",
        "thoothukudi",
        "thuraiyure",
        "tindivanam",
        "tiruchendur",
        "tiruchengode",
        "tiruchirappalli",
        "tirunelvelli",
        "tirupathur",
        "tirupur",
        "tiruttani",
        "tiruvallur",
        "tiruvannamalai",
        "tiruvarur",
        "tiruvellore",
        "tiruvettipuram",
        "trichy",
        "tuticorin",
        "udumalpet",
        "ulundurpet",
        "usiliampatti",
        "uthangarai",
        "valapady",
        "valliyoor",
        "vaniyambadi",
        "vedasandur",
        "vellore",
        "velur",
        "vilathikulam",
        "villupuram",
        "virudhachalam",
        "virudhunagar",
        "wandiwash",
        "yercaud",
      ],
    },
    telangana: {
      districts: [
        "adilabad",
        "bhadradri Kothagudem",
        "hanamkonda",
        "hyderabad",
        "jagtial",
        "jangaon",
        "jayashankar Bhupalpally",
        "jogulamba Gadwal",
        "kamareddy",
        "karimnagar",
        "khammam",
        "kumuram Bheem Asifabad",
        "mahabubabad",
        "mahbubnagar",
        "mancherial",
        "medak",
        "medchal–Malkajgiri",
        "mulugu",
        "nalgonda",
        "narayanpet",
        "nagarkurnool",
        "nirmal",
        "nizamabad",
        "peddapalli",
        "rajanna Sircilla",
        "ranga Reddy",
        "sangareddy",
        "siddipet",
        "suryapet",
        "vikarabad",
        "wanaparthy",
        "warangal",
        "yadadri Bhuvanagiri",
      ],
      cities: [
        "hyderabad",
        "warangal",
        "nizamabad",
        "khammam",
        "karimnagar",
        "ramagundam",
        "mahabubnagar",
        "nalgonda",
        "adilabad",
        "suryapet",
        "miryalaguda",
        "siddipet",
        "jagtial",
        "nirmal",
      ],
    },
    tripura: {
      districts: [
        "dhalai",
        "gomati",
        "khowai",
        "north Tripura",
        "sepahijala",
        "south Tripura",
        "unakoti",
        "west Tripura",
      ],
      cities: [
        "agartala",
        "ambasa",
        "bampurbari",
        "belonia",
        "dhalai",
        "dharam Nagar",
        "kailashahar",
        "kamal Krishnabari",
        "khopaiyapara",
        "khowai",
        "phuldungsei",
        "radha Kishore Pur",
        "tripura",
      ],
    },
    "uttar Pradesh": {
      districts: [
        "agra",
        "aligarh",
        "ambedkar Nagar",
        "amethi",
        "amroha",
        "auraiya",
        "ayodhya",
        "azamgarh",
        "bagpat",
        "bahraich",
        "ballia",
        "balrampur",
        "banda",
        "barabanki",
        "bareilly",
        "basti",
        "bhadohi",
        "bijnor",
        "budaun",
        "bulandshahr",
        "chandauli",
        "chitrakoot",
        "deoria",
        "etah",
        "etawah",
        "farrukhabad",
        "fatehpur",
        "firozabad",
        "gautam Buddha Nagar",
        "ghaziabad",
        "ghazipur",
        "gonda",
        "gorakhpur",
        "hamirpur",
        "hapur",
        "hardoi",
        "hathras",
        "jalaun",
        "jaunpur",
        "jhansi",
        "kannauj",
        "kanpur Dehat",
        "kanpur Nagar",
        "kasganj",
        "kaushambi",
        "kushinagar",
        "lakhimpur Kheri",
        "lalitpur",
        "lucknow",
        "maharajganj",
        "mahoba",
        "mainpuri",
        "mathura",
        "mau",
        "meerut",
        "mirzapur",
        "moradabad",
        "muzaffarnagar",
        "pilibhit",
        "pratapgarh",
        "prayagraj",
        "raebareli",
        "rampur",
        "saharanpur",
        "sambhal",
        "sant Kabir Nagar",
        "shahjahanpur",
        "shamli",
        "shravasti",
        "siddharthnagar",
        "sitapur",
        "sonbhadra",
        "sultanpur",
        "unnao",
        "varanasi",
      ],
      cities: [
        "achhnera",
        "agra",
        "akbarpur",
        "aliganj",
        "aligarh",
        "allahabad",
        "ambedkar Nagar",
        "amethi",
        "amiliya",
        "amroha",
        "anola",
        "atrauli",
        "auraiya",
        "azamgarh",
        "baberu",
        "badaun",
        "baghpat",
        "bagpat",
        "baheri",
        "bahraich",
        "ballia",
        "balrampur",
        "banda",
        "bansdeeh",
        "bansgaon",
        "bansi",
        "barabanki",
        "bareilly",
        "basti",
        "bhadohi",
        "bharthana",
        "bharwari",
        "bhogaon",
        "bhognipur",
        "bidhuna",
        "bijnore",
        "bikapur",
        "bilari",
        "bilgram",
        "bilhaur",
        "bindki",
        "bisalpur",
        "bisauli",
        "biswan",
        "budaun",
        "budhana",
        "bulandshahar",
        "bulandshahr",
        "capianganj",
        "chakia",
        "chandauli",
        "charkhari",
        "chhata",
        "chhibramau",
        "chirgaon",
        "chitrakoot",
        "chunur",
        "dadri",
        "dalmau",
        "dataganj",
        "debai",
        "deoband",
        "deoria",
        "derapur",
        "dhampur",
        "domariyaganj",
        "dudhi",
        "etah",
        "etawah",
        "faizabad",
        "farrukhabad",
        "fatehpur",
        "firozabad",
        "garauth",
        "garhmukteshwar",
        "gautam Buddha Nagar",
        "ghatampur",
        "ghaziabad",
        "ghazipur",
        "ghosi",
        "gonda",
        "gorakhpur",
        "gunnaur",
        "haidergarh",
        "hamirpur",
        "hapur",
        "hardoi",
        "harraiya",
        "hasanganj",
        "hasanpur",
        "hathras",
        "jalalabad",
        "jalaun",
        "jalesar",
        "jansath",
        "jarar",
        "jasrana",
        "jaunpur",
        "jhansi",
        "jyotiba Phule Nagar",
        "kadipur",
        "kaimganj",
        "kairana",
        "kaisarganj",
        "kalpi",
        "kannauj",
        "kanpur",
        "karchhana",
        "karhal",
        "karvi",
        "kasganj",
        "kaushambi",
        "kerakat",
        "khaga",
        "khair",
        "khalilabad",
        "kheri",
        "konch",
        "kumaon",
        "kunda",
        "kushinagar",
        "lalganj",
        "lalitpur",
        "lucknow",
        "machlishahar",
        "maharajganj",
        "mahoba",
        "mainpuri",
        "malihabad",
        "mariyahu",
        "math",
        "mathura",
        "mau",
        "maudaha",
        "maunathbhanjan",
        "mauranipur",
        "mawana",
        "meerut",
        "mehraun",
        "meja",
        "mirzapur",
        "misrikh",
        "modinagar",
        "mohamdabad",
        "mohamdi",
        "moradabad",
        "musafirkhana",
        "muzaffarnagar",
        "nagina",
        "najibabad",
        "nakur",
        "nanpara",
        "naraini",
        "naugarh",
        "nawabganj",
        "nighasan",
        "noida",
        "orai",
        "padrauna",
        "pahasu",
        "patti",
        "pharenda",
        "phoolpur",
        "phulpur",
        "pilibhit",
        "pitamberpur",
        "powayan",
        "pratapgarh",
        "puranpur",
        "purwa",
        "raibareli",
        "rampur",
        "ramsanehi Ghat",
        "rasara",
        "rath",
        "robertsganj",
        "sadabad",
        "safipur",
        "sagri",
        "saharanpur",
        "sahaswan",
        "sahjahanpur",
        "saidpur",
        "salempur",
        "salon",
        "sambhal",
        "sandila",
        "sant Kabir Nagar",
        "sant Ravidas Nagar",
        "sardhana",
        "shahabad",
        "shahganj",
        "shahjahanpur",
        "shikohabad",
        "shravasti",
        "siddharthnagar",
        "sidhauli",
        "sikandra Rao",
        "sikandrabad",
        "sitapur",
        "siyana",
        "sonbhadra",
        "soraon",
        "sultanpur",
        "tanda",
        "tarabganj",
        "tilhar",
        "unnao",
        "utraula",
        "varanasi",
        "zamania",
      ],
    },
    uttarakhand: {
      districts: [
        "almora",
        "bageshwar",
        "chamoli",
        "champawat",
        "dehradun",
        "haridwar",
        "nainital",
        "pauri Garhwal",
        "pithoragarh",
        "rudraprayag",
        "tehri Garhwal",
        "udham Singh Nagar",
        "uttarkashi",
      ],
      cities: [
        "almora",
        "bageshwar",
        "bhatwari",
        "chakrata",
        "chamoli",
        "champawat",
        "dehradun",
        "deoprayag",
        "dharchula",
        "dunda",
        "haldwani",
        "haridwar",
        "joshimath",
        "karan Prayag",
        "kashipur",
        "khatima",
        "kichha",
        "lansdown",
        "munsiari",
        "mussoorie",
        "nainital",
        "pantnagar",
        "partapnagar",
        "pauri Garhwal",
        "pithoragarh",
        "purola",
        "rajgarh",
        "ranikhet",
        "roorkee",
        "rudraprayag",
        "tehri Garhwal",
        "udham Singh Nagar",
        "ukhimath",
        "uttarkashi",
      ],
    },
    "west Bengal": {
      districts: [
        "alipurduar",
        "bankura",
        "birbhum",
        "cooch Behar",
        "dakshin Dinajpur",
        "darjeeling",
        "hooghly",
        "howrah",
        "jalpaiguri",
        "jhargram",
        "kalimpong",
        "kolkata",
        "maldah",
        "murshidabad",
        "nadia",
        "north 24 Parganas",
        "paschim Bardhaman",
        "paschim Medinipur",
        "purba Bardhaman",
        "purba Medinipur",
        "purulia",
        "south 24 Parganas",
        "uttar Dinajpur",
      ],
      cities: [
        "adra",
        "alipurduar",
        "amlagora",
        "arambagh",
        "asansol",
        "balurghat",
        "bankura",
        "bardhaman",
        "basirhat",
        "berhampur",
        "bethuadahari",
        "birbhum",
        "birpara",
        "bishanpur",
        "bolpur",
        "bongoan",
        "bulbulchandi",
        "burdwan",
        "calcutta",
        "canning",
        "champadanga",
        "contai",
        "cooch Behar",
        "daimond Harbour",
        "dalkhola",
        "dantan",
        "darjeeling",
        "dhaniakhali",
        "dhuliyan",
        "dinajpur",
        "dinhata",
        "durgapur",
        "gangajalghati",
        "gangarampur",
        "ghatal",
        "guskara",
        "habra",
        "haldia",
        "harirampur",
        "harishchandrapur",
        "hooghly",
        "howrah",
        "islampur",
        "jagatballavpur",
        "jalpaiguri",
        "jhalda",
        "jhargram",
        "kakdwip",
        "kalchini",
        "kalimpong",
        "kalna",
        "kandi",
        "karimpur",
        "katwa",
        "kharagpur",
        "khatra",
        "krishnanagar",
        "mal Bazar",
        "malda",
        "manbazar",
        "mathabhanga",
        "medinipur",
        "mekhliganj",
        "mirzapur",
        "murshidabad",
        "nadia",
        "nagarakata",
        "nalhati",
        "nayagarh",
        "parganas",
        "purulia",
        "raiganj",
        "rampur Hat",
        "ranaghat",
        "seharabazar",
        "siliguri",
        "suri",
        "takipur",
        "tamluk",
      ],
    },
  };

  const religionList = [
    "hindu",
    "christian",
    "muslim",
    "jain",
    "sikh",
    "buddhist",
  ];
  const motherTongueList = [
    "Hindi",
    "Bengali",
    "Telugu",
    "Marathi",
    "Tamil",
    "Urdu",
    "Gujarati",
    "Kannada",
    "Malayalam",
    "Oriya",
    "Punjabi",
    "Assamese",
    "Maithili",
    "Bhili/Bhilodi",
    "Santali",
    "Kashmiri",
    "Nepali",
    "Gondi",
    "Sindhi",
    "Konkani",
    "Dogri",
    "Khandeshi",
    "Kurukh",
    "Tulu",
    "Meitei/Manipuri",
    "Bodo",
    "Khasi",
    "Mundari",
    "Ho",
    "Garo",
    "Tripuri",
  ];
  let hinduCastes = [
    "24 Manai Telugu Chettiar",
    "aaru Nattu Vellala",
    "achirapakkam Chettiar",
    "adi Dravidar",
    "agamudayar / Arcot / Thuluva Vellala",
    "agaram Vellan Chettiar",
    "ahirwar",
    "arunthathiyar",
    "ayira Vysya",
    "badaga",
    "bairwa",
    "balai",
    "beri Chettiar",
    "boyar",
    "brahmin - Anaviln Desai -",
    "brahmin - Baidhiki/Vaidhiki",
    "brahminBardai",
    "brahminBhargav",
    "brahminGurukkal",
    "brhaminIyengar",
    "brahminIyer",
    "brahmin - Khadayata",
    "brahmin - Khedaval",
    "brahmin - Mevada",
    "brahminOthers",
    "brahmin - Rajgor",
    "brahmin - Rarhi/Radhi",
    "brahmin - Sarua",
    "brahmin - Shri Gaud",
    "brahmin - Tapodhan",
    "brahmin - Valam",
    "brahmin - Zalora",
    "chattada Sri Vaishnava",
    "cherakula Vellalar",
    "chettiar",
    "dasapalanjika / Kannada Saineegar",
    "desikar",
    "desikar Thanjavur",
    "devarThevarMukkalthor",
    "devanga Chettiar",
    "devar/Thevar/Mukkulathor",
    "dhanak",
    "elur Chetty",
    "gandla / Ganiga",
    "gounder",
    "gounder - Kongu Vellala Gounder",
    "gounder - Nattu Gounder",
    "gounder - Others -",
    "gounder - Urali Gounder",
    "gounder - Vanniya Kula Kshatriyar",
    "gounder - Vettuva Gounder",
    "gramani",
    "gurukkal Brahmin",
    "illaththu Pillai",
    "intercaste",
    "isai Vellalar",
    "iyengar Brahmin",
    "iyer Brahmin",
    "julaha",
    "kamma Naidu",
    "kanakkan Padanna",
    "kandara",
    "karkathar",
    "karuneegar",
    "kasukara",
    "kerala Mudali",
    "khatik",
    "kodikal Pillai",
    "kongu Chettiar",
    "kongu Nadar",
    "konguVellalaGounder",
    "kori/Koli",
    "krishnavaka",
    "kshatriya Raju",
    "kulalar",
    "kuravan",
    "kuruhini Chetty",
    "kurumbar",
    "kuruva",
    "manjapudur Chettiar",
    "mannan/Velan / Vannan",
    "maruthuvar",
    "meenavar",
    "meghwal",
    "mudaliyar",
    "mukkulathor",
    "muthuraja / Mutharaiyar",
    "nadar",
    "naicker",
    "naicker - Others",
    "naicker - Vanniya Kula Kshatriyar",
    "naidu",
    "nanjil Mudali",
    "nanjil Nattu Vellalar",
    "nanjil Vellalar",
    "nanjil pillai",
    "nankudi Vellalar",
    "nattu Gounder",
    "nattukottai Chettiar",
    "othuvaar",
    "padmashali",
    "pallan / Devandra Kula Vella",
    "panan",
    "pandaram",
    "pandiya Vellalar",
    "pannirandam Chettiar",
    "paravan / Bharatar",
    "parkavakulam / Udayar",
    "parvatha Rajakulam",
    "paswan / Dusadh",
    "pattinavar",
    "pattusali",
    "pillai",
    "poundra",
    "pulaya/Cheruman",
    "reddy",
    "rohit / Chamar",
    "sC",
    "sT",
    "sadhu Chetty",
    "saiva Pillai Thanjavur",
    "saiva Pillai Tirunelveli",
    "saiva Vellan chettiar",
    "saliyar",
    "samagar",
    "sambava",
    "satnami",
    "senai Thalaivar",
    "senguntha Mudaliyar",
    "sengunthar/Kaikolar",
    "shilpkar",
    "sonkar",
    "sourashtra",
    "sozhia Chetty",
    "sozhiya Vellalar",
    "telugupatti",
    "thandan",
    "thondai Mandala Vellalar",
    "urali Gounder",
    "vadambar",
    "vadugan",
    "valluvan",
    "vaniya Chettiar",
    "vannar",
    "vannia Kula Kshatriyar",
    "veera Saivam",
    "veerakodi Vellala",
    "vellalar",
    "vellan Chettiars",
    "vettuva Gounder",
    "vishwakarma",
    "vokkaliga",
    "yadav",
    "yadava Naidu",
  ];
  let christianCastes = [
    "adventist",
    "anglican Episcopal",
    "anglo-Indian",
    "apostolic",
    "assembly of God (AG)",
    "baptist",
    "born Again",
    "brethren",
    "calvinist",
    "chaldean Syrian (Assyrian)",
    "church of Christ",
    "church of God",
    "church of North India",
    "church of South India",
    "congregational",
    "evangelist",
    "jacobite",
    "jehovah's Witnesses",
    "knanaya",
    "knanaya Catholic",
    "knanaya Jacobite",
    "latin Catholic",
    "latter day saints",
    "lutheran",
    "malabar Independent Syrian Church",
    "malankara Catholic",
    "marthoma",
    "melkite",
    "mennonite",
    "methodist",
    "moravian",
    "orthodox",
    "pentecost",
    "presbyterian",
    "protestant",
    "reformed Baptist",
    "reformed Presbyterian",
    "roman Catholic",
    "seventh-day Adventist",
    "st. Thomas Evangelical",
    "syrian Catholic",
    "syrian Jacobite",
    "syrian Orthodox",
    "syro Malabar",
    "christian - Others",
    "don't wish to specify",
  ];
  let muslimCastes = [
    "muslim - Qureshi",
    "muslim - Rajput",
    "muslim - Rowther",
    "muslim - Shafi",
    "muslim - Sheikh",
    "muslim - Siddiqui",
    "muslim - Syed",
    "muslim - UnSpecified",
    "muslim - Khoja",
    "muslim - Lebbai",
    "muslim - Malik",
    "muslim - Mapila",
    "muslim - Maraicar",
    "muslim - Memon",
    "muslim - Mughal",
    "muslim - Others",
    "muslim - Pathan",
    "muslim - Ansari",
    "muslim - Arain",
    "muslim - Awan",
    "muslim - Bohra",
    "muslim - Dekkani",
    "muslim - Dudekula",
    "muslim - Hanafi",
    "muslim - Jat",
  ];
  let jainCastes = [
    "jain - Agarwal",
    "jain - Asati",
    "jain - Ayodhyavasi",
    "jain - Bagherwal",
    "jain - Bania",
    "jain - Barhiya",
    "jain - Charanagare",
    "jain - Chaturtha",
    "jain - Dhakada",
    "jain - Gahoi/Grihapati",
    "jain - Golalare / Kharaua",
    "jain - Golapurva",
    "jain - Golsinghare",
    "jain - Harada",
    "jain - Humad / Humbad",
    "jain - Intercaste",
    "jain - Jaiswal",
    "jain - KVO",
    "jain - Kambhoja",
    "jain - Kasar",
    "jain - Kathanere",
    "jain - Khandelwal",
    "jain - Kharaua",
    "jain - Kutchi",
    "jain - Lamechu",
    "jain - Nema",
    "jain - Oswal",
    "jain - Others",
    "jain - Padmavati Porwal",
    "jain - Palliwal",
    "jain - Panchama",
    "jain - Parmar",
    "jain - Parwar / Paravara",
    "jain - Porwad / Porwal",
    "jain - Porwal",
    "jain - Saitwal",
    "jain - Samanar / Nayinar",
    "jain - Samiya",
    "jain - Sarak",
    "jain - Shrimal",
    "jain - Unspecified",
    "jain - Upadhyaya",
    "jain - Vaishya",
    "jain - Veerwal",
    "don't wish to specify",
  ];
  let heightList = [
    "4 ft",
    "4 ft 1 inch",
    "4 ft 2 inch",
    "4 ft 3 inch",
    "4 ft 4 inch",
    "4 ft 5 inch",
    "4 ft 6 inch",
    "4 ft 7 inch",
    "4 ft 8 inch",
    "4 ft 9 inch",
    "4 ft 10 inch",
    "4 ft 11 inch",
    "4 ft 12 inch",
    "5 ft",
    "5 ft 2 inch",
    "5 ft 1 inch",
    "5 ft 3 inch",
    "5 ft 4 inch",
    "5 ft 5 inch",
    "5 ft 6 inch",
    "5 ft 7 inch",
    "5 ft 8 inch",
    "5 ft 9 inch",
    "5 ft 10 inch",
    "5 ft 11 inch",
    "5 ft 12 inch",
    "6 ft",
    "6 ft 1 inch",
    "6 ft 2 inch",
    "6 ft 3 inch",
    "6 ft 4 inch",
    "6 ft 5 inch",
    "6 ft 6 inch",
    "6 ft 7 inch",
    "6 ft 8 inch",
    "6 ft 9 inch",
    "6 ft 10 inch",
    "6 ft 11 inch",
    "6 ft 12 inch",
    "7 ft",
    "7 ft 1 inch",
    "7 ft 2 inch",
    "7 ft 3 inch",
    "7 ft 4 inch",
    "7 ft 5 inch",
    "7 ft 6 inch",
    "7 ft 7 inch",
    "7 ft 8 inch",
    "7 ft 9 inch",
    "7 ft 10 inch",
    "7 ft 11 inch",
    "7 ft 12 inch",
  ];
  let weightList = [
    "40 Kgs",
    "41 Kgs",
    "42 Kgs",
    "43 Kgs",
    "44 Kgs",
    "45 Kgs",
    "46 Kgs",
    "47 Kgs",
    "48 Kgs",
    "49 Kgs",
    "50 Kgs",
    "51 Kgs",
    "52 Kgs",
    "53 Kgs",
    "54 Kgs",
    "55 Kgs",
    "56 Kgs",
    "57 Kgs",
    "58 Kgs",
    "59 Kgs",
    "60 Kgs",
    "61 Kgs",
    "62 Kgs",
    "63 Kgs",
    "64 Kgs",
    "65 Kgs",
    "66 Kgs",
    "67 Kgs",
    "68 Kgs",
    "69 Kgs",
    "70 Kgs",
    "71 Kgs",
    "72 Kgs",
    "73 Kgs",
    "74 Kgs",
    "75 Kgs",
    "76 Kgs",
    "77 Kgs",
    "78 Kgs",
    "79 Kgs",
    "80 Kgs",
    "81 Kgs",
    "82 Kgs",
    "83 Kgs",
    "84 Kgs",
    "85 Kgs",
    "86 Kgs",
    "87 Kgs",
    "88 Kgs",
    "89 Kgs",
    "90 Kgs",
    "91 Kgs",
    "92 Kgs",
    "93 Kgs",
    "94 Kgs",
    "95 Kgs",
    "96 Kgs",
    "97 Kgs",
    "98 Kgs",
    "99 Kgs",
    "100 Kgs",
    "101 Kgs",
    "102 Kgs",
    "103 Kgs",
    "104 Kgs",
    "105 Kgs",
    "106 Kgs",
    "107 Kgs",
    "108 Kgs",
    "109 Kgs",
    "110 Kgs",
  ];

  let physicalStatusList = ["normal", "physically Challenged"];
  let educationList = [
    "aeronautical Engineering",
    "b.Arch",
    "bCA",
    "bE",
    "b.Plan",
    "b.Sc IT / Computer Science",
    "b.Tech",
    "b.S. (Engineering)",
    "m.Arch.",
    "mCA",
    "mE",
    "m.Sc. IT / Computer Science",
    "m.S. (Engineering)",
    "m.Tech",
    "pGDCA",
    "aviation Degree",
    "b.A",
    "b.Com",
    "b.Ed.",
    "bFA",
    "bFT",
    "bLIS",
    "b.M.M.",
    "b.Sc.",
    "b.S.W",
    "b.Phil.",
    "m.A",
    "m.Com",
    "m.Ed.",
    "mFA",
    "mLIS",
    "m.Sc.",
    "mSW",
    "m.Phil",
    "bBA",
    "bFA (Financial Management)",
    "bHM (Hotel Management)",
    "bHA / BHM (Hospital Administration)",
    "mBA",
    "mFM (Financial Management)",
    "mHM (Hotel Management)",
    "mHRM (Human Resource Management)",
    "pGDM",
    "mHA / MHM (Hospital Administration)",
    "b.A.M.S.",
    "bDS",
    "bHMS",
    "bSMS",
    "bUMS",
    "bVSc",
    "mBBS",
    "mDS",
    "mD / MS (Medical)",
    "mVSc",
    "mCh",
    "dNB",
    "b.Pharm",
    "bPT",
    "b.Sc. Nursing",
    "m.Pharm",
    "mPT",
    "m.Sc Nursing",
    "bGL",
    "b.L",
    "lL.B",
    "lL.M",
    "m.L.",
    "cA",
    "cFA (Chartered Financial Analyst)",
    "cS",
    "iCWA",
    "iAS",
    "iES",
    "iFS",
    "iRS",
    "iPS",
    "dOCTORATES",
    "ph.D",
    "dM",
    "postdoctoral fellow",
    "fellow of National Board (FNB)",
    "dIPLOMA / OTHERS",
    "diploma",
    "polytechnic",
    "trade School",
    "hIGHER SECONDARY / SECONDARY",
    "higher Secondary School / High School",
    "others",
  ];

  let kulam = {
    "Reddy Arunthathiyar": [
      "Kevviyan",
      "Kamanooran",
      "Yenthalan",
      "Vemparan / Vemparaiyan",
      "Kontakattiyan",
      "Gothapillaiyan",
      "Pantheri",
      "Tiruvannamalaiyan",
      "Thanakontalam",
      "Kattakalalan",
      "Kattaran",
      "Kattakattaalu",
      "Nelapalelu",
      "Mavarathan",
      "Neranjalan / Tharalan",
      "Nanthalaiyan",
      "Kuralan",
      "Vijanagiriyan",
      "Pallagollan",
      "Vattharayadu",
      "Other Optional",
    ],
    "Pannatti Arunthathiyar": [
      "Mattumolu",
      "Pallamolu",
      "Nalimalelu",
      "Kalimalolu",
      "Parathalolu",
      "Kalrolu",
      "Thellakoorelu",
      "Moppurolu",
      "Thasurolu",
      "Pelletolu",
      "Kappurolu",
      "Kuthulurolu",
      "Other",
    ],
    "Murasu Arunthathiyar": [
      "Yerithu Kulam",
      "Azhagar / Alaiyar Kulam",
      "Manjar Kulam",
      "Nagar Kulam",
      "Aantar Kulam",
      "Kadai Kulam",
      "Pooran Koottam",
      "Yemmar Kulam",
      "Sengakannan Kulam",
      "Murasu Koottam",
      "Masarakulam",
      "Kuriyeethu Kulam",
      "Athiyan Kulam",
      "Other",
    ],
    "Anuppa Arunthathiyar": [
      "Sannor",
      "Pothanor",
      "Mooppanor",
      "Yethuppanar",
      "Keerthanar",
      "Olamosharan",
      "Other",
    ],
    "Pathakattiyan - Vattigan": [
      "Santhamuthulu",
      "Pagutugethulu / Pagitikathulu",
      "Sullirivarlu / Sullikarlu",
      "Pittavarlu / Pittakarlu",
      "Konagavar / Konakarlu",
      "Mathigapillivar / Mathigapelli",
      "Peeragavarlu / Peerakatu",
      "Ovilavar / Ovilan / Ovilalu",
      "Settisellivarlu",
      "Pulipothulu",
      "Jallivarlu",
      "Sinthalavarlu",
      "Rama Jothilu",
      "Alli Anthuvar",
      "Sappitavar / Saptikarlu / Sappitan",
      "Utthavar",
      "Rasakavar / Rasakarlu",
      "Kenchakavalu",
      "Kosangivar",
      "Patamasaraluvalu",
      "Kutakalavar",
      "Pallakavar",
      "Nellimvarlu / Nallimvalu",
      "Marikatthuluvar",
      "Thasirivar / Thasarlu",
      "Kommalavar / Komluvarlu",
      "Eantakavar / Eantakarlu",
      "Vattikavar / Vattikan / Vattukulu",
      "Eeramvaru",
      "Paarvar / Parlikarlu",
      "Kummitivar / Kummitiyan / Kummitivalu",
      "Nellampullavar",
      "Earramvar / Earamalavatu / Earamalavarlu",
      "Neelamvarlu",
      "Jonakavar",
      "Rama Jokiluvar",
      "Sathilvar / Sathuluvarlu",
      "Soothagavar / Soothakarlu",
      "Bothuvar",
      "Pegatavar / Pagatakarlu",
      "Paliyavar",
      "Pathagattiyan / Pathakattulu",
      "Kuntalavar / Kuntakarlu",
      "Murrakaru / Murkaru",
      "Mitukarlu / Mitakarlu",
      "Janakarlu / Janakan",
      "Mellakatu / Mellakan",
      "Thiva kootu / Thivva Koottan",
      "Palakarlu",
      "Pangittarlu / Pangittan",
      "Eallettulu",
      "Naramuthulu",
      "Alughuvarlu / Alughuvar",
      "Sullalvarlu",
      "Vandikarlu",
      "Thosakararlu",
      "Kothikethulu",
      "Sittakaralu",
      "Puthakaralu",
      "Kambuguthi kaatu",
      "Mandhapellai",
      "Sappa Rarlu / Sappal",
      "Koshakarlu / Koshakavar",
      "Puritala",
      "Sillapan",
      "Muthikarlu",
      "Podu Thasa Rarlu",
      "Ittu Thasa Rarlu",
      "Puduthu Thasa Rarlu",
      "Kaali Nadhu",
      "Mallikalu",
      "Nettimallumulu",
      "Poolagaaru",
      "Thottakaaru",
      "Mothamokkaluvar",
      "Kolmamaru",
      "Savakeedthulu",
      "Rathankatu",
      "Poovaluru",
      "Kammalavarlu",
      "Kakagattu",
      "Mitakaarlu",
      "Pulagattu",
      "Madakalavar",
      "Jonnakonda",
      "Masalavar / Masaluvar",
      "Jothikalu",
      "Kuyyaluvaru",
      "Vetagarlu",
      "Kaaseekar",
      "Chattalugaaru",
      "Jadhikaaru",
      "Muthukadathanu",
      "Thidakaaru",
      "Sauligaaru",
      "Kaartadu",
      "Kaaraalu",
      "Sama Joki",
      "Seeyam Pokkal",
      "Narithivarlu",
      "Pachakulatdu",
      "Monakaaru",
      "Ponmalavarlu",
      "Matravai",
    ],
    "Avva Pirivu": [
      "Pullanadu",
      "Moganadu",
      "Pagutikethedu",
      "Eanjanadu",
      "Patchedu",
      "Maravanadu",
      "Kenjavadu",
      "Soyyakadu",
      "Kommaladu",
      "Eeravadu / Eravadu",
      "Pangitadu",
      "Kalavanadu",
      "Palikadu",
      "Oviladu",
      "Mudikinadu",
      "Pantedu",
      "Poolanadu",
      "Soothaladu",
      "Deepanadu",
      "Mitukinadu",
      "Marikatthanadu",
      "Bothuvadu",
      "Ketthanadu",
      "Other",
    ],
  };
  let sikhCastes = [
    "sikh - Ahluwalia",
    "sikh - Arora",
    "sikh - Bhatia",
    "sikh - Bhatra",
    "sikh - Ghumar",
    "sikh - Intercaste",
    "sikh - Jat",
    "sikh - Kamboj",
    "sikh - Khatri",
    "sikh - Kshatriya",
    "sikh - Lubana",
    "sikh - Majabi",
    "sikh - Nai",
    "sikh - Others",
    "sikh - Rai",
    "sikh - Rajput",
    "sikh - Ramdasia",
    "sikh - Ramgharia",
    "sikh - Ravidasia",
    "sikh - Saini",
    "sikh - Tonk Kshatriya",
    "sikh - Unspecified",
    "don't wish to specify",
  ];
  let buddhistCastes = [
    "neo Buddhist",
    "others",
    "tibetan Buddhist",
    "others",
    "don't wish to specify",
  ];
  const maritalstatusList = [
    "Never Married",
    "Married",
    "Seprated",
    "Divorced",
    "Widow/Widower",
  ];
  const annualIncomeList = [
    "0 - 1 Lakh",
    "1 - 2 Lakhs",
    "2 - 3 Lakhs",
    "3 - 4 Lakhs",
    "4 - 5 Lakhs",
    "5 - 6 Lakhs",
    "6 - 7 Lakhs",
    "7 - 8 Lakhs",
    "8 - 9 Lakhs",
    "9 - 10 Lakhs",
    "10 - 12 Lakhs",
    "12 - 14 Lakhs",
    "14 - 16 Lakhs",
    "16 - 18 Lakhs",
    "18 - 20 Lakhs",
    "20 - 25 Lakhs",
    "25 - 30 Lakhs",
    "30 - 35 Lakhs",
    "35 - 40 Lakhs",
    "40 - 45 Lakhs",
    "45 - 50 Lakhs",
    "50 - 60 Lakhs",
    "60 - 70 Lakhs",
    "70 - 80 Lakhs",
    "80 - 90 Lakhs",
    "90 - 100 Lakhs",
    "1 Cr & Above",
  ];
  let employedInList = [
    "government / PSU",
    "defence",
    "private",
    "business",
    "self Employed",
    "not Working",
  ];
  let occupationList = [
    "manager",
    "supervisor",
    "officer",
    "administrative Professional",
    "executive",
    "clerk",
    "human Resources Professional",
    "secretary / Front Officer",
    "agriculture & Farming Professional",
    "horticulture",
    "pilot",
    "air Hostess / Flight Attendant",
    "airline Professional",
    "architect",
    "interior Designer",
    "chartered Accountant",
    "company Secretary",
    "account Financial Professional",
    "banking Professional",
    "auditor",
    "financial Accountants",
    "financial Analyst / Planning",
    "investment Professional",
    "fashion Designer",
    "beautician",
    "hair Stylists",
    "jewellery Designer",
    "makeup Artist",
    "designer (Others)",
    "bPO / KPO / ITES / Professional",
    "customer Service Professional",
    "cIVIL SERVICES",
    "civil Services (IAS / IPS / IRS / IES / IFS)",
    "analyst",
    "consultant",
    "corporate Communication",
    "corporate Planning",
    "marketing Professional",
    "operation Management",
    "sales Professional",
    "senior Professional",
    "senior Manager / Manager",
    "subject Matter Expert",
    "business Development Professional",
    "content Writer",
    "army",
    "navy",
    "air Force",
    "paramilitary",
    "defence Services (Other)",
    "doctor",
    "dentist",
    "surgeon",
    "veterinary Doctor",
    "professor / Lecturer",
    "teaching / Academician",
    "education Professional",
    "training Professional",
    "research Assistant",
    "research Scholar",
    "civil Engineering",
    "electronics / Telecom Engineer",
    "mechanical / Production Engineer",
    "quality Assurance Engineer - Non IT",
    "engineer - Non IT",
    "designer",
    "product Manager - Non IT",
    "project Manager - Non IT",
    "hotel / Hospitality Professional",
    "restaurant / Catering Professional",
    "chef / Cook",
    "software Professional",
    "hardware Professional",
    "product Manager",
    "project Manager",
    "program Manager",
    "animator",
    "cyber / Network Security",
    "web / Graphic Designer",
    "software Consultant",
    "data Analyst",
    "data Scientist",
    "network Engineer",
    "quality Assurance Engineer",
    "lawyer & Legal Professional",
    "legal Assistant",
    "journalist",
    "media Professional",
    "entertainment Professional",
    "event Management Professional",
    "advertising / PR Professional",
    "designer",
    "actor / Model",
    "artist",
    "healthcare Professional",
    "paramedical Professional",
    "nurse",
    "pharmacist",
    "physiotherapist",
    "psychologist",
    "therapist",
    "medical Transcriptionist",
    "dietician / Nutrition",
    "lab Technician",
    "medical Representative",
    "mariner / Merchant Navy",
    "sailor",
    "oTHERS",
    "technician",
    "arts / Craftsman",
    "librarian",
    "business Owner / Entrepreneur",
    "transportation / Logistics Professional",
    "agent / Broker / Trader",
    "contractor",
    "fitness Professional",
    "security Professional",
    "social Worker / Volunteer / NGO",
    "sportsperson",
    "travel Professional",
    "singer",
    "writer",
    "politician",
    "associate",
    "builder",
    "chemist",
    "cNC Operator",
    "distributor",
    "driver",
    "freelancer",
    "mechanic",
    "musician",
    "photo / Videographer",
    "surveyor",
    "tailor",
    "law Enforcement Officer",
    "police",
    "scientist / Researcher",
    "cXO / President, Director, Chairman",
    "vP / AVP / GM / DGM / AGM",
  ];
  let zodiacStarList = [
    "ashwini",
    "barani",
    "karthikai",
    "rohini",
    "mirugasiridam",
    "thiruvadhirai",
    "punarpoosam",
    "poosam",
    "ayilyam",
    "magam",
    "pooram",
    "uthiram",
    "astham",
    "chithirai",
    "swathi",
    "visagam",
    "anusham",
    "kettai",
    "mulam",
    "puradam",
    "uthiradam",
    "thiruvonam",
    "avittam",
    "sadayam",

    "purattadhi",
    "uttrttadhi",
    "revathi",
  ];
  let dhosamList = [
    "no Dhosam",
    "chevvai Dhosam",
    "rahu Dhosam",
    "kethu Dhosam",
    "kalathra Dhosham",
    "kala Sarpa Dhosam",
    "naga Dosham",
    "don't know",
  ];
  const smoke = [
    "Never Smokes",
    "Smoke Occasionally",
    "Smoke Regularly",
    "Not Specified",
  ];
  const drink = [
    "Never Drinks",
    "Drinks Socially",
    "Drinks Regularly",
    "Not Specified",
  ];
  const financialStatus = [
    "Rich",
    "Very Rich",
    " Upper Middle class",
    "Middle class",
    "Lower Middle class",
    "Affluent",
  ];
  const eat = ["Non-Vegetarian", "Vegetarian", "Eggetarian", "Not Specified"];
  const [data, setData] = useState({
    age: "",
    motherTongue: "",
    religion: "",
    // caste: "",
    // subCaste: "",
    maritalStatus: "",
    country: "",
    state: "",
    district: "",
    city: "",
    height: "",

    physicalStatus: "",
    education: "",
    employedIn: "",
    occupation: "",
    annualIncome: "",
    zodiacStar: "",
    dhosam: "",
    familyStatus: "",
    smokingHabits: "",
    drinkingHabits: "",
    eatingHabits: "",
  });
  const [render, setRender] = useState(false);
  let { id } = useParams();
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  function handleSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();
    const fullData = { ...data, id: Number(id) };

    api.post("/admin/manageUserProfile/addPartner", fullData).then((res) => {
      setRender(!render);
      setData("");
      Swal.fire(res.data.data).then(() => {
        history.goBack(); // Navigate back to the previous page after Swal confirmation
      });
    });
  }
  useEffect(() => {
    if (id) {
      api
        .post("admin/adminDashboard/userFullDetails", { id: id })
        .then((res) => {
          if (res.data.data !== "Data not found") {
            const partnerDetails = res.data.data.partnerDetails;
            setData((prevData) => ({
              ...prevData,
              age: partnerDetails.age,
              religion: partnerDetails.religion,
              // caste: partnerDetails.caste || prevData.caste,
              // subCaste: partnerDetails.subCaste || prevData.subCaste,
              maritalStatus: partnerDetails.maritalStatus,
              motherTongue: partnerDetails.motherTongue,
              country: partnerDetails.country,
              state: partnerDetails.state,
              district: partnerDetails.district || prevData.district,
              city: partnerDetails.city,
              height: partnerDetails.height,
              physicalStatus: partnerDetails.physicalStatus,
              education: partnerDetails.education,
              employedIn: partnerDetails.employedIn,
              occupation: partnerDetails.occupation,
              annualIncome: partnerDetails.annualIncome,
              zodiacStar: partnerDetails.zodiacStar,
              dhosam: partnerDetails.dhosam,
              smokingHabits: partnerDetails.smokingHabits,
              drinkingHabits: partnerDetails.drinkingHabits,
              eatingHabits: partnerDetails.eatingHabits,
              familyStatus: partnerDetails.familyStatus,
            }));
          }
        });
    }
  }, [id]);
  // console.log(data);
  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title"  style={{fontWeight:"600"}}>Update Partner Details</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <div className="content-body">
                  <Form onSubmit={handleSubmit} className="form p-4">
                    <Box sx={{ minWidth: 120 }}>
                      <FormGroup>
                        <Label for="height"  style={{fontWeight:"600"}}>Height</Label>
                        <Input
                          name="height"
                          value={data.height}
                          type="select"
                          id="height"
                          onChange={handleChange}
                        >
                          <option value="">Select Height</option>

                          {heightList.map((x) => {
                            return <option value={x}>{x} </option>;
                          })}
                        </Input>
                      </FormGroup>{" "}
                      <FormGroup>
                        <Label for=""  style={{fontWeight:"600"}}>Age</Label>
                        <Input
                          name="age"
                          value={data.age}
                          placeholder="Enter Partner age"
                          type="text"
                          id="age"
                          onChange={handleChange}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="maritalStatus"  style={{fontWeight:"600"}}>Select Marital Status</Label>
                        <Input
                          name="maritalStatus"
                          value={data.maritalStatus}
                          type="select"
                          id="maritalStatus"
                          onChange={handleChange}
                        >
                          <option value="">Select MaritalStatus</option>

                          {maritalstatusList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="mothertongue" style={{fontWeight:"600"}}>
                          Select your mother tongue
                        </Label>
                        <Input
                          name="motherTongue"
                          value={data.motherTongue}
                          type="select"
                          id="mothertongue"
                          onChange={handleChange}
                          style={{ textTransform: "capitalize" }}
                        >
                          <option value="">Select MotherTongue</option>
                          {motherTongueList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="physicalStatus" style={{fontWeight:"600"}}>Physical Status</Label>
                        <Input
                          name="physicalStatus"
                          value={data.physicalStatus}
                          type="select"
                          id="physicalStatus"
                          onChange={handleChange}
                          style={{ textTransform: "capitalize" }}
                        >
                          {physicalStatusList.map((x) => {
                            return <option value={x}>{x} </option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="eatingHabit" style={{fontWeight:"600"}}>Select Eating Habit</Label>
                        <Input
                          name="eatingHabits"
                          value={data.eatingHabits}
                          type="select"
                          id="eatingHabit"
                          onChange={handleChange}
                        >
                          <option value="">Select eatingHabit</option>

                          {eat.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>{" "}
                      <FormGroup>
                        <Label for="drinkingHabit" style={{fontWeight:"600"}}>Select Drinking Habit</Label>
                        <Input
                          name="drinkingHabits"
                          value={data.drinkingHabits}
                          type="select"
                          id="drinkingHabit"
                          onChange={handleChange}
                        >
                          <option value="">Select Drinking habit</option>

                          {drink.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="smokingHabit" style={{fontWeight:"600"}}>Select Smoking Habit</Label>
                        <Input
                          name="smokingHabits"
                          value={data.smokingHabits}
                          type="select"
                          id="smokingHabit"
                          onChange={handleChange}
                        >
                          <option value="">Select smoking habit</option>

                          {smoke.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                     
                      <FormGroup>
                        <Label for="religion" style={{fontWeight:"600"}}>Religion</Label>
                        <Input
                          name="religion"
                          onChange={handleChange}
                          type="select"
                          value={data.religion}
                          id="religion"
                          style={{ textTransform: "capitalize" }}
                        >
                          <option value="">Select Religion</option>

                          {religionList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                    
                      <FormGroup>
                        <Label for="dhosam" style={{fontWeight:"600"}}>Dhosam</Label>
                        <Input
                          name="dhosam"
                          value={data.dhosam}
                          type="select"
                          id="dhosam"
                          style={{ textTransform: "capitalize" }}
                          onChange={handleChange}
                        >
                          {" "}
                          <option value="">Select Dhosam</option>
                          {dhosamList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="zodiacStar" style={{fontWeight:"600"}}>Zodiac Star</Label>
                        <Input
                          name="zodiacStar"
                          value={data.zodiacStar}
                          type="select"
                          id="zodiacStar"
                          style={{ textTransform: "capitalize" }}
                          onChange={handleChange}
                        >
                          {" "}
                          <option value="">Select ZodiacStar</option>
                          {zodiacStarList.map((x) => {
                            return <option value={x}>{x}</option>;
                          })}
                        </Input>
                      </FormGroup>
                      <>
                        <FormGroup>
                          <Label for="countryLivingIn" style={{fontWeight:"600"}}>Country living in</Label>
                          <Input
                            name="country"
                            value={data.country}
                            type="select"
                            id="countryLivingIn"
                            onChange={handleChange}
                          >
                            <option value="">Select country</option>

                            <option value="India">India</option>
                            <option value="Abroad">Abroad</option>
                          </Input>
                        </FormGroup>

                        {data.country === "India" ? (
                          <>
                            <FormGroup>
                              <Label for="state" style={{fontWeight:"600"}}>State Living in</Label>
                              <Input
                                name="state"
                                value={data.state}
                                type="select"
                                id="state"
                                style={{ textTransform: "capitalize" }}
                                onChange={handleChange}
                              >
                                <option value="">Select State</option>

                                {Object.keys(stateData).map((state) => (
                                  <option key={state} value={state}>
                                    {state}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                            
                            <FormGroup>
                              <Label for="cityLivingIn" style={{fontWeight:"600"}}>City Living in</Label>
                              <Input
                                name="city"
                                value={data.city}
                                type="select"
                                id="cityLivingIn"
                                style={{ textTransform: "capitalize" }}
                                onChange={handleChange}
                              >
                                <option value="">Select City</option>

                                {stateData[data.state] &&
                                  stateData[data.state].cities &&
                                  stateData[data.state].cities.map((city) => (
                                    <option key={city} value={city}>
                                      {city}
                                    </option>
                                  ))}
                              </Input>
                            </FormGroup>
                          </>
                        ) : (
                          <>
                            <FormGroup>
                              <Label for="countryName" style={{fontWeight:"600"}}>Country Name</Label>
                              <Input
                                name="country"
                                value={data.country}
                                placeholder="Enter your country name"
                                type="text"
                                id="countryName"
                                onChange={handleChange}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="state" style={{fontWeight:"600"}}>State Living in</Label>
                              <Input
                                name="state"
                                value={data.state}
                                placeholder="Enter your state"
                                type="text"
                                id="state"
                                onChange={handleChange}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="districtLivingIn" style={{fontWeight:"600"}}>
                                District Living in
                              </Label>
                              <Input
                                name="district"
                                value={data.district}
                                placeholder="Enter your district"
                                type="text"
                                id="districtLivingIn"
                                onChange={handleChange}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="cityLivingIn" style={{fontWeight:"600"}}>City Living in</Label>
                              <Input
                                name="city"
                                value={data.city}
                                placeholder="Enter your city"
                                type="text"
                                id="cityLivingIn"
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </>
                        )}
                      </>
                      <FormGroup>
                        <Label for="education" style={{fontWeight:"600"}}>Education</Label>
                        <Input
                          name="education"
                          value={data.education}
                          type="select"
                          id="education"
                          style={{ textTransform: "capitalize" }}
                          onChange={handleChange}
                        >
                          {educationList.map((x) => {
                            return <option value={x}>{x} </option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="employedIn" style={{fontWeight:"600"}}>Employed in</Label>
                        <Input
                          name="employedIn"
                          value={data.employedIn}
                          type="select"
                          id="employedIn"
                          style={{ textTransform: "capitalize" }}
                          onChange={handleChange}
                        >
                          <option value="">Select Employee in</option>

                          {employedInList.map((x) => {
                            return <option value={x}>{x} </option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="occupation" style={{fontWeight:"600"}}>Occupation</Label>
                        <Input
                          name="occupation"
                          value={data.occupation}
                          type="select"
                          id="occupation"
                          style={{ textTransform: "capitalize" }}
                          onChange={handleChange}
                        >
                          <option value="">Select Occupation</option>

                          {occupationList.map((x) => {
                            return <option value={x}>{x} </option>;
                          })}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="annualIncome" style={{fontWeight:"600"}}>
                          Annual Income (In Rupees)
                        </Label>
                        <Input
                          name="annualIncome"
                          value={data.annualIncome}
                          type="select"
                          id="annualIncome"
                          style={{ textTransform: "capitalize" }}
                          onChange={handleChange}
                        >
                          <option value="">Select Annual Income</option>

                          {annualIncomeList.map((x) => {
                            return <option value={x}>{x} </option>;
                          })}
                        </Input>
                      </FormGroup>
                      <Button style={{ background: "black" }}>Submit</Button>
                    </Box>
                  </Form>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UpdatePartner;
