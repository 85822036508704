import React, { useEffect, useState, useRef } from "react";
import { Form, FormGroup, Label, Input, Row, Col, Table } from "reactstrap";
import Swal from "sweetalert2";
import Cropper from "./Cropper";
import api, { baseURL } from "../Axios";
import NotificationAlert from "react-notification-alert";

const Notification = () => {
  const notiRef = useRef();
  const [data, setData] = useState({
    msgTitle: "",
    msgLink: "",
    msgDesc: "",
    image: "",
  });

  const [render, setRender] = useState(false);

  const [notification, setNotification] = useState([]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    api.get("/admin/adminDashboard/viewPushMessage").then((res) => {
      setNotification(res.data.data);
    });
  }, [render]);

  function handleImageChange(e) {
    // console.log("image", e);
    setData({ ...data, image: e });
  }

  function handleSubmit(e) {
    e.preventDefault();
    e.currentTarget.reset();
    const formdata = new FormData();
    Object.entries(data).map((data) => {
      formdata.append(data[0], data[1]);
  
    });

    api.post("/admin/adminDashboard/addPushMessage", formdata).then((res) => {
      setRender(!render);
      setData("");
      Swal.fire("Added!", "Notification has been Added.", "success");
    });
  }

  return (
    <div>
      <div className="content">
        <div className="notification-popup">
          <NotificationAlert ref={notiRef} />
        </div>
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Notification</h1>
              </div>
            </div>

            <div className="col-12">
              <section className="box ">
                <header className="panel_header">
                  <h1
                    className="title float-left"
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "500",
                    }}
                  >
                    Add Notification
                  </h1>
                </header>
                <div className="content-body">
                  <div className="row">
                    <div className="col-12 ">
                      <Form onSubmit={handleSubmit}>
                        <FormGroup>
                          <Label>Title</Label>
                          <Input
                            onChange={handleChange}
                            type="text"
                            name="msgTitle"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Links</Label>
                          <Input
                            onChange={handleChange}
                            type="text"
                            name="msgLink"
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label>Description</Label>
                          <Input
                            onChange={handleChange}
                            type="text"
                            name="msgDesc"
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label
                            htmlFor="NotiificationImage"
                            style={{
                              color: "#000",
                              fontWeight: "500",
                              fontSize: "16px",
                            }}
                          >
                            Notification Image
                          </Label>
                          <Cropper
                            imageStore={handleImageChange}
                            aspectRatio={16 / 9}
                            reset={render}
                          />
                        </FormGroup>

                        <FormGroup style={{ marginBottom: "0px" }}>
                          <button
                            type="submit"
                            className="btn "
                            style={{
                              padding: "5px 10px",
                              background: "black",
                              color: "white",
                              fontWeight: "500",
                            }}
                          >
                            Submit
                          </button>
                        </FormGroup>
                      </Form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <Row>
              <div className="container-fluid p-3 mt-5">
                <section className="box ">
                  <header className="panel_header">
                    <h1
                      className="ml-3"
                      style={{
                        textTransform: "capitalize",
                        fontWeight: "500",
                      }}
                    >
                      Notification List
                    </h1>
                  </header>
                  <Table>
                    <thead
                      style={{
                        background: "black",
                        color: "white",
                      }}
                    >
                      <tr>
                        <th style={{ textAlign: "center" }}>S No</th>
                        <th style={{ textAlign: "center" }}>Title</th>
                        <th style={{ textAlign: "center" }}>Link</th>
                        <th style={{ textAlign: "center" }}> Description</th>
                        <th style={{ textAlign: "center" }}>Image</th>
                        {/* <th style={{ textAlign: "center" }}>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(notification) &&
                      notification.length > 0 ? (
                        notification.map((notification, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",
                              }}
                            >
                              {index + 1}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              {notification.msgTitle}
                            </td>

                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              {notification.msgLink}
                            </td>

                            <td
                              style={{
                                textAlign: "center",
                                color: "#000",
                                fontWeight: "500",
                                textTransform: "capitalize",
                              }}
                            >
                              {notification.msgDesc}
                            </td>

                            <td style={{ textAlign: "center" }}>
                              <img
                                src={baseURL + notification.msgImage}
                                style={{ height: "100px", width: "150px" }}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div>
                          <p>Notification Not Found</p>
                        </div>
                      )}
                    </tbody>
                  </Table>
                </section>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Notification;
