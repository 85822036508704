import React from "react";
import { Row, Col } from "reactstrap";
import { useEffect } from "react";
import api from "../../../Axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  // FiUsers,
  FiUserCheck,
  FiUserX,
  // FiUser,
  // FiWifi,
  // FiHash,
  // FiMessageSquare,
  // FiCloudDrizzle
} from "react-icons/fi";
import { SlWallet } from "react-icons/sl";
import {
  BsChatLeftTextFill,
  BsGridFill,
  BsPeopleFill,
  BsPersonDashFill,
  BsPersonCheckFill,
  BsPersonFill,
  BsGenderMale,
  BsGenderFemale,
  BsPersonBadge,
  BsCalendar,
  BsCalendar2,
  BsCalendar2Day,
  BsCash,
  BsCashStack,
} from "react-icons/bs";
import { BsPersonArmsUp } from "react-icons/bs";
// import { BiRupee , } from "react-icons/bi";
import Usercard from "../../../components/Usercard";
import DashboardCard from "../../../components/DashboardCard";

const Dashboard9 = () => {
  const [render, setRender] = useState(false);
  const [getuser, setGetuser] = useState([]);
  const [allUsers, setAllUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [inactiveUsers, setInactiveUsers] = useState(0);
  const [paidUsers, setPaidUsers] = useState(0);
  const [unpaidUsers, setUnpaidUsers] = useState(0);
  const [succeseUsers, setSucceseUsers] = useState(0); // Note: Probably meant "successUsers"
  const [smsCount, setSmsCount] = useState(0);
  const [femaleCount, setFemaleCount] = useState(0);
  const [maleCount, setMaleCount] = useState(0);
  const [oneMonthCount, setOneMonthCount] = useState(0);
  const [oneMonthEarnings, setOneMonthEarnings] = useState(0);
  const [oneMonthMembers, setOneMonthMembers] = useState(0);
  const [tenDaysCount, setTenDaysCount] = useState(0);
  const [todayCount, setTodayCount] = useState(0);
  const [paymentCount, setPaymentCount] = useState(0);
  const [horoscopeApiCount, setHoroscopeApiCount] = useState(0);

  useEffect(() => {
    api.get("/admin/adminDashboard/count").then((res) => {
      const data = res.data.data;

      setAllUsers(Array.isArray(data.allUsers) ? data.allUsers.length : 0);
      setActiveUsers(
        Array.isArray(data.activeUsers) ? data.activeUsers.length : 0
      );
      setInactiveUsers(
        Array.isArray(data.inactiveUsers) ? data.inactiveUsers.length : 0
      );
      setPaidUsers(Array.isArray(data.paidUsers) ? data.paidUsers.length : 0);
      setUnpaidUsers(
        Array.isArray(data.unpaidUsers) ? data.unpaidUsers.length : 0
      );
      setSucceseUsers(
        Array.isArray(data.succeseUsers) ? data.succeseUsers.length : 0
      ); // Note: Probably meant "successUsers"
      setSmsCount(Array.isArray(data.smsCount) ? data.smsCount.length : 0);
      setFemaleCount(
        Array.isArray(data.femaleCount) ? data.femaleCount.length : 0
      );
      setMaleCount(Array.isArray(data.maleCount) ? data.maleCount.length : 0);
      setOneMonthCount(
        Array.isArray(data.oneMonthCount) ? data.oneMonthCount.length : 0
      );
      setOneMonthEarnings(data.oneMonthEarnings);
      setOneMonthMembers(
        Array.isArray(data.oneMonthMembers) ? data.oneMonthMembers.length : 0
      );
      setTenDaysCount(
        Array.isArray(data.tenDaysCount) ? data.tenDaysCount.length : 0
      );
      setTodayCount(
        Array.isArray(data.todayCount) ? data.todayCount.length : 0
      );
      setPaymentCount(
        Array.isArray(data.paymentCount) ? data.paymentCount.length : 0
      );
      setHoroscopeApiCount(data.horoscopeApiCount);
    });
  }, []);

  useEffect(() => {
    api.get("/admin/allUsers/getRecentUsers").then((res) => {
      setGetuser(res.data.data);

      Array.isArray(res.data.data) && res.data.data.map((dat) => dat.images);
    });
  }, []);
  // function verify(i) {
  //   api.post("/admin/adminDashboard/imageVerified", { id: i.id }).then(() => {
  //     api.get("/admin/allUsers/getRecentUsers").then((res) => {
  //       setGetuser(res.data.data);
  //     });
  //   });
  // }
  function deletee(i) {
    api
      .post("/admin/manageuserProfile/terminateUser", { id: i.id })
      .then(() => {
        api.get("/admin/allUsers/getAllUsers").then((res) => {
          setGetuser(res.data.data);
        });
      });
  }
  function activeinactive(i) {
    api.post("/admin/manageuserProfile/deleteUser", { id: i.id }).then(() => {
      api.get("/admin/allUsers/getAllUsers").then((res) => {
        setGetuser(res.data.data);
      });
    });
  }
  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Dashboard</h1>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/dashboardUsers/paymentCount`}>
                  <DashboardCard
                    title={"Payments"}
                    count={"₹ " + paymentCount}
                    icon={<SlWallet className="icon-lg" />}
                  />
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/dashboardUsers/smsCount`}>
                  <DashboardCard
                    title={"SMS Counts"}
                    count={smsCount}
                    icon={<BsChatLeftTextFill className="icon-lg" />}
                  />
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <DashboardCard
                  title={"Horoscope API Hit Count"}
                  count={horoscopeApiCount}
                  icon={<BsGridFill className="icon-lg" />}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/dashboardUsers/allUsers`}>
                  <DashboardCard
                    title={"All Users"}
                    count={allUsers}
                    icon={<BsPersonFill className="icon-lg" />}
                  />
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/dashboardUsers/activeUsers`}>
                  <DashboardCard
                    title={"Active Users"}
                    count={activeUsers}
                    icon={<FiUserCheck className="icon-lg" />}
                  />
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/dashboardUsers/inactiveUsers`}>
                  <DashboardCard
                    title={"Inactive Users"}
                    count={inactiveUsers}
                    icon={<FiUserX className="icon-lg" />}
                  />
                </Link>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/dashboardUsers/paidUsers`}>
                  <DashboardCard
                    title={"Paid Users"}
                    count={paidUsers}
                    icon={<BsPersonCheckFill className="icon-lg" />}
                  />
                </Link>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/dashboardUsers/unpaidUsers`}>
                  <DashboardCard
                    title={"Unpaid Users"}
                    count={unpaidUsers}
                    icon={<BsPersonDashFill className="icon-lg" />}
                  />
                </Link>{" "}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/dashboardUsers/successUsers`}>
                  <DashboardCard
                    title={"Success Stories"}
                    count={succeseUsers}
                    icon={<BsPeopleFill className="icon-lg" />}
                  />
                </Link>{" "}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/dashboardUsers/maleCount`}>
                  <DashboardCard
                    title={"Male Members"}
                    count={maleCount}
                    icon={<BsGenderMale className="icon-lg" />}
                  />
                </Link>{" "}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/dashboardUsers/femaleCount`}>
                  <DashboardCard
                    title={"Female Members"}
                    count={femaleCount}
                    icon={<BsGenderFemale className="icon-lg" />}
                  />
                </Link>{" "}
              </div>
              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/dashboardUsers/unpaidUsers`}>
                  <DashboardCard
                    title={"Regular"}
                    count={unpaidUsers}
                    icon={<BsPersonBadge className="icon-lg" />}
                  />
                </Link>{" "}
              </div> */}
              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/dashboardUsers/paidUsers`}>
                  <DashboardCard
                    title={"Prime"}
                    count={paidUsers}
                    icon={<BsPersonArmsUp className="icon-lg" />}
                  />
                </Link>{" "}
              </div> */}
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/dashboardUsers/todayCount`}>
                  <DashboardCard
                    title={"Today Joined"}
                    count={todayCount}
                    icon={<BsCalendar className="icon-lg" />}
                  />
                </Link>{" "}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/dashboardUsers/tenDaysCount`}>
                  <DashboardCard
                    title={"Last 10 days"}
                    count={tenDaysCount}
                    icon={<BsCalendar2 className="icon-lg" />}
                  />
                </Link>{" "}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/dashboardUsers/oneMonthCount`}>
                  <DashboardCard
                    title={"Last 30 days"}
                    count={oneMonthCount}
                    icon={<BsCalendar2Day className="icon-lg" />}
                  />
                </Link>{" "}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
               
                  <DashboardCard
                    title={"Last month earnings"}
                    count={oneMonthEarnings}
                    icon={<BsCashStack className="icon-lg" />}
                  />
      
              </div>
            </div>
          </Col>
        </Row>

        {getuser == "Data Not Found!" ? (
          <></>
        ) : (
          <>
            <h3 style={{ textTransform: "uppercase" }}>Recent Users</h3>
            <Row>
              {Array.isArray(getuser) && getuser != "Data Not Found!" ? (
                getuser.map((user, index) => (
                  <Usercard
                    key={index}
                    id={user.id}
                    profileId={user.profileId}
                    name={user.userName}
                    image={user.images}
                    email={user.email}
                    countryCode={user.countryCode}
                    phone={user.phone}
                    district={user.district}
                    state={user.state}
                    dob={user.dob}
                    gender={user.gender}
                    age={user.age}
                    status={user.status}
                    onActive={() => activeinactive(user)}
                    onDelete={() => deletee(user)}
                  />
                ))
              ) : (
                <div style={{ marginLeft: "500px", marginTop: "250px" }}>
                  <h3> Profile Not Found</h3>
                </div>
              )}
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard9;
