import React, { useState, useEffect } from "react";
import style from "../assets/css/navtab.module.css"
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import { useParams } from "react-router-dom";
import api from "../Axios";
import moment from "moment";

const AdminProfile = () => {
  const [data, setData] = useState("");

  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    api
      .get("/admin/profile/viewProfile")
      .then((res) => {
      
        setData(res.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  // function checkArray(data) {
  //   if (Array.isArray(data)) return data;
  //   else if (typeof data === "object") return [data];
  //   else return [];
  // }

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Admin Profile</h1>
              </div>
            </div>

            <div className="col-xl-12">
              <section className="box profile-page" style={{ width: "500px" }}>
                <div className="content-body">
                  <div className="col-12">
                    <div className="row uprofile">
                      <div>
                        <div className="uprofile-name col-xl-10 col-lg-9 col-md-9 col-sm-8 col-12">
                          <h3 className="uprofile-owner" style={{textTransform:"capitalize"}}>{data.username}</h3>

                          <div className="clearfix"></div>
                          <div className="row">
                            <div className="col-lg-4 col-md-5 col-sm-6">
                              <p>
                                <span> {data.email}</span>
                              </p>
                              <p>{data.phone}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* <div style={{backgroundColor:"white",padding:"25px"}}>
              <Nav tabs style={{paddingBottom: "2px" }}>
                <NavItem>
                  <NavLink className="active" id={style.tab1} style={activeTab===1?{color: "black",borderBottom:"2px solid black"}:{}}  onClick={() => setActiveTab(1)}>
                    Tab1
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="active" id={style.tab2} style={activeTab===2?{color: "black",borderBottom:"2px solid black"}:{}} onClick={() => setActiveTab(2)}>
                    Tab2
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="active" id={style.tab3} style={activeTab===3?{color: "black",borderBottom:"2px solid black"}:{}} onClick={() => setActiveTab(3)}>
                    Tab3
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent
                activeTab={`${activeTab}`}
                style={{borderColor:"#fff0", borderTop: "1px solid #fff9" }}
              >
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <h4>Tab 1 Contents</h4>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <h4>Tab 2 Contents</h4>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <h4>Tab 3 Contents</h4>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdminProfile;
